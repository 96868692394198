import { POST, GET } from "../../utils/request"
import request, { newRequest } from "../../utils/newRequest"
import { stringify } from "qs"

/**
 * 新增店内区域
 */
export const AddShopRegion = (params) =>
  POST("/data/center/v1/shop/region/add", params)

/**
 * 修改店内区域
 */
export const EditShopRegion = (params) =>
  POST("/data/center/v1/shop/region/edit", params)

/**
 * 获取店内区域分页列表
 */
export const ShopRegionListpage = (params) =>
  GET(`/data/center/v1/shop/region/pageList?${stringify(params)}`)

/**
 * 删除店内区域
 */
export const ShopRegionDelete = (params) =>
  GET(`/data/center/v1/shop/region/delete?${stringify(params)}`)

/**
 * 省市区树
 */
export const Area_Tree = () => GET(`/data/center/v1/index/area/tree`)

/**
 * 门店下拉列表
 */
export const Pull_list = (params) =>
  GET(`/data/center/v1/shop/pull/list?${stringify(params)}`)

/**
 * 组织机构新增
 */
export const District_Add = (params) =>
  POST("/data/center/v1/district/add", params)

/**
 * 组织机构编辑
 */
export const District_Edit = (params) =>
  POST("/data/center/v1/district/edit", params)

/**
 * 组织架构删除
 */
export const District_Delete = (id) =>
  GET(`/data/center/v1/district/delete?id=${id}`)

/**
 * 组织架构分级查询列表
 */
export const District_List = (parentId) =>
  GET(`/data/center/v1/district/list?parentId=${parentId}`)

/**
 * 组织架构关联门店
 */
export const District_Shop = (params) =>
  POST("/data/center/v1/district/relevancy/shop", params)

/**
 * 组织架构关联的门店列表
 */
export const District_ShopList = (params) =>
  GET(`/data/center/v1/district/relevancy/shop/list?${stringify(params)}`)

/**
 * 组织架构移除关联的门店
 */
export const District_ShopRemove = (params) =>
  POST("/data/center/v1/district/remove/shop", params)

/**
 * 组织架构树形列表
 */
export const District_TreeList = () => GET(`/data/center/v1/district/tree/list`)
