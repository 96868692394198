import React, { useEffect, useState, useRef } from "react"
import { Card, Button, Table, Modal, message, Select, Form } from "antd"
import * as ShopmanageDevice from "../../services/goodmanage"
import { QuestionCircleFilled } from "@ant-design/icons"
import PubInput from "../../../components/PubInput"
import styles from "./Index.module.css"

const { Option } = Select
function Index(props) {
  const [form] = Form.useForm()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [dataList, setDataList] = useState([]) //门店设备列表
  const [total, setTotal] = useState(0) //每页显示的行数
  const [exportTime, setExpportTime] = useState(0) //导入时间
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false) // 删除二次提示框
  const [editShowModal, setEditShowModal] = useState(false) // 编辑二次提示框
  const [GiveParams, setGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    code: undefined,
  })
  const [id, setId] = useState("") // 删除id
  const [editData, setEditData] = useState({}) // 编辑详情
  // 清除表单缓存
  useEffect(() => {
    setTimeout(() => {
      form.resetFields()
    })
  }, [editData])

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    // //调用获取表格列表数据
    setTimeout(getTableList, 0)
  }, [])

  /**
   * 自定义表格高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 获取表格列表数据
   */
  const getTableList = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const { code, msg, data } = await ShopmanageDevice.size_goods_list(
        GiveParamsRef
      )
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
      setExpportTime(data.importTime) // 导入时间
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  /**
   * 查询
   */
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getTableList, 0)
  }

  /**
   * 重置
   */
  const handClear = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      code: undefined,
    })
    setTimeout(getTableList, 0)
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getTableList, 0)
      },
    }
    return paginationProps
  }
  const columns = [
    {
      title: "主商品代码",
      dataIndex: "spuCode",
      ellipsis: { showTitle: true },
      key: "spuCode",
      width: "15%",
    },
    {
      title: "主商品条码",
      dataIndex: "spuBarCode",
      ellipsis: { showTitle: true },
      key: "spuBarCode",
      width: "15%",
    },
    {
      title: "主商品名称",
      dataIndex: "spuName",
      ellipsis: { showTitle: true },
      key: "spuName",
      width: "15%",
    },
    {
      title: "子商品代码",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },
      key: "skuCode",
      width: "15%",
    },
    {
      title: "子商品条码",
      dataIndex: "barCode",
      ellipsis: { showTitle: true },
      key: "barCode",
      width: "15%",
    },
    {
      title: "子商品名称",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },
      key: "skuName",
      width: "15%",
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      width: "15%",
      render: (info) => {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                setEditShowModal(true)
                setEditData(info)
              }}
            >
              编辑
            </Button>
            <Button
              type="link"
              onClick={() => {
                setShowModal(true)
                setId(info.id)
              }}
            >
              删除
            </Button>
          </div>
        )
      },
    },
  ]

  /**
   * 删除确定
   */
  const handleOk = async () => {
    try {
      const params = { id }
      const { code, data, msg } = await ShopmanageDevice.size_goods_delete(
        params
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        setShowModal(false)
        // //调用获取表格列表数据
        setTimeout(getTableList, 0)
      }
    } catch (error) {
      message.error(error)
    }
    setShowModal(false)
  }
  /**
   * 删除取消
   */
  const handleCancel = () => {
    setShowModal(false)
  }

  /**
   * 编辑取消
   */
  const onEditCancel = () => {
    setEditShowModal(false)
    setEditData({})
  }

  /**
   * 编辑确认提交
   */
  const onFinish = async (values) => {
    try {
      form.validateFields().then(async (values) => {
        try {
          const params = {
            ...values,
            id: editData.id,
          }
          const { code, data, msg } = await ShopmanageDevice.size_goods_edit(
            params
          )
          if (code != 200) {
            message.error(msg)
            return
          }
          if (data) {
            message.success("编辑成功")
            setEditShowModal(false)
            // //调用获取表格列表数据
            setTimeout(getTableList, 0)
          }
        } catch (error) {
          console.log(error)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Card>
        <div className={styles.look_import_head}>
          <div className={styles.look_import_head_body}>
            <div className={styles.look_import_head_search_body_child}>
              <span>主或子商品名称/条码/代码：</span>
              <PubInput
                onChange={(val) => setGiveParams({ ...GiveParams, code: val })}
                width="250px"
                placeholder="主或子商品名称/条码/代码"
                value={GiveParams.code}
              />
            </div>
          </div>
          <div className={styles.look_import_head_button_record}>
            <div>
              <Button type="primary" onClick={() => handSearch()}>
                查询
              </Button>
              <Button onClick={() => handClear()}>重置</Button>
            </div>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <div className={styles.look_import_head_exporttime}>
          <span>仅展示最近一次的导入记录</span>
          <span>导入时间{exportTime}</span>
        </div>
        <Table
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          scroll={{
            y: `${WinHeight - 450}px`,
          }}
        />
      </Card>

      {/* 删除二次提示框 */}
      <Modal
        title="系统提示"
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        keyboard
      >
        <div>
          <QuestionCircleFilled
            style={{ color: "#E6A23C", marginRight: "10px" }}
          />
          删除后将不可恢复，确定要删除吗？
        </div>
      </Modal>

      {/* 编辑二次提示框 */}
      <Modal
        title="编辑"
        open={editShowModal}
        onOk={onFinish}
        onCancel={onEditCancel}
        keyboard
      >
        <div>
          <Form
            form={form}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 10 }}
            onFinish={onFinish}
            initialValues={editData}
          >
            <Form.Item
              label="主商品代码:"
              name="spuCode"
              rules={[{ required: true, message: "主商品代码不能为空!" }]}
            >
              <PubInput placeholder="主商品代码/条码/代码！" />
            </Form.Item>
            <Form.Item label="主商品条码:" name="spuBarCode">
              <PubInput placeholder="主商品条码！" />
            </Form.Item>
            <Form.Item
              label="主商品名称:"
              name="spuName"
              rules={[{ required: true, message: "主商品名称不能为空!" }]}
            >
              <PubInput placeholder="主商品名称！" />
            </Form.Item>
            <Form.Item
              label="子商品代码:"
              name="skuCode"
              rules={[{ required: true, message: "子商品代码不能为空!" }]}
            >
              <PubInput placeholder="主商品代码！" />
            </Form.Item>
            <Form.Item label="子商品条码:" name="barCode">
              <PubInput placeholder="子商品条码！" />
            </Form.Item>
            <Form.Item
              label="子商品名称:"
              name="skuName"
              rules={[{ required: true, message: "子商品名称不能为空!" }]}
            >
              <PubInput placeholder="子商品名称！" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default Index
