import React, { useEffect, useState, useRef } from "react"
import { Button, Card, message, Select, Radio, Input } from "antd"
import * as OrphanServer from "../../../services/orphan"
import PubInput from "../../../../components/PubInput"
import styles from "./Index.module.css"

const { Option } = Select

function Index() {
  const [shopList, setShopList] = useState([]) // 门店列表
  const [goodsRule, setGoodsRule] = useState([]) // 商品图片库icon列表
  const [ParamsInfo, setParamsInfo] = useState({
    name: undefined, // 规则名称
    shopIdList: [], // 适用门店
    skuList: [], // 商品plu
    timeoutMinute: "", // 超时时间
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  /**
   * 初始化
   */
  useEffect(() => {
    // 门店列表
    getShopList()
    //请求商品图片库icon列表接口
    getIconList()
  }, [])

  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await OrphanServer.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        data?.list.unshift({
          shopName: "全部",
          shopId: "0",
        })
        setShopList(data?.list)
      } else {
        setShopList([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 商品图片库icon列表接口
   */
  const getIconList = async () => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 500,
      }
      const { code, msg, data } = await OrphanServer.IconList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        data.list.map((v) => {
          v.showTitle = v?.skuCode + "-" + v.skuName
        })
        setGoodsRule(data.list)
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 商品图片库icon列表接口 查询
   */
  const getIconListSearch = async (value) => {
    try {
      const params = {
        code: value,
        pageNum: 1,
        pageSize: 500,
      }
      const { code, msg, data } = await OrphanServer.IconList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        let NewArr = goodsRule
        var obj = {}
        var newarr = []
        NewArr = data?.list.concat(NewArr)
        for (var i = 0; i < NewArr.length; i++) {
          NewArr[i].showTitle = NewArr[i]?.skuCode + "-" + NewArr[i]?.skuName
          if (!obj[NewArr[i].skuCode] && !obj[NewArr[i].skuName]) {
            newarr.push(NewArr[i])
            obj[NewArr[i].skuCode] = true
          }
        }
        setGoodsRule(newarr)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 保存
   */
  const onSaveSet = async () => {
    try {
      if (!ParamsInfo?.name) {
        message.warning("请输入规则名称")
        return
      }
      if (!ParamsInfo?.shopIdList || ParamsInfo?.shopIdList.length == 0) {
        message.warning("请选择适用门店")
        return
      }
      if (!ParamsInfo?.skuList || ParamsInfo?.skuList.length == 0) {
        message.warning("请选择商品plu")
        return
      }
      if (!ParamsInfo?.timeoutMinute) {
        message.warning("请输入超时时间")
        return
      }
      if (ParamsInfo?.timeoutMinute < 5) {
        message.warning("设置超时时间不能小于5分钟")
        return
      }
      let params = ParamsInfo
      if (ParamsInfo.shopIdList[0] == 0) {
        params = {
          ...ParamsInfo,
          shopIdList: [],
        }
      }

      const { code, data, msg } = await OrphanServer.orphan_good_add(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("新增规则成功")
        window.history.back(-1)
      }
    } catch (error) {
      console.log(error)
      // message.error("保存失败")
    }
  }

  // 新建防损规则
  return (
    <Card>
      <div className={styles.add_loss_body}>
        {/* 规则名称 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          规则名称：
          <PubInput
            onChange={(val) => {
              setParamsInfo({
                ...ParamsInfo,
                name: val,
              })
            }}
            placeholder="规则名称"
            value={ParamsInfo.name}
            width={500}
            showCount={true}
            max={20}
          />
        </div>

        {/* 适用门店 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          适用门店：
          <Select
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="请选择适用门店"
            style={{ width: 500 }}
            allowClear
            value={ParamsInfo.shopIdList}
            showSearch
            filterOption={(i, o) => o.props.children.includes(i)}
            onChange={(e) => {
              if (e.length > 0 && e.includes("0")) {
                setParamsInfo({
                  ...ParamsInfo,
                  shopIdList: ["0"],
                })
              } else {
                setParamsInfo({
                  ...ParamsInfo,
                  shopIdList: e || [],
                })
              }
            }}
          >
            {shopList.map((item, index) => {
              return (
                <Option value={item?.shopId} key={index}>
                  {item?.shopName}
                </Option>
              )
            })}
          </Select>
        </div>

        {/* 商品plu： */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          商品plu ：
          <Select
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            filterOption={(i, o) => o.props.children.includes(i)}
            placeholder="请选择自定义商品plu"
            style={{ width: 500 }}
            allowClear
            value={ParamsInfo.skuList}
            showSearch
            onSearch={(e) => {
              if (e.length > 0) {
                getIconListSearch(e)
              }
            }}
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                skuList: e,
              })
            }}
          >
            {goodsRule.map((item, index) => {
              return (
                <Option value={item?.skuCode} key={index}>
                  {item?.showTitle}
                </Option>
              )
            })}
          </Select>
        </div>

        {/* 超时时间 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          超时时间：
          <div className={styles.add_loss_body_child_input}>
            打标签超时
            <Input
              addonAfter="分"
              placeholder="请设置"
              style={{ width: "150px", padding: "0 10px" }}
              value={ParamsInfo.timeoutMinute}
              onChange={(e) => {
                // 使用正则表达式移除非数字字符
                let newValue = e.target.value.replace(/[^0-9]/g, "")
                setParamsInfo({
                  ...ParamsInfo,
                  timeoutMinute: newValue,
                })
              }}
              // onBlur={(e) => {
              //   let NewStr = e.target.value || ""
              //   if (NewStr && NewStr < 30) {
              //     setParamsInfo({
              //       ...ParamsInfo,
              //       timeoutMinute: "",
              //     })
              //     message.warning("设置超时时间”不能小于30分钟")
              //   }
              // }}
            />
            未收银，视为孤儿商品
          </div>
        </div>

        {/* 确定取消 */}
        <div className={styles.add_loss_body_child}>
          <Button type="primary" onClick={onSaveSet}>
            确定
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => {
              window.history.back(-1)
            }}
          >
            取消
          </Button>
        </div>
        {/* </Card> */}
      </div>
    </Card>
  )
}

export default Index
