import React, { useState, useEffect, useRef } from "react"
import {
  Button,
  message,
  Upload,
  Checkbox,
  Card,
  Modal,
  Form,
  Radio,
  Popconfirm,
  Select,
  List,
  Image as NewImage,
  Tooltip,
  Pagination,
} from "antd"
import * as shopingSever from "../../services/shoping"
import md5 from "md5"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import * as qiniu from "qiniu-js"
import PromiseQueue from "../../../tools/queue"
import styles from "./Index.module.css"
import { urlToFileObject } from "../../../utils/download"
import { isClickableInput } from "@testing-library/user-event/dist/utils"
import {
  parseURL,
  ReplaceFilePathBase,
  CalcFilePathMD5,
} from "../../../utils/filepath.ts"
import PubInput from "../../../components/PubInput"
import { DataPullList, ClearnImgData } from "../../services/datasetof"
const { Option } = Select

var doneFiles = []
let uploadQueue = new PromiseQueue({ concurrency: 4 })
function Index() {
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度

  const [token, setToken] = useState("") //上传token
  const [uid, setUid] = useState([]) //uid
  const [nowInfo, setNowInfo] = useState({ skuCode: "label" }) //当前编辑的行数据
  const [domain, setDomain] = useState("") //域名
  const [imgUrl, setImgUrl] = useState("") //上传成功后返回的图片路径
  const [delShow, setDelShow] = useState(false) //删除功能弹窗状态
  const [labelShow, setLabelShow] = useState(false) //应用弹窗状态
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(10) //每页显示的行数

  //批量上传
  const [fileList, setFileList] = useState([]) //上传图片的列表
  const [successUploadList, setSuccessUploadList] = useState([]) //这里是批量上传图片成功的列表
  const [errUploadList, setErrUploadList] = useState([]) //这里是批量上传图片失败的列表
  const [isModalOpen, setIsModalOpen] = useState(false) //modal打开关闭状态
  const [uploadDoneFiles, setUploadDoneFiles] = useState(new Map()) //名

  const [uploadId, setUploadId] = useState("") //编辑时存的id

  const [isSearch, setIsSearch] = useState(false) //是否查询

  //数据展示
  const [iconList, setIconList] = useState([]) //icon列表
  const [total, setTotal] = useState(0) //icon列表数据总数
  const [code, setCode] = useState(undefined) //关键词
  const [ids, setIds] = useState([]) //删除时的id数组
  const [delTitle, setDelTitle] = useState("") //区分单删还是多删的文本
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [form] = Form.useForm()

  const [order, setOrder] = useState(undefined) // 识别排序 ascend:正序2   descend:倒叙1

  //应用标签
  const [lableList, setLableList] = useState([]) //标签列表
  const [lableName, setLableName] = useState(undefined) //标签名
  const [lableNInfo, setLableNInfoe] = useState({}) //inco详情数据
  const [dropArea, setDropArea] = useState(1) //放置区域1:左下角，2：右下角
  const [lableRadio, setLableRadio] = useState(undefined) //选择标签radio
  const [notWater, setNotWater] = useState("") //无水印原图
  // 选中的标签url
  const [iconUrl, setIconUrl] = useState("")

  //
  const [dataSetList, setDataSetList] = useState([]) // 数据汇总下拉列表
  const [dataSetOf, setDataSetOf] = useState(undefined) //数据汇总选中value
  const [modalDataSetOf, setModalDataSetOf] = useState(undefined) //Modal 内的数据汇总选中value
  // 全选
  const [allcheck, setAllCheck] = useState(false) // 全选
  const [often, setOften] = useState(1) //列表数据复选框选中时时时刷新列表数据

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    pageNoRef: "",
    codeRef: "",
    lableRadioRef: "",
    dropAreaRef: "",
    isSearchRef: "",
    lableName: "",
    orderTypeRef: "",
    dataSetOfRef: "", // 数据汇总选中value
    // iconListRef: [],
    iconUrlRef: "",
  })
  useEffect(() => {
    updataRef.current = {
      pageNoRef: pageNum,
      codeRef: code,
      dataSetOfRef: dataSetOf,
      lableRadioRef: lableRadio,
      dropAreaRef: dropArea,
      isSearchRef: isSearch,
      lableNameRef: lableName,
      orderType: order,
      iconUrlRef: iconUrl,
      // iconListRef: iconList,
    }
  }, [
    pageNum,
    code,
    dataSetOf,
    lableRadio,
    dropArea,
    isSearch,
    lableName,
    order,
    iconUrl,
  ])

  useEffect(() => {
    setTimeout(getIconList, 0)
  }, [order])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  //列表内的复选框点击时会触发这这个钩子
  useEffect(() => {
    if (iconList.length > 0) {
      setAllCheck(
        iconList.every((v) => {
          return v.checked === true
        })
      )
    }
  }, [often])

  useEffect(() => {
    //调用获取token接口
    gettoken()

    //调用商品图片库icon列表
    getIconList()

    // 数据汇总下拉接口
    getDataPullList()

    setUid(window.localStorage.getItem("uid").toString().padStart(8, "0"))
  }, [])

  /**
   * 数据汇总下拉接口
   */
  const getDataPullList = async () => {
    try {
      const { code, data, msg } = await DataPullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDataSetList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  //商品图片库icon列表
  const getIconList = async () => {
    try {
      setLoading(true)
      let _isSearch = updataRef.current.isSearchRef
      let _isdataSetOf = updataRef.current.dataSetOfRef
      const params = {
        code: _isSearch ? updataRef.current.codeRef : "",
        dataSetOf: _isdataSetOf ? updataRef.current.dataSetOfRef : "",
        pageNum: updataRef.current.pageNoRef,
        orderType: updataRef.current.orderType,
        pageSize: pageSize,
        dataSetId: _isdataSetOf,
      }
      const { code, msg, data } = await shopingSever.Icon_List(params)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      if (data) {
        // 给列表数据增加字段，复选框选中的字段
        if (data.list.length > 0) {
          setLoading(false)
          setIconList(data.list)
          setTotal(data.total)
        } else {
          setLoading(false)
          setIconList([])
          setTotal(0)
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  //获取token
  const gettoken = async () => {
    try {
      const { code, data, msg } = await shopingSever.Get_Token()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setToken(data.token)
        setDomain(data?.domain || "")
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 限制宽高类型
  const handleFilebeforeUpload = (file) => {
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader()
      filereader.onload = (e) => {
        let src = e.target.result
        const image = new Image()
        image.onload = function () {
          // console.log(file)
          if (
            file.size < 512000
            // file.type === "image/png" ||
            // file.type === "image/jpeg" ||
            // file.type === "image/jpg"
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve("上传成功")
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = src
      }
      filereader.readAsDataURL(file)
    })
  }

  // 上传
  const uploadProps = {
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(e)
        fileReader.onload = (ee) => {
          var _md5only = md5(ee.target.result)
          resolve({
            token,
            key: `icons/project/${uid}/${nowInfo?.skuCode}_${_md5only}.${
              e.type.split("/")[1]
            }`,
          })
        }
        fileReader.onerror = (err) => {
          reject(err)
        }
      })
    },
    beforeUpload: async (e) => {
      try {
        const sizeQualified = await handleFilebeforeUpload(e)
        // message.success(sizeQualified)
        return true
      } catch (err) {
        message.error("不可大于500kb")
        return false
      }
    },
    onChange: async (info) => {
      try {
        const _file = info.file
        if (_file.status === "done") {
          setImgUrl(domain + "" + _file?.response?.key || "")
          const params = {
            id: uploadId,
            iconUrl: domain + "" + _file?.response?.key || "",
          }
          const { code, data, msg } = await shopingSever.Icon_Edit(params)
          if (code != 200) {
            message.error(msg)
            return
          }
          if (data) {
            message.success("icon编辑成功")
            setTimeout(getIconList, 0)
          }
        }
        if (_file.status === "error") {
          message.error(_file?.response.error)
        }
      } catch (error) {
        console.log(error)
      }
    },
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  // 图片上传**********************************************************************************************************************************

  const showModal = () => {
    setFileList([]) //清空初始化上传图片列表（包含成功与上传中）
    setSuccessUploadList([]) //清空上传成功列表
    setErrUploadList([]) //清空上传失败列表
    setIsModalOpen(true)
    setModalDataSetOf(undefined) // 清空Modal 内的数据汇总选中value
    doneFiles = [] //打开导入icon-modal时，先将存储图片uid和路径的全局数组清空
  }

  const handleChange = (info) => {
    let newFileList = info.fileList.map((file) => {
      if (uploadDoneFiles.has(file.uid)) {
        let d = uploadDoneFiles.get(file.uid)
        file.status = d.status
        if (d.status === "done") {
          file.key = d.key
          file.url = domain + "" + file.key
        }
      }
      return file
    })

    let successUpload = [] //这里是为了拿到上传成功的数组
    let errUpload = [] //这里是为了拿到上传失败的数组
    newFileList.map((v) => {
      if (v?.status == "done") {
        successUpload.push(v)
      } else if (v?.status == "error") {
        errUpload.push(v.name)
      }
    })
    setSuccessUploadList([...successUpload]) //上传成功后存储
    setErrUploadList([...errUpload]) //上传失败后存储

    setFileList([...newFileList])
    return true
  }
  // 获取文件流md5
  const getFileMd5 = (file) => {
    return new Promise(function (resolve, reject) {
      const fileReader = new FileReader()
      // fileReader.readAsBinaryString(file)
      fileReader.onload = (e) => {
        const image = new Image()
        image.onload = function () {
          console.log(file)
          if (
            (file.type === "image/png" ||
              file.type === "image/jpeg" ||
              file.type === "image/jpg") &&
            file.size < 512000
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve(md5(e.target.result))
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = e.target.result
      }
      fileReader.onerror = (err) => {
        reject(err)
      }

      fileReader.readAsDataURL(file)
    })
  }

  const fileListProps = {
    action: "https://up-z0.qiniup.com",
    onChange: handleChange,
    multiple: true,
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        resolve({
          token,
          key: `icons/project/${uid}/${
            e.name.split(".")[0]
          }_${new Date().getTime()}.${e.type.split("/")[1]}`,
        })
      })
    },
    beforeUpload: (e) => {},
    //批量上传自定义
    customRequest: (options) => {
      const { onSuccess, onError, file, onProgress } = options
      getFileMd5(file)
        .then((md5) => {
          uploadQueue.add(
            () =>
              new Promise((resolve) => {
                const key = `icons/project/${uid}/${
                  file.name.split(".")[0]
                }_${md5}.${file.type.split("/")[1]}`
                const observable = qiniu.upload(
                  file,
                  key,
                  options.data.token,
                  {},
                  {}
                )
                const subscription = observable.subscribe(
                  (event) => {
                    onProgress(
                      { percent: (event.loaded / event.total) * 100 },
                      file
                    )
                  },
                  (err) => {
                    console.log("error", err)
                    setUploadDoneFiles(
                      uploadDoneFiles.set(file.uid, {
                        key: "",
                        status: "error",
                      })
                    )
                    resolve()
                  },
                  (res) => {
                    setTimeout(function () {
                      onSuccess(file)
                    }, 1000)

                    setUploadDoneFiles(
                      uploadDoneFiles.set(file.uid, {
                        key: res.key,
                        status: "done",
                      })
                    )
                    resolve()
                  }
                )
              })
          )
        })
        .catch((err) => {
          console.log(err)
          message.error(err)
          setUploadDoneFiles(
            uploadDoneFiles.set(file.uid, {
              key: "",
              status: "error",
            })
          )
          onError(err)
        })
    },
  }

  //下载到本地
  const download = (name, href) => {
    let element = document.createElement("a")
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(errUploadList.join("\n"))
    )
    element.setAttribute("download", "图片上传失败名")
    element.style.display = "none"
    element.click()
  }

  //modal提交
  const handleOk = async () => {
    try {
      // modalDataSetOf
      let newiIonList = []
      successUploadList.map((v) => {
        v.iconUrl = v.url
        v.skuCode = v.name.split(".")[0]
        newiIonList.push({ iconUrl: v.iconUrl, skuCode: v.skuCode })
      })
      const params = {
        iconList: newiIonList,
        dataSetId: modalDataSetOf,
      }
      if (!modalDataSetOf) {
        message.error("请选择数据汇总")
        return
      }
      const { code, data, msg } = await shopingSever.Icon_Add(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setIsModalOpen(false)
        setTimeout(getIconList, 0)
        message.success("导入成功")
      }
    } catch (error) {
      console.log(error)
    }
  }
  //*********************************************************************************************************************************** */

  // 应用标签按钮方法
  const handUseLable = (info) => {
    console.log(info)
    setLableRadio(undefined)
    setIconUrl(undefined)
    setLabelShow(true)
    setLableNInfoe(info)
    setLableName(undefined)
    setTimeout(getLableList, 0)
    setDropArea("NorthWest")
    form.setFieldsValue({
      twotwo: undefined,
      three: "NorthWest",
    })
  }

  //选择标签请求水印接口
  const handCreateImg = async () => {
    try {
      const params = {
        gravity: updataRef.current.dropAreaRef,
        labelId: updataRef.current.lableRadioRef,
        labelType: 1,
        id: lableNInfo.id,
        watermarkUrl: updataRef.current.iconUrlRef, // 标签图
        imageUrl: lableNInfo?.originalIconUrl, // 原图
      }
      setNotWater(lableNInfo.iconUrl)
      const { code, data, msg } = await shopingSever.Icon_Watermark(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setLableNInfoe({ ...lableNInfo, iconUrl: data.url })
    } catch (error) {
      console.log(error)
    }
  }

  //应用标签提交
  const onFinish = async () => {
    try {
      const values = await form.validateFields()

      // 解析URL
      let urlObj = parseURL(notWater)
      // console.log("新的解析", urlObj)
      // 相对路径就是旧的文件名
      let oldFileName = urlObj.relative
      // 七牛云需要移除前置'/'
      if (oldFileName.indexOf("/") == 0) {
        oldFileName = oldFileName.substring(1)
      }
      // console.log("oldFileName", oldFileName)
      // 新的带水印图片的文件名
      const newFileName = ReplaceFilePathBase(
        oldFileName,
        CalcFilePathMD5(oldFileName)
      )
      // console.log("newFileName", newFileName)
      //获取file文件
      const fileObject = await urlToFileObject(
        lableNInfo.iconUrl.replace("http://", "https://"),
        newFileName
      )
      // console.log("URL转File对象完成: ", fileObject)
      // 上传水印图片到七牛云
      qiniu.upload(fileObject, newFileName, token, {}, {}).subscribe({
        next(res) {
          console.log("上传进度：", res)
        },
        error(err) {
          console.log("上传失败：", err)
        },
        complete: async (res) => {
          console.log(res)
          const urlPath = `${urlObj.scheme}://${urlObj.host}/${res.key}`
          // console.log("上传完成：", urlPath)
          const params = {
            iconUrl: urlPath,
            id: lableNInfo.id,
            labelId: values.twotwo,
          }
          const { code, data } = await shopingSever.Icon_Edit(params)
          if (code == 200) {
            if (data) {
              setLabelShow(false)
              setTimeout(getIconList, 0)
              message.success("标签应用成功")
            }
          }
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  //标签列表数据
  const getLableList = async () => {
    try {
      const params = {
        name: updataRef.current.lableNameRef,
        dataSetOf: updataRef.current.dataSetOfRef,
        pageNum: 1,
        pageSize: 10000,
      }
      const { code, data, msg } = await shopingSever.Label_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setLableList(data.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 清空图片数据
   */
  const onClearnImgData = async () => {
    try {
      const _isdataSetOf = updataRef.current.dataSetOfRef
      const { code, msg, data } = await ClearnImgData(_isdataSetOf)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("图片数据清除成功")
        setPageNum(1)
        setTimeout(getIconList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.shopurllirrary_page}>
      {/* 顶部查询 */}
      <Card>
        <div className={styles.shopurllirrary_page_head}>
          <div style={{ display: "flex" }}>
            {/* 关键词 */}
            <div className={styles.shopurllirrary_head_search}>
              <span>关键词：</span>
              <PubInput
                onChange={(val) => {
                  setCode(val)
                  // 表格数据清空选中
                  setSelectedRowKeys([])
                }}
                placeholder="关键词"
                value={code}
              />
            </div>
            {/* 数据汇总 */}
            <div
              className={styles.shopurllirrary_head_search}
              style={{ marginLeft: "20px" }}
            >
              <span>数据汇总：</span>
              <Select
                style={{ width: 200 }}
                placeholder="请选择数据汇总"
                value={dataSetOf}
                allowClear
                onChange={(e) => {
                  setDataSetOf(e)
                  setModalDataSetOf(e)
                  // setTimeout(getIconList, 0)
                  // // 表格数据清空选中
                  // setSelectedRowKeys([])
                }}
              >
                {dataSetList.map((v) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setPageNum(1)
                setTimeout(getIconList, 0)
                setIsSearch(true)
              }}
            >
              查询
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setIsSearch(false)
                setCode(undefined)
                setDataSetOf(undefined)
                setModalDataSetOf(undefined)
                setPageNum(1)
                setTimeout(getIconList, 0)
                setSelectedRowKeys([])
                // setOrder(undefined)
              }}
            >
              重置
            </Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        {/* 删除，导入图片，导入设备，标签管理 */}
        <div className={styles.shopurllirrary_page_operate}>
          <div className={styles.shopurllirrary_operate_del}>
            {/* 批量删除 */}
            <Button
              type="primary"
              danger
              style={{ marginRight: "20px" }}
              disabled={
                !iconList.some((v) => {
                  return v.checked == true
                })
              }
              onClick={() => {
                let NewIds = []
                iconList.map((v) => {
                  if (v.checked) {
                    NewIds.push(v.id)
                  }
                })
                setIds(NewIds) //删除时存取选中的id，存入集合
                setDelTitle("所选商品")
                setDelShow(true)
              }}
            >
              批量删除商品图片
            </Button>
            {/* 全选复选框 */}
            <Checkbox
              checked={allcheck}
              onChange={(e) => {
                setAllCheck(e.target.checked)
                iconList.map((v) => {
                  v.checked = e.target.checked ? true : false
                })
                setIconList(iconList)
              }}
            >
              全选
            </Checkbox>
          </div>
          <div className={styles.shopurllirrary_operate_btn}>
            {/* 清空图片数据 */}
            <Popconfirm
              title="确定要清空图片数据吗?"
              onConfirm={onClearnImgData}
              onCancel={() => {}}
              okText="确定"
              cancelText="取消"
            >
              <Button danger disabled={dataSetOf ? false : true}>
                清空图片数据
              </Button>{" "}
            </Popconfirm>
            <Link
              to={{
                pathname: `/admin/goodsmanage/subscriptmanage`,
                state: 132,
              }}
            >
              <Button style={{ marginLeft: "20px" }}>角标管理</Button>
            </Link>
            {/* 导入商品图片 */}
            <Button
              type="primary"
              onClick={showModal}
              style={{ marginLeft: "20px" }}
            >
              导入商品图片
            </Button>
          </div>
        </div>
        {/* 标签，图片列表 */}
        <div className={styles.shopurllirrary_list_data_body}>
          {iconList.map((v, i) => {
            return (
              <div
                className={styles.shopurllirrary_list_data_body_item}
                key={i}
              >
                <div className={styles.shopurllirrary_list_data}>
                  <div className={styles.shopurllirrary_list_data_top_body}>
                    <div className={styles.shopurllirrary_list_data_top_check}>
                      <Checkbox
                        style={{ margin: "0 10px 5px 0 " }}
                        checked={v.checked}
                        onClick={() => {
                          setOften(often + 1)
                        }}
                        onChange={(e) => {
                          iconList.map((w) => {
                            if (w.id === v.id) {
                              v.checked = e.target.checked
                            }
                          })
                          setIconList(iconList)
                        }}
                      />
                    </div>
                    <div className={styles.shopurllirrary_list_data_top}>
                      <NewImage
                        src={v.iconUrl}
                        width={200}
                        height={150}
                        style={{ marginTop: "13px" }}
                      />
                    </div>
                  </div>

                  {/* title 与 plu */}
                  <div className={styles.shopurllirrary_list_data_bottom}>
                    <div
                      className={styles.shopurllirrary_list_data_bottom_skuName}
                    >
                      商品名称：
                      <Tooltip title={v.skuName}>
                        <span>{v.skuName}</span>
                      </Tooltip>
                    </div>
                    <div
                      className={styles.shopurllirrary_list_data_bottom_skuName}
                    >
                      PLU：
                      <Tooltip title={v.skuCode}>
                        <span>{v.skuCode}</span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={styles.shopurllirrary_list_data_function}>
                  <Button type="link" onClick={() => handUseLable(v)}>
                    修改角标
                  </Button>
                  <Upload
                    action="https://up-z0.qiniup.com"
                    name="file"
                    showUploadList={false}
                    multiple={false}
                    {...uploadProps}
                    onClick={() => {
                      setUploadId(v.id)
                    }}
                  >
                    <Button type="link">编辑图片</Button>
                  </Upload>
                  <Button
                    type="link"
                    onClick={() => {
                      setDelShow(true)
                      setIds([v.id])
                      setDelTitle(v.skuCode + "" + v.skuName)
                    }}
                  >
                    删除
                  </Button>
                </div>
              </div>
            )
          })}
          {Array.from(new Array(10)).map(() => {
            return (
              <div
                className={styles.shopurllirrary_list_data_body_item_null}
              ></div>
            )
          })}
        </div>
        {total > 0 ? null : (
          <div className={styles.shopurllirrary_list_data_body_empty}>
            <div className={styles.ant_empty_image}>
              <svg
                width="64"
                height="41"
                viewBox="0 0 64 41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                  <ellipse
                    fill="#f5f5f5"
                    cx="32"
                    cy="33"
                    rx="32"
                    ry="7"
                  ></ellipse>
                  <g fillRule="nonzero" stroke="#d9d9d9">
                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      fill="#fafafa"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            <div
              className={styles.ant_empty_description}
              style={{ color: "#bfbfbf" }}
            >
              暂无数据
            </div>
          </div>
        )}
        {total > 0 ? (
          <div className={styles.shopurllirrary_pagination}>
            <Pagination
              showTotal={() => `共${total}条`}
              pageSize={pageSize}
              current={pageNum}
              total={total}
              showSizeChanger={false}
              showQuickJumper={false}
              onChange={(current) => {
                setIconList([]) //将存列表的字段置空
                setPageNum(current)
                setAllCheck(false) //复选框全选置为false
                setOften(1) //归一
                setTimeout(getIconList, 0)
              }}
            />
          </div>
        ) : null}
      </Card>

      {/* 删除二次提示框 */}
      <Modal
        open={delShow}
        destroyOnClose
        keyboard
        title="系统提示"
        onCancel={() => {
          setDelShow(false)
          setIds([])
        }}
        onOk={async () => {
          try {
            const params = { ids: ids }
            const { code, data, msg } = await shopingSever.Icon_Del(params)
            if (code != 200) {
              message.error(msg)
              return
            }
            if (data) {
              message.success("删除成功")
              setDelShow(false)
              setTimeout(getIconList, 0)
              setSelectedRowKeys([])
            }
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <div className={styles.shopurllirrar_del}>
          <QuestionCircleOutlined
            style={{ marginRight: "10px", color: "#E6A23C" }}
          />
          删除后将不可恢复，确认删除{delTitle}
          的图片吗？
        </div>
      </Modal>
      {/* 应用标签 */}
      <Modal
        open={labelShow}
        destroyOnClose
        width={800}
        keyboard
        title="修改角标"
        onCancel={() => {
          setLabelShow(false)
        }}
        onOk={onFinish}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          // initialValues={{
          //   three: 1,
          // }}
        >
          <Form.Item label="商品icon" name="one">
            <div className={styles.shopurllirrar_form_img}>
              <img src={lableNInfo?.iconUrl} />{" "}
            </div>
          </Form.Item>
          <div>
            <div className={styles.shopurllirrar_form_input}>
              <PubInput
                onChange={(val) => setLableName(val)}
                placeholder="角标关键词"
                value={lableName}
                allowClear={true}
                max={15}
              />
              <Button
                style={{ marginLeft: "10px" }}
                type="primary"
                onClick={getLableList}
              >
                查询
              </Button>
            </div>
            <Form.Item
              label="关键词"
              name="twotwo"
              rules={[
                {
                  required: true,
                  message: "请选择角标!",
                },
              ]}
              className={styles.shopurllirrar_form_label}
            >
              <Radio.Group className={styles.shopurllirrar_form_label_radio}>
                {lableList.map((v) => {
                  return (
                    <Radio
                      value={v.id}
                      key={v.id}
                      onChange={(e) => {
                        // v.iconUrl
                        setIconUrl(v.iconUrl)
                        setLableRadio(e.target.value)
                        setTimeout(handCreateImg, 0)
                      }}
                    >
                      <div className={styles.shopurllirrar_form_radioimg}>
                        <img src={v.iconUrl} />
                      </div>
                    </Radio>
                  )
                })}
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item
            label="放置区域"
            name="three"
            rules={[
              {
                required: true,
                message: "请选择放置区域!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                if (!lableRadio) {
                  message.error("请选择标签")
                  return false
                }
                setDropArea(e.target.value)
                setTimeout(handCreateImg, 0)
              }}
            >
              <Radio value={"NorthWest"}>左上角</Radio>
              <Radio value={"SouthWest"}>左下角</Radio>
              <Radio value={"NorthEast"}>右上角</Radio>
              <Radio value={"SouthEast"}>右下角</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      {/* 批量上传 */}
      <Modal
        open={isModalOpen}
        destroyOnClose
        keyboard
        width={800}
        title="导入图片"
        onCancel={() => {
          setIsModalOpen(false)
        }}
        // onOk={() => {}}
        footer={[]}
      >
        <div className="page_goodmodal">
          <div className={styles.goodmanage_modal_title1}>
            <div style={{ marginLeft: "20px" }}>
              规则说明：商品图片必须以商品编码命名，且仅支持JPG、PNG格式，建议尺寸比例16:9，大小不超过500kB
            </div>
          </div>
          <div className={styles.sbcpsjdlj}>
            <span className={styles.sbcpsjdlj_span}>商品图片</span>
            <Upload {...fileListProps} fileList={fileList}>
              <a style={{ color: "#2EB998" }}>点击上传</a>
            </Upload>
          </div>
          {fileList.length == 0 ? (
            <div className={styles.svcpftp}>暂无图片</div>
          ) : null}

          <div className={styles.goodmodal_foot}>
            {successUploadList.length > 0 || errUploadList.length > 0 ? (
              <div className={styles.sbcp}>
                <div style={{ color: "#999999" }}>
                  {successUploadList.length}张 上传成功
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ color: "#999999" }}>
                    {errUploadList.length}张 上传失败
                  </div>

                  <a
                    disabled={errUploadList.length == 0 ? true : false}
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      if (errUploadList.length == 0) {
                        return false
                      }
                      download()
                    }}
                  >
                    下载到本地
                  </a>
                </div>
              </div>
            ) : null}
            <div className={styles.sbcp}>
              <div>
                <span>数据汇总：</span>
                <Select
                  style={{ width: 200 }}
                  placeholder="请选择数据汇总"
                  value={modalDataSetOf}
                  onChange={(e) => {
                    setModalDataSetOf(e)
                  }}
                >
                  {dataSetList.map((v) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    )
                  })}
                </Select>
              </div>

              <div>
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    setIsModalOpen(false)
                  }}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  onClick={handleOk}
                  disabled={successUploadList.length == 0 ? true : false}
                >
                  导入
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default Index
