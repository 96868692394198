import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import moment from "moment"
import request, { newRequest } from "../../utils/newRequest"

/**
 * 设备数据统计-设备在线情况
 */
export const GetDeviceStatus = (params) =>
  GET(`/data/center/v1/device/getDeviceStatusCollection`)

/**
 * 商品数据统计导出
 */
// export const GoodsDataExport = (params) =>
//   GET(`/data/center/v1/index/goods/data/excel?${stringify(params)}`)
export async function GoodsDataExport(params) {
  const defaultFileName = `商品数据统计${moment().format("YYYYMMDD")}.csv`
  return newRequest(
    `/data/center/v1/index/goods/data/excel?${stringify(params, {
      arrayFormat: "repeat",
    })}`,
    {
      method: "GET",
      responseType: "blob",
      defaultFileName: defaultFileName,
    }
  )
}

/**
 * 门店数据统计导出
 */
// export const ShopDataExport = (params) =>
//   GET(`/data/center/v1/index/shop/data/excel?${stringify(params)}`)

export async function ShopDataExport(params) {
  const defaultFileName = `门店数据统计${moment().format("YYYYMMDD")}.csv`
  return newRequest(
    `/data/center/v1/index/shop/data/excel?${stringify(params, {
      arrayFormat: "repeat",
    })}`,
    {
      method: "GET",
      responseType: "blob",
      defaultFileName: defaultFileName,
    }
  )
}

/**
 * 获取最近周
 */
export const LastWeek = (params) => GET(`/data/center/v1/index/last/week`)

/**
 * 获取最近月
 */
export const LastMonth = (params) => GET(`/data/center/v1/index/last/month`)

/**
 * 首页项目维度统计数据
 */
export const StatisticsData = (params) =>
  GET(`/data/center/v1/index/statistics/data?${stringify(params)}`)

/**
 * 首页五个排行榜数据
 */
export const TopData = (params) =>
  GET(`/data/center/v1/index/top/data?${stringify(params)}`)

/**
 * 门店数据统计
 */
export const ShopDataList = (params) =>
  GET(
    `/data/center/v1/index/shop/data/list?${stringify(params, {
      arrayFormat: "repeat",
    })}`
  )

/**
 * 商品数据统计
 */
export const GoodsDataList = (params) =>
  GET(`/data/center/v1/index/goods/data/list?${stringify(params)}`)

/**
 * 门店下拉列表
 */
export const Pull_list = (params) =>
  POST("/data/center/v1/shop/pull/page", params)
