import {
  Button,
  Table,
  Input,
  DatePicker,
  Select,
  message,
  Modal,
  Cascader,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
import locale from "antd/lib/date-picker/locale/zh_CN"
import dayjs from "dayjs"
import moment from "moment"
import "moment/locale/zh-cn"
import "./Index.css"
import { useLocation } from "react-router-dom"
import {
  GetGoodsWeighDetail,
  ExportStaffWeightDetailByProject,
  ShopTreeList,
} from "../../services/datareport"
moment.locale("zh-cn")
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = "YYYY-MM-DD"

// 开始时间
let ST = dayjs(
  dayjs().subtract(1, "month").format("YYYY-MM-DD") + " " + "00:00:00"
).valueOf()
// 结束时间
let ET = dayjs(dayjs().format("YYYY-MM-DD") + " " + "23:59:59").valueOf()
let JAT = [
  dayjs().subtract(1, "month").format("YYYY-MM-DD") + " " + "00:00:00",
  dayjs().format("YYYY-MM-DD") + " " + "23:59:59",
] // 所有
let newOperator = "" //员工工号
// 项目id
const projectId = localStorage.getItem("uid")

function Index(props) {
  const location = useLocation()
  let TIME = location.search.split("?")[1].split("&")[0].split("=")[1]
  let TIME_TYPE = location.search.split("?")[1].split("&")[1].split("=")[1]
  newOperator = location.search.split("?")[1].split("&")[2].split("=")[1]
  if (TIME_TYPE == 1) {
    ST = dayjs(dayjs(TIME).format("YYYY-MM-DD") + " " + "00:00:00").valueOf()
    ET = dayjs(dayjs(TIME).format("YYYY-MM-DD") + " " + "23:59:59").valueOf()

    JAT = [
      dayjs(TIME).format("YYYY-MM-DD") + " " + "00:00:00",
      dayjs(TIME).format("YYYY-MM-DD") + " " + "23:59:59",
    ]
  }
  if (TIME_TYPE == 2) {
    const New_Start = TIME.split(",")[1].substring(0, 21).split("-")[0]
    const New_End = TIME.split(",")[1].substring(0, 21).split("-")[1]
    ST = dayjs(New_Start + " " + "00:00:00").valueOf()
    ET = dayjs(New_End + " " + "23:59:59").valueOf()
    JAT = [New_Start + " " + "00:00:00", New_End + " " + "23:59:59"]
  }
  if (TIME_TYPE == 3) {
    ST = dayjs(TIME).startOf("month").valueOf()
    ET = dayjs(TIME).endOf("month").valueOf()
    JAT = [
      dayjs(TIME).startOf("month").format("YYYY-MM-DD"),
      dayjs(TIME).endOf("month").format("YYYY-MM-DD"),
    ]
  }

  //shopId：门店id：门店id,staffName:员工,skuCode:商品，recoredId:精确查询id,specific_time:时间

  //定义参数
  const [timeArr, setTimeArr] = useState(JAT) //时间
  const [startTime, setStartTime] = useState(ST) //开始时间
  const [endTime, setEndTime] = useState(ET) //结束时间
  const [shopName, setShopName] = useState() //商品名称
  const [show, setShow] = useState(false) //查看图片modal状态
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]) //表格选中数据
  const [operator, setOperator] = useState(newOperator) //操作人员
  const [shopId, setShopId] = useState("") //门店id
  const [fileName, setFileName] = useState("") //图片链接
  const [deviceSn, setDeviceSn] = useState("") //操作设备
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [shopList, setShopList] = useState([]) //门店设备列表
  const [GiveParams, setGiveParams] = useState({
    startDate: ST,
    endDate: ET,
    pageNum: 1,
    pageSize: 20,
    shopId: "",
    deviceSn: "",
    operator: "",
    barCode: "",
    isChangePrice: "",
    skuCode: "",
    skuName: "",
    // ids: [],
    projectId: projectId, //项目id
    id: undefined, //精确查询的id
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  //时间选择限制30天
  // const disabledDate = (current) => {
  //   if (!timeArr) {
  //     return false
  //   }
  //   const tooLate = timeArr[0] && current.diff(timeArr[0], "days") >= 30
  //   const tooEarly = timeArr[1] && timeArr[1].diff(current, "days") >= 30
  //   return !!tooEarly || !!tooLate
  // }

  useEffect(() => {
    //调用获取表格列表数据
    setTimeout(getGoodsWeighDetail, 0)
    //调用门店设备
    getShopTreeList()
  }, [])

  //门店设备
  const getShopTreeList = (value) => {
    const params = value
    ShopTreeList(params).then((res) => {
      if (res.code == 200) {
        if (res.data.length > 0) {
          res.data.map((v) => {
            v.value = v.shopId
            v.label = v.name
            if (v.deviceList) {
              v.deviceList.map((w) => {
                w.value = w.deviceSn
                w.label = w.cdKeyCode
              })
            }
            v.children = v.deviceList
          })
          // 合并原始数组与查询出的数组，并去重
          const NewArr = res.data.concat(shopList)
          const obj = {}
          let NewArr1 = []
          for (let i = 0; i < NewArr.length; i++) {
            if (!obj[NewArr[i]["shopId"]]) {
              obj[NewArr[i]["shopId"]] = 1
              NewArr1.push(NewArr[i])
            }
          }
          setShopList(NewArr1)
        }
      }
    })
  }

  //获取表格列表数据
  const getGoodsWeighDetail = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const { code, msg, data } = await GetGoodsWeighDetail(GiveParamsRef)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.rows || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //导出
  const getExport = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      GiveParamsRef.projectId = localStorage.getItem("uid")
      console.log("导出数据传值：", GiveParamsRef)
      const res = await ExportStaffWeightDetailByProject(GiveParamsRef)
    } catch (error) {
      console.log(error)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: total,
      onChange: (current) => {
        setPageNum(current)
        setGiveParams({
          ...GiveParams,
          pageNum: current,
          // ids: [],
        })
        // setSelectedRowKeys([])
        setTimeout(getGoodsWeighDetail, 0)
      },
    }
    return paginationProps
  }

  //表格复选框
  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys)
  //   setGiveParams({
  //     ...GiveParams,
  //     ids: newSelectedRowKeys,
  //   })
  // }
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // }

  const columns = [
    {
      title: "称重时间",
      dataIndex: "weighDateYear",
      ellipsis: { showTitle: true },

      key: "weighDateYear",
      width: 200,
    },
    {
      title: "门店",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },

      key: "shopName",
      width: 200,
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },

      key: "scaleNo",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },

      key: "skuName",
      width: 200,
    },
    {
      title: "称重类型",
      dataIndex: "chooseResultType",
      ellipsis: { showTitle: true },

      key: "chooseResultType",
      width: 200,
      render: (info) => {
        return (
          <>
            {info == 0
              ? "无后续操作"
              : info == 1
              ? "自动打印"
              : info == 2
              ? "识别结果点选"
              : info == 3
              ? "快捷键点选"
              : info == 4
              ? "搜索中选择"
              : info == 5
              ? "重新识别"
              : info == 6
              ? "按称键盘"
              : info == 8
              ? "直打标签"
              : info == 9
              ? "学习模式"
              : info == 10
              ? "临时商品"
              : ""}
          </>
        )
      },
    },
    {
      title: "员工工号",
      dataIndex: "operator",
      ellipsis: { showTitle: true },

      key: "operator",
      width: 100,
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },

      key: "skuCode",
      width: 100,
    },
    {
      title: "单价",
      dataIndex: "salePrice",
      ellipsis: { showTitle: true },

      key: "salePrice",
      width: 100,
      render: (info, rowInfo) => {
        return (
          <>
            {info == rowInfo.agoPrice ? (
              ""
            ) : rowInfo.agoPrice > 0 ? (
              <div style={{ textDecoration: "line-through" }}>
                {(rowInfo.agoPrice / 100).toFixed(2)}元{" "}
              </div>
            ) : (
              ""
            )}
            {info > 0 ? (info / 100).toFixed(2) : 0}元
            {rowInfo?.priceUnit == 1
              ? "/个"
              : rowInfo?.priceUnit == 0
              ? "/kg"
              : ""}
          </>
        )
      },
    },
    {
      title: "重量/数量",
      dataIndex: "saleWeight",
      ellipsis: { showTitle: true },

      key: "saleWeight",
      width: 100,
      render: (info, rowInfo) => {
        return (
          <>
            {info > 0 ? info : ""}
            {rowInfo?.priceUnit == 1
              ? "/个"
              : rowInfo?.priceUnit == 0
              ? "/kg"
              : ""}
          </>
        )
      },
    },
    {
      title: "总价",
      dataIndex: "amount",
      ellipsis: { showTitle: true },

      key: "amount",
      width: 100,
      render: (info) => {
        return <>{info > 0 ? (info / 100).toFixed(2) : 0}元</>
      },
    },
    {
      title: "交易条码",
      dataIndex: "barCode",
      ellipsis: { showTitle: true },

      key: "barCode",
      width: 100,
    },
    {
      title: "设备IP",
      dataIndex: "intranetIp",
      ellipsis: { showTitle: true },

      key: "intranetIp",
      width: 100,
    },
    {
      title: "称重时长",
      dataIndex: "weightDuration",
      ellipsis: { showTitle: true },

      key: "weightDuration",
      width: 100,
      render: (info) => {
        return <>{info} 毫秒</>
      },
    },
    {
      title: "识别时长",
      dataIndex: "duration",
      ellipsis: { showTitle: true },

      key: "duration",
      width: 100,
      render: (info) => {
        return <>{info} 毫秒</>
      },
    },
    {
      title: "点选行为时长",
      dataIndex: "selectDuration",
      ellipsis: { showTitle: true },

      key: "selectDuration",
      width: 120,
      render: (info) => {
        return <>{info} 毫秒</>
      },
    },
    {
      title: "称重照片",
      dataIndex: "fileName",
      ellipsis: { showTitle: true },

      key: "fileName",
      fixed: "right",
      width: 100,
      render: (info) => {
        return (
          <div
            className="weightrecord_table_img"
            onClick={() => handShow(info)}
          >
            <img src={info} />
          </div>
        )
      },
    },
  ]

  //日期：
  const timeChange = (e) => {
    //当时间选择框选中时间时，取选中时间，清除时间时回到默认时间
    console.log(e)
    if (e) {
      setTimeArr(e) //存时间数组
      setStartTime(
        dayjs(e[0].format("YYYY-MM-DD") + " " + "00:00:00").valueOf()
      )
      setEndTime(dayjs(e[1].format("YYYY-MM-DD") + " " + "23:59:59").valueOf())

      //清空员工下拉框选中数据
    } else {
      setTimeArr(JAT)
      setEndTime(ET)
      setStartTime(ST)
    }
  }
  //商品名称：
  const shopNameChange = (e) => {
    setShopName(e)
  }
  //查询
  const handSearch = () => {
    setPageNum(1)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      startDate: startTime,
      endDate: endTime,
      deviceSn: deviceSn,
      operator: operator,
      skuName: shopName,
      shopId: shopId,
      // ids: selectedRowKeys,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }
  //重置
  const handClear = () => {
    // 重置时将时间恢复默认
    setPageNum(1)
    setDeviceSn("")
    setShopId(undefined)
    setShopName(undefined)
    setOperator(newOperator)
    // setSelectedRowKeys([])
    setTimeArr(JAT)
    setEndTime(ET)
    setStartTime(ST)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      startDate: ST,
      endDate: ET,
      deviceSn: "",
      operator: "",
      skuName: "",
      shopId: "",
      // ids: [],
      id: "",
    })
    setTimeout(getGoodsWeighDetail, 0)
  }

  //点击查看称重照片
  const handShow = (value) => {
    setFileName(value)
    setShow(true)
  }

  return (
    <div className="weightrecord_page">
      {/* 查询条件，查询，重置 */}
      <div className="weightrecord_page_search">
        <div className="weightrecord_search_body">
          <div className="weightrecord_search_body_child">
            <span>日期：</span>
            <RangePicker
              locale={locale}
              // disabledDate={disabledDate}
              value={
                timeArr.length == 0
                  ? []
                  : [
                      dayjs(timeArr[0], dateFormat),
                      dayjs(timeArr[1], dateFormat),
                    ]
              }
              onChange={(e) => timeChange(e)}
            />
          </div>
          <div className="weightrecord_search_body_child">
            <span>门店/设备：</span>
            <Cascader
              getPopupContainer={(triggerNode) => triggerNode?.parentNode}
              showSearch
              style={{ width: 220 }}
              changeOnSelect
              options={shopList}
              placeholder="请选择门店/设备"
              value={
                shopId && deviceSn ? [shopId, deviceSn] : shopId ? [shopId] : []
              }
              onSearch={(e) => {
                if (e.length >= 2) {
                  getShopTreeList(e)
                }
              }}
              onChange={(e) => {
                if (e) {
                  setShopId(e[0] ? e[0] : "") //商品编号
                  setDeviceSn(e[1] ? e[1] : "") //操作设备
                } else {
                  setShopId(undefined)
                  setDeviceSn(undefined)
                }
              }}
            />
          </div>
          <div className="weightrecord_search_body_child">
            <span>商品：</span>
            <Input
              placeholder="请输入商品名称/PLU"
              style={{ width: 200 }}
              value={shopName}
              onChange={(e) =>
                shopNameChange(
                  e.target.value
                    ? e.target.value.replace(/(^\s+)|(\s+$)/g, "")
                    : undefined
                )
              }
            />
          </div>
          <div className="weightrecord_search_body_child">
            <span>员工工号：</span>
            <Input
              placeholder="请输入员工工号"
              style={{ width: 200 }}
              value={operator}
              onChange={(e) =>
                setOperator(
                  e.target.value
                    ? e.target.value.replace(/(^\s+)|(\s+$)/g, "")
                    : undefined
                )
              }
            />
          </div>
        </div>

        <div className="weightrecord_search_button">
          {/* <Button
            onClick={() => {
              window.history.back(-1)
            }}
          >
            返回上层
          </Button> */}
          <Button onClick={() => handClear()}>重置</Button>
          <Button type="primary" onClick={() => handSearch()}>
            查询
          </Button>
          {/* 导出 */}
          <Button type="primary" onClick={getExport}>
            导出数据
          </Button>
        </div>
      </div>
      {/* <div className="weightrecord_page_body"> */}
      {/* 表格数据 */}
      <Table
        dataSource={dataList}
        columns={columns}
        pagination={onTableChange()}
        rowKey="id"
        loading={loading}
        scroll={{ x: "max-content", y: window.screen.height * 0.55 }}
        // rowSelection={rowSelection}
        // rowClassName={(record, index) => {
        //   return record.id === "af3af69fffe9986cfa12ab2c59efa230"
        //     ? "rowBackground"
        //     : ""
        // }}
      />
      {/* </div> */}
      <Modal
        open={show}
        closable={false}
        onCancel={() => {
          setShow(false)
        }}
        footer={[]}
        width={"50%"}
      >
        <div className="weightrecord_img">
          <img src={fileName} />
        </div>
      </Modal>
    </div>
  )
}

export default Index
