import { Button, Table, Input, DatePicker, Select, message } from "antd"
import React, { useEffect, useState, useRef } from "react"
import locale from "antd/lib/date-picker/locale/zh_CN"
import dayjs from "dayjs"
import moment from "moment"
import { Link } from "react-router-dom"
import "moment/locale/zh-cn"
import {
  Shop_List,
  GetStaffWeightRecordPage,
  ExportStaffWeightRecord,
} from "../../services/lossmanage"
import styles from "./Index.module.css"

const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = "YYYY-MM-DD"
//默认开始时间
const ST = dayjs().subtract(1, "month").format("YYYYMMDD")
//默认结束时间
const ET = dayjs().format("YYYYMMDD")
//默认时间，结束时间为当前时间，开始时间往前推一个月
const AT = [dayjs(), dayjs().subtract(1, "month")]

moment.locale("zh-cn")

function Index() {
  const [timeArr, setTimeArr] = useState([]) //时间
  const [startTime, setStartTime] = useState("") //开始时间
  const [endTime, setEndTime] = useState("") //结束时间
  const [shopId, setShopId] = useState(undefined) //门店id
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]) //表格选中数据
  const [operatorValue, setOperatorValue] = useState(undefined) //员工
  const [GiveParams, setGiveParams] = useState({
    startDate: ST,
    endDate: ET,
    operator: "",
    shopId: "",
    pageSize: 20,
    pageNum: 1,
    type: "2",
    // ids: [],
  })

  const [shopList, setShopList] = useState([]) //门店列表
  const [dataList, setDataList] = useState([]) //表格列表

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    //默认时间，结束时间为当前时间，开始时间往前推一个月
    setTimeArr(AT)
    setEndTime(ET)
    setStartTime(ST)

    //请求门店列表接口
    getShopList()
    //表格数据列表接口请求
    getStaffWeight()
  }, [])

  //门店列表接口请求
  const getShopList = () => {
    const params = {}
    Shop_List(params).then((res) => {
      if (res.code == 200) {
        setShopList(res.data || [])
      }
    })
  }

  //表格数据列表接口请求
  const getStaffWeight = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const { code, msg, data } = await GetStaffWeightRecordPage(GiveParamsRef)
      if (code != 200) {
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //导出
  const getExport = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      const res = await ExportStaffWeightRecord(GiveParamsRef)
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: total,
      onChange: (current) => {
        setPageNum(current)
        setGiveParams({
          ...GiveParams,
          pageNum: current,
          ids: [],
        })
        // setSelectedRowKeys([])
        setTimeout(getStaffWeight, 0)
      },
    }
    return paginationProps
  }

  //表格复选框
  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys)
  //   setGiveParams({
  //     ...GiveParams,
  //     ids: newSelectedRowKeys,
  //   })
  // }
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // }
  const columns = [
    {
      title: "门店",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },

      key: "shopName",
    },
    {
      title: "员工工号",
      dataIndex: "staffName",
      ellipsis: { showTitle: true },

      key: "staffName",
      // render: (rowInfo, info) => {
      //   return handJump(rowInfo, info)
      // },
    },
    {
      title: "监控改价出售总数",
      dataIndex: "abnormalPriceCount",
      ellipsis: { showTitle: true },

      key: "abnormalPriceCount",
      render: (rowInfo, info) => {
        return handJump(
          rowInfo,
          { staffName: info?.staffName },
          "/admin/lossmanage/updateroad"
        )
      },
    },
    {
      title: "异常称重总数",
      dataIndex: "abnormalWeighNum",
      ellipsis: { showTitle: true },

      key: "abnormalWeighNum",
      render: (rowInfo, info) => {
        return handJump(
          rowInfo,
          { staffName: info?.staffName },
          "/admin/datareport/weightrecord"
        )
      },
    },
  ]

  //门店：
  const shopChange = (e) => {
    setShopId(e)
  }

  //日期：
  const timeChange = (e) => {
    //当时间选择框选中时间时，取选中时间，清除时间时回到默认时间
    if (e) {
      setTimeArr(e) //存时间数组
      setStartTime(e[0].format("YYYYMMDD"))
      setEndTime(e[1].format("YYYYMMDD"))
    } else {
      setTimeArr(AT)
      setEndTime(ET)
      setStartTime(ST)
    }
  }

  //查询
  const handSearch = () => {
    //页码归1
    setPageNum(1)
    //给接口传的参数赋值
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      startDate: startTime,
      endDate: endTime,
      operator: operatorValue,
      shopId: shopId,
    })
    setTimeout(getStaffWeight, 0)
  }

  //重置
  const handClear = () => {
    setShopId(undefined)
    setOperatorValue(undefined)
    setPageNum(1)
    // setSelectedRowKeys([])
    setTimeArr(AT)
    setEndTime(ET)
    setStartTime(ST)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      startDate: ST,
      endDate: ET,
      operator: undefined,
      shopId: undefined,
      // ids: [],
    })
    setTimeout(getStaffWeight, 0)
  }

  //跳转
  const handJump = (rowInfo, info, path) => {
    return (
      <Link
        to={{
          pathname: path,
          state: info,
        }}
      >
        <span style={{ color: "#2EB998" }}>{rowInfo}</span>
      </Link>
    )
  }

  //时间选择限制30天
  const disabledDate = (current) => {
    if (!timeArr) {
      return false
    }
    const tooLate = timeArr[0] && current.diff(timeArr[0], "days") >= 30
    const tooEarly = timeArr[1] && timeArr[1].diff(current, "days") >= 30
    return !!tooEarly || !!tooLate
  }

  return (
    <div className={styles.weight_page}>
      {/* 查询条件，查询，重置 */}
      <div className={styles.errorweightbody_weight_page_search}>
        <div className={styles.errorweightbody_weight_search_body}>
          <div className={styles.errorweightbody_weight_search_body_child}>
            <span>日期：</span>
            <RangePicker
              disabledDate={disabledDate}
              locale={locale}
              value={
                timeArr.length == 0
                  ? []
                  : [
                      dayjs(timeArr[0], dateFormat),
                      dayjs(timeArr[1], dateFormat),
                    ]
              }
              onChange={(e) => timeChange(e)}
            />
          </div>
          <div className={styles.errorweightbody_weight_search_body_child}>
            <span>门店：</span>
            <Select
              placeholder="请选择门店"
              allowClear
              style={{ width: 200 }}
              value={shopId}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
              onChange={(e) => shopChange(e)}
            >
              {shopList.map((v) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className={styles.errorweightbody_weight_search_body_child}>
            <span>员工工号：</span>
            <Input
              placeholder="请输入员工工号"
              value={operatorValue}
              style={{ width: 200, height: 38 }}
              onChange={(e) => {
                setOperatorValue(
                  e.target.value
                    ? e.target.value.replace(/(^\s+)|(\s+$)/g, "")
                    : undefined
                )
              }}
            />
          </div>
        </div>
        <div className={styles.errorweightbody_weight_search_button}>
          <Button onClick={() => handClear()}>重置</Button>
          <Button type="primary" onClick={() => handSearch()}>
            查询
          </Button>
          <Button type="primary" onClick={getExport}>
            导出数据
          </Button>
        </div>
      </div>
      <div className={styles.weight_page_body}>
        {/* 导出 */}
        {/* <div className={styles.}weight_body_export">
         
        </div> */}
        {/* 表格数据 */}
        <div className={styles.weight_body_table}>
          <Table
            dataSource={dataList}
            columns={columns}
            pagination={onTableChange()}
            rowKey="id"
            loading={loading}
            // rowSelection={rowSelection}
            scroll={{ y: window.screen.height * 0.55 }}
          />
        </div>
      </div>
    </div>
  )
}

export default Index
