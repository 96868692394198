import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { ConfigProvider, Button } from "antd"
import zh_CN from "antd/es/locale/zh_CN"
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { mainRoutes } from "./routes"
import reportWebVitals from "./reportWebVitals"
import dayjs from "dayjs"
import "dayjs/locale/zh-cn"
import locale from "antd/locale/zh_CN"
import { Provider } from "react-redux"
import store from "./store"
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#2EB998", // 全局主题色
          colorText: "black", // 全局文本色
          colorPrimaryBgHover: "#2EB998",
        },
        components: {
          Menu: {
            itemColor: "black", // 菜单项文字颜色
            itemSelectedColor: "white", // 菜单项文字选中颜色
            itemHoverColor: "white", // 菜单项文字悬浮颜色
            itemSelectedBg: "#2EB998", // 菜单项选中态背景色
            itemHoverBg: "#2EB998", // 菜单项悬浮态背景色
            itemActiveBg: "#2EB998", // 菜单项激活态背景色
          },
          Button: {
            colorPrimary: "#2EB998",
            algorithm: true, // 启用算法
          },
        },
      }}
    >
      {/* <React.StrictMode> */}
      <Router>
        <Switch>
          <Route
            path="/admin"
            render={(routeProps) => <App {...routeProps} />}
          />
          {mainRoutes.map((route) => {
            return <Route key={route.path} {...route} />
          })}
          <Redirect to="/admin" from="/" />
          <Redirect to="/404" />
        </Switch>
      </Router>
      {/* </React.StrictMode> */}
    </ConfigProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
