import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import moment from "moment"
import request, { newRequest } from "../../utils/newRequest"

/**
 * 防损设置保存
 */
export const GetPrevenTion = (params) =>
  POST("/data/center/v1/setting/loss/prevention/save", params)

/**
 * 获取项目配置的防损设置
 */
export const GetPrevenInfo = (params) =>
  GET(`/data/center/v1/setting/loss/prevention/info`)

/**
 * 新建角色
 */
export const AddRole = (params) => POST("/data/center/v1/user/role/add", params)

/**
 * 编辑角色
 */
export const EditRole = (params) =>
  POST("/data/center/v1/user/role/edit", params)

/**
 * 角色详情
 */
export const RoleInfo = (roleId) =>
  GET(`/data/center/v1/user/role/info?roleId=${roleId}`)

/**
 * 角色列表
 */
export const GetUserList = (params) =>
  GET(`/data/center/v1/user/role/list?${stringify(params)}`)

/**
 * 角色删除
 */
export const DeleteRole = (roleId) =>
  GET(`/data/center/v1/user/role/delete?roleId=${roleId}`)

/**
 * 角色禁用启用
 */
export const RoleStatus = (params) =>
  POST(`/data/center/v1/user/role/status`, params)

/**
 * 下拉角色列表
 */
export const RolePullList = (status) =>
  GET(`/data/center/v1/user/role/pull/list?status=${status}`)

/**
 * 新建账号
 */
export const AddAccount = (params) =>
  POST(`/data/center/v1/user/account/add`, params)

/**
 * 编辑账号
 */
export const EditAccount = (params) =>
  POST(`/data/center/v1/user/account/edit`, params)

/**
 * 账号详情
 */
export const AccountInfo = (accountId) =>
  GET(`/data/center/v1/user/account/info?accountId=${accountId}`)

/**
 * 账号列表
 */
export const GetAccountList = (params) =>
  GET(`/data/center/v1/user/account/list?${stringify(params)}`)

/**
 * 账号删除
 */
export const DeleteAccount = (accountId) =>
  GET(`/data/center/v1/user/account/delete?accountId=${accountId}`)

/**
 * 账号禁用启用
 */
export const AccountStatus = (params) =>
  POST(`/data/center/v1/user/account/status`, params)

/**
 * token
 */
export const Get_Token = () => GET("/data/center/v1/qiniuyun/token")

/**
 * 重置密码
 */
export const ResetPassword = (accountId) =>
  GET(`/data/center/v1/login/reset/password?accountId=${accountId}`)

/**
 * 获取登录用户信息
 */
export const UserInfo = () => GET(`/data/center/v1/user/login/info`)

/**
 * 商品图片库icon列表
 */
export const IconList = (params) => {
  return GET(
    `/data/center/v1/goods/icon/list?${stringify(params, {
      arrayFormat: "repeat",
    })}`
  )
}

/**
 * 添加新的防损规则
 */
export const AddNewPrevention = (params) =>
  POST(`/data/center/v1/setting/loss/prevention/add`, params)

/**
 * 编辑防损规则
 */
export const EditPrevention = (params) =>
  POST(`/data/center/v1/setting/loss/prevention/edit`, params)

/**
 * 防损规则列表
 */
export const PreventionList = (params) =>
  GET(`/data/center/v1/setting/loss/prevention/list?${stringify(params)}`)

/**
 * 防损规则删除
 */
export const DeletePrevention = (id) =>
  GET(`/data/center/v1/setting/loss/prevention/delete?id=${id}`)

/**
 * 防损规则开启关闭
 */
export const PreventionSwitch = (params) =>
  POST(`/data/center/v1/setting/loss/prevention/switch`, params)

/**
 * 防损规则编辑
 */
export const Edit_Detail = (id) =>
  GET(`/data/center/v1/setting/loss/prevention/detail?id=${id}`)
