import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import moment from "moment"
import request, { newRequest } from "../../utils/newRequest"

/**
 * 清除告警记录新消息
 */
export const Clearn_Retrieve_Password = (params) =>
  GET("/data/center/v1/loss/prevention/clear/warning/message", params)

/**
 * 门店下设备下拉列表
 */
export const Shop_List = (params) =>
  POST("/data/center/v1/goods/shop_list", params)

/**
 * 门店设备
 */
export const ShopTreeList = (params) =>
  POST("/data/center/v1/shop/pull_tree", params)
// GET(
//   `/data/center/v1/share/learn/shop_tree_list?code=${
//     params ? params : ""
//   }&isAll=2`
// )

/**
 * 获取最近周
 */
export const LastWeek = (params) => GET(`/data/center/v1/index/last/week`)

/**
 * 获取最近月
 */
export const LastMonth = (params) => GET(`/data/center/v1/index/last/month`)

/**
 * 获取称重统计列表
 */
export const GetStaffWeightRecordPage = (params) =>
  POST("/data/center/v1/record/getStaffWeightRecordPage", params)

/**
 * 员工异常称重导出
 */
export async function ExportStaffWeightRecord(params) {
  return newRequest(
    `/data/center/v1/record/exportStaffWeightRecord?${stringify(params)}`,
    {
      responseType: "blob",
    }
  )
}

/**
 * 商品改价记录
 */
export const GoodsChangePrice = (params) =>
  GET(
    `/data/center/v1/loss/prevention/goods/change/price/log?${stringify(
      params
    )}`
  )

/**
 * 商品改价记录导出
 */
export async function ExportGoodsChange(params) {
  return newRequest(
    `/data/center/v1/loss/prevention/goods/change/price/excel?${stringify(
      params
    )}`,
    {
      responseType: "blob",
    }
  )
}

/**
 * 告警记录
 */
export const WarningLog = (params) =>
  POST("/data/center/v1/loss/prevention/warning/log", params)
// GET(`/data/center/v1/loss/prevention/warning/log?${stringify(params)}`)

/**
 * 告警记录导出
 */
// export const ExportWarningLog = (params) =>
//   GET(`/data/center/v1/loss/prevention/warning/excel?${stringify(params)}`)
export async function ExportWarningLog(params) {
  return newRequest(`/data/center/v1/loss/prevention/warning/excel`, {
    method: "POST",
    responseType: "blob",
    body: params,
  })
}

/**
 * 改价记录
 */
export const PreventionChange = (params) =>
  // GET(`/data/center/v1/loss/prevention/change/price/log?${stringify(params)}`)
  POST("/data/center/v1/loss/prevention/change/price/log", params)

/**
 * 根据项目滚动导出改价记录
 */
export async function ExportStaffChange(params) {
  return newRequest(
    `/data/center/v1/weigh/exportStaffChangePriceByProject?${stringify(
      params
    )}`,
    {
      responseType: "blob",
    }
  )
}

/**
 * 获取称重统计列表
 */
export const PlaybackCamera = (params) =>
  POST("/data/center/v1/device/monitor/camera/playback", params)

/**
 * 省市区树
 */
export const Area_Tree = () => GET(`/data/center/v1/index/area/tree`)

/**
 * 触发告警类型统计(百分比以及异常次数)
 */
export const Alarm_Statistics = (params) =>
  GET(`/data/center/v1/alarm/alarm/statistics?${stringify(params)}`)

/**
 * 触发告警商品排名
 */
export const Alarm_GoodsList = (params) =>
  GET(`/data/center/v1/alarm/top/goodsList?${stringify(params)}`)

/**
 * 触发告警门店排名
 */
export const Alarm_ShopList = (params) =>
  GET(`/data/center/v1/alarm/top/shopList?${stringify(params)}`)

/**
 * 门店下拉列表
 */
export const Pull_list = (params) =>
  // GET(`/data/center/v1/shop/pull/list?${stringify(params)}`)
  POST("/data/center/v1/shop/pull/page", params)

/**
 * 导出任务列表
 */
export const exportWorkList = (params) =>
  GET(
    `/data/center/v1/export/work/list?${stringify(params, { indices: false })}`
  )
