import React from "react"
import { Button, Result } from "antd"
import { customRoutes } from "../routes"

function PageNotFound(props) {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="很抱歉，您访问的页面不存在。"
        extra={
          <Button
            type="primary"
            onClick={() => {
              if (customRoutes.length > 0) {
                props.history.push(customRoutes[0].path)
              }
            }}
          >
            返回首页
          </Button>
        }
      />
    </div>
  )
}

export default PageNotFound
