import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { VideoCameraAddOutlined } from "@ant-design/icons"
import {
  Select,
  Button,
  Table,
  Modal,
  message,
  Tag,
  Form,
  Card,
  Spin,
} from "antd"
import * as DeviceServices from "../../services/device_manage"
import Hls from "hls.js"
import PubInput from "../../../components/PubInput"
import styles from "./Index.module.css"

const { Option } = Select
//////////////////////////
// 播放器实例
var player = null
window.__SHOW_LOG__ = true

// Promise封装实例释放
function playerDestroyPromise() {
  return new Promise((resolve, reject) => {
    if (!player) {
      resolve("OK")
    }
    player.stop()
    player
      .destroy()
      .then(() => {
        resolve("OK")
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// 同步方式等待释放
async function playerDestroy() {
  try {
    await playerDestroyPromise()
    player = null
  } catch (e) {
    console.error(e)
  }
}

//////////////////////////
function Index() {
  const myRef = useRef(null)
  var hls = new Hls()
  const [form] = Form.useForm()
  const history = useHistory()
  //
  const [nameValue, setNameValue] = useState() // 1:海康，2：TP-LINK
  const [typeNum, setTypeNum] = useState("") // 1:海康，2：TP-LINK
  //
  const [monitorStatus, setMonitorStatus] = useState(false) // 监控Modal状态
  const [deviceSn, setDeviceSn] = useState(undefined) //设备SN
  const [cdKeyCodeOrscaleNo, setCdKeyCodeOrscaleNo] = useState(undefined) //激活码秤号
  const [editValue, setEditValue] = useState({})
  const [cameraList, setCameraList] = useState([]) // 列表
  const [total, setTotal] = useState() //总数
  const [loading, setloading] = useState(false) //LOADING
  const [selectedRows, setSelectedRows] = useState("") // 点击拿到的行信息
  //video loading
  const [videoLoading, setVideoLoading] = useState(false) //

  const [GiveParams, setGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    settingId: "", //监控配置ID,非必填，默认取第一个
    monitorRegion: "", //监控点区域
    monitorNum: "", // 监控点编号
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    const NewInfo = history?.location?.search.split("?")[1].split("$")
    // 设备SN
    const CdKeyCode = NewInfo[1].split("=")[1]
    // 秤号
    const ScaleNo = NewInfo[2].split("=")[1]
    // 设备SN
    setDeviceSn(NewInfo[0].split("=")[1])
    //激活码秤号
    setCdKeyCodeOrscaleNo(CdKeyCode + " / " + ScaleNo)

    // 获取监控列表
    getCameraList()

    // 销毁视cavas
    return playerDestroy
  }, [])

  /**
   * 获取监控列表
   */
  const getCameraList = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      GiveParamsRef.pageNo = GiveParamsRef.pageNum
      setloading(true)
      const { code, data, msg } = await DeviceServices.Camera_List(
        GiveParamsRef
      )
      if (code != 200) {
        setloading(false)
        message.error(msg)
        setCameraList([])
        setTotal(0)
        setGiveParams({
          ...GiveParams,
          pageNum: 1,
          monitorNum: undefined,
        })
        return
      }
      if (data && data.list) {
        setCameraList(data.list)
        setloading(false)
        setTotal(data.total) //数据总条数
        if (data.list?.length > 0) {
          setTypeNum(data.list[0].type)
        }
      }
    } catch (error) {
      console.log(error)
      setCameraList([])
      setTotal(0)
      setGiveParams({
        ...GiveParams,
        pageNum: 1,
        monitorNum: undefined,
      })
      setloading(false)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getCameraList, 0)
      },
    }
    return paginationProps
  }

  //查询
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      monitorNum: GiveParams?.monitorNum || "",
    })
    setTimeout(getCameraList, 0)
  }

  //重置
  const handClear = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      monitorNum: undefined,
    })
    setTimeout(getCameraList, 0)
  }

  // 绑定
  const onBinding = async () => {
    try {
      let params = selectedRows[0]
      params.deviceSN = deviceSn
      const { code, data, msg } = await DeviceServices.Monitor_Bind(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("绑定成功")
        setSelectedRows("")
        window.history.go(-1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //表格选中
  const rowSelection = {
    onChange: (key, selectedRows) => {
      setSelectedRows(selectedRows)
    },
  }

  const columns = [
    {
      title: "监控区域",
      dataIndex: "monitorRegion",
      key: "monitorRegion",

      ellipsis: { showTitle: true },
    },
    {
      title: "摄像头编号",
      dataIndex: "monitorNum",
      key: "monitorNum",

      ellipsis: { showTitle: true },
    },
    {
      title: "操作",

      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <a
            onClick={async () => {
              await playerDestroy()
              onPreview(info)
            }}
          >
            预览摄像
          </a>
        )
      },
    },
  ]

  /**
   * 打开监控设置Modal
   */
  const onOpenModal = async () => {
    try {
      const { code, data, msg } = await DeviceServices.New_Monito()
      if (code != 200) {
        message.error(msg)
        return
      }
      console.log(data)
      form.resetFields()
      setMonitorStatus(true)
      setNameValue(data.type) // 判断name1是海康还是name2TP-LINK
      setEditValue(data)
      form.setFieldsValue(data)
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 监控设置Modal确认按钮
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values)
        setTypeNum(values.type)
        if (editValue?.id) {
          values.id = editValue.id
          DeviceServices.Edit_Monito(values).then((res) => {
            if (res.code != 200) {
              return
            }
            if (res.data) {
              message.success("监控配置成功")
              setMonitorStatus(false)
              // 刷新列表
              setTimeout(getCameraList, 0)
            }
          })
        } else {
          DeviceServices.Add_Monitor(values).then((res) => {
            if (res.code != 200) {
              return
            }
            if (res.data) {
              message.success("监控配置成功")
              setMonitorStatus(false)
              // 刷新列表
              setTimeout(getCameraList, 0)
            }
          })
        }
      })
      .catch((err) => {
        // 校验失败
      })
  }

  /**
   * 预览
   */
  const onPreview = async (info) => {
    try {
      const params = {
        monitorIndexCode: info?.monitorIndexCode || "", // 监控点ID
        settingId: "",
      }
      const { code, msg, data } = await DeviceServices.Monitor_Preview(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      //  海康
      if (data && data.playUrl && info.type == 1) {
        if (Hls.isSupported()) {
          hls.loadSource(data.playUrl)
          // hls.loadSource(
          //   "https://hw-m-l.cztv.com/channels/lantian/channel002/1080p.m3u8"
          // )
          hls.attachMedia(myRef.current)
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            myRef.current.play()
          })
        } else {
          message.error("不支持HLS播放器")
        }
      } else if (data && data.playUrl && info.type == 2) {
        // TP-LINK商用云平台
        var TumsPlayer = window["tums-player"].default
        player = new TumsPlayer("video-container", {
          type: "relay", // 协议类型，rtsp/relay
          url: data.playUrl, // 取流地址
          pluginPath: "https://smbcloud.tp-link.com.cn/static",
        })
        // 当媒介数据将要开始播放时触发
        player.on("play", () => {
          // 隐藏加载框
        })
      } else {
        //异常
        message.error("异常")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.camera_page}>
      {/* //顶部查询 */}
      <Card>
        <div className={styles.camera_page_head}>
          <div className={styles.camera_search}>
            <div className={styles.camera_page_head_store_search}>
              激活码/秤号：
              <PubInput
                placeholder="激活码/秤号"
                value={cdKeyCodeOrscaleNo}
                disabled={true}
              />
            </div>
            <div className={styles.camera_page_head_store_search}>
              摄像头编号：
              <PubInput
                onChange={(val) =>
                  setGiveParams({ ...GiveParams, monitorNum: val })
                }
                placeholder="摄像头编号"
                value={GiveParams?.monitorNum}
              />
            </div>
          </div>
          <div className={styles.camera_page_btn}>
            <Button type="primary" onClick={() => handSearch()}>
              查询
            </Button>
            <Button onClick={() => handClear()}>重置</Button>
          </div>
        </div>
        {/* 提示 */}
        <div className={styles.camera_tag}>
          <Button
            type="primary"
            icon={<VideoCameraAddOutlined />}
            onClick={() => onOpenModal()}
          >
            配置监控
          </Button>
          <Tag color="orange">
            请选择需要和设备 {cdKeyCodeOrscaleNo}{" "}
            绑定的摄像头，若没有数据，请配置监控，若已配置，请刷新页面
          </Tag>
        </div>
      </Card>

      {/* 主体内容 */}
      <Card>
        <div className={styles.camera_content}>
          <div className={styles.camera_content_left}>
            <Table
              dataSource={cameraList}
              columns={columns}
              rowKey="monitorIndexCode"
              pagination={onTableChange()}
              scroll={{ y: "500px " }}
              loading={loading}
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
            />
            {cameraList && cameraList.length > 0 ? (
              <Button
                type="primary"
                disabled={selectedRows ? false : true}
                onClick={() => onBinding()}
              >
                确定
              </Button>
            ) : null}
          </div>

          <div className={styles.camera_content_right}>
            <div className={styles.camera_content_right_title}>
              <div className={styles.camera_content_right_title_one}>
                摄像头预览区域
              </div>
              {/* <div className="camera_content_right_title_two">
              若无法播放，请点击
            </div>
            <div className="camera_content_right_title_three">下载插件</div> */}
            </div>
            <div className={styles.camera_content_right_body}>
              {typeNum == 1 ? (
                <video
                  ref={myRef}
                  width="100%"
                  height="100%"
                  muted
                  controls
                  autoPlay
                ></video>
              ) : typeNum == 2 ? (
                <div
                  id="video-container"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    player?.fullscreen()
                  }}
                ></div>
              ) : null}
            </div>
          </div>
        </div>
      </Card>

      {/* 监控设置 */}

      <Modal
        title="监控设置"
        open={monitorStatus}
        onOk={onFinish}
        onCancel={() => {
          setMonitorStatus(false)
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="摄像头品牌"
            name="type"
            initialValue={editValue?.type || undefined}
            rules={[
              {
                required: true,
                message: "摄像头品牌不能为空!",
              },
            ]}
          >
            <Select
              width={200}
              allowClear
              placeholder="请选择摄像头品牌！"
              onChange={(e) => {
                setNameValue(e)
              }}
            >
              {" "}
              {/* <Option key={1} value={1}>
                海康
              </Option> */}
              <Option key={2} value={2}>
                TP-LINK
              </Option>
            </Select>
          </Form.Item>

          {nameValue == 1 ? (
            <Form.Item
              label="IP"
              name="ip"
              initialValue={editValue?.ip || ""}
              rules={[
                {
                  required: true,
                  message: "IP不能为空!",
                },
              ]}
            >
              <PubInput placeholder="IP！" max={15} />
            </Form.Item>
          ) : null}
          {nameValue == 1 ? (
            <Form.Item
              label="端口"
              name="port"
              initialValue={editValue?.port || ""}
              rules={[
                {
                  required: true,
                  message: "端口不能为空!",
                },
              ]}
            >
              <PubInput placeholder="端口！" max={15} />
            </Form.Item>
          ) : null}

          {nameValue == 2 ? (
            <Form.Item
              label="客户端ID"
              name="terminalId"
              initialValue={editValue?.terminalId || ""}
              rules={[
                {
                  required: true,
                  message: "客户端ID不能为空!",
                },
              ]}
            >
              <PubInput placeholder="客户端ID！" max={30} />
            </Form.Item>
          ) : null}
          <Form.Item
            label="appKey"
            name="appKey"
            initialValue={editValue?.appKey || ""}
            rules={[
              {
                required: true,
                message: "appKey不能为空!",
              },
            ]}
          >
            <PubInput placeholder="appkey！" max={50} />
          </Form.Item>
          <Form.Item
            label="secret"
            name="secret"
            initialValue={editValue?.secret || ""}
            rules={[
              {
                required: true,
                message: "secret不能为空!",
              },
            ]}
          >
            <PubInput placeholder="secret！" max={50} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Index
