import React, { useEffect, useState, useRef } from "react"
import {
  Input,
  Select,
  Button,
  Table,
  Modal,
  message,
  Form,
  Card,
  Radio,
  Tooltip,
  Popconfirm,
} from "antd"
import * as DeviceServices from "../../services/device_manage"
import * as StoreSever from "../../services/store"

import { QuestionCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import styles from "./Index.module.css"
const { Option } = Select

function Index(props) {
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度

  //
  const [modalOpen, setModalOpen] = useState(false) // Modal
  const [modaltext, setModaltext] = useState("") //动态添加/编辑Modal-title
  const [FormValuie, setFormValuie] = useState({
    name: undefined, // 区域名称
    regionNum: undefined, // 区域编号
    shopId: undefined,
    type: undefined, // 区域性质
    id: undefined, // 门店表自增ID
    thirdShopCode: undefined, // 门店编号
  }) // 设备门店时的回显
  const [isEdit, setIsEdit] = useState(false) // 判断是新增还是编辑
  //监控设置

  //排序类型
  const [sortedInfo, setSortedInfo] = useState({})
  //
  const [shopRegionList, setShopRegionList] = useState([]) //店内区位列表
  const [total, setTotal] = useState({}) //店内区位列表
  const [loading, setLoading] = useState(false) //门店列表loading
  const [PullList, setPullList] = useState([]) //门店下拉

  const [ParamsInfo, setParamsInfo] = useState({
    shopId: undefined, //门店id
    pageNum: 1,
    pageSize: 20,
    groudbynumber: 0, // 排序传值
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])
  //

  useEffect(() => {
    setTimeout(getPullList) //门店下拉列表
    if (props?.location?.search) {
      setParamsInfo({
        ...ParamsInfo,
        shopId:
          props?.location?.search?.split("?")[1].split("$")[0].split("=")[1] *
            1 || undefined,
      })
    }

    setTimeout(getShopRegionListpage, 0)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 查询
   */
  const handlSearch = () => {
    //点击查询时将子集关闭，排除数据污染
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    setTimeout(getShopRegionListpage, 0)
  }

  /**
   * 重置
   */
  const handClear = async () => {
    window.localStorage.removeItem("shopId")
    setParamsInfo({
      shopId: "", //门店id
      pageNum: 1,
      pageSize: 20,
      groudbynumber: 0, // 排序传值
    })
    setSortedInfo("")

    setTimeout(getShopRegionListpage, 0)
  }

  /**
   * 门店下拉列表
   */
  const getPullList = () => {
    const params = {
      pageNum: 1,
      pageSize: 9999999,
    }
    DeviceServices.Pull_list(params)
      .then((res) => {
        if (res.code == 200) {
          if (res.data) {
            setPullList(res.data.list || [])
          }
        } else {
          setPullList([])
        }
      })
      .catch((err) => {
        setPullList([])
      })
  }

  /**
   * 门店下设备列表
   */
  const getShopRegionListpage = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      setLoading(true)
      const { code, data, msg } = await StoreSever.ShopRegionListpage(
        ParamsInfoRef
      )
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLoading(false)
      setShopRegionList(data.list)
      setTotal(data.total)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  /**
   * modal确认按钮
   */
  const handleOk = async () => {
    try {
      if (!FormValuie?.name) {
        message.warning("请输入区域名称")
        return
      }
      if (!FormValuie?.regionNum && !isEdit) {
        message.warning("请输入区域编号")
        return
      }
      if (!FormValuie?.shopId) {
        message.warning("请选择所属门店")
        return
      }
      if (!FormValuie?.type) {
        message.warning("请勾选区域性质")
        return
      }
      if (isEdit) {
        const { code, msg, data } = await StoreSever.EditShopRegion(FormValuie)
        if (code != 200) {
          message.error(msg)
          return
        }
        if (data) {
          message.success("店内区位编辑成功")
          setModalOpen(false)
          setTimeout(getShopRegionListpage)
        }
      } else {
        const { code, msg, data } = await StoreSever.AddShopRegion(FormValuie)
        if (code != 200) {
          message.error(msg)
          return
        }
        if (data) {
          message.success("店内区位新建成功")
          setModalOpen(false)
          setTimeout(getShopRegionListpage)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 删除
   */
  const onDelete = async (id) => {
    try {
      const params = { id }
      const { code, msg, data } = await StoreSever.ShopRegionDelete(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        setTimeout(getShopRegionListpage)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //父级，门店列表接口
  const columns = [
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "区位编号",
      dataIndex: "regionNum",
      ellipsis: { showTitle: true },
      key: "regionNum",
      width: 200,
    },
    {
      title: "区位名称",
      dataIndex: "name",
      ellipsis: { showTitle: true },
      key: "name",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "区位性质",
      dataIndex: "type",
      ellipsis: { showTitle: true },
      key: "type",
      width: 200,
      render: (info) => {
        return (
          <>
            {info == 1
              ? "内场"
              : info == 2
              ? "人工收银"
              : info == 3
              ? "自助收银"
              : "-"}
          </>
        )
      },
    },
    {
      title: "设备数",
      dataIndex: "deviceCount",
      ellipsis: { showTitle: true },
      key: "deviceCount",
      width: 150,
      sorter: true,
      groudbynumber: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "deviceCount" ? sortedInfo?.order : null,
      render: (info) => {
        return <>{info ? info : "0"}</>
      },
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setModaltext("编辑店内区位")
                setModalOpen(true)
                setIsEdit(true)
                setFormValuie({
                  shopId: rowInfo?.shopId || "",
                  name: rowInfo?.name || "",
                  regionNum: rowInfo?.regionNum || "",
                  type: rowInfo?.type || "",
                  id: rowInfo?.id || "",
                  thirdShopCode: rowInfo?.thirdShopCode || "",
                })
              }}
            >
              编辑
            </Button>

            <Popconfirm
              title="删除"
              description="确认删除该数据吗?"
              onConfirm={() => {
                onDelete(rowInfo.id)
              }}
              onCancel={() => {}}
              okText="确认"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  /**
   * 排序
   */
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)

    // ascend:正序      descend：倒序
    if (sorter.field === "deviceCount") {
      setParamsInfo({
        ...ParamsInfo,
        groudbynumber:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : 0,
        pageNum: pagination.current,
      })
    }
    setTimeout(getShopRegionListpage, 0)
  }
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
    }
    return paginationProps
  }
  return (
    <div>
      <Card>
        <div className={styles.device_list_head}>
          <div className={styles.device_list_head_search}>
            <div className={styles.device_list_head_search_input_or_select}>
              门店名称：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                style={{ width: 200 }}
                placeholder="请选择所属门店"
                allowClear
                value={ParamsInfo.shopId || undefined}
                onChange={(e) => {
                  //清除门店管理点击设备数跳转设备管理的门店id
                  window.localStorage.removeItem("shopId")
                  setParamsInfo({ ...ParamsInfo, shopId: e || undefined })
                }}
              >
                {PullList.map((v) => {
                  return (
                    <Option value={v.shopId} key={v.shopId}>
                      {v.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className={styles.device_list_head_right}>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
            <Button onClick={handClear}>重置</Button>
          </div>
        </div>
      </Card>

      <Card style={{ marginTop: "16px" }}>
        {/*新建 */}
        <div className={styles.device_list_caozo_btn}>
          <Button
            type="primary"
            onClick={() => {
              setFormValuie({
                name: undefined, // 区域名称
                regionNum: undefined, // 区域编号
                shopId: undefined,
                type: undefined, // 区域性质
                id: undefined,
                thirdShopCode: undefined, //fsadfs
              })
              setIsEdit(false)
              setModalOpen(true)
              setModaltext("新建店内区位")
            }}
          >
            新建
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={shopRegionList}
            rowKey="id"
            loading={loading}
            scroll={{ x: "max-content", y: `${WinHeight - 430}px` }}
            onChange={handleChange}
            // style={{ minHeight: `${WinHeight - 350}px` }}
          />
        </div>
      </Card>

      {/* 编辑设备 */}
      <Modal
        title={modaltext}
        open={modalOpen}
        keyboard
        maskClosable
        onOk={() => handleOk()}
        onCancel={() => {
          setModalOpen(false)
        }}
      >
        <div className={styles.device_list_modal_edit_device}>
          <div className={styles.device_list_modal_edit_device_child}>
            <div className={styles.device_list_modal_edit_device_childname}>
              区位名称： <span>*</span>
            </div>
            <Input
              placeholder="请输入区位名称"
              style={{ width: "290px" }}
              value={FormValuie?.name}
              showCount
              maxLength={20}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, name: e.target.value })
              }}
            />
          </div>
          <div className={styles.device_list_modal_edit_device_child}>
            <div className={styles.device_list_modal_edit_device_childname}>
              区位编号：{isEdit ? null : <span>*</span>}
            </div>
            <Input
              // disabled={isEdit ? true : false}
              placeholder="请输入区位编号"
              style={{ width: "290px" }}
              value={FormValuie?.regionNum}
              showCount
              maxLength={20}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, regionNum: e.target.value })
              }}
            />
          </div>
          {/* {isEdit ? (
            <div className={styles.device_list_modal_edit_device_child}>
              <div className={styles.device_list_modal_edit_device_childname}>
                门店编号：
              </div>
              <Input
                disabled={isEdit ? true : false}
                placeholder="请输入门店编号"
                style={{ width: "290px" }}
                value={FormValuie?.thirdShopCode}
                showCount
                maxLength={20}
              />
            </div>
          ) : null} */}

          <div className={styles.page_store_select}>
            <div className={styles.device_list_modal_edit_device_child}>
              <div className={styles.device_list_modal_edit_device_childname}>
                所属门店： <span>*</span>
              </div>
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属门店"
                style={{ width: 290 }}
                // allowClear
                value={FormValuie?.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setFormValuie({ ...FormValuie, shopId: e })
                }}
              >
                {PullList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className={styles.page_store_select}>
            <div className={styles.device_list_modal_edit_device_child}>
              <div className={styles.device_list_modal_edit_device_childname}>
                区位性质： <span>*</span>
              </div>
              <Radio.Group
                onChange={(e) => {
                  setFormValuie({ ...FormValuie, type: e.target.value })
                }}
                value={FormValuie?.type}
              >
                <Radio value={1}>内场</Radio>
                <Radio value={2}>人工收银区</Radio>
                <Radio value={3}>自助收银区</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Index
