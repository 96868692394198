import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Modal,
  Table,
  Switch,
  message,
  Select,
  Tooltip,
  Form,
  Input,
  Radio,
} from "antd"
import * as JiaSaiServer from "../../services/jiasai"
import { useHistory } from "react-router-dom"
import { QuestionCircleOutlined, QuestionCircleFilled } from "@ant-design/icons"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"

const { Option } = Select

function Index(props) {
  let history = useHistory()
  const [form] = Form.useForm()
  const [reviewList, setReviewList] = useState([]) // 防加塞规则列表
  const [shopList, setShopList] = useState([]) // 门店列表
  const [deviceList, setDeviceList] = useState([]) // 设备列表
  const [goodsList, setGoodsList] = useState([]) // 门店列表
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false) // 删除Modal状态
  const [addOrEditOpen, setAddOrEditOpen] = useState(false) // 新建编辑Modal状态
  const [id, setId] = useState("") // 删除编辑用到的Id

  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    name: undefined, // 商品名称
    shopId: undefined, // 门店ID
    isOpen: undefined,
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // 初始化
  useEffect(() => {
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    // 孤儿商品规则列表接口，
    setTimeout(getReviewList, 0)

    // 门店列表
    getShopList()
  }, [])

  // 清除表单缓存，editData在变化时立即清空Form表单，比如点击编辑按钮时存储编辑回显数据时，就会执行
  useEffect(() => {
    // setTimeout(() => {
    //   form.resetFields()
    // })
    if (!addOrEditOpen) {
      form.resetFields() // 当Modal关闭时，重置表单
    }
  }, [addOrEditOpen, form])

  /**
   * 防加塞规则列表
   */
  const getReviewList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      setLoading(true)
      const { code, data, msg } = await JiaSaiServer.Review_List(ParamsInfoRef)
      if (code != 200 || data === null) {
        message.error(msg)
        setLoading(false)
        return
      }
      setReviewList(data.list || [])
      setTotal(data.total || 0)
      setLoading(false)
    } catch (error) {
      message.error("防加塞规则列表获取失败")
      setLoading(false)
    }
  }

  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await JiaSaiServer.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data?.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      //孤儿商品规则列表
      setTimeout(getReviewList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        pageNum: 1,
        pageSize: 20,
        name: undefined, // 商品名称
        shopId: undefined, // 门店ID
        isOpen: undefined,
      })
      //孤儿商品规则列表
      setTimeout(getReviewList, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 开关编辑
   */
  const onEdit = async (id, checked) => {
    console.log(checked)
    try {
      let params = { id: id, isOpen: checked ? 1 : 0 }
      const { code, data, msg } = await JiaSaiServer.Review_Switch(params)
      if (code != 200 || data === null) {
        message.error(msg)
        return
      }
      message.success(`操作成功`)
      //孤儿商品规则列表
      setTimeout(getReviewList, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 删除
   */
  const onDelete = async () => {
    try {
      const { code, data, msg } = await JiaSaiServer.Review_Delete(id)
      if (code != 200 || data === null) {
        message.error(msg)
        return
      }
      message.success("操作成功")
      setDeleteOpen(false)
      //孤儿商品规则列表
      setTimeout(getReviewList, 0)
    } catch (error) {
      // message.error("删除失败")
      return
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({ ...ParamsInfo, pageNum: current })
        // 孤儿商品规则列表
        setTimeout(getReviewList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 设备列表
   */
  const getDeviceList = async (shopId) => {
    try {
      const { code, msg, data } = await JiaSaiServer.Shop_Device(shopId)
      if (code != 200) {
        message.error(msg)
        return
      }
      setDeviceList(data || [])
    } catch (error) {}
  }

  /**
   * 获取门店下商品信息
   */
  const getShopSkuList = async (shopId) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 999999,
        shopId: shopId,
        priceUnit: "0",
        skuName: "",
      }
      const { code, msg, data } = await JiaSaiServer.Shop_Sku_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setGoodsList(data?.list || [])
    } catch (error) {}
  }
  /**
   * 编辑详情
   */
  const getDetails = async (id) => {
    try {
      const { code, data, msg } = await JiaSaiServer.Review_Detail(id)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data?.shopId) {
        // 请求该门店下的设备列表
        getDeviceList(data?.shopId || "")
        // 请求该门店下的商品列表
        getShopSkuList(data?.shopId || "")
      }

      form.setFieldsValue({ ...data, deviceRules: 1 })
      // 打开Modal
      setAddOrEditOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监听表单提交
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values)
        if (!values.weightThresholdValue) {
          message.error("请设置重量阈值设置")
          return
        }
        // 有Id是编辑，否则是新建
        if (id) {
          const params = {
            ...values,
            id,
          }
          const { code, msg, data } = await JiaSaiServer.Review_Edit(params)
          if (code != 200) {
            message.error(msg)
            return
          }
          if (data) {
            message.success("编辑规则成功")
            setId("")
            setAddOrEditOpen(false)
            //孤儿商品规则列表
            setTimeout(getReviewList, 0)
          }
        } else {
          const { code, msg, data } = await JiaSaiServer.Review_Add(values)
          if (code != 200) {
            message.error(msg)
            return
          }
          if (data) {
            message.success("新建规则成功")
            setId("")
            setAddOrEditOpen(false)
            //孤儿商品规则列表
            setTimeout(getReviewList, 0)
          }
        }
      })
      .catch((err) => {
        // 校验失败
        console.log(err)
      })
  }

  const columns = [
    {
      title: "规则编码",
      dataIndex: "id",
      key: "id",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "规则名称",
      dataIndex: "name",
      key: "name",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "重量阈值",
      dataIndex: "weightThresholdValue",
      key: "weightThresholdValue",
      ellipsis: { showTitle: true },
      width: 200,
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="复核称重的重量与扫码标签重量的差值">
          <QuestionCircleOutlined style={{ fontSize: "15px" }} />
        </Tooltip>
      ),
    },
    {
      title: "适用门店",
      dataIndex: "shopName",
      key: "shopName",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "开关",
      dataIndex: "isOpen",
      ellipsis: { showTitle: true },
      width: 200,
      fixed: "right",
      render: (info, rowInfo) => {
        return (
          <Switch
            checked={info == 1 ? true : false}
            onChange={(checked) => onEdit(rowInfo.id, checked)}
          />
        )
      },
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                // 存储ID
                setId(rowInfo.id)
                // 调用获取编辑详情接口方法
                getDetails(rowInfo.id)
                form.resetFields() // 清除表单字段
              }}
            >
              编辑
            </Button>
            <Button
              type="link"
              onClick={() => {
                // 存储ID
                setId(rowInfo.id)
                // 打开删除二次弹窗提示
                setDeleteOpen(true)
              }}
            >
              删除
            </Button>
          </>
        )
      },
    },
  ]
  return (
    // 防损列表
    <div>
      <Card>
        <div className={styles.loss_prevention_list_head}>
          <div className={styles.loss_prevention_list_head_search}>
            <div className={styles.loss_prevention_list_head_search_child}>
              规则名称：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    name: val,
                  })
                }}
                placeholder="规则名称"
                value={ParamsInfo.name}
              />
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              所属门店：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属门店"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                  })
                }}
              >
                {shopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              状态：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择开关"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.isOpen}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    isOpen: e || undefined,
                  })
                }}
              >
                <Option value={"1"} key={"1"}>
                  开
                </Option>
                <Option value={"0"} key={"0"}>
                  关
                </Option>
              </Select>
            </div>
          </div>
          <div className={styles.loss_prevention_list_head_btn}>
            <Button onClick={handClearn}>重置</Button>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <div style={{ marginBottom: 20 }}>
          <Button
            type="primary"
            onClick={() => {
              setId("")
              setAddOrEditOpen(true)
              setDeviceList([])
              setGoodsList([])
              form.setFieldsValue({ deviceRules: 1 })
            }}
          >
            新建规则
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={reviewList}
            rowKey="id"
            loading={loading}
            scroll={{ x: 1000, y: window.screen.height * 0.49 }}
          />
        </div>
      </Card>

      {/* 删除Modal */}
      <Modal
        onCancel={() => {
          setId("")
          setDeleteOpen(false)
        }}
        onOk={() => {
          onDelete()
        }}
        open={deleteOpen}
        title="系统提示"
      >
        <QuestionCircleFilled
          style={{ color: "#E6A23C", marginRight: "5px" }}
        />
        删除后将不可恢复，确定要删除吗？
      </Modal>

      {/* 新建编辑Modal */}
      <Modal
        onCancel={() => {
          setId("")
          setAddOrEditOpen(false)
        }}
        onOk={onFinish}
        open={addOrEditOpen}
        title={id ? "编辑规则" : "新建规则"}
        width={"800px"}
        keyboard
      >
        <Form
          style={{ width: "750px" }}
          form={form}
          labelCol={{ span: 7 }}
          colon={false}
          // wrapperCol={{ span: 9 }}
          onFinish={onFinish}
        >
          {/* 规则名称 */}
          <Form.Item
            label="规则名称 ："
            name="name"
            rules={[
              { required: true, message: "规则名称不能为空!" },
              {
                pattern: new RegExp(/^[\u4e00-\u9fa5a-zA-Z0-9]*$/, "g"),
                message: "禁止输入特殊符号",
              },
            ]}
          >
            <Input
              placeholder="输入规则名称"
              style={{ width: "80%" }}
              maxLength={20}
              showCount
            />
          </Form.Item>

          {/* 适用门店 */}
          <Form.Item
            label="适用门店 ："
            name="shopId"
            rules={[
              {
                required: true,
                message: "适用门店不能为空!",
              },
            ]}
          >
            <Select
              // mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择适用门店"
              style={{ width: "80%" }}
              allowClear
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
              onChange={(e) => {
                if (e) {
                  setDeviceList([])
                  setGoodsList([])
                  form.resetFields(["effectDeviceIdList", "skuList"])
                  // 请求该门店下的设备列表
                  getDeviceList(e)
                  // 请求该门店下的商品列表
                  getShopSkuList(e)
                } else {
                  form.resetFields(["effectDeviceIdList", "skuList"])
                  setDeviceList([])
                  setGoodsList([])
                }
              }}
            >
              {shopList &&
                shopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>

          {/* 生效设备 */}
          <Form.Item
            label="生效设备 ："
            name="effectDeviceIdList"
            rules={[
              {
                required: true,
                message: "生效设备不能为空!",
              },
            ]}
          >
            <Select
              mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择生效设备"
              style={{ width: "80%" }}
              allowClear
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
            >
              {deviceList &&
                deviceList.map((item, index) => {
                  return (
                    <Option value={item?.deviceId} key={index}>
                      {item?.cdKeyCode}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>

          {/* 适用商品 */}
          <Form.Item
            label="适用商品 ："
            name="skuList"
            rules={[
              {
                required: true,
                message: "适用商品不能为空!",
              },
            ]}
          >
            <Select
              mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择适用商品"
              style={{ width: "80%" }}
              allowClear
              showSearch
              filterOption={(i, o) =>
                (o.props.children[2] + "" + o.props.children[0]).includes(i)
              }
            >
              {goodsList &&
                goodsList.map((item, index) => {
                  return (
                    <Option value={item?.skuCode} key={index}>
                      {item?.skuCode} {item?.skuName}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>

          {/* 设备规则 */}
          <Form.Item
            label="设备规则 ："
            name="deviceRules"
            rules={[
              {
                required: true,
                message: "设备规则不能为空!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value={1}> 内场打秤后，银台需复核称重 </Radio>
            </Radio.Group>
          </Form.Item>

          {/* 重量阈值设置 */}
          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "220px",
              }}
            >
              <span style={{ marginBottom: "20px" }}>
                重量阈值设置：大于标签量
              </span>
              <Form.Item
                label=" "
                name="weightThresholdValue"
                rules={[
                  {
                    pattern: new RegExp(/^(1[0-4]\d{3}|[1-9]\d{0,3})$/),
                    message: "只能输入大于0小于15000的正整数",
                  },
                ]}
              >
                <Input
                  maxLength={5}
                  placeholder="请设置"
                  style={{ width: "100px", margin: "0 10px" }}
                  addonAfter="g"
                />
              </Form.Item>
              <span style={{ marginBottom: "20px" }}>生成异常记录</span>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Index
