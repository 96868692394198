import React from "react"
import { DatePicker } from "antd"
import dayjs from "dayjs"
import moment from "moment"
import "moment/locale/zh-cn"
import locale from "antd/lib/date-picker/locale/zh_CN"
moment.locale("zh-cn")
const { RangePicker } = DatePicker
const dateFormat = "YYYY-MM-DD"

/**
 * RangePicker时间区间选择封装公用
 */
export const PubRangePicker = (props) => {
  /**
   * 时间选择限制天数（天数自定义）
   */
  const disabledDate = (current) => {
    if (!props?.value) {
      return false
    }
    const tooLate =
      props?.value[0] && current.diff(props?.value[0], "days") >= props?.disDate
    const tooEarly =
      props?.value[1] && props?.value[1].diff(current, "days") >= props?.disDate
    return !!tooEarly || !!tooLate
  }
  return (
    <RangePicker
      locale={locale}
      disabledDate={props?.disDate ? disabledDate : false} // 不可选择的日期
      value={
        props?.value?.length == 0
          ? []
          : [
              dayjs(props?.value[0], dateFormat),
              dayjs(props?.value[1], dateFormat),
            ]
      }
      onChange={(e) => {
        if (props?.onChange) {
          // 有值传入时进行处理，否则else走默认处理
          if (e) {
            props?.onChange({
              // 总时间区间
              TotalTime: e,

              // 开始时间字符串格式
              startDate: e[0].format("YYYY-MM-DD") + " " + "00:00:00",

              // 结束时间字符串格式
              endDate: e[1].format("YYYY-MM-DD") + " " + "23:59:59",

              // 开始时间时间戳格式
              startDateValueOf: dayjs(
                e[0].format("YYYY-MM-DD") + " " + "00:00:00"
              ).valueOf(),

              // 结束时间时间戳格式
              endDateValueOf: dayjs(
                e[1].format("YYYY-MM-DD") + " " + "23:59:59"
              ).valueOf(),
            })
          } else {
            props?.onChange({
              TotalTime: [
                dayjs(dayjs().format("YYYY-MM-DD") + " " + "00:00:00"),
                dayjs(dayjs().format("YYYY-MM-DD") + " " + "23:59:59"),
              ],
              startDate: dayjs().format("YYYY-MM-DD") + " " + "00:00:00",
              endDate: dayjs().format("YYYY-MM-DD") + " " + "23:59:59",
              startDateValueOf: dayjs(
                dayjs().format("YYYY-MM-DD") + " " + "00:00:00"
              ).valueOf(),
              endDateValueOf: dayjs(
                dayjs().format("YYYY-MM-DD") + " " + "23:59:59"
              ).valueOf(),
            })
          }
        }
      }}
    />
  )
}

/**
 * 全局默认时间对象
 * TotalTime：总时间
 * startDate:开始时间
 * endDate：结束时间
 */
export const HandDefaultTime = {
  TotalTime: [
    dayjs(dayjs().format("YYYY-MM-DD") + " " + "00:00:00"),
    dayjs(dayjs().format("YYYY-MM-DD") + " " + "23:59:59"),
  ],

  /**
   * 当天开始时间与结束时间字符串格式
   */
  startDate: dayjs().format("YYYY-MM-DD") + " " + "00:00:00",
  endDate: dayjs().format("YYYY-MM-DD") + " " + "23:59:59",
  /**
   * 当天开始时间与结束时间时间戳格式
   */
  startDateValueOf: dayjs(
    dayjs().format("YYYY-MM-DD") + " " + "00:00:00"
  ).valueOf(),
  endDateValueOf: dayjs(
    dayjs().format("YYYY-MM-DD") + " " + "23:59:59"
  ).valueOf(),

  /**
   * 一月前开始时间与结束时间字符串格式
   */
  MonStaDat:
    dayjs().subtract(1, "month").format("YYYY-MM-DD") + " " + "00:00:00",
  MonEndDat: dayjs().format("YYYY-MM-DD") + " " + "23:59:59",
  /**
   * 一个月前开始时间与结束时间时间戳格式
   */
  MonStaDatVal: dayjs(
    dayjs().subtract(1, "month").format("YYYY-MM-DD") + " " + "00:00:00"
  ).valueOf(),
  MonEndDatVal: dayjs(
    dayjs().format("YYYY-MM-DD") + " " + "23:59:59"
  ).valueOf(),

  /**
   * 一天前开始时间与结束时间时间戳格式
   */
  OneDayStaDatVal: dayjs(
    dayjs().subtract(1, "day").format("YYYY-MM-DD") + " " + "00:00:00"
  ).valueOf(),
  OneDayEndDatVal: dayjs(
    dayjs().subtract(1, "day").format("YYYY-MM-DD") + " " + "23:59:59"
  ).valueOf(),
  /**
   * 一天前开始时间与结束时间字符串格式
   */
  OneDayStaDatStr: dayjs(
    dayjs().subtract(1, "day").format("YYYY-MM-DD") + " " + "00:00:00"
  ).format("YYYY-MM-DD HH:mm:ss"),
  OneDayEndDatStr: dayjs(
    dayjs().subtract(1, "day").format("YYYY-MM-DD") + " " + "23:59:59"
  ).format("YYYY-MM-DD HH:mm:ss"),
}
