import { POST, GET } from "../../utils/request"
/**
 * 共享记录列表查询
 */
export const Task_List = (params) =>
  POST("/data/center/v1/share/learn/task/list", params)

/**
 * 接收设备设备列表

 */
export const Device_List = (params) =>
  POST("/data/center/v1/share/learn/task/device/list", params)

/**
 * 接收设备设备列表

 */
export const Tree_List = (params) =>
  GET("/data/center/v1/share/learn/shop_tree_list", params)

/**
 * 信息基准门店
 */
export const Info_Benchmark = () =>
  GET("/data/center/v1/setting/info-benchmark-shop")

/**
 * 门店列表查询接口
 */
export const Shop_List = (params) =>
  POST("/data/center/v1/goods/shop_list", params)

/**
 * 门店下设备下拉列表
 */
export const Shop_Device = (params) =>
  GET(`/data/center/v1/device/pull/list?shopId=${params}`)

/**
 * 列表查询
 */
export const Learn_List = (params) =>
  POST("/data/center/v1/share/learn/list", params)

/**
 * 图片查询
 */
export const img_List = (params) =>
  POST("/data/center/v1/share/learn/image/list", params)

/**
 * 创建任务
 */
export const Task_Create = (params) =>
  POST("/data/center/v1/share/learn/task/create", params)

/**
 * 获取icon 匹配数据
 */
export const sku_list = (params) =>
  POST(`/data/center/v1/share/learn/sku/list`, params)

/**
 * 项目下所有的设备列表
 */
export const SimplePullList = (params) =>
  GET(`/data/center/v1/device/simple/pull/list`)
