import {
  Table,
  Button,
  message,
  Cascader,
  Select,
  DatePicker,
  Input,
  Row,
  Col,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import { CalendarOutlined, RightCircleOutlined } from "@ant-design/icons"
import "./Index.css"
import {
  GoodsChangePrice,
  ExportGoodsChange,
  LastWeek,
  LastMonth,
} from "../../services/lossmanage"
import { Link } from "react-router-dom"

const { Option } = Select
const dateFormat = "YYYY-MM-DD"
const tabs = [
  { name: "日报", value: 1 },
  { name: "周报", value: 2 },
  { name: "月报", value: 3 },
]

function Index() {
  const [dateTime, setDateTime] = useState()
  const [tabKey, setTabKey] = useState(1) //tab值，1：日报，2：周报，3：月报
  const [weekList, setWeekList] = useState([]) //周报要展示的列表
  const [monthList, setMonthList] = useState([]) //月要展示的列表
  const [dateValue, setDateValue] = useState("") //日报选择的日期值
  const [weekValue, setWeekValue] = useState([]) //周报选择的日期值
  const [monthValue, setMonthValue] = useState("") //月报选择的日期值
  const [goodValue, setGoodValue] = useState(undefined) //商品inputvalue
  const [loading, setLoading] = useState(false) //loading
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [shopTable, setShopTable] = useState([]) //商品表格列表
  const [shopTableInfo, setShopTableInfo] = useState({}) //商品表格接口数据
  const [lastSearch, setLastSearch] = useState({
    dateType: 1,
    code: "",
    date: dayjs().format("YYYYMMDD"),
    pageNum: 1,
    pageSize: 20,
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    lastSearchRef: {}, //点击查询后的条件值
  })
  useEffect(() => {
    refInfo.current = {
      lastSearchRef: lastSearch,
    }
  }, [tabKey, lastSearch])
  //

  useEffect(() => {
    onGetWeek() //(调用获取周列表接口方法)
    onGetMonth() //(调用获取月列表接口方法)

    setDateTime(dayjs().format("YYYYMMDD"))
    // 延迟调用商品列表
    setTimeout(onGetShopList, 0)
  }, [])

  /**
   * 当tab值切换时就会useEffect
   */
  useEffect(() => {
    let DateTime = ""
    // 日报
    if (tabKey == 1) {
      setDateValue(dayjs().format(dateFormat))
      // 统计数据传值
      DateTime = dayjs().format("YYYYMMDD")
    }
    // 周报
    if (tabKey == 2) {
      setWeekValue([
        weekList[0].value,
        weekList[0].children[weekList[0].children.length - 1].value,
      ])
      // 统计数据传值
      DateTime = weekList[0].children[weekList[0].children.length - 1].value
    }
    // 月报
    if (tabKey == 3) {
      setMonthValue(monthList[monthList.length - 1].yearMonth)
      // 统计数据传值
      DateTime = dayjs(monthList[monthList.length - 1].yearMonth).format(
        "YYYYMM"
      )
    }
    setDateTime(DateTime)
  }, [tabKey])

  /**
   * 获取周列表
   */
  const onGetWeek = async () => {
    try {
      const { code, data, msg } = await LastWeek()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.label = v.year
          v.value = v.year
          v.children = v.lastWeekRspList
          v.children.map((w) => {
            w.value =
              dayjs(w.weekStartDate).format("YYYYMMDD") +
              "-" +
              dayjs(w.weekEndDate).format("YYYYMMDD")
            w.label =
              w.weekStartDate + "-" + w.weekEndDate + `（第${w.weekOfYear}）周`
          })
        })
      }
      setWeekList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取月列表
   */
  const onGetMonth = async () => {
    try {
      const { code, data, msg } = await LastMonth()
      if (code != 200) {
        message.error(msg)
        return
      }
      setMonthList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 商品列表接口
   */
  const onGetShopList = async (value) => {
    try {
      setLoading(true)
      const { lastSearchRef } = refInfo?.current
      const { code, msg, data } = await GoodsChangePrice(lastSearchRef)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLoading(false)
      setShopTable(data.list || [])
      setShopTableInfo(data)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const onHandSearch = () => {
    setPageNum(1)
    setLastSearch({
      ...lastSearch,
      pageNum: 1,
      dateType: tabKey,
      code: goodValue,
      date: dateTime,
    })
    setTimeout(onGetShopList, 0)
  }

  /**
   * 重置
   */
  const onHandClearn = () => {
    setPageNum(1)
    //重置归为日报
    setTabKey(1)
    //日报时间归当天
    setDateValue(dayjs().format(dateFormat))
    //门店归为undefined
    setGoodValue(undefined)
    //日期默认今天
    setDateTime(dayjs().format("YYYYMMDD"))
    setLastSearch({
      ...lastSearch,
      pageNum: 1,
      dateType: 1,
      code: "",
      date: dayjs().format("YYYYMMDD"),
    })
    setTimeout(onGetShopList, 0)
  }

  /**
   * 导出
   */
  const onHandExport = async () => {
    try {
      const { lastSearchRef } = refInfo?.current
      const res = await ExportGoodsChange(lastSearchRef)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${shopTableInfo?.total}条`, //接口返回
      pageSize: pageSize,
      current: pageNum,
      total: shopTableInfo?.total, //接口返回
      onChange: (current) => {
        setPageNum(current)
        setLastSearch({
          ...lastSearch,
          pageNum: current,
        })
        setTimeout(onGetShopList, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "商品",
      dataIndex: "goodsName",

      key: "goodsName",
      ellipsis: { showTitle: true },
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",

      key: "skuCode",
      ellipsis: { showTitle: true },
    },
    {
      title: "改价总次数",
      dataIndex: "priceCount",

      key: "priceCount",
      ellipsis: { showTitle: true },
      render: (info, rowInfo) => {
        return (
          <Link
            to={{
              pathname: "/admin/lossmanage/updateroad",
              state: { skuCode: rowInfo.goodsName },
            }}
          >
            <span style={{ color: "#2EB998" }}>{info}</span>
          </Link>
        )
      },
    },
  ]

  return (
    <div className="goodDetail_page">
      <Row>
        <Col span={14} style={{ display: "flex" }}>
          <Select
            style={{ width: 120 }}
            onChange={(e) => {
              setTabKey(e)
            }}
            placeholder="请选择"
            value={tabKey}
          >
            {tabs.map((v) => {
              return (
                <Option value={v.value} key={v.value}>
                  {v.name}
                </Option>
              )
            })}
          </Select>
          {/* 日期选择 */}
          <div className="goodDetail_page_time">
            {/* 日期选择框 */}
            {tabKey == 1 ? (
              <DatePicker
                style={{ width: 200 }}
                onChange={(e) => {
                  setDateValue(dayjs(e).format(dateFormat))
                  setDateTime(dayjs(e).format("YYYYMMDD"))
                }}
                value={dayjs(dateValue)}
                allowClear={false}
                // suffixIcon={<div></div>}
              />
            ) : null}

            {/* 周报选择框 */}
            {tabKey == 2 ? (
              <Cascader
                style={{ width: "auto" }}
                options={weekList}
                value={weekValue}
                onChange={(e) => {
                  setWeekValue(e)
                  setDateTime(e[1])
                }}
                placeholder="请选择周"
              />
            ) : null}

            {/* 月报选择框 */}
            {tabKey == 3 ? (
              <Select
                style={{ width: 200 }}
                value={monthValue}
                onChange={(e) => {
                  setMonthValue(e)
                  setDateTime(dayjs(e).format("YYYYMM"))
                }}
                placeholder="请选择月"
              >
                {monthList.map((v) => {
                  return (
                    <Option key={v.yearMonth} value={v.yearMonth}>
                      {v.yearMonth}
                    </Option>
                  )
                })}
              </Select>
            ) : null}
          </div>
          <div className="goodDetail_page_shop">
            商品：
            <Input
              placeholder="请输入商品"
              className="goodDetail_page_shop_input"
              value={goodValue}
              onChange={(e) => {
                setGoodValue(e.target.value ? e.target.value : undefined)
              }}
            />
          </div>
        </Col>
        <Col span={10}>
          <div className="goodDetail_head_brn">
            <Button type="primary" onClick={onHandExport}>
              导出
            </Button>
            <Button type="primary" onClick={onHandSearch}>
              查询
            </Button>
            <Button onClick={onHandClearn}>重置</Button>
          </div>
        </Col>
      </Row>
      <Table
        dataSource={shopTable}
        columns={columns}
        style={{ marginTop: "20px" }}
        loading={loading}
        pagination={onTableChange()}
        rowKey="id"
      />
    </div>
  )
}

export default Index
