import request, { newRequest } from "../../utils/newRequest"
import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import moment from "moment"

/**
 * 信息基准门店
 */
export const Info_Benchmark = () =>
  GET("/data/center/v1/setting/info-benchmark-shop")

/**
 * 商品列表查询接口
 */
export const Goods_List = (params) => POST("/data/center/v1/goods/list", params)

/**
 * 门店列表查询接口
 */
export const Shop_List = (params) =>
  POST("/data/center/v1/goods/shop_list", params)

/**
 * 门店下设备下拉列表
 */
export const Shop_Device = (params) =>
  GET(`/data/center/v1/device/pull/list?shopId=${params}`)

/**
 * 批量删除接口
 */
export const Deletes_good = (params) =>
  POST("/data/center/v1/goods/delete", params)

/**
 * 批量管理商品状态接口
 */
export const Edit_Status = (params) =>
  POST("/data/center/v1/goods/edit_status", params)

/**
 * 导出商品
 */
export async function Export_Good(params) {
  return newRequest(`/data/center/v1/goods/export/goods`, {
    method: "POST",
    responseType: "blob",
    body: params,
  })
}

/**
 * 获取图形验证码
 */
export const Get_Captcha = (params) => GET("/data/center/v1/goods/captcha")

/**
 * 获取短信验证码
 */
export const Get_Send = (params) =>
  POST("/data/center/v1/goods/sms/send", params)

/**
 * 短信验证码校验
 */
export const Login_Init = (params) =>
  POST("/data/center/v1/goods/sms/login", params)

/**
 * token
 */
export const Get_Token = () => GET("/data/center/v1/qiniuyun/token")

/**
 * 修改图片icon
 */
export const Icon_Choose = (params) =>
  POST("/data/center/v1/goods/icon_choose", params)

// export async function Icon_Choose(params) {
//   return newRequest(`/data/center/v1/goods/icon_choose`, {
//     method: "POST",
//     body: params,
//   })
// }

/**
 * 批量修改商品icon接口
 */
export const Icon_Upload = (params) =>
  POST("/data/center/v1/goods/batch/icon_update", params)

/**
 * 门店、设备查询
 */
export const Shop_Tree = (params) =>
  GET(`/data/center/v1/share/learn/shop_tree_list`)

/**
 * 门店设备
 */
export const ShopTreeList = (params) =>
  GET(
    `/data/center/v1/share/learn/shop_tree_list?code=${
      params ? params : ""
    }&isAll=2`
  )
// export const ShopTreeList = (params) =>
//   POST(`/data/center/v1/shop/pull_tree`, params)

/*
 * 导入商品大小码数据
 */
export const import_size_goods = (params) => {
  let fd = new FormData()
  for (const key in params) {
    fd.append(key, params[key])
  }
  return POST("/data/center/v1/goods/import/size/goods", fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

/*
 * 大小码数据删除
 */
export const size_goods_delete = (params) =>
  POST(`/data/center/v1/goods/size/goods/delete`, params)

/*
 * 大小码数据编辑
 */
export const size_goods_edit = (params) =>
  POST(`/data/center/v1/goods/size/goods/edit`, params)

/**
 * 大小码列表
 */
export const size_goods_list = (params) =>
  GET(`/data/center/v1/goods/size/goods/list?${stringify(params)}`)

/**
 * 大小码商品称重记录列表
 */
export const size_goodslog_list = (params) =>
  GET(`/data/center/v1/goods/size/goods/weight/log?${stringify(params)}`)

/**
 * 大小码商品称重记录导出
 */
export async function size_goodslog_export(params) {
  return newRequest(`/data/center/v1/goods/size/goods/weight/log/export`, {
    method: "GET",
    responseType: "blob",
    params: params,
    // paramsSerializer: (params) => {
    //   return stringify(params, {
    //     arrayFormat: "repeat",
    //   })
    // },
  })
}
