import React, { useEffect, useState, useRef, Children } from "react"
import {
  Input,
  Select,
  Button,
  Table,
  Modal,
  message,
  TreeSelect,
  Tooltip,
  Card,
} from "antd"
import styles from "./Index.module.css"
import { useHistory } from "react-router-dom"
import {
  CaretRightOutlined,
  CaretDownOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons"
import * as OrginServer from "../../services/orgin"
const { Option } = Select

function Index() {
  let history = useHistory()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [colorId, setColorId] = useState()

  const [leftMenuList, setLeftMenuList] = useState([]) // 第二级数据
  const [leftThildList, setLeftThildList] = useState([]) // 第三级数据
  const [leftFourList, setLeftFourList] = useState([]) // 第四级数据
  const [leftFiveList, setLeftFiveList] = useState([]) // 第五级数据
  const [firstId, setFirstId] = useState("") // 第一级
  const [twoId, setTwoId] = useState("") // 第二级
  const [threeId, setThreeId] = useState("") // 第三级
  const [fourId, setFourId] = useState("") // 第四级
  const [fiveId, setFiveId] = useState("") // 第五级
  const [hoverId, setHoverId] = useState("") // 鼠标悬浮ID

  const [deleteOpen, setDeleteOpen] = useState(false) // 表格删除Modal状态
  const [deleteMenuOpen, setDeleteMenuOpen] = useState(false) // 菜单删除Modal状态
  const [addMenuOpen, setAddMenuOpen] = useState(false) // 菜单新增Modal状态

  const [addMenuTitle, setAddMenuTitle] = useState("") // 菜单操作Modal-title
  const [treeData, setTreeData] = useState([]) // 新增树区域菜单数据
  const [qyName, setQyName] = useState(undefined) // 区域名称
  const [sjName, setSjName] = useState(undefined) // 上级区域
  const [editId, setEditId] = useState(undefined) // 编辑时的ID
  const [deleteShopId, setDeleteShopId] = useState(undefined) // 移除关联门店时时的ID
  const [deleteId, setDeleteId] = useState(undefined) // 删除区域时的ID
  const [cenNum, setCenNum] = useState(undefined) // 操作区域时的层级
  const [addArr, setAddArr] = useState([]) // 新建时的路径

  const [total, setTotal] = useState(0) //表格数据总数
  const [loading, setLoading] = useState(false) ///表格数据loading
  const [tableList, setTableList] = useState([]) ///表格数据列表

  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    code: undefined, // 门店名称
    districtId: "0", // 组织架构id
    province: undefined, // 省
    city: undefined, // 市
    region: undefined, // 区
    shopId: undefined, // 门店ID
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])
  //

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 初始化
   */
  useEffect(() => {
    // 获取表格数据
    setTimeout(getDistrictShopList, 0)
    // 组织架构树形列表
    setTimeout(getDistrictTreeList, 0)

    setColorId("0")
    setFirstId(firstId ? "" : "0")
    setTwoId("")
    setThreeId("")
    setFourId("")
    setFiveId("")
    // 获取下级数据
    // setTimeout(getDistrict_List("0", 2), 0)
    getDistrict_List("0", 2)
    setParamsInfo({
      ...ParamsInfo,
      districtId: "0",
      pageNum: 1,
    })
  }, [])

  /**
   * 组织架构树形列表组
   */
  const getDistrictTreeList = async () => {
    try {
      const res = await OrginServer.District_TreeList()
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      if (res?.data?.length > 0) {
        res.data.map((v) => {
          if (v.subList.length > 0) {
            v.subList.map((w) => {
              if (w.subList.length > 0) {
                w.subList.map((z) => {
                  z.subList = []
                })
              }
            })
          }
        })
      }
      let NewArr = [
        {
          id: "0",
          name: "总部",
          subList: res?.data || [],
        },
      ]
      setTreeData(NewArr)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 组织架构分级查询列表
   */
  const getDistrict_List = async (parentId, type) => {
    try {
      const res = await OrginServer.District_List(parentId)
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      if (type == 2) {
        setLeftMenuList(res?.data)
      } else if (type == 3) {
        setLeftThildList(res?.data)
      } else if (type == 4) {
        setLeftFourList(res?.data)
      } else if (type == 5) {
        setLeftFiveList(res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 组织架构关联的门店列表
   */
  const getDistrictShopList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      setLoading(true)
      const { code, data, msg } = await OrginServer.District_ShopList(
        ParamsInfoRef
      )
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLoading(false)
      setTableList(data.list)
      setTotal(data.total)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  /**
   * 组织架构移除关联的门店
   */
  const getDistrictShopRemove = async () => {
    try {
      const params = { shopId: deleteShopId }
      const { code, data, msg } = await OrginServer.District_ShopRemove(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setDeleteOpen(false)
        message.success("移除成功")
        setTimeout(getDistrictShopList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 组织架构删除
   */
  const getDistrictDelete = async () => {
    try {
      const { code, data, msg } = await OrginServer.District_Delete(deleteId)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setDeleteMenuOpen(false)
        message.success("移除成功")
        if (cenNum == 3) {
          // 获取下级数据
          setTimeout(getDistrict_List("0", 2), 0)
        } else if (cenNum == 4) {
          // 获取下级数据
          setTimeout(getDistrict_List(twoId, 3), 0)
        } else if (cenNum == 5) {
          // 获取下级数据
          setTimeout(getDistrict_List(threeId, 4), 0)
        } else if (cenNum == 6) {
          // 获取下级数据
          setTimeout(getDistrict_List(fourId, 5), 0)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 区域保存
   */
  const onSaveArea = async () => {
    try {
      // const { qyName, sjName } = refInfo?.current
      if (!qyName) {
        message.error("请输入区域名称")
        return
      }
      if (!sjName && !editId) {
        message.error("请选择上级区域")
        return
      }
      const params = {
        name: qyName,
        parentId: sjName,
      }
      const paramsEdit = {
        id: editId,
        name: qyName,
      }
      let res
      if (editId) {
        res = await OrginServer.District_Edit(paramsEdit)
      } else {
        res = await OrginServer.District_Add(params)
      }
      console.log(res)
      if (!res) {
        return
      }

      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      if (editId) {
        if (res?.data) {
          message.success("编辑区域成功")
          setAddMenuOpen(false)

          if (cenNum == 3) {
            // 获取下级数据
            setTimeout(getDistrict_List("0", 2), 0)
          } else if (cenNum == 4) {
            // 获取下级数据
            setTimeout(getDistrict_List(twoId, 3), 0)
          } else if (cenNum == 5) {
            // 获取下级数据
            setTimeout(getDistrict_List(threeId, 4), 0)
          } else if (cenNum == 6) {
            // 获取下级数据
            setTimeout(getDistrict_List(fourId, 5), 0)
          }
        } else {
          message.success("编辑区域失败")
        }
      } else {
        if (res?.data?.success) {
          // // console.log(res?.data?.id)
          message.success("新建区域成功")
          setAddMenuOpen(false)
          setFirstId("0")
          setTimeout(getDistrict_List("0", 2), 0)
          if (addArr.length == 1) {
            setTwoId(addArr[0])
            setTimeout(getDistrict_List(addArr[0], 3), 0)
          } else if (addArr.length == 2) {
            setTwoId(addArr[0])
            setTimeout(getDistrict_List(addArr[0], 3), 0)
            setThreeId(addArr[1])
            setTimeout(getDistrict_List(addArr[1], 4), 0)
          } else if (addArr.length == 3) {
            setTwoId(addArr[0])
            setTimeout(getDistrict_List(addArr[0], 3), 0)
            setThreeId(addArr[1])
            setTimeout(getDistrict_List(addArr[1], 4), 0)
            setFourId(addArr[2])
            setTimeout(getDistrict_List(addArr[2], 5), 0)
          }
          // 组织架构树形列表
          setTimeout(getDistrictTreeList, 0)
        } else {
          message.success("新建区域失败")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 区域保存并关联门店
   */
  const onSaveAreaMore = async () => {
    try {
      // const { qyName, sjName } = refInfo?.current
      if (!qyName) {
        message.error("请输入区域名称")
        return
      }
      if (!sjName && !editId) {
        message.error("请选择上级区域")
        return
      }
      const params = {
        name: qyName,
        parentId: sjName,
      }
      const paramsEdit = {
        id: editId,
        name: qyName,
      }
      let res
      if (editId) {
        res = await OrginServer.District_Edit(paramsEdit)
      } else {
        res = await OrginServer.District_Add(params)
      }
      console.log(res)
      if (!res) {
        return
      }

      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      if (editId) {
        if (res?.data) {
          message.success("编辑区域成功")
          setAddMenuOpen(false)
          if (cenNum == 3) {
            // 获取下级数据
            setTimeout(getDistrict_List("0", 2), 0)
          } else if (cenNum == 4) {
            // 获取下级数据
            setTimeout(getDistrict_List(twoId, 3), 0)
          } else if (cenNum == 5) {
            // 获取下级数据
            setTimeout(getDistrict_List(threeId, 4), 0)
          } else if (cenNum == 6) {
            // 获取下级数据
            setTimeout(getDistrict_List(fourId, 5), 0)
          }
          // 跳转
          history.push(`/admin/accountmanage/relatedshops?id=${editId}`)
        } else {
          message.success("编辑区域失败")
        }
      } else {
        if (res?.data?.success) {
          message.success("新建区域成功")
          setAddMenuOpen(false)
          setFirstId("0")
          setTimeout(getDistrict_List("0", 2), 0)
          if (addArr.length == 1) {
            setTwoId(addArr[0])
            setTimeout(getDistrict_List(addArr[0], 3), 0)
          } else if (addArr.length == 2) {
            setTwoId(addArr[0])
            setTimeout(getDistrict_List(addArr[0], 3), 0)
            setThreeId(addArr[1])
            setTimeout(getDistrict_List(addArr[1], 4), 0)
          } else if (addArr.length == 3) {
            setTwoId(addArr[0])
            setTimeout(getDistrict_List(addArr[0], 3), 0)
            setThreeId(addArr[1])
            setTimeout(getDistrict_List(addArr[1], 4), 0)
            setFourId(addArr[2])
            setTimeout(getDistrict_List(addArr[2], 5), 0)
          }
          // 组织架构树形列表
          setTimeout(getDistrictTreeList, 0)
          // 跳转
          history.push(`/admin/accountmanage/relatedshops?id=${res?.data?.id}`)
        } else {
          message.success("新建区域失败")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * table表格columns
   */
  const columns = [
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "所属区域",
      dataIndex: "district",
      ellipsis: { showTitle: true },
      key: "district",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "运营平台账号数量",
      dataIndex: "dataCenterAccountNum",
      ellipsis: { showTitle: true },
      key: "dataCenterAccountNum",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <a
            onClick={() => {
              if (info == 0) {
                message.error("该门店下无运营平台账号")
                return
              }
              history.push(
                `/admin/accountmanage/accountmanage?shopId=${rowInfo?.shopId}&districtId=${rowInfo?.districtId}`
              )
            }}
            style={{ color: "#2eb998" }}
          >
            {info}
          </a>
        )
      },
    },
    {
      title: "小程序账号数量",
      dataIndex: "shopAccountNum",
      ellipsis: { showTitle: true },
      key: "shopAccountNum",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <a
            onClick={() => {
              if (info == 0) {
                message.error("该门店下无小程序账号")
                return
              }
              history.push(
                `/admin/devicemanage/shopmanag/xappmanage?shopId=${rowInfo?.shopId}`
              )
            }}
            style={{ color: "#2eb998" }}
          >
            {info}
          </a>
        )
      },
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      ellipsis: { showTitle: true },
      key: "createdAt",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 100,
      render: (info, rowInfo) => {
        return (
          <Button
            disabled={rowInfo?.district ? false : true}
            type="link"
            onClick={() => {
              setDeleteOpen(true)
              setDeleteShopId(rowInfo?.shopId)
            }}
          >
            移除
          </Button>
        )
      },
    },
  ]

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({
          ...ParamsInfo,
          pageNum: current,
        })
        setTimeout(getDistrictShopList, 0)
      },
    }
    return paginationProps
  }
  return (
    <div>
      <Card style={{ marginBottom: "16px" }}>
        <div className={styles.page_head}>
          <div className={styles.page_head_left}>
            搜索门店：
            <Input
              placeholder="请输入门店"
              value={ParamsInfo?.code}
              onChange={(e) => {
                setParamsInfo({
                  ...ParamsInfo,
                  code: e.target.value,
                })
              }}
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                // 获取表格数据
                setTimeout(getDistrictShopList, 0)
              }}
            >
              查询
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setParamsInfo({
                  ...ParamsInfo,
                  pageNum: 1,
                  pageSize: 20,
                  code: undefined, // 门店名称
                  // districtId: "0", // 组织架构id
                  province: undefined, // 省
                  city: undefined, // 市
                  region: undefined, // 区
                  shopId: undefined, // 门店ID
                })
                // 获取表格数据
                setTimeout(getDistrictShopList, 0)
              }}
            >
              重置
            </Button>
          </div>
        </div>
      </Card>
      <div className={styles.page_body}>
        {/* 左侧Menu菜单 */}
        <Card className={styles.page_body_left}>
          <Button
            type="primary"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              // 清空菜单，打开新增Modal
              setQyName(undefined)
              setEditId(undefined)
              setSjName("0")
              setAddMenuTitle("新增区域")
              setAddMenuOpen(true)
            }}
          >
            新建区域
          </Button>
          <div
            className={styles.page_body_left_list}
            style={{ height: `${WinHeight - 300}px` }}
          >
            <div>
              {/* 第一级 */}
              <div
                className={styles.page_body_left_list_firstbody}
                style={{
                  color: firstId ? "#39BE87" : "",
                  background: colorId === "0" ? "#fafafa" : "",
                  color: colorId === "0" ? "#39BE87" : "",
                }}
                onClick={() => {
                  setColorId("0")
                  setFirstId(firstId ? "" : "0")
                  setTwoId("")
                  setThreeId("")
                  setFourId("")
                  setFiveId("")
                  // 获取下级数据
                  // setTimeout(getDistrict_List("0", 2), 0)
                  getDistrict_List("0", 2)
                  setParamsInfo({
                    ...ParamsInfo,
                    districtId: "0",
                    pageNum: 1,
                  })
                  setTimeout(getDistrictShopList, 0)
                }}
              >
                {firstId ? (
                  <CaretDownOutlined style={{ margin: "0 5px" }} />
                ) : (
                  <CaretRightOutlined style={{ margin: "0 5px" }} />
                )}
                总部
              </div>

              {/* 第二级 省*/}
              {firstId
                ? leftMenuList.map((v) => {
                    return (
                      <div key={v.id}>
                        <div
                          className={styles.page_body_left_list_twobody}
                          style={{
                            color: v.id == twoId ? "#39BE87" : "",
                            background: colorId == v.id ? "#fafafa" : "",
                            color: colorId == v.id ? "#39BE87" : "",
                          }}
                          onClick={() => {
                            setColorId(v.id)
                            setTwoId(v.id == twoId ? "" : v.id)
                            setThreeId("")
                            setFourId("")
                            setFiveId("")
                            if (v.isSub && v.id != twoId) {
                              // 获取下级数据
                              // setTimeout(getDistrict_List(v.id, 3), 0)
                              getDistrict_List(v.id, 3)
                              setParamsInfo({
                                ...ParamsInfo,
                                districtId: v.id,
                                pageNum: 1,
                              })
                              setTimeout(getDistrictShopList, 0)
                            } else {
                              setLeftThildList([])
                              setParamsInfo({
                                ...ParamsInfo,
                                districtId: v.id,
                                pageNum: 1,
                              })
                              setTimeout(getDistrictShopList, 0)
                            }
                          }}
                          onMouseOver={() => {
                            setHoverId(v.id)
                          }}
                          onMouseOut={() => {
                            setHoverId(undefined)
                          }}
                        >
                          <div
                            className={styles.page_body_left_list_title}
                            style={{
                              marginLeft: "20px",
                            }}
                          >
                            {v.isSub ? (
                              twoId && v.id == twoId ? (
                                <CaretDownOutlined
                                  style={{ margin: "0 5px" }}
                                />
                              ) : (
                                <CaretRightOutlined
                                  style={{ margin: "0 5px" }}
                                />
                              )
                            ) : null}

                            <span
                              style={{ marginLeft: v.isSub ? "0px" : "22px" }}
                            >
                              {v.name}
                            </span>
                          </div>
                          {/* 通过悬浮存储ID判断是否要展示操作按钮 */}
                          {v.id == hoverId ? (
                            <div className={styles.page_body_left_list_btn}>
                              <a
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setTimeout(getDistrictTreeList, 0)
                                  setAddArr([v.id])
                                  setAddMenuTitle("新增区域")
                                  setQyName(undefined)
                                  setEditId(undefined)
                                  setSjName(v.id)
                                  setAddMenuOpen(true)
                                }}
                              >
                                新增下级
                              </a>
                              <a
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setTimeout(getDistrictTreeList, 0)
                                  setAddMenuTitle("编辑区域")
                                  setQyName(v.name)
                                  setSjName("0")
                                  setEditId(v.id)
                                  setAddMenuOpen(true)
                                  setCenNum(3)
                                }}
                              >
                                编辑
                              </a>
                              <a
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setDeleteMenuOpen(true)
                                  setDeleteId(v.id)
                                  setCenNum(3)
                                }}
                              >
                                删除
                              </a>
                            </div>
                          ) : null}
                        </div>

                        {/* 第三级 市*/}
                        {twoId && twoId == v.id
                          ? leftThildList &&
                            leftThildList.map((w) => {
                              return (
                                <div key={w.id}>
                                  <div
                                    className={
                                      styles.page_body_left_list_threebody
                                    }
                                    style={{
                                      color: w.id == threeId ? "#39BE87" : "",
                                      background:
                                        colorId == w.id ? "#fafafa" : "",
                                      color: colorId == w.id ? "#39BE87" : "",
                                    }}
                                    onClick={() => {
                                      setColorId(w.id)
                                      setThreeId(w.id == threeId ? "" : w.id)
                                      setFourId("")
                                      setFiveId("")
                                      if (w.isSub && w.id != threeId) {
                                        // 获取下级数据
                                        // setTimeout(getDistrict_List(w.id, 4), 0)
                                        getDistrict_List(w.id, 4)
                                        setParamsInfo({
                                          ...ParamsInfo,
                                          districtId: w.id,
                                          pageNum: 1,
                                        })
                                        setTimeout(getDistrictShopList, 0)
                                      } else {
                                        setLeftFourList([])
                                        setParamsInfo({
                                          ...ParamsInfo,
                                          districtId: w.id,
                                          pageNum: 1,
                                        })
                                        setTimeout(getDistrictShopList, 0)
                                      }
                                    }}
                                    onMouseOver={() => {
                                      setHoverId(w.id)
                                    }}
                                    onMouseOut={() => {
                                      setHoverId(undefined)
                                    }}
                                  >
                                    <div
                                      className={
                                        styles.page_body_left_list_title
                                      }
                                      style={{
                                        marginLeft: "40px",
                                      }}
                                    >
                                      {w.isSub ? (
                                        threeId && w.id == threeId ? (
                                          <CaretDownOutlined
                                            style={{ margin: "0 5px" }}
                                          />
                                        ) : (
                                          <CaretRightOutlined
                                            style={{ margin: "0 5px" }}
                                          />
                                        )
                                      ) : null}

                                      <span
                                        style={{
                                          marginLeft: w.isSub ? "0px" : "22px",
                                        }}
                                      >
                                        {w.name}
                                      </span>
                                    </div>
                                    {/* 通过悬浮存储ID判断是否要展示操作按钮 */}
                                    {w.id == hoverId ? (
                                      <div
                                        className={
                                          styles.page_body_left_list_btn
                                        }
                                      >
                                        <a
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setTimeout(getDistrictTreeList, 0)
                                            setAddArr([twoId, w.id])
                                            setQyName(undefined)
                                            setEditId(undefined)
                                            setSjName(w.id)
                                            setAddMenuTitle("新增区域")
                                            setAddMenuOpen(true)
                                          }}
                                        >
                                          新增下级
                                        </a>
                                        <a
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setTimeout(getDistrictTreeList, 0)
                                            setAddMenuTitle("编辑区域")
                                            setQyName(w.name)
                                            setSjName(v.id)
                                            setEditId(w.id)
                                            setAddMenuOpen(true)
                                            setCenNum(4)
                                          }}
                                        >
                                          编辑
                                        </a>
                                        <a
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setDeleteMenuOpen(true)
                                            setDeleteId(w.id)
                                            setCenNum(4)
                                          }}
                                        >
                                          删除
                                        </a>
                                      </div>
                                    ) : null}
                                  </div>

                                  {/* 第四级 */}
                                  {threeId && threeId == w.id
                                    ? leftFourList &&
                                      leftFourList.map((c) => {
                                        return (
                                          <div key={c.id}>
                                            <div
                                              className={
                                                styles.page_body_left_list_fourbody
                                              }
                                              style={{
                                                color:
                                                  c.id == fourId
                                                    ? "#39BE87"
                                                    : "",
                                                background:
                                                  colorId == c.id
                                                    ? "#fafafa"
                                                    : "",
                                                color:
                                                  colorId == c.id
                                                    ? "#39BE87"
                                                    : "",
                                              }}
                                              onClick={() => {
                                                setColorId(c.id)
                                                setFourId(
                                                  c.id == fourId ? "" : c.id
                                                )
                                                setFiveId("")
                                                if (c.isSub && c.id != fourId) {
                                                  // 获取下级数据
                                                  // setTimeout(
                                                  //   getDistrict_List(c.id, 5),
                                                  //   0
                                                  // )
                                                  getDistrict_List(c.id, 5)
                                                  setParamsInfo({
                                                    ...ParamsInfo,
                                                    districtId: c.id,
                                                    pageNum: 1,
                                                  })
                                                  setTimeout(
                                                    getDistrictShopList,
                                                    0
                                                  )
                                                } else {
                                                  setLeftFiveList([])
                                                  setParamsInfo({
                                                    ...ParamsInfo,
                                                    districtId: c.id,
                                                    pageNum: 1,
                                                  })
                                                  setTimeout(
                                                    getDistrictShopList,
                                                    0
                                                  )
                                                }
                                              }}
                                              onMouseOver={() => {
                                                setHoverId(c.id)
                                              }}
                                              onMouseOut={() => {
                                                setHoverId(undefined)
                                              }}
                                            >
                                              <div
                                                className={
                                                  styles.page_body_left_list_title
                                                }
                                                style={{
                                                  marginLeft: "60px",
                                                }}
                                              >
                                                {c.isSub ? (
                                                  fourId && c.id == fourId ? (
                                                    <CaretDownOutlined
                                                      style={{
                                                        margin: "0 5px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <CaretRightOutlined
                                                      style={{
                                                        margin: "0 5px",
                                                      }}
                                                    />
                                                  )
                                                ) : null}

                                                <span
                                                  style={{
                                                    marginLeft: c.isSub
                                                      ? "0px"
                                                      : "22px",
                                                  }}
                                                >
                                                  {c.name}
                                                </span>
                                              </div>
                                              {/* 通过悬浮存储ID判断是否要展示操作按钮 */}
                                              {c.id == hoverId ? (
                                                <div
                                                  className={
                                                    styles.page_body_left_list_btn
                                                  }
                                                >
                                                  <a
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      setTimeout(
                                                        getDistrictTreeList,
                                                        0
                                                      )
                                                      setAddArr([
                                                        twoId,
                                                        threeId,
                                                        c.id,
                                                      ])
                                                      setQyName(undefined)
                                                      setEditId(undefined)
                                                      setSjName(c.id)
                                                      setAddMenuTitle(
                                                        "新增区域"
                                                      )
                                                      setAddMenuOpen(true)
                                                    }}
                                                  >
                                                    新增下级
                                                  </a>
                                                  <a
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      setTimeout(
                                                        getDistrictTreeList,
                                                        0
                                                      )
                                                      setAddMenuTitle(
                                                        "编辑区域"
                                                      )
                                                      setQyName(c.name)
                                                      setSjName(w.id)
                                                      setEditId(c.id)
                                                      setAddMenuOpen(true)
                                                      setCenNum(5)
                                                    }}
                                                  >
                                                    编辑
                                                  </a>
                                                  <a
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      setDeleteMenuOpen(true)
                                                      setDeleteId(c.id)
                                                      setCenNum(5)
                                                    }}
                                                  >
                                                    删除
                                                  </a>
                                                </div>
                                              ) : null}
                                            </div>

                                            {/* 第五级 */}
                                            {fourId && fourId == c.id
                                              ? leftFiveList &&
                                                leftFiveList.map((n) => {
                                                  return (
                                                    <div key={n.id}>
                                                      <div
                                                        className={
                                                          styles.page_body_left_list_fivebody
                                                        }
                                                        style={{
                                                          color:
                                                            n.id == fiveId
                                                              ? "#39BE87"
                                                              : "",
                                                          background:
                                                            colorId == n.id
                                                              ? "#fafafa"
                                                              : "",
                                                          color:
                                                            colorId == n.id
                                                              ? "#39BE87"
                                                              : "",
                                                        }}
                                                        onClick={() => {
                                                          setColorId(n.id)
                                                          setFiveId(
                                                            n.id == fiveId
                                                              ? ""
                                                              : n.id
                                                          )

                                                          setParamsInfo({
                                                            ...ParamsInfo,
                                                            districtId: n.id,
                                                            pageNum: 1,
                                                          })
                                                          setTimeout(
                                                            getDistrictShopList,
                                                            0
                                                          )
                                                        }}
                                                        onMouseOver={() => {
                                                          setHoverId(n.id)
                                                        }}
                                                        onMouseOut={() => {
                                                          setHoverId(undefined)
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            styles.page_body_left_list_title
                                                          }
                                                          style={{
                                                            marginLeft: "80px",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              margin: "0 20px",
                                                            }}
                                                          >
                                                            <Tooltip
                                                              title={n.name}
                                                            >
                                                              {n.name}
                                                            </Tooltip>
                                                          </span>
                                                        </div>
                                                        {/* 通过悬浮存储ID判断是否要展示操作按钮 */}
                                                        {n.id == hoverId ? (
                                                          <div
                                                            className={
                                                              styles.page_body_left_list_btn
                                                            }
                                                          >
                                                            <a
                                                              onClick={(e) => {
                                                                e.stopPropagation()
                                                                setTimeout(
                                                                  getDistrictTreeList,
                                                                  0
                                                                )
                                                                setAddMenuTitle(
                                                                  "编辑区域"
                                                                )
                                                                setQyName(
                                                                  n.name
                                                                )
                                                                setEditId(n.id)
                                                                setSjName(c.id)
                                                                setAddMenuOpen(
                                                                  true
                                                                )
                                                                setCenNum(6)
                                                              }}
                                                            >
                                                              编辑
                                                            </a>
                                                            <a
                                                              onClick={(e) => {
                                                                e.stopPropagation()
                                                                setDeleteMenuOpen(
                                                                  true
                                                                )
                                                                setDeleteId(
                                                                  n.id
                                                                )
                                                                setCenNum(6)
                                                              }}
                                                            >
                                                              删除
                                                            </a>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                  )
                                                })
                                              : null}
                                          </div>
                                        )
                                      })
                                    : null}
                                </div>
                              )
                            })
                          : null}
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
        </Card>
        {/* 右侧表格展示 */}
        <Card className={styles.page_body_rig}>
          <Button
            type="primary"
            style={{ marginBottom: "20px" }}
            onClick={() => {
              let id = ""
              if (twoId) {
                id = twoId
              } else if (threeId) {
                id = threeId
              } else if (fourId) {
                id = fourId
              } else if (fiveId) {
                id = fiveId
              }
              history.push(`/admin/accountmanage/relatedshops?id=${id}`)
            }}
          >
            关联门店
          </Button>
          <div>
            <Table
              columns={columns}
              pagination={onTableChange()}
              dataSource={tableList}
              rowKey="id"
              loading={loading}
              scroll={{ x: "max-content", y: `${WinHeight - 430}px` }}

              // style={{ minHeight: `${WinHeight - 350}px` }}
            />
          </div>
        </Card>
      </div>
      {/* 表格数据删除弹窗 */}
      <Modal
        title="系统提示"
        open={deleteOpen}
        onCancel={() => {
          setDeleteOpen(false)
        }}
        footer={[
          <Button
            onClick={() => {
              setDeleteOpen(false)
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              // setDeleteOpen(false)
              getDistrictShopRemove()
            }}
          >
            移除
          </Button>,
        ]}
      >
        <QuestionCircleFilled
          style={{ color: "#E6A23C", marginRight: "10px" }}
        />
        是否要移除当前区域？
      </Modal>

      {/* 菜单数据删除弹窗 */}
      <Modal
        title="系统提示"
        open={deleteMenuOpen}
        onCancel={() => {
          setDeleteMenuOpen(false)
        }}
        footer={[
          <Button
            onClick={() => {
              setDeleteMenuOpen(false)
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
            onClick={() => {
              getDistrictDelete()
            }}
          >
            删除
          </Button>,
        ]}
      >
        <QuestionCircleFilled
          style={{ color: "#E6A23C", marginRight: "10px" }}
        />
        是否删除当前区域？
      </Modal>

      {/* 区域新建与 */}
      <Modal
        title={addMenuTitle}
        open={addMenuOpen}
        onCancel={() => {
          setAddMenuOpen(false)
        }}
        footer={[
          <Button
            onClick={() => {
              setAddMenuOpen(false)
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onSaveArea()
            }}
          >
            保存
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onSaveAreaMore()
            }}
          >
            保存并关联门店
          </Button>,
        ]}
      >
        <div className={styles.page_add_modal}>
          <div className={styles.page_add_modal_div}>
            <span style={{ color: "#F56C6C" }}>* </span>
            区域名称：
            <Input
              style={{
                width: "300px",
              }}
              placeholder="请输入区域名称"
              maxLength={10}
              showCount
              value={qyName}
              onChange={(e) => {
                setQyName(e.target.value)
              }}
            />
          </div>
          <div className={styles.page_add_modal_div}>
            <span style={{ color: "#F56C6C" }}>* </span>
            上级区域：
            <TreeSelect
              disabled={addMenuTitle === "编辑区域" ? true : false}
              showSearch
              filterTreeNode={(input, treeNode) =>
                treeNode.props.name
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) !== -1
              }
              style={{ width: "300px" }}
              value={sjName}
              placeholder="请选择上级区域"
              allowClear
              treeDefaultExpandAll
              onChange={(e) => {
                setSjName(e)
              }}
              onSelect={(value, node, extra) => {
                console.log(node)
                let firstNum = ""
                let NewArr = []
                if (node.id != 0) {
                  if (!node?.path) {
                    firstNum = node.id
                    NewArr = []
                    NewArr[0] = firstNum
                    console.log(NewArr)
                  } else {
                    NewArr = node?.path.split("/")
                    let filteredArr = NewArr.filter((item) => item !== "")
                    filteredArr.push(node?.id)
                    NewArr = filteredArr
                  }
                  setAddArr(NewArr)
                } else {
                  setAddArr([])
                }
              }}
              treeData={treeData}
              fieldNames={{ label: "name", value: "id", children: "subList" }}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Index
