import { createStore } from "redux"
const notices = (state = { isAllRead: false, count: 8 }, action) => {
  console.log(state, "state")
  console.log(action, "action")

  switch (action.type) {
    case "READ_ALL":
      return { ...state, count: action.count, isAllRead: action.isAllRead }
    default:
      return state
  }
}

const store = createStore(notices)

export default store
