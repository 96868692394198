const configs = {
  // 测试环境
  test: {
    API_SERVER: "/api",
    NEW_API_SERVER: "",
  },

  // 开发环境
  dev: {
    API_SERVER: "http://192.168.3.5:10088/api",
    NEW_API_SERVER: "http://dop.docker.yoyo.link",
    // NEW_API_SERVER: 'http://192.168.3.5:8888',
  },

  // 本地环境
  local: {
    // API_SERVER: 'http://127.0.0.1:10088',
    API_SERVER: "http://192.168.3.5:10088",
    NEW_API_SERVER: "http://dop.docker.yoyo.link",
    // NEW_API_SERVER: "http://192.168.3.242:8889",
    // API_SERVER: 'http://192.168.3.131:8887',
  },

  // 正式环境
  production: {
    // API_SERVER: 'http://admin.yoyo.link',
    API_SERVER: "/api",
    NEW_API_SERVER: "", //3月21日
    // NEW_API_SERVER: "http://192.168.3.5:8889",
    // API_SERVER: '',
  },
}

export { configs }
