import React, { useState, useEffect, useRef } from "react"
import {
  Button,
  Input,
  message,
  Upload,
  List,
  Checkbox,
  Modal,
  Form,
  Spin,
  Card,
  Select,
  Radio,
  Image as NewImage,
  Tooltip,
  Pagination,
} from "antd"

import * as LabelSever from "../../services/shoping"

import md5 from "md5"
import { PlusOutlined } from "@ant-design/icons"
import PubInput from "../../../components/PubInput"
import styles from "./Index.module.css"
const { Option } = Select

function Index() {
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [token, setToken] = useState("") //上传token
  const [uid, setUid] = useState([]) //uid
  const [nowInfo, setNowInfo] = useState({ skuCode: "label" }) //当前编辑的行数据
  const [domain, setDomain] = useState("") //域名
  const [imgUrl, setImgUrl] = useState("") //上传成功后返回的图片路径

  const [labelTypeIf, setLabelType] = useState(undefined) //角标类型

  const [delShow, setDelShow] = useState(false) //删除功能弹窗状态
  const [labelShow, setLabelShow] = useState(false) //应用弹窗状态
  const [imgLoading, setImgLoading] = useState(false) //上传loging
  const [form] = Form.useForm()

  //标签名颜色列表
  const [labelColorList, setLabelColor] = useState([])
  // 原图
  const [drawing, setDrawing] = useState(
    "https://static.yoyo.link/shop/default_apple.jpg"
  )

  //是否查询
  const [isSearch, setIsSearch] = useState(false)
  //数据展示
  const [lableList, setLableList] = useState([]) //icon列表
  const [total, setTotal] = useState(0) //icon列表数据总数
  const [often, setOften] = useState(1) //列表数据复选框选中时时时刷新列表数据
  const [allcheck, setAllCheck] = useState(false) //全选
  const [ids, setIds] = useState([]) //删除时的id数组
  const [delTitle, setDelTitle] = useState("") //区分单删还是多删的文本
  const [lableId, setLableId] = useState("") //商品图片库标签编辑时的id

  //保证每次拿到的都是准确的值
  const [GiveParams, setGiveParams] = useState({
    pageNum: 1,
    pageSize: 10,
    labelType: undefined, // （1-图片角标，2-文字角标）
    name: undefined, // 标签名称
  })

  //保证每次拿到的都是准确的值
  const AccountRef = useRef({
    GiveParamsRef: {},
  })
  useEffect(() => {
    AccountRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    //调用获取token接口
    gettoken()

    //调用获取商品图片库标签列表
    getLableList()

    setUid(window.localStorage.getItem("uid").toString().padStart(8, "0"))

    // 角标颜色背景图列表
    getLabelColor()
  }, [])

  //列表内的复选框点击时会触发这这个钩子
  useEffect(() => {
    if (lableList.length > 0) {
      setAllCheck(
        lableList.every((v) => {
          return v.checked === true
        })
      )
    }
  }, [often])
  // 动态获取高度
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 角标颜色背景图列表
   */
  const getLabelColor = async () => {
    try {
      const { code, data, msg } = await LabelSever.Label_Color()
      if (code != 200) {
        message.error(msg)
        setLabelColor([])
        return
      }
      if (data) {
        setLabelColor(data)
      } else {
        setLabelColor([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  //获取商品图片库标签列表
  const getLableList = async () => {
    try {
      const { GiveParamsRef } = AccountRef.current
      const { code, data, msg } = await LabelSever.Label_List(GiveParamsRef)
      if (code !== 200) {
        message.error(msg)
        setLableList([])
        return
      }
      if (data) {
        // 给列表数据增加字段，复选框选中的字段
        if (data.list.length > 0) {
          data.list.map((v) => {
            v.checked = false
          })
          setLableList(data.list)
          setTotal(data.total)
          if (data.total == 0 || data.list.length == 0) {
            setAllCheck(false)
          }
        } else {
          setLableList([])
          setTotal(0)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  //获取token
  const gettoken = async () => {
    try {
      const { code, data, msg } = await LabelSever.Get_Token()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setToken(data.token)
        setDomain(data?.domain || "")
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 限制宽高类型
  const handleFilebeforeUpload = (file) => {
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader()
      filereader.onload = (e) => {
        let src = e.target.result
        const image = new Image()
        image.onload = function () {
          if (
            // file.type === "image/png" ||
            // file.type === "image/jpeg" ||
            // file.type === "image/jpg"
            file.size < 512000
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve("上传成功")
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = src
      }
      filereader.readAsDataURL(file)
    })
  }

  // 上传
  const uploadProps = {
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(e)
        fileReader.onload = (ee) => {
          var _md5only = md5(ee.target.result)
          resolve({
            token,
            key: `icons/project/${uid}/${nowInfo?.skuCode}_${_md5only}.${
              e.type.split("/")[1]
            }`,
          })
        }
        fileReader.onerror = (err) => {
          reject(err)
        }
      })
    },
    beforeUpload: async (e) => {
      try {
        const sizeQualified = await handleFilebeforeUpload(e)
        // message.success(sizeQualified)
        setImgLoading(true)
        return true
      } catch (err) {
        message.error("上传失败,请按建议上传")
        setImgLoading(false)
        return false
      }
    },
    onChange(info) {
      const _file = info.file
      if (_file.status === "done") {
        console.log(_file, "上传成功的数据")
        setImgLoading(false)
        setImgUrl(domain + "" + _file?.response?.key || "")
        getIconWatermark(domain + "" + _file?.response?.key, 1)
      }
      if (_file.status === "error") {
        setImgLoading(false)
        message.error(_file?.response.error)
      }
    },
  }

  /**
   * 角标颜色事件
   */
  const onChangeRadio = async (e) => {
    try {
      if (!form.getFieldsValue()?.name) {
        message.error("请补充角标信息")
        form.resetFields(["labelColorBackgroundId"])
        return
      }
      const params = {
        labelColorBackgroundId: e.target.value,
        labelFont: form.getFieldsValue()?.name || "",
        labelType: form.getFieldsValue()?.labelType || "",
        imageUrl: "https://static.yoyo.link/shop/default_apple.jpg", // TODO，所以先传苹果，后期删掉
      }
      const { code, msg, data } = await LabelSever.Icon_Watermark(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.url) {
        setDrawing(data.url)
        setImgUrl(data.url)
        // 再去走图片加水印（加角标）预览图的接口
        getIconWatermark(
          data.url,
          1,
          "https://static.yoyo.link/shop/default_apple.jpg"
        )
      }
    } catch (error) {
      form.resetFields(["labelColorBackgroundId"])
      console.log(error)
    }
  }

  /**
   * 图片加水印（加角标）预览图 （新建）
   */
  const getIconWatermark = async (info, num) => {
    try {
      const params = {
        gravity: "NorthWest",
        imageUrl: "https://static.yoyo.link/shop/default_apple.jpg",
        labelFont: form.getFieldsValue()?.name || "",
        labelType: num,
        watermarkUrl: info,
      }
      const { code, msg, data } = await LabelSever.Icon_Watermark(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.url) {
        setDrawing(data.url)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 图片加水印（加角标）预览图 (编辑)
   */
  const getIconWatermarkEdit = async (info) => {
    try {
      const params = {
        gravity: "NorthWest",
        imageUrl: "https://static.yoyo.link/shop/default_apple.jpg",
        labelFont: info.name,
        labelType: info.labelType,
        watermarkUrl: info.iconUrl,
        labelColorBackgroundId: info.labelColorBackgroundId,
      }
      const { code, msg, data } = await LabelSever.Icon_Watermark(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.url) {
        setDrawing(data.url)
        if (info.labelType == 2) {
          getIconWatermark(
            data.url,
            1,
            "https://static.yoyo.link/shop/default_apple.jpg"
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  //应用标签提交
  const onFinish = (values) => {
    form
      .validateFields()
      .then(async (values) => {
        if (!values.one && labelTypeIf == 1) {
          message.error("请上传商品标签")
          return false
        }
        const params = {
          url: imgUrl,
          id: lableId ? lableId : undefined,
          ...values,
        }
        // 图片角标
        if (labelTypeIf == 1) {
          params.labelColorBackgroundId = undefined
        }
        // 文字角标
        if (labelTypeIf == 2) {
          params.imgUrl = ""
        }

        if (lableId) {
          const { code, data, msg } = await LabelSever.Label_Edit(params)
          if (code != 200) {
            message.error(msg)
            return
          }
          if (data) {
            message.success("标签编辑成功")
            setTimeout(getLableList, 0)
            setLabelShow(false)
            setLabelType(undefined)
          }
        } else {
          const { code, data, msg } = await LabelSever.Label_Add(params)
          if (code != 200) {
            message.error(msg)
            return
          }
          if (data) {
            message.success("标签添加成功")
            setTimeout(getLableList, 0)
            setLabelShow(false)
            setLabelType(undefined)
          }
        }
      })
      .catch((err) => {
        // 校验失败
      })
  }

  const formTitle = () => {
    return (
      <div>
        <span className={styles.page_form_xing}>*</span>商品标签
      </div>
    )
  }

  return (
    <div className={styles.lable_page}>
      {/* 顶部查询 */}
      <Card>
        <div className={styles.lable_page_head}>
          <div className={styles.lable_head_search}>
            <span>标签类别：</span>
            <PubInput
              onChange={(val) => {
                setGiveParams({ ...GiveParams, name: val })
              }}
              placeholder="类别关键词"
              value={GiveParams.name}
            />
            <span style={{ marginLeft: "20px" }}>角标类型：</span>
            <Select
              style={{ width: 200 }}
              placeholder="请选择角标类型"
              value={GiveParams.labelType}
              allowClear
              onChange={(e) => {
                setGiveParams({
                  ...GiveParams,
                  labelType: e,
                })
              }}
            >
              <Option value={1} key={1}>
                图片角标
              </Option>
              <Option value={2} key={2}>
                文字角标
              </Option>
            </Select>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setGiveParams({ ...GiveParams, pageNum: 1 })
                setTimeout(getLableList, 0)
                setAllCheck(false)
                setIsSearch(true)
              }}
            >
              查询
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setGiveParams({
                  pageNum: 1,
                  pageSize: 20,
                  labelType: undefined, // （1-图片角标，2-文字角标）
                  name: undefined, // 标签名称})
                })
                setTimeout(getLableList, 0)
                setAllCheck(false)
                setIsSearch(false)
              }}
            >
              重置
            </Button>
          </div>
        </div>
      </Card>
      {/* 删除，导入图片，导入设备，标签管理 */}
      <Card style={{ marginTop: "16px" }}>
        <div className={styles.lable_page_operate}>
          <div className={styles.lable_operate_del}>
            <Button
              type="primary"
              onClick={() => {
                setLabelShow(true)
                setImgUrl("")
                setLableId("")
                form.resetFields()
                // 重置预览图
                setDrawing("https://static.yoyo.link/shop/default_apple.jpg")
              }}
            >
              新建角标
            </Button>

            {/* 批量删除 */}
            <Button
              type="primary"
              danger
              disabled={
                !lableList.some((v) => {
                  return v.checked == true
                })
              }
              style={{ margin: "0 20px" }}
              onClick={() => {
                let NewIds = []
                lableList.map((v) => {
                  if (v.checked) {
                    NewIds.push(v.id)
                  }
                })
                setIds(NewIds) //删除时存取选中的id，存入集合
                setDelTitle("所选")
                setDelShow(true)
              }}
            >
              批量删除商品标签
            </Button>

            {/* 全选复选框 */}
            <Checkbox
              checked={allcheck}
              onChange={(e) => {
                setAllCheck(e.target.checked)
                lableList.map((v) => {
                  v.checked = e.target.checked ? true : false
                })
                setLableList(lableList)
              }}
            >
              全选
            </Checkbox>
          </div>
        </div>
        {/* 标签，图片列表 */}
        <div className={styles.lable_list_data_body}>
          {lableList && lableList.length == 0 ? (
            <div
              className={styles.lable_list_data_body_noInfo}
              style={{ height: `${WinHeight * 0.67}px` }}
            >
              <svg
                width="64"
                height="41"
                viewBox="0 0 64 41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                  <ellipse
                    fill="#f5f5f5"
                    cx="32"
                    cy="33"
                    rx="32"
                    ry="7"
                  ></ellipse>
                  <g fill-rule="nonzero" stroke="#d9d9d9">
                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      fill="#fafafa"
                    ></path>
                  </g>
                </g>
              </svg>
              <div>暂无数据</div>
            </div>
          ) : (
            <>
              {lableList &&
                lableList.map((item, i) => {
                  return (
                    <div className={styles.lable_list_data_body_item} key={i}>
                      <div className={styles.lable_list_data}>
                        <div className={styles.lable_list_data_top_body}>
                          <div className={styles.lable_list_data_top_check}>
                            <Checkbox
                              style={{ margin: "0 10px 5px 0 " }}
                              checked={item.checked}
                              onClick={() => {
                                setOften(often + 1)
                              }}
                              onChange={(e) => {
                                lableList.map((v) => {
                                  if (v.id === item.id) {
                                    v.checked = e.target.checked
                                  }
                                })
                                setLableList(lableList)
                              }}
                            ></Checkbox>
                          </div>
                          {item.labelType == 1 ? (
                            <div className={styles.lable_list_data_top}>
                              {/* <img src={item.iconUrl} alt="" /> */}
                              <NewImage
                                src={item.iconUrl}
                                width={200}
                                height={150}
                                style={{ marginTop: "13px" }}
                              />
                            </div>
                          ) : item.labelType == 2 ? (
                            <div className={styles.lable_list_data_top1}>
                              {/* <img src={item.iconUrl} alt="" /> */}
                              <NewImage
                                src={item.iconUrl}
                                width={200}
                                height={150}
                                style={{ marginTop: "13px" }}
                              />
                            </div>
                          ) : null}
                        </div>

                        {/* title 与 plu */}
                        <div className={styles.lable_list_data_bottom}>
                          <div
                            className={styles.lable_list_data_bottom_skuName}
                          >
                            角标名称：
                            <Tooltip title={item.name}>
                              <span>{item.name}</span>
                            </Tooltip>
                          </div>
                          <div
                            className={styles.lable_list_data_bottom_skuName}
                          >
                            角标类型：
                            {item.labelType == 1
                              ? "图片角标"
                              : item.labelType == 2
                              ? "文字角标"
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className={styles.lable_list_data_function}>
                        <Button
                          onClick={() => {
                            setDelShow(true)
                            setIds([item.id])
                            setDelTitle(item.name)
                          }}
                          type="link"
                        >
                          删除标签
                        </Button>
                        <Button
                          type="link"
                          onClick={() => {
                            setLabelShow(true)
                            setLableId(item.id)
                            setImgUrl(item.iconUrl)
                            setLabelType(item.labelType)
                            form.setFieldsValue({
                              one: item.iconUrl,
                              name: item.name,
                              labelType: item.labelType,
                              labelColorBackgroundId:
                                item.labelColorBackgroundId,
                            })

                            getIconWatermarkEdit(item)
                            // 重置预览图
                            // setDrawing(
                            //   "https://static.yoyo.link/shop/default_apple.jpg"
                            // )
                          }}
                        >
                          编辑标签
                        </Button>
                      </div>
                    </div>
                  )
                })}
              {Array.from(new Array(10)).map(() => {
                return (
                  <div className={styles.lable_list_data_body_item_null}></div>
                )
              })}
            </>
          )}
        </div>
        <div className={styles.lable_list_pagination}>
          <Pagination
            showTotal={() => `共${total}条`}
            pageSize={GiveParams.pageSize}
            current={GiveParams.pageNum}
            total={total}
            onChange={(current) => {
              setLableList([]) //将存列表的字段置空
              setAllCheck(false) //复选框全选置为false
              setGiveParams({ ...GiveParams, pageNum: current })
              setTimeout(getLableList, 0)
            }}
          />
        </div>
      </Card>
      {/* 删除二次提示框 */}
      <Modal
        open={delShow}
        destroyOnClose
        keyboard
        title="删除提示"
        onCancel={() => {
          setDelShow(false)
          setIds([])
        }}
        onOk={async () => {
          try {
            const params = { ids: ids }
            const { code, data, msg } = await LabelSever.Label_Del(params)
            if (code != 200) {
              message.error(msg)
              return
            }
            if (data) {
              message.success("删除成功")
              setDelShow(false)
              setTimeout(getLableList, 0)
              setAllCheck(false)
            }
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <div className={styles.lable_del}>确认删除{delTitle}标签吗？</div>
      </Modal>

      {/* 应用标签 */}
      <Modal
        open={labelShow}
        destroyOnClose
        keyboard
        title={lableId ? "编辑角标" : "新建角标"}
        onCancel={() => {
          setLabelShow(false)
          setLabelType(undefined)
        }}
        onOk={onFinish}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="角标名称"
            name="name"
            rules={[
              {
                required: true,
                message: "角标名称不能为空!",
              },
              // {
              //   pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{5,15}$/, "g"),
              //   message: "支持英文、数字、特殊字符，长度为5到15",
              // },
            ]}
          >
            <Input placeholder="请输入角标名称！" />
          </Form.Item>
          <Form.Item
            label="角标类型"
            name="labelType"
            rules={[
              {
                required: true,
                message: "角标类型不能为空!",
              },
            ]}
          >
            <Select
              disabled={lableId ? true : false}
              width={200}
              allowClear
              placeholder="请选择角标类型"
              onChange={(e) => {
                setLabelType(e)
                form.setFieldValue("labelColorBackgroundId", undefined)
                setImgUrl("")
              }}
            >
              <Option key={1} value={1}>
                图片角标
              </Option>
              <Option key={2} value={2}>
                文字角标
              </Option>
            </Select>
          </Form.Item>
          {/* 图片角标 */}
          {labelTypeIf == 1 ? (
            <Form.Item label={formTitle()} name="one">
              <Upload
                action="https://up-z0.qiniup.com"
                name="file"
                showUploadList={false}
                multiple={false}
                {...uploadProps}
              >
                <div className={styles.page_upload_btn}>
                  {imgLoading ? (
                    <Spin />
                  ) : imgUrl ? (
                    <img src={imgUrl} alt="" />
                  ) : (
                    <div className={styles.page_upload_icon}>
                      <PlusOutlined style={{ fontSize: "30px" }} />
                      上传图片
                    </div>
                  )}
                </div>
              </Upload>
            </Form.Item>
          ) : labelTypeIf == 2 ? (
            // 文字角标选择颜色
            <Form.Item name="labelColorBackgroundId" label="角标颜色">
              <Radio.Group
                onChange={(e) => {
                  onChangeRadio(e)
                }}
              >
                {labelColorList.map((v, i) => {
                  return (
                    <Radio
                      value={v.labelColorBackgroundId}
                      key={i}
                      className={styles.lable_list_color}
                    >
                      <img src={v.labelColorBackgroundUrl} />
                    </Radio>
                  )
                })}
              </Radio.Group>
            </Form.Item>
          ) : null}

          <div style={{ color: "grey", margin: "-10px 0  20px 80px" }}>
            支持JPG\PNG格式的图片
          </div>

          <Form.Item label="角标示例图">
            <div className={styles.example_img}>
              <img src={drawing} alt="" />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
export default Index
