import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import moment from "moment"
import request, { newRequest } from "../../utils/newRequest"

/**
 * 门店下设备下拉列表
 */
export const Shop_List = (params) =>
  POST("/data/center/v1/shop/pull/page", params)

/**
 * 导出人员打称统计
 */
export async function ExportStaffWeightRecord(params) {
  const defaultFileName = `员工数据统计${moment().format("YYYYMMDD")}.csv`
  return newRequest(
    `/data/center/v1/record/exportStaffWeightRecord?${stringify(params, {
      arrayFormat: "repeat",
    })}`,
    {
      method: "GET",
      responseType: "blob",
      defaultFileName: defaultFileName,
    }
  )
}

/**
 * 员工称重统计-按类型导出日报周报月报
 */
// export async function ExportStaffReport(params) {
//   const defaultFileName = `员工数据统计${moment().format("YYYYMMDD")}.csv`
//   return newRequest(
//     `/data/center/v1/record/exportStaffReport?${stringify(params, {
//       arrayFormat: "repeat",
//     })}`,
//     {
//       method: "GET",
//       responseType: "blob",
//       defaultFileName: defaultFileName,
//     }
//   )
// }
export const ExportStaffReport = (params) =>
  GET(`/data/center/v1/record/exportStaffReport?${stringify(params)}`)

/**
 * 获取员工打称记录
 */
export const GetGoodsWeighDetail = (params) =>
  POST("/data/center/v1/record/getGoodsWeighDetail", params)

/**
 * 获取打称人员列表
 */
export const GetStaffList = (params) =>
  GET(`/data/center/v1/record/getStaffList?${stringify(params)}`)

/**
 * 获取称重统计列表
 */
export const GetStaffWeightRecordPage = (params) =>
  POST("/data/center/v1/record/getStaffWeightRecordPage", params)

/**
 * 员工称重统计-日报周报月报查询
 */
export const GetStaffWeightReportPage = (params) =>
  POST("/data/center/v1/record/getStaffWeightReportPage", params)

/**
 * 门店设备
 */
export const ShopTreeList = (params) =>
  POST("/data/center/v1/shop/pull_tree", params)
// GET(
//   `/data/center/v1/share/learn/shop_tree_list?code=${
//     params ? params : ""
//   }&isAll=2`
// )

/**
 * 导出人员打称统计
 */
export async function ExportStaffWeightDetailByProject(
  params,
  onDownloadProgress
) {
  return newRequest(
    `/data/center/v1/weigh/exportStaffWeightDetailByProject?${stringify(
      params
    )}`,
    {
      responseType: "blob",
      onDownloadProgress: onDownloadProgress,
    }
  )
}

/**
 * 设备数据统计-最近七天折线
 */
export const GetDeviceChartData = (params) =>
  GET(`/data/center/v1/device/getDeviceChartData?${stringify(params)}`)

/**
 * 设备数据统计-使用量统计
 */
export const GetDeviceCollection = (params) =>
  GET(`/data/center/v1/device/getDeviceCollection?${stringify(params)}`)

/**
 * 设备使用情况-导出
 */
export async function GetDeviceExport(params) {
  const defaultFileName = `设备使用情况${moment().format("YYYYMMDD")}.csv`
  return newRequest(
    `/data/center/v1/device/getDeviceCollectionExport?${stringify(params, {
      arrayFormat: "repeat",
    })}`,
    {
      method: "GET",
      responseType: "blob",
      defaultFileName: defaultFileName,
    }
  )
}

/**
 * 设备使用情况列表
 */
export const GetDevicenPage = (params) =>
  GET(`/data/center/v1/device/getDeviceCollectionPage?${stringify(params)}`)

/**
 * 设备数据统计-设备在线情况
 */
export const GetDeviceStatus = (params) =>
  GET(`/data/center/v1/device/getDeviceStatusCollection`)

/**
 * 信息基准门店
 */
export const Info_Benchmark = () =>
  GET("/data/center/v1/setting/info-benchmark-shop")

/**
 * 设置绩效折算金额
 */
export const SetConverted_Amount = (params) =>
  POST("/data/center/v1/setting/edit/converted/amount", params)

/**
 * 获取绩效折算金额
 */
export const GetConverted_Amount = (params) =>
  GET(`/data/center/v1/setting/get/converted/amount`)

/**
 * 绩效统计自定义时间导出
 */
export const Export_Staff_Report = (params) =>
  GET(`/data/center/v1/record/custom/export/staff/report?${stringify(params)}`)
// export async function Export_Staff_Report(params) {
//   return newRequest(`/data/center/v1/record/custom/export/staff/report`, {
//     method: "GET",
//     responseType: "blob",
//     params: params,
//   })
// }

/**
 * 导出任务列表
 */
export const Export_Work_List = (params) =>
  GET(
    `/data/center/v1/export/work/list?${stringify(params, {
      arrayFormat: "repeat",
    })}`
  )
