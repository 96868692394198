import React from "react"
import { Input } from "antd"

/**
 * Input输入框封装公用
 */
const PubInput = (props) => {
  return (
    <Input
      style={{ width: props?.width || "200px" }}
      placeholder={`请输入${props?.placeholder}`}
      value={props?.value || undefined}
      showCount={props?.showCount || false}
      disabled={props?.disabled || false}
      allowClear={props?.allowClear || false}
      maxLength={props?.max || 99}
      onChange={(e) => {
        if (props?.onChange) {
          props?.onChange(
            e.target.value
              ? e.target.value.replace(/(^\s+)|(\s+$)/g, "")
              : undefined
          )
        }
      }}
    />
  )
}
export default PubInput
