import { POST, GET } from "../../utils/request"
import { stringify } from "qs"

/**
 * 创建数据集合
 */
export const CreateDataSet = (params) =>
  POST("/data/center/v1/setting/create/data/set", params)

/**
 * 数据集合清空数据
 */
export const ClearnData = (id) =>
  GET(`/data/center/v1/setting/data/set/clear/data?id=${id}`)

/**
 * 数据集合删除
 */
export const DeleteData = (id) =>
  GET(`/data/center/v1/setting/data/set/delete?id=${id}`)

/**
 * 数据集合设备列表
 */
export const DataDeviceList = (params) =>
  GET(`/data/center/v1/setting/data/set/device/list?${stringify(params)}`)

/**
 * 数据集合详情
 */
export const DataInfo = (id) =>
  GET(`/data/center/v1/setting/data/set/info?id=${id}`)

/**
 * 数据集合列表
 */
export const DataSetList = () => GET(`/data/center/v1/setting/data/set/list`)

/**
 * 合并记录
 */
export const MergeLog = (params) =>
  GET(`/data/center/v1/setting/data/set/merge/log?${stringify(params)}`)

/**
 * 开启/关闭自动同步
 */
export const SetOpen = (params) =>
  POST("/data/center/v1/setting/data/set/open", params)

/**
 * 数据集合下拉接口
 */
export const DataPullList = () => GET(`/data/center/v1/setting/data/set/pull`)

/**
 * 编辑数据集合
 */
export const EditData = (params) =>
  POST("/data/center/v1/setting/edit/data/set", params)

/**
 * 清空图片数据
 */
export const ClearnImgData = (dataSetId) =>
  GET(`/data/center/v1/goods/icon/clear?dataSetId=${dataSetId}`)
