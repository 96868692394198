import { POST, GET } from "../../utils/request"
import request, { newRequest } from "../../utils/newRequest"
import { stringify } from "qs"

/**
 * 产品线类型树
 */
export const Product_List = () => GET(`/data/center/v1/shop/type/tree/list`)

/**
 * 门店下设备下拉列表
 */
export const Device_List = (params) =>
  // GET(`/data/center/v1/shop/device/list?${stringify(params)}`)
  POST("/data/center/v1/shop/device/list", params)

/**
 * 门店下拉列表
 */
export const Pull_list = (params) =>
  // GET(`/data/center/v1/shop/pull/list?${stringify(params)}`)
  POST("/data/center/v1/shop/pull/page", params)

/*
 * 编辑设备门店以及称号
 */
export const Edit_Device = (params) =>
  POST("/data/center/v1/device/edit", params)

/**
 * 省市区树
 */
export const Area_Tree = () => GET(`/data/center/v1/index/area/tree`)

/**
 * 门店下设备列表导出接口
 */
export async function Export_Device(params) {
  return newRequest(`/data/center/v1/shop/device/list/excel`, {
    method: "POST",
    responseType: "blob",
    body: params,
  })
}
// export const Export_Device = (params) =>
//   POST("/data/center/v1/shop/device/list/excel", params, {
//     responseType: "blob",
//   })

/*
 * 新增监控配置
 */
export const Add_Monitor = (params) =>
  POST("/data/center/v1/device/monitor/setting/add", params)

/**
  编辑监控配置
 */
export const Edit_Monito = (params) =>
  POST(`/data/center/v1/device/monitor/setting/edit`, params)

/**
  获取最新监控配置
 */
export const New_Monito = (params) =>
  GET(`/data/center/v1/device/monitor/setting/info`)

/*
 * 监控预览
 */
export const Monitor_Preview = (params) =>
  POST("/data/center/v1/device/monitor/camera/preview", params)

/*
 * 监控回放
 */
export const Monitor_Playback = (params) =>
  POST("/data/center/v1/device/monitor/camera/playback", params)

/*
 * 绑定摄像头
 */
export const Monitor_Bind = (params) =>
  POST("/data/center/v1/device/monitor/camera/bind", params)

/*
 * 获取监控列表
 */
export const Camera_List = (params) =>
  POST("/data/center/v1/device/monitor/camera/list", params)

/**
 * 门店下设备列表
 */
export const Devices_List = (params) =>
  // GET(`/data/center/v1/shop/device/list?${stringify(params)}`)
  POST("/data/center/v1/shop/device/list", params)

/**
 * 产品线类型树
 */
export const Tree_List = () => GET(`/data/center/v1/shop/type/tree/list`)

/**
 * 添加门店账号
 */
export const Add_Account = (params) =>
  POST("/data/center/v1/shop/add/account", params)

/**
 * 获取店内区域列表
 */
export const ShopRegionList = (params) =>
  GET(`/data/center/v1/shop/region/list?${stringify(params)}`)

/**
 * 当前账号拥有的数据权限
 */
export const Data_Auth = () => GET(`/data/center/v1/user/data/auth`)

/**
 * 获取数据权限下的店内区域列表
 */
export const ShopListUnderAuth = (params) =>
  POST("/data/center/v1/shop/region/listUnderAuth", params)
