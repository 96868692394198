import {
  Table,
  Button,
  message,
  Cascader,
  Select,
  DatePicker,
  Tooltip,
  Row,
  Col,
  Card,
} from "antd"
import React, { useEffect, useState, useRef, useLatest } from "react"
import dayjs from "dayjs"
import styles from "./Index.module.css"
import { QuestionCircleOutlined } from "@ant-design/icons"
import * as HomeServer from "../../services/home"
import { Link } from "react-router-dom"
import { disabledDateDay } from "../../../utils/util"
const { Option } = Select
const dateFormat = "YYYY-MM-DD"
const DTIME = dayjs().format("YYYYMMDD")
const tabs = [
  { name: "日报", value: 1 },
  { name: "周报", value: 2 },
  { name: "月报", value: 3 },
]
/**
 * 门店数据统计
 */
function Index(props) {
  //specific_time:时间
  let JumpToHereInfo = props.location.state
  // 时间处理
  let DT = "" //天
  let WT = [] // 周
  let MT = "" //月
  let TK = 1
  if (JumpToHereInfo?.specific_time) {
    const { specific_time } = JumpToHereInfo
    TK = JumpToHereInfo.tabKey
    if (JumpToHereInfo.tabKey == 1) {
      DT = dayjs(specific_time).format("YYYYMMDD")
      WT = []
      MT = ""
    } else if (JumpToHereInfo.tabKey == 2) {
      DT = ""
      WT = specific_time
      MT = ""
    } else if (JumpToHereInfo.tabKey == 3) {
      DT = ""
      WT = []
      MT = dayjs(specific_time).format("YYYYMM")
    }
  }
  const [sortedInfo, setSortedInfo] = useState({}) //排序类型

  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [dateTime, setDateTime] = useState()
  const [tabKey, setTabKey] = useState(TK) //tab值，1：日报，2：周报，3：月报
  const [weekList, setWeekList] = useState([]) //周报要展示的列表
  const [monthList, setMonthList] = useState([]) //月要展示的列表
  const [dateValue, setDateValue] = useState("") //日报选择的日期值
  const [weekValue, setWeekValue] = useState([]) //周报选择的日期值
  const [monthValue, setMonthValue] = useState("") //月报选择的日期值
  const [shopValue, setShopValue] = useState([]) //商品select-value
  const [loading, setLoading] = useState(false) //loading
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [shopSelect, setShopSelect] = useState([]) //门店下拉列表
  const [shopTable, setShopTable] = useState([]) //门店表格列表
  const [shopTableInfo, setShopTableInfo] = useState({}) //门店表格接口数据
  const [lastSearch, setLastSearch] = useState({
    pageNum: 1,
    pageSize: 20,
    dateType: TK || 1,
    date: DT ? DT : WT.length > 0 ? WT[1] : MT ? MT : DTIME,
    shopIds: [],
    orderType: "",
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    lastSearchRef: {}, //点击查询后的条件值
  })
  useEffect(() => {
    refInfo.current = {
      lastSearchRef: lastSearch,
    }
  }, [lastSearch])
  //

  useEffect(() => {
    onGetWeek() //(调用获取周列表接口方法)
    onGetMonth() //(调用获取月列表接口方法)
    onPull_list() //(调用门店下拉列表)

    setDateTime(DTIME)
    // 延迟调用门店列表
    setTimeout(onGetShopList, 0)
  }, [])

  /**
   * 动态获取浏览器高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 当tab值切换时就会useEffect
   */
  useEffect(() => {
    let DateTime = ""
    // 日报
    if (tabKey == 1) {
      setDateValue(DT ? DT : dayjs().format(dateFormat))
      // 统计数据传值
      DateTime = DT ? DT : DTIME
    }
    // 周报
    if (tabKey == 2) {
      setWeekValue(
        WT.length > 0
          ? WT
          : [
              weekList[0].value,
              weekList[0].children[weekList[0].children.length - 1].value,
            ]
      )
      // 统计数据传值
      DateTime =
        WT.length > 0
          ? WT
          : weekList[0].children[weekList[0].children.length - 1].value
    }
    // 月报
    if (tabKey == 3) {
      setMonthValue(
        MT
          ? dayjs(MT).format("YYYY-MM")
          : monthList[monthList.length - 1].yearMonth
      )
      // 统计数据传值
      DateTime = MT
        ? MT
        : dayjs(monthList[monthList.length - 1].yearMonth).format("YYYYMM")
    }
    setDateTime(DateTime)
  }, [tabKey])

  /**
   * 获取周列表
   */
  const onGetWeek = async () => {
    try {
      const { code, data, msg } = await HomeServer.LastWeek()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.label = v.year
          v.value = v.year
          v.children = v.lastWeekRspList
          v.children.map((w) => {
            w.value =
              dayjs(w.weekStartDate).format("YYYYMMDD") +
              "-" +
              dayjs(w.weekEndDate).format("YYYYMMDD")
            w.label =
              w.weekStartDate + "-" + w.weekEndDate + `（第${w.weekOfYear}）周`
          })
        })
      }
      setWeekList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取月列表
   */
  const onGetMonth = async () => {
    try {
      const { code, data, msg } = await HomeServer.LastMonth()
      if (code != 200) {
        message.error(msg)
        return
      }
      setMonthList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 门店列表
   */
  const onPull_list = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 }
      const { code, msg, data } = await HomeServer.Pull_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopSelect(data.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 门店列表接口
   */
  const onGetShopList = async (value) => {
    try {
      const { lastSearchRef } = refInfo?.current
      const { code, msg, data } = await HomeServer.ShopDataList(lastSearchRef)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLoading(false)
      setShopTable(data.list || [])
      setShopTableInfo(data)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const onHandSearch = () => {
    setPageNum(1)
    setLastSearch({
      ...lastSearch,
      pageNum: 1,
      dateType: tabKey,
      shopIds: shopValue,
      date: dateTime,
    })
    setTimeout(onGetShopList, 0)
  }

  /**
   * 重置
   */
  const onHandClearn = () => {
    // 置空上级传递的数据
    props.location.state = null

    setPageNum(1)
    //重置归为日报
    setTabKey(1)
    //日报时间归当天
    setDateValue(dayjs().format(dateFormat))
    //门店归为undefined
    setShopValue([])
    setDateTime(DTIME)
    setLastSearch({
      ...lastSearch,
      pageNum: 1,
      dateType: 1,
      shopIds: [],
      date: DTIME,
      orderType: "",
    })
    setSortedInfo({})
    setTimeout(onGetShopList, 0)
  }

  /**
   * 导出
   */
  const onHandExport = async () => {
    try {
      const { lastSearchRef } = refInfo?.current
      const res = await HomeServer.ShopDataExport(lastSearchRef)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 返回上层
   */
  const onHandGoBack = () => {
    window.history.back(-1)
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${shopTableInfo?.total}条`, //接口返回
      pageSize: lastSearch.pageSize,
      current: lastSearch.pageNum,
      total: shopTableInfo?.total, //接口返回
    }
    return paginationProps
  }

  /**
   * 排序
   */
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)
    // 排序类型：1：总识别率倒序，2：总识别率正序，3：改价次数倒序，4：改价次数正序，
    // 5：称重笔数倒序，6：称重笔数正序，7：称重金额倒序，8：称重金额正序，9：监控改价出售次数倒序，
    // 10：监控改价出售次数正序，11：异常告警次数倒序，12：异常告警次数正序
    // ascend:正序      descend：倒序

    //总识别率
    if (sorter.field === "aiOkRate") {
      setLastSearch({
        ...lastSearch,
        orderType:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      })
    }

    //改价次数
    if (sorter.field === "priceCount") {
      setLastSearch({
        ...lastSearch,
        orderType:
          sorter.order === "ascend" ? 4 : sorter.order === "descend" ? 3 : "",
        pageNum: pagination.current,
      })
    }

    //称重次数
    if (sorter.field === "count") {
      setLastSearch({
        ...lastSearch,
        orderType:
          sorter.order === "ascend" ? 6 : sorter.order === "descend" ? 5 : "",
        pageNum: pagination.current,
      })
    }

    //称重金额
    if (sorter.field === "amount") {
      setLastSearch({
        ...lastSearch,
        orderType:
          sorter.order === "ascend" ? 8 : sorter.order === "descend" ? 7 : "",
        pageNum: pagination.current,
      })
    }

    //监控改价出售次数
    if (sorter.field === "abnormalPriceCount") {
      setLastSearch({
        ...lastSearch,
        orderType:
          sorter.order === "ascend" ? 10 : sorter.order === "descend" ? 9 : "",
        pageNum: pagination.current,
      })
    }

    //损耗触发次数
    if (sorter.field === "abnormalNum") {
      setLastSearch({
        ...lastSearch,
        orderType:
          sorter.order === "ascend" ? 12 : sorter.order === "descend" ? 11 : "",
        pageNum: pagination.current,
      })
    }

    //
    if (!sorter.field) {
      setLastSearch({
        ...lastSearch,
        pageNum: pagination.current,
      })
    }

    setTimeout(onGetShopList, 0)
  }

  const columns = [
    {
      title: "门店",
      dataIndex: "shopName",

      key: "shopName",
      width: 150,
      ellipsis: { showTitle: true },
    },
    {
      title: "总识别率",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天总识别率">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "aiOkRate",

      ellipsis: { showTitle: true },
      key: "aiOkRate",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "aiOkRate" ? sortedInfo?.order : null,
      render: (info) => {
        return <>{info == 0 ? 0 : (info * 100).toFixed(2)}%</>
      },
    },
    {
      title: "改价次数",
      dataIndex: "priceCount",

      ellipsis: { showTitle: true },
      key: "priceCount",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "priceCount" ? sortedInfo?.order : null,
      render: (info, rowInfo) => {
        return <>{info}</>
      },
    },
    {
      title: "称重次数",
      dataIndex: "count",

      ellipsis: { showTitle: true },
      key: "count",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo?.columnKey === "count" ? sortedInfo?.order : null,
      render: (info, rowInfo) => {
        return <>{info}</>
      },
    },
    {
      title: "称重金额",
      dataIndex: "amount",

      ellipsis: { showTitle: true },
      key: "amount",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo?.columnKey === "amount" ? sortedInfo?.order : null,
    },
    {
      title: "监控改价出售次数",
      dataIndex: "abnormalPriceCount",

      ellipsis: { showTitle: true },
      key: "abnormalPriceCount",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "abnormalPriceCount"
          ? sortedInfo?.order
          : null,
      render: (info, rowInfo) => {
        return <>{info}</>
      },
    },
    {
      title: "损耗触发次数",
      dataIndex: "abnormalNum",

      ellipsis: { showTitle: true },
      key: "abnormalNum",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "abnormalNum" ? sortedInfo?.order : null,
    },
    // {
    //   title: "日期",
    //   dataIndex: "dateId",
    //
    //   key: "dateId",
    //   ellipsis: { showTitle: true },
    // },
    // {
    //   title: "改价次数",
    //   dataIndex: "priceCount",
    //
    //   key: "priceCount",
    //   ellipsis: { showTitle: true },
    //   render: (info, rowInfo) => {
    //     return (
    //       <Link
    //         to={{
    //           pathname: "/admin/lossmanage/updateroad",
    //           state: {
    //             shopIds: rowInfo?.shopIds,
    //             tabKey: tabKey,
    //             specific_time:
    //               tabKey == 1
    //                 ? dateValue
    //                 : tabKey == 2
    //                 ? weekValue
    //                 : tabKey == 3
    //                 ? monthValue
    //                 : "",
    //           },
    //         }}
    //       >
    //         <span style={{ color: "#2EB998" }}>{info}</span>
    //       </Link>
    //     )
    //   },
    // },
    // {
    //   title: "称重笔数",
    //   dataIndex: "count",
    //
    //   key: "count",
    //   ellipsis: { showTitle: true },
    //   render: (info, rowInfo) => {
    //     return (
    //       <Link
    //         to={{
    //           pathname: "/admin/datareport/weightrecord",
    //           state: {
    //             shopIds: rowInfo?.shopIds,
    //             tabKey: tabKey,
    //             specific_time:
    //               tabKey == 1
    //                 ? dateValue
    //                 : tabKey == 2
    //                 ? weekValue
    //                 : tabKey == 3
    //                 ? monthValue
    //                 : "",
    //           },
    //         }}
    //       >
    //         <span style={{ color: "#2EB998" }}>{info}</span>
    //       </Link>
    //     )
    //   },
    // },
    // {
    //   title: "称重金额",
    //   dataIndex: "amount",
    //
    //   key: "amount",
    //   ellipsis: { showTitle: true },
    // },
    // {
    //   title: "监控改价出售次数",
    //   dataIndex: "abnormalPriceCount",
    //
    //   key: "abnormalPriceCount",
    //   ellipsis: { showTitle: true },
    //   render: (info, rowInfo) => {
    //     return (
    //       <Link
    //         to={{
    //           pathname: "/admin/lossmanage/updateroad",
    //           state: {
    //             shopIds: rowInfo?.shopIds,
    //             tabKey: tabKey,
    //             specific_time:
    //               tabKey == 1
    //                 ? dateValue
    //                 : tabKey == 2
    //                 ? weekValue
    //                 : tabKey == 3
    //                 ? monthValue
    //                 : "",
    //           },
    //         }}
    //       >
    //         <span style={{ color: "#2EB998" }}>{info}</span>
    //       </Link>
    //     )
    //   },
    // },
    // {
    //   title: "异常称重笔数",
    //   dataIndex: "abnormalWeighNum",
    //
    //   key: "abnormalWeighNum",
    //   ellipsis: { showTitle: true },
    //   render: (info, rowInfo) => {
    //     return (
    //       <Link
    //         to={{
    //           pathname: "/admin/datareport/weightrecord",
    //           state: {
    //             shopIds: rowInfo?.shopIds,
    //             tabKey: tabKey,
    //             specific_time:
    //               tabKey == 1
    //                 ? dateValue
    //                 : tabKey == 2
    //                 ? weekValue
    //                 : tabKey == 3
    //                 ? monthValue
    //                 : "",
    //           },
    //         }}
    //       >
    //         <span style={{ color: "#2EB998" }}>{info}</span>
    //       </Link>
    //     )
    //   },
    // },
  ]

  return (
    <div className={styles.shopDetail_page}>
      <Card>
        <Row>
          <Col span={20} style={{ display: "flex" }}>
            <div>
              <Select
                style={{ width: 120 }}
                onChange={(e) => {
                  setTabKey(e)
                }}
                placeholder="请选择"
                value={tabKey}
              >
                {tabs.map((v) => {
                  return (
                    <Option value={v.value} key={v.value}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </div>

            {/* 日期选择 */}
            <div className={styles.shopDetail_page_time}>
              {/* 日期选择框 */}
              {tabKey == 1 ? (
                <DatePicker
                  style={{ width: 200, height: 32 }}
                  onChange={(e) => {
                    setDateValue(dayjs(e).format(dateFormat))
                    setDateTime(dayjs(e).format("YYYYMMDD"))
                  }}
                  value={dayjs(dateValue)}
                  allowClear={false}
                  disabledDate={disabledDateDay}
                  // suffixIcon={<div></div>}
                />
              ) : null}

              {/* 周报选择框 */}
              {tabKey == 2 ? (
                <Cascader
                  style={{ width: "auto", height: 32 }}
                  options={weekList}
                  value={weekValue}
                  onChange={(e) => {
                    setWeekValue(e)
                    setDateTime(e[1])
                  }}
                  placeholder="请选择周"
                />
              ) : null}

              {/* 月报选择框 */}
              {tabKey == 3 ? (
                <Select
                  style={{ width: 200, height: 32 }}
                  value={monthValue}
                  onChange={(e) => {
                    setMonthValue(e)
                    setDateTime(dayjs(e).format("YYYYMM"))
                  }}
                  placeholder="请选择月"
                >
                  {monthList.map((v) => {
                    return (
                      <Option key={v.yearMonth} value={v.yearMonth}>
                        {v.yearMonth}
                      </Option>
                    )
                  })}
                </Select>
              ) : null}
            </div>

            {/* 门店 */}
            <div className={styles.shopDetail_page_shop}>
              门店：
              <Select
                mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                style={{ minWidth: 200, maxWidth: 300 }}
                value={shopValue}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  console.log(e)
                  setShopValue(e)
                }}
                placeholder="请选择门店"
              >
                {shopSelect.map((v) => {
                  return (
                    <Option key={v.shopId} value={v.shopId}>
                      {v.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </Col>
          <Col span={4}>
            <div className={styles.shopDetail_head_brn}>
              {/* <Button type="primary" onClick={onHandGoBack}>
                返回上层
              </Button>
             */}
              <Button type="primary" onClick={onHandSearch}>
                查询
              </Button>
              <Button onClick={onHandClearn}>重置</Button>
            </div>
          </Col>
        </Row>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <Button type="primary" onClick={onHandExport}>
          导出数据
        </Button>
        <Table
          dataSource={shopTable}
          columns={columns}
          style={{ marginTop: "16px" }}
          loading={loading}
          pagination={onTableChange()}
          rowKey="id"
          scroll={{ x: "max-content", y: `${WinHeight - 460}px` }}
          onChange={handleChange}
        />
      </Card>
    </div>
  )
}

export default Index
