import React, { useState, useEffect, useRef } from "react"
import {
  Select,
  Input,
  Radio,
  Button,
  Table,
  Modal,
  message,
  Upload,
  Steps,
  Tree,
  Checkbox,
  Badge,
} from "antd"
import {
  ArrowUpOutlined,
  CloudUploadOutlined,
  CloseCircleOutlined,
  ContainerOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"
import { connect } from "react-redux"
import md5 from "md5"
import {
  Info_Benchmark,
  Goods_List,
  Shop_Device,
  Shop_List,
  Edit_Status,
  Deletes_good,
  Get_Captcha,
  Get_Send,
  Export_Good,
  Get_Token,
  Icon_Choose,
  Icon_Upload,
  Shop_Tree,
} from "../../services/goodmanage"
import "./Index.css"
import { Link } from "react-router-dom"
import * as qiniu from "qiniu-js"

import PromiseQueue from "../../../tools/queue"

const { Dragger } = Upload
const { Option } = Select
var countdown = 60
// 存储图片uid和路径的
var doneFiles = []
let newTreeDate = [] //全局定义树列表
let newRigCheDel = []
let uploadQueue = new PromiseQueue({ concurrency: 4 })

function Index(props) {
  // const statusRef = useRef(null)
  // const pageNoRef = useRef(null)
  // const deviceSnRef = useRef(null)
  // const shopIdRef = useRef(null)
  // const isLearnRef = useRef(null)
  // const skuNameRef = useRef(null)
  const [infoBenchmark, setInfoBenchmark] = useState({}) //默认门店与设备的value对象
  const [goodList, setGoodList] = useState([]) //商品管理列表
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [goodListInfo, setGoodListInfo] = useState({}) //商品管理总数据
  const [isStatusOpen, setIsStatusOpen] = useState(false) //单个更改状态modal打开关闭状态
  const [shopList, setShopList] = useState([]) //门店列表
  const [devoceList, setDevoceList] = useState([]) //设备列表
  const [deviceSn, setDeviceSn] = useState("") //设备sn
  const [isIcon, setIsIcon] = useState("") //有无图标（1：有：0：无）
  const [isLearn, setIsLearn] = useState("") //有无学习数据（0：无，1：有）
  const [isLock, setIsLock] = useState("") //是否锁定（1：是；0：否）
  const [isOn, setIsOn] = useState("") //是否上架（1：是；0：否）
  const [pageNo, setPageNo] = useState(1) //当前页码数（默认给1）
  const [pageSize, setPageSize] = useState(10) //每页显示的行数
  const [shopId, setShopId] = useState("") //门店ID
  const [skuName, setSkuName] = useState("") //商品名称
  const [status, setStatus] = useState("") //状态（1：锁定；2：上架；3：下架,0：全部）
  const [tableSetStatus, setTableSetStatus] = useState() //做状态更改时存的状态
  const [skuCodeTotal, setSkuCodeTotal] = useState() //点击状态更改时存的当前数据的skucode
  //-------------------------批量--------------------------
  const [isBatcStateh, setIsBatcStateh] = useState(false) //批量更改状态modal打开关闭状态
  const [batcStatehTyps, setBatcStatehTyps] = useState() //批量操作类型，上下架，锁定，删除
  const [getCapthcha, setGetCapthcha] = useState({}) //图形码返回数据code,image
  const [secondTime, setSecondTime] = useState("获取验证码") //短信倒计时text
  const [isSecondTime, setIsSecondTime] = useState(false) //短信倒计时按钮是否可以点击
  const [phone, SetPhone] = useState("") //用户名
  const [captText, SetCaptText] = useState("") //图形码
  const [verificat, SetVerificat] = useState("") //验证码
  //-----------------------表格图片编辑--------------------------
  const [isOut, setIsOut] = useState(false) //鼠标移入移出状态
  const [outSkuCode, setOutSkuCode] = useState("") //鼠标移入移出时存个id做判断
  const [imgEditState, setImgEditState] = useState(false) //表格icon更改状态
  const [nowInfo, setNowInfo] = useState({}) //当前编辑的行数据
  const [token, setToken] = useState("") //token
  const [domain, setDomain] = useState("") //域名
  const [fileName, setFileName] = useState("") //名
  const [uid, setUid] = useState([]) //uid
  const [imgUrl, setImgUrl] = useState("") //上传成功后返回的图片路径
  //-----------------------------批量上传-------------------
  const [allLoading, setAllLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false) //modal打开关闭状态
  const [fileList, setFileList] = useState([]) //上传图片的列表
  const [successUploadList, setSuccessUploadList] = useState([]) //这里是批量上传图片成功的列表
  const [errUploadList, setErrUploadList] = useState([]) //这里是批量上传图片失败的列表
  const [fileListKey, setFileListKey] = useState("") //名
  const [uploadDoneFiles, setUploadDoneFiles] = useState(new Map()) //名
  const [againPlay, setAgain] = useState(false) //下发到其他设备
  const [treeList, setTreeList] = useState([]) //全部选中的数据
  //--------------穿梭框树--------------
  const [deviceNum, setDeviceNum] = useState(0) //设备数量
  const [treeLeftValue, setTreeLeftValue] = useState([]) //树结构左侧选中数据
  const [newRigShowData, setNewRigShowData] = useState([]) //树结构右边数据列表查询数据
  const [treeCheck, setTreeCheck] = useState([]) //去除父级选中的数据(要给后端传的数据)
  const [treeData, setTreeData] = useState([]) //树形结构左边默认的列表
  const [shop_tree_list, setShop_tree_list] = useState([]) //门店、设备查询李恩（共享设备下拉数据）
  const [deviceListChildSnList, setDeviceListChildSnList] = useState([]) //
  const [cascaderValue, setCascaderValue] = useState("") //共享设备下拉数据
  const [deviceListChildSn, setDeviceListChildSn] = useState("") //SN
  const [equipmentCheckAllInfo, setEnetCkAllInfo] = useState({})
  const [searchNewTree, setSearchNewTree] = useState([]) //当有值时展示它，否则展示newTreeDate
  const [allTreeLeftKey, setAllTreeLeftKey] = useState([]) //树左边所有的key
  const [allLeftDisa, setAllLeftDisa] = useState(false) //
  const [sourt, setSourt] = useState([]) //下发结果

  const [RightFalseTree, setRightFalseTree] = useState([]) //临时存储右侧数据
  const [RightTrueTree, setRightTrueTree] = useState([]) //将临时存储变为真，并显示
  const [RightCheckValue, setRightCheckValue] = useState([]) //右侧树选中数据
  const [RightAllCheck, setRightAllCheck] = useState([]) //右侧全部key

  //------------------------------------------------------
  const changeSelectRef = useRef({
    statusRef: "",
    pageNoRef: "",
    deviceSnRef: "",
    shopIdRef: "",
    isLearnRef: "",
    skuNameRef: "",
  })
  useEffect(() => {
    changeSelectRef.current = {
      statusRef: status, //状态切换，解决useState拿不到最新值的问题
      pageNoRef: pageNo, //解决翻页，解决useState拿不到最新值页码的问题
      deviceSnRef: deviceSn,
      shopIdRef: shopId,
      isLearnRef: isLearn, //是否有学习数据
      skuNameRef: skuName, //商品名称
    }
  }, [status, pageNo, deviceSn, shopId, isLearn, skuName])

  useEffect(() => {
    setUid(window.localStorage.getItem("uid").toString().padStart(8, "0"))
    getShopTree() //树接口
    getShopList() //门店列表查询接口
    Get_Token().then((res) => {
      if (res.code == 200) {
        setToken(res.data?.token || "")
        setDomain(res.data?.domain || "")
      }
    })
    // getInfoBenchmark() //信息基准门店
  }, [])
  //
  //
  useEffect(() => {
    if (RightTrueTree.length > 0) {
      getAllCheck()
    }
  }, [RightAllCheck])
  useEffect(() => {
    if (RightTrueTree.length > 0) {
      getAllCheck()
    }
  }, [RightTrueTree])
  //鼠标移入
  const handOver = (info) => {
    setOutSkuCode(info?.skuCode)
    setIsOut(true)
  }
  //鼠标移出
  const handOut = () => {
    setIsOut(false)
  }
  //右侧全选的保障
  const getAllCheck = () => {
    let allArrRightTree = []
    RightTrueTree.map((v) => {
      allArrRightTree.push(v.key)
      v.children.map((v1) => {
        allArrRightTree.push(v1.key)
      })
    })
    setRightAllCheck(allArrRightTree)
  }
  /**
   * 信息基准门店
   */
  const getInfoBenchmark = (INFO) => {
    //判断门店列表有没有数据,没有的话就直接false
    if (INFO.length == 0 || !INFO) {
      return false
    }
    Info_Benchmark().then((res) => {
      if (res.code == 200) {
        if (res.data) {
          setInfoBenchmark(res.data) //默认设置的门店与设备值
          //判断基准id是否在门店列表里，不在的话直接return false
          const isYes =
            INFO.filter((v) => {
              return v.id == res?.data?.id
            }).length > 0

          if (!isYes) {
            setShopId("")
            return false
          }
          setShopId(res?.data?.id ? res?.data?.id : "") //门店id
          //判断当门店id存在时，去请求设备列表
          if (res?.data?.id) {
            getDeviceListDefault(res?.data?.id, res?.data)
          } else {
            return false
          }
        }
      }
    })
  }

  /**
   * 门店下设备下拉列表
   */
  // const getDeviceList = (info) => {
  //   const params = info
  //   Shop_Device(params).then((res) => {
  //     if (res.code == 200) {
  //       if (res?.data) {
  //         setDevoceList(res.data || [])
  //       } else {
  //         setDeviceSn(undefined)
  //       }
  //     }
  //   })
  // }

  /**
   * 默认门店下设备下拉列表
   */
  const getDeviceListDefault = (id, info) => {
    const params = id
    Shop_Device(params)
      .then((res) => {
        if (res.code == 200) {
          if (res.data) {
            setDevoceList(res.data || [])
            const IsCanShow = res.data.filter(
              (v) => v.deviceSn === info?.deviceSn
            )
            setDeviceSn(IsCanShow[0].deviceSn ? IsCanShow[0].deviceSn : "")
            setTimeout(getGoodsList, 0)
          }
        }
      })
      .catch((err) => {})
  }

  /**
   * 门店列表查询接口
   */
  const getShopList = () => {
    const params = {}
    Shop_List(params)
      .then((res) => {
        if (res.code == 200) {
          if (res?.data) {
            if (res.data.length == 0) {
              return false
            }
            setShopList(res.data) //

            //从设备管理跳转到商品管理并携带数据
            if (props.location.state) {
              const { data } = props.location.state
              if (data.shopId && data.deviceSn) {
                setShopId(data.shopId)
                getDeviceListDefault(data.shopId, data)
                // setDeviceSn(data.deviceSn)
              }
            } else {
              // 如果没携带数据，就用基准门店的默认数据
              getInfoBenchmark(res.data)
            }
          } else {
            getDeviceListDefault("")
            setDevoceList([])
          }
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  /**
   * 商品列表
   */
  const getGoodsList = (info) => {
    let _status = changeSelectRef.current?.statusRef
    let _pageNo = changeSelectRef.current?.pageNoRef
    let _deviceSn = changeSelectRef.current?.deviceSnRef
    let _shopId = changeSelectRef.current?.shopIdRef
    let _isLearn = changeSelectRef.current?.isLearnRef
    let _skuName = changeSelectRef.current?.skuNameRef
    const params = {
      deviceSn: _deviceSn,
      isIcon: isIcon,
      isLearn: _isLearn,
      isLock: isLock,
      isOn: isOn,
      pageNo: _pageNo,
      pageSize: pageSize,
      shopId: _shopId,
      skuName: _skuName,
      status: _status,
    }
    setLoading(true)
    Goods_List(params)
      .then((res) => {
        if (res.code == 200) {
          setLoading(false)
          if (res.data) {
            setGoodList(res?.data?.list)
            setGoodListInfo(res?.data)
          }
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        setGoodList([])
        setGoodListInfo({})
      })
  }

  /**
   * 查询
   */
  const handlSearch = () => {
    setPageNo(1)
    setTimeout(getGoodsList, 0)
    setSelectedRowKeys([]) //清除表头选中的复选框
  }
  /**
   * 重置
   */
  const handClearn = () => {
    getShopList() //门店列表查询接口
    setSkuName("") //清空商品名称
    setStatus("") //清空状态
    setIsLearn("") //清空学习数据
    setPageNo(1)
    setTimeout(getGoodsList, 0)
    setSelectedRowKeys([]) //清除表头选中的复选框
  }

  /**
   * 导出
   */
  const handleExport = () => {
    let _status = changeSelectRef.current?.statusRef
    let _pageNo = changeSelectRef.current?.pageNoRef
    let _deviceSn = changeSelectRef.current?.deviceSnRef
    let _shopId = changeSelectRef.current?.shopIdRef
    let _isLearn = changeSelectRef.current?.isLearnRef
    let _skuName = changeSelectRef.current?.skuNameRef
    const params = {
      deviceSn: _deviceSn,
      isIcon: isIcon,
      isLearn: _isLearn,
      isLock: isLock,
      isOn: isOn,
      pageNo: _pageNo,
      pageSize: goodListInfo?.total,
      shopId: _shopId,
      skuName: _skuName,
      status: _status,
    }
    // console.log("dsd")
    Export_Good(params).then((res) => {
      if (res.code == 200) {
        console.log(res)
      }
    })
  }

  /**
   * 批量上传props------------------------------------------------------------------------------------------------
   */

  const handleChange = (info) => {
    // let newFileList = [...info.fileList]
    console.log("handleChange", info)
    let newFileList = info.fileList.map((file) => {
      if (uploadDoneFiles.has(file.uid)) {
        let d = uploadDoneFiles.get(file.uid)
        file.status = d.status
        if (d.status === "done") {
          file.key = d.key
          file.url = domain + "" + file.key
        }
      }
      // doneFiles.map((v) => {
      //   if (file.uid === v.uid) {
      //     file.state = "done"
      //     file.key = domain + "" + v.key
      //   }
      // })
      // if (file.status === "done") {
      //   if (file.response) {
      //     file.url = file.key
      //   }
      // }
      return file
    })

    let successUpload = [] //这里是为了拿到上传成功的数组
    let errUpload = [] //这里是为了拿到上传失败的数组
    newFileList.map((v) => {
      if (v?.status == "done") {
        successUpload.push(v)
      } else if (v?.status == "error") {
        errUpload.push(v.name)
      }
    })
    setSuccessUploadList([...successUpload]) //上传成功后存储
    setErrUploadList([...errUpload]) //上传失败后存储

    setFileList([...newFileList])
    return true
  }
  // 获取文件流md5
  const getFileMd5 = (file) => {
    return new Promise(function (resolve, reject) {
      const fileReader = new FileReader()
      // fileReader.readAsBinaryString(file)
      fileReader.onload = (e) => {
        const image = new Image()
        image.onload = function () {
          if (
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg"
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve(md5(e.target.result))
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = e.target.result
      }
      fileReader.onerror = (err) => {
        reject(err)
      }

      fileReader.readAsDataURL(file)
    })
  }

  const fileListProps = {
    action: "https://up-z0.qiniup.com",
    onChange: handleChange,
    multiple: true,
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        resolve({
          token,
          key: `icons/project/${uid}/${
            e.name.split(".")[0]
          }_${new Date().getTime()}.${e.type.split("/")[1]}`,
        })
      })
    },
    beforeUpload: (e) => {
      // return new Promise(function (resolve, reject) {
      //   getFileMd5(e).then((result) => {
      //     console.log("md5--------", result, "uid: ", e.uid)
      //     resolve()
      //   })
      // })
    },
    //批量上传自定义
    customRequest: (options) => {
      const { onSuccess, onError, file, onProgress } = options
      console.log(options, "------------------------------------------")
      getFileMd5(file)
        .then((md5) => {
          uploadQueue.add(
            () =>
              new Promise((resolve) => {
                const key = `icons/project/${uid}/${
                  file.name.split(".")[0]
                }_${md5}.${file.type.split("/")[1]}`
                const observable = qiniu.upload(
                  file,
                  key,
                  options.data.token,
                  {},
                  {}
                )
                const subscription = observable.subscribe(
                  (event) => {
                    onProgress(
                      { percent: (event.loaded / event.total) * 100 },
                      file
                    )
                  },
                  (err) => {
                    console.log("error", err)
                    setUploadDoneFiles(
                      uploadDoneFiles.set(file.uid, {
                        key: "",
                        status: "error",
                      })
                    )
                    resolve()
                  },
                  (res) => {
                    setTimeout(function () {
                      onSuccess(file)
                    }, 1000)

                    setUploadDoneFiles(
                      uploadDoneFiles.set(file.uid, {
                        key: res.key,
                        status: "done",
                      })
                    )

                    // doneFiles.push(file.uid)
                    //这里是将存储了图片的uid和接口返回的key
                    // doneFiles.push({ uid: file.uid, key: res.key })
                    resolve()
                  }
                )
              })
          )
        })
        .catch((err) => {
          console.log(err)
          setUploadDoneFiles(
            uploadDoneFiles.set(file.uid, {
              key: "",
              status: "error",
            })
          )
          onError(err)
        })
    },
  }

  //下载到本地
  const download = (name, href) => {
    let element = document.createElement("a")
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(errUploadList.join("\n"))
    )
    element.setAttribute("download", "图片上传失败名")
    element.style.display = "none"
    element.click()
    // const imgUrl = href // 图片链接
    // const a = document.createElement("a")

    // // 这里是将url转成blob地址，
    // fetch(imgUrl) // 跨域时会报错
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //     // 将链接地址字符内容转变成blob地址
    //     a.href = URL.createObjectURL(blob)
    //     a.download = name // 下载文件的名字
    //     document.body.appendChild(a)
    //     a.click()
    //     //在资源下载完成后 清除 占用的缓存资源
    //     window.URL.revokeObjectURL(a.href)
    //     document.body.removeChild(a)
    //   })

    // setFileList(successUploadList) //将原始批量上传列表换成成功上传的列表
    // setErrUploadList([]) //清除失败列表
  }
  //打开modal
  const showModal = () => {
    setFileList([]) //清空初始化上传图片列表（包含成功与上传中）
    setSuccessUploadList([]) //清空上传成功列表
    setErrUploadList([]) //清空上传失败列表
    setIsModalOpen(true)
    setAgain(false)
    doneFiles = [] //打开导入icon-modal时，先将存储图片uid和路径的全局数组清空
    newRigCheDel = []
    setTreeLeftValue([]) //树结构左侧选中数据清空
    setRightCheckValue([])
    setRightTrueTree([])
    setRightFalseTree([])
    setTreeCheck([])
    setSearchNewTree([])
  }
  //modal提交
  const handleOk = () => {
    // treeCheck
    setAllLoading(true)
    let icons = []
    successUploadList.map((v) => {
      v.iconUrl = v.url
      v.skuCode = v.name.split(".")[0]
      icons.push({ iconUrl: v.iconUrl, skuCode: v.skuCode })
    })
    let params = {}
    if (againPlay) {
      params = {
        deviceSns: treeCheck,
        icons: icons,
      }
    } else {
      params = {
        deviceSns: [deviceSn],
        icons: icons,
      }
    }

    Icon_Upload(params)
      .then((res) => {
        if (res.code == 200) {
          if (res.data) {
            if (!againPlay) {
              if (res.data?.resultList[0].informResult) {
                setAllLoading(false) //按钮loading
                message.success("icon已成功导入")
                setIsModalOpen(false)
                setAgain(false)
              } else {
                message.error("icon导入失败")
              }
            } else {
              //选择其他设备下发
              console.log(res)
              if (res.code === 200) {
                if (res.data) {
                  setSourt(res?.data?.resultList)
                }
              }
            }
          }
        } else {
          setAllLoading(false)
          console.log(res)
        }
      })
      .catch((err) => {
        // console.log(err)
      })
  }
  /**
   * 门店、设备查询，设备树
   */
  const getShopTree = () => {
    let listMap = []
    let listMap1 = []
    let listChildNum = 0
    Shop_Tree().then((res) => {
      if (res.code == 200) {
        if (res?.data) {
          // console.log(res.data)
          // setTreeList(res.data)
          res.data.map((v) => {
            v.id = v.shopId
            ;(v.deviceList || v.deviceList !== null ? v.deviceList : []).map(
              (x) => {
                listMap[v.id + x.id] = x.deviceSn ? x.deviceSn : ""
                listMap1[v.id + x.id] = x
                x.name =
                  !x.scaleNo ||
                  (x.systemName !== 0 && x.systemName !== 1) ||
                  !x.matchingRulesVersion
                    ? x.cdKeyCode
                    : x.scaleNo +
                      "-" +
                      (x.systemName == 0
                        ? "And"
                        : x.systemName == 1
                        ? "Win"
                        : "") +
                      "-" +
                      x.matchingRulesVersion
              }
            )
          })
          const defaultSnId =
            res.data.length > 0
              ? [
                  res.data[0].id,
                  res.data[0].deviceList !== null
                    ? res.data[0].deviceList[0].id
                    : "",
                ]
              : []
          //设备数量
          if (res?.data?.length > 0) {
            res?.data.map((v, i) => {
              listChildNum =
                (v.deviceList || v.deviceList !== null
                  ? v.deviceList.length
                  : 0) + listChildNum
            })
          }
          setShop_tree_list(res.data)
          setDeviceListChildSnList(listMap)
          setCascaderValue(defaultSnId)
          setDeviceListChildSn(listMap[defaultSnId[0] + defaultSnId[1]])
          setTreeData(res.data)
          setDeviceNum(listChildNum)
          setEnetCkAllInfo(
            listMap1[defaultSnId[0] + defaultSnId[1]]
              ? listMap1[defaultSnId[0] + defaultSnId[1]]
              : ""
          )
        }
      }
    })
  }
  //下发到其他设备
  const handleOkAgain = () => {
    if (successUploadList.length == 0) {
      return false
    }
    setAgain(true)
    setpsValue()
  }
  //modal关闭
  const handleCancel = () => {
    setIsModalOpen(false)
    setAgain(false)
  }

  //------------------------------------------------------------------------------------------------------------
  //tab切换
  const handleTab = (info) => {
    setStatus(info)
    setPageNo(1)
    setTimeout(getGoodsList, 0)
    setSelectedRowKeys([]) //清除表头选中的复选框
  }

  //单个上下架锁定删除
  const setShelvesStatus = (num, info) => {
    setSkuCodeTotal(info?.skuCode) //数据的skucode
    setTableSetStatus(num) //自定义的识别数
    setIsStatusOpen(true)
  }
  //单个更改状态modal确定按钮
  const handleSetStatusOk = () => {
    //上下架，锁定
    if (tableSetStatus != 99) {
      const params = {
        shopId: shopId,
        skuCode: [skuCodeTotal],
        status: tableSetStatus,
        deviceSn: deviceSn,
      }
      Edit_Status(params).then((res) => {
        if (res.code == 200) {
          if (res.data?.result) {
            setPageNo(1)
            setTimeout(getGoodsList, 0)
            setSelectedRowKeys([]) //清除表头选中的复选框
            setIsStatusOpen(false)
            message.success(
              tableSetStatus == 2
                ? "商品已上架"
                : tableSetStatus == 3
                ? "商品已下架"
                : tableSetStatus == 1
                ? "商品已锁定"
                : ""
            )
          }
        }
      })
    } else {
      //单个删除
      const params = {
        shopId: shopId,
        skuCode: [skuCodeTotal],
        deviceSn: deviceSn,
      }
      Deletes_good(params).then((res) => {
        if (res.code == 200) {
          if (res?.data) {
            if (res.data?.result) {
              message.success("删除成功")
              setPageNo(1)
              setTimeout(getGoodsList, 0)
              setSelectedRowKeys([]) //清除表头选中的复选框
              setIsStatusOpen(false)
            }
          }
        }
      })
    }

    //
  }
  //单个更改状态modal取消按钮
  const handleSetStatusCancel = () => {
    setIsStatusOpen(false)
  }

  //批量更改状态打开modal
  const handleBatch = (INFO) => {
    setBatcStatehTyps(INFO)
    setIsBatcStateh(true)
  }
  //图形验证码
  const getCaptcha = () => {
    Get_Captcha().then((res) => {
      if (res.code == 200) {
        setGetCapthcha(res.data || {})
      }
    })
  }
  //批量更改状态确认提交
  const handleBatcOk = () => {
    //上下架，锁定
    if (batcStatehTyps == 1 || batcStatehTyps == 2 || batcStatehTyps == 3) {
      const params = {
        shopId: shopId,
        skuCode: selectedRowKeys,
        status: batcStatehTyps,
        deviceSn: deviceSn,
      }
      console.log(params)
      Edit_Status(params).then((res) => {
        if (res.code == 200) {
          if (res.data?.result) {
            setPageNo(1)
            setTimeout(getGoodsList, 0)
            setSelectedRowKeys([]) //清除表头选中的复选框
            setIsBatcStateh(false)
            message.success(
              batcStatehTyps == 2
                ? "商品已上架"
                : batcStatehTyps == 3
                ? "商品已下架"
                : batcStatehTyps == 1
                ? "商品已锁定"
                : ""
            )
          }
        }
      })
    } else if (batcStatehTyps == 99) {
      // 批量删除第一次确认
      setBatcStatehTyps(999)
      getCaptcha()
    } else if (batcStatehTyps == 999) {
      // 批量删除第二次次确认，并要验证码校验
      if (!phone) {
        message.error("请输入手机号！")
        return false
      }
      if (!captText) {
        message.error("请输入图形码！")
        return false
      }
      if (!verificat) {
        message.error("请输入验证码！")
        return false
      }
      //批量删除
      const params = {
        code: verificat, //短信验证码
        shopId: shopId,
        skuCode: selectedRowKeys,
        deviceSn: deviceSn,
      }
      Deletes_good(params).then((res) => {
        if (res.code == 200) {
          if (res?.data) {
            if (res.data?.result) {
              message.success("删除成功")
              setPageNo(1)
              setTimeout(getGoodsList, 0)
              setSelectedRowKeys([]) //清除表头选中的复选框
              setIsBatcStateh(false)
            }
          }
        }
      })
    }
  }
  //批量更改状态取消
  const handleBatcCancel = () => {
    setIsBatcStateh(false)
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${goodListInfo?.total}条`,
      pageSize: pageSize,
      current: pageNo,
      total: goodListInfo?.total,
      onChange: (current) => {
        setPageNo(current) //
        // getGoodsList("", current) //翻页时调用列表接口
        setTimeout(getGoodsList, 0)
        setSelectedRowKeys([]) //翻页时清除表头选中的复选框
      },
    }
    return paginationProps
  }

  //表格复选框
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columns = [
    {
      title: "商品名称",
      dataIndex: "skuName",

      key: "skuName",
      ellipsis: { showTitle: true },
    },
    {
      title: "商品图片",
      width: 180,
      dataIndex: "icon",

      key: "icon",
      ellipsis: { showTitle: true },
      render: (info, rowInfo) => {
        return (
          <div className="page_table_img">
            {info ? (
              <img
                src={info}
                onMouseOver={() => handOver(rowInfo)}
                onMouseOut={() => handOut()}
              />
            ) : null}

            {/* {info ? (
              <>
                <img
                  src={info}
                  onMouseOver={() => handOver(rowInfo)}
                  onMouseOut={() => handOut()}
                />
                {isOut && rowInfo?.skuCode == outSkuCode ?
                 (
                  <div
                    className="tabimg_img_edit"
                    onMouseOver={() => handOver(rowInfo)}
                    onMouseOut={() => handOut()}
                    onClick={() => handleImgEdit(rowInfo)}
                  >
                    编辑
                  </div>
                ) 
                : null}
              </>
            ) : (
              <div
                className="tabimg_img_edit"
                onMouseOver={() => handOver(rowInfo)}
                onMouseOut={() => handOut()}
                onClick={() => handleImgEdit(rowInfo)}
              >
                新建
              </div>
            )} */}
          </div>
        )
      },
    },
    {
      title: "商品编码",
      dataIndex: "skuCode",

      key: "skuCode",
      ellipsis: { showTitle: true },
    },
    {
      title: "学习数据/张",
      dataIndex: "learnNum",

      key: "learnNum",
      ellipsis: { showTitle: true },
    },
    {
      title: "销售状态",
      dataIndex: "status",

      key: "status",
      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <>
            {info == 1 ? "锁定" : info == 2 ? "上架" : info == 3 ? "下架" : ""}
          </>
        )
      },
    },
    {
      title: "操作",

      ellipsis: { showTitle: true },
      render: (info) => (
        <>
          <a className="page_table_btn" onClick={() => handleImgEdit(info)}>
            编辑图片
          </a>
          {status == 0 ? (
            <>
              {info?.status == 3 ? (
                <>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(2, info)}
                  >
                    上架
                  </a>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(1, info)}
                  >
                    锁定
                  </a>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(99, info)}
                  >
                    删除
                  </a>
                </>
              ) : info?.status == 2 ? (
                <>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(3, info)}
                  >
                    下架
                  </a>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(1, info)}
                  >
                    锁定
                  </a>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(99, info)}
                  >
                    删除
                  </a>
                </>
              ) : info?.status == 1 ? (
                <>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(2, info)}
                  >
                    上架
                  </a>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(3, info)}
                  >
                    下架
                  </a>
                  <a
                    className="page_table_btn"
                    onClick={() => setShelvesStatus(99, info)}
                  >
                    删除
                  </a>
                </>
              ) : null}
            </>
          ) : status == 1 ? (
            <>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(2, info)}
              >
                上架
              </a>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(3, info)}
              >
                下架
              </a>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(99, info)}
              >
                删除
              </a>
            </>
          ) : status == 2 ? (
            <>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(3, info)}
              >
                下架
              </a>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(1, info)}
              >
                锁定
              </a>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(99, info)}
              >
                删除
              </a>
            </>
          ) : status == 3 ? (
            <>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(2, info)}
              >
                上架
              </a>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(1, info)}
              >
                锁定
              </a>
              <a
                className="page_table_btn"
                onClick={() => setShelvesStatus(99, info)}
              >
                删除
              </a>
            </>
          ) : null}
        </>
      ),
    },
  ]
  /**
   * 倒计时
   */
  const settime = (secondTime) => {
    if (countdown == 0) {
      setIsSecondTime(false)
      secondTime = "获取验证码"
      countdown = 60
    } else {
      setIsSecondTime(true)
      secondTime = "重新发送(" + countdown + ")"
      countdown--
      setTimeout(function () {
        settime(secondTime)
      }, 1000)
    }
    setSecondTime(secondTime)
  }
  /**
   * 获取短信验证码
   */
  const handGetCode = () => {
    if (!captText) {
      message.error("请输入图形码！")
      return false
    }
    if (!phone) {
      message.error("请输入手机号！")
      return false
    }
    //调用倒计时
    settime()
    const params = {
      code: getCapthcha?.code,
      phone: phone,
      verificationCode: captText,
    }
    console.log(params)
    Get_Send(params).then((res) => {
      if (res.code == 200 && res.data) {
        message.success(res.msg)
      }
    })
  }
  //表格icon更改状态打开modal
  const handleImgEdit = (info) => {
    console.log(info)
    setNowInfo(info || {})
    setImgEditState(true)
    setImgUrl("")
  }
  //表格icon更改状态提交确定
  const handeleIconOK = () => {
    if (!imgUrl) {
      setImgEditState(false)
      return false
    }
    const params = {
      deviceSn: deviceSn,
      iconUrl: imgUrl,
      skuCode: nowInfo?.skuCode,
    }
    Icon_Choose(params).then((res) => {
      if (res.code == 200) {
        if (res.data?.result) {
          message.success("icon更换成功")
          getGoodsList()
          setImgEditState(false)
        }
      }
    })
    // }
  }
  //表格icon更改状态关闭
  const handleIconClose = () => {
    setImgEditState(false)
  }

  // 限制宽高
  const handleFilebeforeUpload = (file) => {
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader()
      filereader.onload = (e) => {
        let src = e.target.result
        const image = new Image()
        image.onload = function () {
          if (
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg"
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve("上传成功")
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = src
      }
      filereader.readAsDataURL(file)
    })
  }

  // 表格数据中的图片编辑上传
  const uploadProps = {
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(e)
        fileReader.onload = (ee) => {
          var _md5only = md5(ee.target.result)
          resolve({
            token,
            key: `icons/project/${uid}/${nowInfo?.skuCode}_${_md5only}.${
              e.type.split("/")[1]
            }`,
          })
        }
        fileReader.onerror = (err) => {
          reject(err)
        }
      })
    },
    beforeUpload: async (e) => {
      try {
        const sizeQualified = await handleFilebeforeUpload(e)
        message.success(sizeQualified)
        return true
      } catch (err) {
        message.error("上传失败,请按建议上传")
        return false
      }
    },
    onChange(info) {
      console.log(info, "---------1-------------")
      const _file = info.file
      if (_file.status === "done") {
        console.log(_file, "上传成功的数据")
        setImgUrl(domain + "" + _file?.response?.key || "")
      }
      if (_file.status === "error") {
        message.error(_file?.response.error)
      }
    },
  }

  //树形结构select事件
  const handTreeSelect = (e) => {
    console.log(e)
  }

  //树形结构check事件
  const handTreeCheck = (e) => {
    // /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //左侧选中，
    let newFalseArr = []
    //将newTreeDate循环，找到选中数据的父级
    newTreeDate.map((v) => {
      var cs = v.children.filter((item) => {
        return e.indexOf(item.deviceSn) > -1
      })
      if (cs.length > 0) {
        var _v = JSON.parse(JSON.stringify(v))
        _v.children = cs
        newFalseArr.push(_v)
      }
    })
    setRightFalseTree(newFalseArr) //临时存储
    // /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // // /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // //左侧选中，
    // let newFalseArr = []
    // //将newTreeDate循环，找到选中数据的父级
    // newTreeDate.map((v) => {
    //   e.map((w) => {
    //     if (v.key === w) {
    //       newFalseArr.push(v)
    //     }
    //   })
    // })
    // //将选好的父级循环，匹配子集
    // newFalseArr.map((v) => {
    //   let newZ = []
    //   v.children.map((w) => {
    //     e.map((z) => {
    //       if (w.deviceSn === z) {
    //         newZ.push(w)
    //       }
    //     })
    //   })
    //   v.children = newZ
    // })
    // setRightFalseTree(newFalseArr) //临时存储
    // // /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //newE:将子集拆出来，因为父级只做展示
    const newE = e.filter((item) => newTreeDate.every((v) => v.key != item))
    const neweRight = []
    //循环树的列表，将拆出来的子集数组从树列表找到，渠道完成的数组，
    newTreeDate.map((v, i) => {
      v.children.map((v1, ii1) => {
        newE.map((v2, i2) => {
          if (v1.key == v2) {
            neweRight.push(v1)
          }
        })
      })
    })
    // console.log(neweRight)

    setTreeCheck(newE) //去除父级选中的数据(要给后端传的数据)
    setTreeLeftValue(e) //全部选中的数据
  }
  //右侧选中
  const handTreeCheck1 = (e) => {
    console.log(e)
    setRightAllCheck(e)
    setRightCheckValue(e)
  }

  const setIsOnline = (info) => {
    let meonLine = 0
    let meonLineStatus = true
    let identical = false
    ;(info.deviceList || []).map((v) => {
      meonLine = meonLine + v.online
      if (
        equipmentCheckAllInfo.matchingRulesVersion !== v.matchingRulesVersion
      ) {
        meonLineStatus = false
        return false
      }
    })
    if ((info?.deviceList || [])?.length == 1) {
      if (info?.deviceList[0].id == cascaderValue[1]) {
        identical = true
      }
    }
    // return meonLine == 0 || !meonLineStatus || identical ? true : false
    return meonLine == 0 ? true : false
  }
  const setpsValue = () => {
    newTreeDate = treeData.map((v) => ({
      key: v.shopId,
      title: v.name,
      disabled: setIsOnline(v),
      ...v,
      children: (v.deviceList || []).map((v1) => ({
        key: v1.deviceSn,
        title:
          !v1.scaleNo ||
          (v1.systemName !== 0 && v1.systemName !== 1) ||
          !v1.matchingRulesVersion
            ? v1.cdKeyCode
            : `${v1.scaleNo}-${
                v1.systemName == 0 ? "And" : v1.systemName == 1 ? "Win" : ""
              }-${v1.matchingRulesVersion}`,
        disabled:
          v1.online == 1
            ? // &&
              //   v1.matchingRulesVersion ===
              //     equipmentCheckAllInfo.matchingRulesVersion &&
              //   v1.id !== cascaderValue[1]
              false
            : true,
        icon: (
          <div
            style={{
              color: `${
                v1.online == 1
                  ? //  &&
                    // v1.matchingRulesVersion === equipmentCheckAllInfo.matchingRulesVersion &&
                    // v1.id !== cascaderValue[1]
                    "#19BC89"
                  : "red"
              }`,
              fontSize: "50px",
              position: "relative",
              top: "-3px",
            }}
          >
            ·
          </div>
        ),
        ...v1,
      })),
    }))
    console.log(newTreeDate)
    let isDisa = false
    newTreeDate.map((v) => {
      if (v.disabled === true) {
        isDisa = true
        return false
      }
    })
    setAllLeftDisa(isDisa)
    //获取所有的key,做全选
    let allCheckInfo = []
    newTreeDate.map((v) => {
      allCheckInfo.push(v.key)
      v.children.map((v1) => {
        allCheckInfo.push(v1.key)
      })
    })
    setAllTreeLeftKey(allCheckInfo)
    // setTreeLeftValue(allCheckInfo)
  }

  //树输入框查询
  const handlInput = (e) => {
    //当searchNewTree有值时展示searchNewTree，否则展示newTreeDate
    const inputValue = e.target.value
    if (inputValue) {
      const newDeptList = newTreeDate.filter(
        (item) => item.name.indexOf(inputValue) != -1
      )
      setSearchNewTree(newDeptList)
    } else {
      setSearchNewTree([])
    }
  }
  //右侧输入框查询
  const handlInputRig = (e) => {
    const inputValue = e.target.value
    if (inputValue) {
      const newDeptList = RightTrueTree.filter(
        (item) => item.name.indexOf(inputValue) != -1
      )
      setNewRigShowData(newDeptList)
    } else {
      setNewRigShowData([])
    }
  }

  return (
    <div className="page">
      {/* 顶部筛选 */}
      <div className="page_head">
        {/* 顶部左侧筛选条件 */}
        <div className="page_col_left">
          门店：
          {/*---------- 门店下拉 ----------*/}
          <Select
            onChange={(e) => {
              setShopId(e)
              getDeviceListDefault(e) //门店切换时，请求设备列表
              setDeviceSn("") //门店切换时，清除设备select-value
              //
              if (e == infoBenchmark?.id) {
                getDeviceListDefault(infoBenchmark?.id, infoBenchmark)
              }
            }}
            placeholder="请选择门店！"
            value={shopId ? shopId : undefined}
          >
            {shopList.map((v) => {
              return (
                <Option value={v.id} key={v.id}>
                  {v.name}
                </Option>
              )
            })}
          </Select>
          {/*---------- 设备下拉 ----------*/}
          <Select
            style={{ margin: " 0 20px 0 9px" }}
            value={deviceSn ? deviceSn : undefined}
            onChange={(e) => {
              setDeviceSn(e)
            }}
            placeholder="请选择设备！"
          >
            {devoceList.map((v) => {
              return (
                <Option value={v.deviceSn} key={v.deviceSn}>
                  {v.cdKeyCode}
                </Option>
              )
            })}
          </Select>
          {/*---------- 商品名称 ----------*/}
          <div className="page_head_input">
            商品名称：
            <Input
              className="page_head_input"
              placeholder="请输入商品名称"
              value={skuName ? skuName : undefined}
              onChange={(e) => {
                setSkuName(e.target.value)
              }}
            />
          </div>
          {/*----------  销售状态|商品状态 ----------*/}
          <div>
            销售状态：
            <Select
              placeholder="请选择销售状态"
              value={status ? status : undefined}
              onChange={(e) => {
                setStatus(e)
              }}
              style={{ marginRight: "20px" }}
            >
              <Option value={2} key={2}>
                上架
              </Option>
              <Option value={3} key={3}>
                下架
              </Option>
              <Option value={1} key={1}>
                锁定
              </Option>
            </Select>
          </div>
          {/*---------- 学习数据单选按钮 ----------*/}
          <div className="page_head_radio">
            学习数据：
            <Radio.Group
              value={isLearn}
              onChange={(e) => {
                setIsLearn(e.target.value)
              }}
            >
              <Radio value={"1"}>有</Radio>
              <Radio value={"0"}>无</Radio>
            </Radio.Group>
          </div>
        </div>
        {/*  顶部右侧查询按钮 */}
        <div className="page_col_right">
          <div className="page_search_btn">
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
          </div>
          <div className="page_search_btn">
            <Button onClick={handClearn}>重置</Button>
          </div>
          <div className="page_search_btn">
            <Button
              style={{
                color: "#2EB998",
                border: "1px solid #2EB998",
              }}
              onClick={() => {
                window.history.back(-1)
              }}
            >
              返回上层
            </Button>
          </div>
        </div>
      </div>
      {/* 主体部分 */}
      <div className="page_body">
        {/* 操作功能，tab选择 */}
        <div className="page_body_tabs">
          <div style={{ display: "flex" }} className="page_body_tabs_btn">
            <Button
              onClick={() => handleBatch(2)}
              type="primary"
              disabled={selectedRowKeys.length >= 2 ? false : true}
              style={{
                background: "#5DBA9D",
                color: selectedRowKeys.length >= 2 ? "" : "white",
                opacity: selectedRowKeys.length >= 2 ? 1 : "0.6",
              }}
            >
              上架
            </Button>
            <Button
              onClick={() => handleBatch(3)}
              type="primary"
              style={{
                color: selectedRowKeys.length >= 2 ? "" : "white",
                background: "#648DFF",
                opacity: selectedRowKeys.length >= 2 ? 1 : "0.6",
              }}
              disabled={selectedRowKeys.length >= 2 ? false : true}
            >
              下架
            </Button>
            <Button
              onClick={() => handleBatch(1)}
              type="primary"
              style={{
                color: selectedRowKeys.length >= 2 ? "" : "white",
                background: "#F59A23",
                opacity: selectedRowKeys.length >= 2 ? 1 : "0.6",
              }}
              disabled={selectedRowKeys.length >= 2 ? false : true}
            >
              锁定
            </Button>
            <Button
              onClick={() => handleBatch(99)}
              type="primary"
              style={{
                background: "",
                color: selectedRowKeys.length >= 2 ? "" : "white",
                background: "#EC808D",
                opacity: selectedRowKeys.length >= 2 ? 1 : "0.6",
              }}
              disabled={selectedRowKeys.length >= 2 ? false : true}
            >
              删除
            </Button>
          </div>
          <div className="body_tabs_btn">
            {/* <Button
              type="primary"
              onClick={showModal}
              disabled={goodList.length == 0 || !goodList ? true : false}
            >
              导入图片
            </Button> */}
            <Link
              to={{
                pathname:
                  goodList.length == 0 || !goodList
                    ? ""
                    : "/admin/discernmanage/datashare",
              }}
            >
              <Button
                type="primary"
                disabled={goodList.length == 0 || !goodList ? true : false}
                onClick={() => {
                  sessionStorage.setItem("page", "shopImgMang")
                  sessionStorage.setItem("sn", deviceSn)
                }}
              >
                导入图片
              </Button>
            </Link>
            <Button
              type="primary"
              icon={<ArrowUpOutlined />}
              disabled={
                goodList.length == 0 || !goodList
                  ? true
                  : selectedRowKeys.length > 0
                  ? true
                  : false
              }
              onClick={handleExport}
            >
              导出数据
            </Button>
          </div>
        </div>
        {/* 表格 */}
        <div className="page_body_table">
          <Table
            pagination={onTableChange()}
            dataSource={goodList}
            columns={columns}
            loading={loading}
            rowSelection={rowSelection}
            rowKey="skuCode"
            scroll={{ y: window.screen.height * 0.5 }}
          />
        </div>
      </div>
      {/* 批量上传以及下发 */}
      <Modal
        title={
          sourt.length > 0
            ? "下发提示"
            : againPlay
            ? "选择其他设备"
            : "导入ICON"
        }
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        {againPlay ? (
          // 选择其他设备
          sourt.length == 0 ? (
            <div>
              <div className="page_goodmodal">
                <div className="page_again_title">
                  若所选的设备中没有这些商品ICON，则下发失败
                </div>
                {/* -------------------------------------------------------------------------------------------------------------------------- */}
                <div>
                  <div className="page_equipment">
                    <div className="tree_left_body">
                      <div className="page_tree_search">
                        <div>
                          <Checkbox
                            disabled={allLeftDisa}
                            checked={
                              allTreeLeftKey.length == treeLeftValue.length &&
                              allTreeLeftKey.length != 0 &&
                              treeLeftValue.length != 0
                                ? true
                                : false
                            }
                            onChange={() => {
                              setTreeLeftValue(allTreeLeftKey)
                              handTreeCheck(allTreeLeftKey)
                            }}
                          />
                          <span style={{ marginLeft: "10px" }}>全选</span>
                        </div>
                        <Input
                          style={{ width: "200px" }}
                          placeholder="请输入门店名称"
                          onChange={handlInput}
                        />
                      </div>

                      <div className="tree">
                        <Tree
                          showIcon
                          checkable
                          onSelect={handTreeSelect}
                          onCheck={handTreeCheck}
                          treeData={
                            searchNewTree.length == 0
                              ? newTreeDate
                              : searchNewTree
                          }
                          checkedKeys={treeLeftValue}
                          autoExpandParent={true}
                          defaultExpandParent={true}
                          defaultExpandAll={true}
                        />
                      </div>
                    </div>
                    <div className="treeSubOrClearBtn">
                      <Button
                        type="primary"
                        onClick={() => {
                          setRightTrueTree(RightFalseTree)
                        }}
                      >
                        确认添加
                      </Button>
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                          //当右侧输入框有值时，移除时将搜索到的值也移除掉
                          if (newRigShowData.length > 0) {
                            const diffentSear = newRigShowData.filter(
                              (v) => !RightCheckValue.some((v1) => v.key == v1)
                            )
                            setNewRigShowData(diffentSear)
                          }
                          const diffentInfo = treeLeftValue.filter(
                            (v) => !RightCheckValue.some((v1) => v == v1)
                          )
                          const newArr = newTreeDate.filter((item) =>
                            diffentInfo.some((v) => item.key == v)
                          )
                          let newFatKey = []
                          newArr.map((v) => {
                            newFatKey.push(v.key)
                          })
                          const newDiffentInfo = diffentInfo.filter(
                            (v) => !newFatKey.some((v1) => v == v1)
                          )
                          setTreeLeftValue(newDiffentInfo)
                          let newFalseArr = []
                          newTreeDate.map((v) => {
                            console.log(v.name, v.children)
                            var cs = v.children.filter((item) => {
                              return newDiffentInfo.indexOf(item.deviceSn) > -1
                            })
                            if (cs.length > 0) {
                              console.log("parent id:", v.id)
                              console.log("childrens ", cs)
                              var _v = JSON.parse(JSON.stringify(v))
                              _v.children = cs
                              newFalseArr.push(_v)
                            }
                          })
                          setRightTrueTree(newFalseArr) //右边树临时存储
                          setRightFalseTree(newFalseArr) //右边树实际存储并展示
                          setRightCheckValue([])
                          // const diffentInfo = treeLeftValue.filter(
                          //   (v) => !RightCheckValue.some((v1) => v == v1)
                          // )
                          // setTreeLeftValue(diffentInfo)
                          // const resultInfo = []
                          // newTreeDate.map((v) => {
                          //   diffentInfo.map((w) => {
                          //     if (v.key === w) {
                          //       resultInfo.push(v)
                          //     }
                          //   })
                          // })

                          // resultInfo.map((v) => {
                          //   let newZ = []
                          //   v.children.map((w) => {
                          //     diffentInfo.map((z) => {
                          //       if (w.deviceSn === z) {
                          //         newZ.push(w)
                          //       }
                          //     })
                          //   })
                          //   v.children = newZ
                          // })
                          // setRightTrueTree(resultInfo) //右边树临时存储
                          // setRightFalseTree(resultInfo) //右边树实际存储并展示
                          // setRightCheckValue([])
                        }}
                      >
                        确认移除
                      </Button>
                    </div>
                    {/* <div> */}
                    {/* <div>已选{RightTrueTree.length}个</div> */}
                    <div className="tree_right_body">
                      <div className="page_tree_search">
                        <div>
                          <Checkbox
                            checked={
                              RightAllCheck.length == RightCheckValue.length &&
                              RightAllCheck.length != 0 &&
                              RightCheckValue.length != 0
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                if (RightTrueTree.length > 0) {
                                  //拿到全部key,为了做全选
                                  let allArrRightTree = []
                                  RightTrueTree.map((v) => {
                                    allArrRightTree.push(v.key)
                                    v.children.map((v1) => {
                                      allArrRightTree.push(v1.key)
                                    })
                                  })
                                  setRightAllCheck(allArrRightTree)
                                  handTreeCheck1(allArrRightTree)
                                }
                              } else {
                                setRightAllCheck([])
                                handTreeCheck1([])
                              }
                            }}
                          />
                          <span style={{ marginLeft: "10px" }}>全选</span>
                        </div>
                        <Input
                          style={{ width: "200px" }}
                          placeholder="请输入门店名称"
                          onChange={handlInputRig}
                        />
                      </div>
                      <div className="tree">
                        <Tree
                          showIcon
                          checkable
                          // onSelect={handTreeSelect}
                          onCheck={handTreeCheck1}
                          treeData={
                            newRigShowData.length == 0
                              ? RightTrueTree
                              : newRigShowData
                          }
                          checkedKeys={RightCheckValue}
                          autoExpandParent={true}
                          defaultExpandParent={true}
                          defaultExpandAll={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* -------------------------------------------------------------------------------------------------------------------------- */}
                <div className="goodmodal_again_foot">
                  <div className="goodmodal_again_left">
                    <div style={{ color: "#19BC89" }}>
                      已选择：{RightTrueTree.length}台设备
                    </div>
                  </div>
                  <div className="goodmodal_again_right">
                    <Button
                      type="default"
                      onClick={() => {
                        setAgain(false)
                        setAllLoading(false)
                      }}
                    >
                      上一步
                    </Button>
                    <Button
                      type="primary"
                      disabled={RightTrueTree.length > 0 ? false : true}
                      onClick={handleOk}
                    >
                      确认导入
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="sourt">
                <div className="sourt_body">
                  {sourt.map((v) => {
                    return (
                      <div
                        style={{
                          color: `${v.informResult ? "#19BC89" : "red"}`,
                          padding: "5px 0",
                        }}
                      >
                        {v?.shopName} {v?.cdKeyCode}下发
                        {v.informResult ? "成功" : "失败"}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="goodmodal_again_foot">
                <div className="sourt_btn">
                  <div className="beatch"></div>
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsModalOpen(false)
                      setAgain(false)
                      setSourt([])
                    }}
                  >
                    我知道了
                  </Button>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="page_goodmodal">
            {/*<div className="page_goodmodal_hand">
             <div className="goodmodal_hand_left">
                <CloudUploadOutlined />
              </div>
              <div className="goodmodal_hand_right">
                <div> 1、图片比例建议为1:1</div>
                <div> 2、尺寸建议不小于300*300,不大于800*800px</div>
                <div> 3、格式需为PNG/JPG</div>
                <div> 4、icon图片需以商品编码命名，否则将导入失败</div>
              </div> 
            </div>*/}
            {/* <Upload {...newprops}>
            <Button type="primary" block className="page_goodmodal_btn">
              点击上传
            </Button>
          </Upload> */}
            <div className="goodmanage_modal_title1">
              <div style={{ marginLeft: "20px" }}>
                规则说明：商品图片必须以商品编码命名，且仅支持JPG、PNG格式，建议尺寸比例16:9
              </div>
            </div>
            <div className="sbcpsjdlj">
              <span className="sbcpsjdlj_span">商品图片</span>
              <Upload {...fileListProps} fileList={fileList}>
                <a style={{ color: "#2EB998" }}>点击上传</a>
              </Upload>
            </div>
            {fileList.length == 0 ? (
              <div className="svcpftp">暂无图片</div>
            ) : null}

            {/* <div className="page_goodmodal_imgnum">
            
          </div> */}

            <div className="goodmodal_foot">
              {successUploadList.length > 0 || errUploadList.length > 0 ? (
                <div className="sbcp">
                  <div style={{ color: "#999999" }}>
                    {successUploadList.length}张 上传成功
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ color: "#999999" }}>
                      {errUploadList.length}张 上传失败
                    </div>

                    <a
                      disabled={errUploadList.length == 0 ? true : false}
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        // console.log(errUploadList, "上传失败")
                        // errUploadList.map((v) => {
                        //   download(v?.originFileObj?.name, v.key)
                        // })
                        if (errUploadList.length == 0) {
                          return false
                        }
                        download()
                      }}
                    >
                      下载到本地
                    </a>
                  </div>
                </div>
              ) : null}
              <div className="sbcp">
                {successUploadList.length > 0 || errUploadList.length > 0 ? (
                  <a
                    onClick={handleOkAgain}
                    disabled={successUploadList.length == 0 ? true : false}
                  >
                    导入其他设备
                  </a>
                ) : (
                  <div></div>
                )}
                <div>
                  <Button
                    style={{ marginRight: "20px" }}
                    onClick={handleCancel}
                  >
                    取消
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleOk}
                    disabled={successUploadList.length == 0 ? true : false}
                    // loading={allLoading}
                  >
                    导入
                  </Button>
                </div>
              </div>

              {/* <div className="goodmodal_foot_left">
                  <div style={{ color: "#19BC89" }}>
                    上传成功：{successUploadList.length}张ICON
                  </div>
                  <div>
                    上传失败：{errUploadList.length}张ICON{" "}
                    <a
                      disabled={errUploadList.length == 0 ? true : false}
                      style={{ color: "red", marginLeft: "5px" }}
                      onClick={() => {
                        // console.log(errUploadList, "上传失败")
                        // errUploadList.map((v) => {
                        //   download(v?.originFileObj?.name, v.key)
                        // })
                        download()
                      }}
                    >
                      下载到本地
                    </a>
                  </div>
                </div> */}
              {/* <div className="goodmodal_foot_right">
                  <Button
                    type="default"
                    onClick={handleOk}
                    disabled={successUploadList.length == 0 ? true : false}
                    // loading={allLoading}
                  >
                    确认导入
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleOkAgain}
                    disabled={successUploadList.length == 0 ? true : false}
                  >
                    确认导入并下发到其他设备
                  </Button>
                </div> */}
            </div>
          </div>
        )}
      </Modal>
      {/* 单状态更改 */}
      <Modal
        title={`确定要${
          tableSetStatus == 2
            ? "上架"
            : tableSetStatus == 3
            ? "下架"
            : tableSetStatus == 1
            ? "锁定"
            : tableSetStatus == 99
            ? "删除"
            : ""
        }该商品吗？`}
        open={isStatusOpen}
        onOk={handleSetStatusOk}
        onCancel={handleSetStatusCancel}
      >
        {tableSetStatus == 3 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            下架指定商品后，该商品不会被智能秤自动识别
            但点选商品后再次放上智能秤的该商品会被智能秤自动识别
          </div>
        ) : tableSetStatus == 2 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            上架指定商品时，该商品可被智能秤自动识别
          </div>
        ) : tableSetStatus == 1 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            锁定指定商品后，该商品不能被智能秤自动识别
          </div>
        ) : tableSetStatus == 99 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            删除后设备上的商品数据也将被清除
          </div>
        ) : null}
      </Modal>
      {/* 批量更改状态 */}
      <Modal
        title={
          batcStatehTyps != 999
            ? `确定要${
                batcStatehTyps == 2
                  ? "上架"
                  : batcStatehTyps == 3
                  ? "下架"
                  : batcStatehTyps == 1
                  ? "锁定"
                  : batcStatehTyps == 99
                  ? "删除"
                  : ""
              }所选商品吗？`
            : "批量删除验证"
        }
        open={isBatcStateh}
        onOk={handleBatcOk}
        onCancel={handleBatcCancel}
      >
        {batcStatehTyps == 3 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            下架指定商品后，该商品不会被智能秤自动识别
            但点选商品后再次放上智能秤的该商品会被智能秤自动识别
          </div>
        ) : batcStatehTyps == 2 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            上架指定商品时，该商品可被智能秤自动识别
          </div>
        ) : batcStatehTyps == 1 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            锁定指定商品后，该商品不能被智能秤自动识别
          </div>
        ) : batcStatehTyps == 99 ? (
          <div className="modal_text">
            <InfoCircleOutlined className="modal_text_icon" />
            删除后设备上的商品数据也将被清除
          </div>
        ) : batcStatehTyps == 999 ? (
          <div className="delete_verifi">
            <div className="delete_verifi_body">
              <div>
                <Input
                  placeholder="请输入手机号"
                  maxLength={11}
                  onChange={(e) => {
                    SetPhone(e.target.value)
                  }}
                />
              </div>
              <div className="img_code">
                <Input
                  placeholder="请输入图形码"
                  maxLength={4}
                  onChange={(e) => {
                    SetCaptText(e.target.value)
                  }}
                />
                <div className="img_code_img" onClick={getCaptcha}>
                  <img src={getCapthcha?.image} />
                </div>
              </div>
              <div className="img_code">
                <Input
                  placeholder="请输入短信验证码"
                  maxLength={6}
                  onChange={(e) => {
                    SetVerificat(e.target.value)
                  }}
                />
                <Button
                  type="primary"
                  onClick={handGetCode}
                  disabled={isSecondTime}
                >
                  {secondTime}
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
      {/* 表格图片编辑 */}
      <Modal
        title={nowInfo?.skuName + " icon" + (nowInfo?.icon ? "编辑" : "新建")}
        open={imgEditState}
        onOk={handeleIconOK}
        okText="导入"
        onCancel={handleIconClose}
        width={700}
      >
        <div className="goodmanage_modal_img">
          <div className="goodmanage_modal_title">
            <div style={{ marginLeft: "20px" }}>
              规则说明：商品图片必须以商品编码命名，且仅支持JPG、PNG格式，建议尺寸比例16:9
            </div>
          </div>
          <div className="goodmanage_modal_count">
            <div>商品图片</div>
            <Upload
              action="https://up-z0.qiniup.com"
              name="file"
              showUploadList={false}
              multiple={false}
              {...uploadProps}
            >
              <a style={{ color: "#2EB998" }}>上传图片</a>
            </Upload>
          </div>
          <div className="goodmanage_modal_image">
            {nowInfo?.icon || imgUrl ? (
              imgUrl ? (
                <img src={imgUrl} />
              ) : (
                <img src={nowInfo?.icon} />
              )
            ) : (
              "暂无图片"
            )}
          </div>
          {/* <div className="icon_modal_left">
            {imgUrl ? <img src={imgUrl} /> : <img src={nowInfo?.icon} />}

            <div className="icon_modal_title">
              <Upload
                action="https://up-z0.qiniup.com"
                name="file"
                showUploadList={false}
                multiple={false}
                {...uploadProps}
              >
                {nowInfo?.icon ? (
                  <span style={{ color: "white" }}>更换图片</span>
                ) : (
                  <span style={{ color: "white" }}>上传图片</span>
                )}
              </Upload>
            </div>
          </div>
          <div className="icon_modal_right">
            <span> 1、图片比例建议为1:1 </span>
            <span> 2、尺寸建议不小于300*300,不大于800*800px </span>
            <span> 3、格式需为PNG/JPG </span>
          </div> */}
        </div>
      </Modal>
    </div>
  )
}

export default connect((state) => state)(Index)
