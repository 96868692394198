import {
  Table,
  Button,
  message,
  Cascader,
  Select,
  DatePicker,
  Input,
  Row,
  Col,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import { CalendarOutlined, RightCircleOutlined } from "@ant-design/icons"
import "./Index.css"

const { Option } = Select
const dateFormat = "YYYY-MM-DD"
const tabs = [
  { name: "日报", value: 1 },
  { name: "周报", value: 2 },
  { name: "月报", value: 3 },
]
const week = [
  {
    label: 2023,
    value: 2023,
    children: [
      {
        weekStartDate: "2023.03.13",
        weekEndDate: "2023.03.19",
        weekOfYear: 12,
        value: "20230313-20230319",
        label: `2023.03.13-2023.03.19 （第${12}周）`,
        year: 2023,
      },
      {
        weekStartDate: "2023.03.20",
        weekEndDate: "2023.03.26",
        weekOfYear: 13,
        value: 13,
        label: 13,
        year: 2023,
      },
      {
        weekStartDate: "2023.03.27",
        weekEndDate: "2023.04.02",
        weekOfYear: 14,
        value: 14,
        label: 14,
        year: 2023,
      },
      {
        weekStartDate: "2023.04.03",
        weekEndDate: "2023.04.09",
        weekOfYear: 15,
        value: 15,
        label: 15,
        year: 2023,
      },
      {
        weekStartDate: "2023.04.10",
        weekEndDate: "2023.04.16",
        weekOfYear: 16,
        value: "20230410-20230416",
        label: `2023.04.10-2023.04.16 （第${16}周）`,
        year: 2023,
      },
    ],
  },
]
const month = [
  {
    yearMonth: "2022-12",
  },
  {
    yearMonth: "2023-01",
  },
  {
    yearMonth: "2023-02",
  },
  {
    yearMonth: "2023-03",
  },
  {
    yearMonth: "2023-04",
  },
]

function Index() {
  const [tabKey, setTabKey] = useState(1) //tab值，1：日报，2：周报，3：月报
  const [weekList, setWeekList] = useState([]) //周报要展示的列表
  const [monthList, setMonthList] = useState([]) //月要展示的列表
  const [dateValue, setDateValue] = useState("") //日报选择的日期值
  const [weekValue, setWeekValue] = useState([]) //周报选择的日期值
  const [monthValue, setMonthValue] = useState("") //月报选择的日期值
  const [shopValue, setShopValue] = useState(undefined) //商品select-value
  const [isSearch, setIsSearch] = useState(false) //是否点击查询
  const [loading, setLoading] = useState(false) //loading
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数

  // REF,动态值，影响表格接口刷新
  const refInfo = {
    pageNumRef: "", //页码
    shopInputValueRef: undefined, //门店inputvalue
    monthValueRef: "", //月报
    weekValueRef: "", //周报
    dateValueRef: "", //日报
    tabKeyRef: "", //日期tab切换
  }
  useEffect(() => {
    refInfo.current = {
      pageNumRef: pageNum,
      shopInputValueRef: shopValue,
      monthValueRef: monthValue,
      weekValue: weekValue,
      dateValueRef: dateValue,
      tabKeyRef: tabKey,
    }
  }, [pageNum, shopValue, monthValue, weekValue, dateValue, tabKey])
  //

  useEffect(() => {
    onGetWeek() //(调用获取周列表接口方法)
    onGetMonth() //(调用获取月列表接口方法)
    setIsSearch(false)
  }, [])

  /**
   * 当tab值切换时就会useEffect
   */
  useEffect(() => {
    // 日报
    if (tabKey == 1) {
      setDateValue(dayjs().format(dateFormat))
    }
    // 周报
    if (tabKey == 2) {
      setWeekValue([
        weekList[0].value,
        weekList[0].children[weekList[0].children.length - 1].value,
      ])
    }
    // 月报
    if (tabKey == 3) {
      setMonthValue(monthList[monthList.length - 1].yearMonth)
    }
  }, [tabKey])

  /**
   * 获取周列表
   */
  const onGetWeek = () => {
    setWeekList(week)
  }

  /**
   * 获取月列表
   */
  const onGetMonth = () => {
    setMonthList(month)
  }

  /**
   * 门店列表接口
   */
  const onGetShopList = async () => {
    try {
      const params = {
        pageNum: pageNum,
        pageSize: pageSize,
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const onHandSearch = () => {
    setIsSearch(true)
    onGetShopList()
  }

  /**
   * 重置
   */
  const onHandClearn = () => {
    //重置归为日报
    setTabKey(1)
    //日报时间归当天
    setDateValue(dayjs().format(dateFormat))
    //门店归为undefined
    setShopValue(undefined)
    //是否查询状态为false
    setIsSearch(false)
  }

  /**
   * 导出
   */
  const onHandExport = () => {}

  /**
   * 返回上层
   */
  const onHandGoBack = () => {
    window.history.back(-1)
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${1}条`, //接口返回
      pageSize: pageSize,
      current: pageNum,
      total: 1, //接口返回
      onChange: (current) => {
        setPageNum(current)
      },
    }
    return paginationProps
  }

  const dataSource = [
    {
      key: "1",
      name: "胡彦斌",
      age: 32,
      address: "西湖区湖底公园1号",
    },
    {
      key: "2",
      name: "胡彦祖",
      age: 42,
      address: "西湖区湖底公园1号",
    },
  ]

  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "年龄",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "住址",
      dataIndex: "address",
      key: "address",
    },
  ]

  return (
    <div className="shopDetail_page">
      <Row>
        <Col span={14} style={{ display: "flex" }}>
          <Select
            style={{ width: 120 }}
            onChange={(e) => {
              setTabKey(e)
            }}
            placeholder="请选择"
            value={tabKey}
          >
            {tabs.map((v) => {
              return <Option value={v.value}>{v.name}</Option>
            })}
          </Select>
          {/* 日期选择 */}
          <div className="shopDetail_page_time">
            {/* 日期选择框 */}
            {tabKey == 1 ? (
              <DatePicker
                style={{ width: 200 }}
                onChange={(e) => {
                  setDateValue(dayjs(e).format(dateFormat))
                }}
                value={dayjs(dateValue)}
                allowClear={false}
                // suffixIcon={<div></div>}
              />
            ) : null}

            {/* 周报选择框 */}
            {tabKey == 2 ? (
              <Cascader
                style={{ width: "auto" }}
                options={week}
                value={weekValue}
                onChange={(e) => {
                  setWeekValue(e)
                }}
                placeholder="请选择周"
              />
            ) : null}

            {/* 月报选择框 */}
            {tabKey == 3 ? (
              <Select
                style={{ width: 200 }}
                value={monthValue}
                onChange={(e) => {
                  setMonthValue(e)
                }}
                placeholder="请选择月"
              >
                {month.map((v) => {
                  return (
                    <Option key={v.yearMonth} value={v.yearMonth}>
                      {v.yearMonth}
                    </Option>
                  )
                })}
              </Select>
            ) : null}
            {/* 
            <CalendarOutlined
              style={{
                // color: "#2EB998",
                fontSize: "20px",
                padding: "0 0 0 10px",
              }}
            /> */}
          </div>
          <div className="shopDetail_page_shop">
            门店：
            <Select
              style={{ width: 200 }}
              value={shopValue}
              onChange={(e) => {
                setShopValue(e ? e : undefined)
              }}
              placeholder="请选择门店"
            >
              <Option key={1} value={1}>
                饭饭
              </Option>
            </Select>
          </div>
          {/* <div className="shopDetail_page_shop">
            商品：
            <Input
              placeholder="请输入商品名称/PLU"
              className="shopDetail_page_shop_input"
              value={shopValue}
              onChange={(e) => {
                setShopValue(e.target.value ? e.target.value : undefined)
              }}
            />
          </div> */}
        </Col>
        <Col span={10}>
          <div className="shopDetail_head_brn">
            <Button type="primary" onClick={onHandGoBack}>
              返回上层
            </Button>
            <Button type="primary" onClick={onHandExport}>
              导出
            </Button>
            <Button type="primary" onClick={onHandSearch}>
              查询
            </Button>
            <Button onClick={onHandClearn}>重置</Button>
          </div>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: "20px" }}
        loading={loading}
        pagination={onTableChange()}
      />
    </div>
  )
}

export default Index
