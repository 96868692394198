import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Select,
  Image,
  Upload,
} from "antd"
import * as ShopmanageDevice from "../../services/storemanage"
import { useHistory } from "react-router-dom"
import { QuestionCircleOutlined } from "@ant-design/icons"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"
const { Option } = Select

// 门店管理

function Index(props) {
  let history = useHistory()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [shopList, setShopList] = useState([]) //门店列表数据
  const [allShopList, setAllShopList] = useState([]) //门店列表数据
  const [goodShopList, setGoodShopList] = useState([]) //门店维度商品列表
  const [loading, setLoading] = useState(false) //门店列表loading
  const [DelShow, setDelShow] = useState(false) // 批量删除打开Modal
  const [openStatus, setOpenStatus] = useState(false) // 导入门店商品数据打开Modal
  const [deleteSkuCode, setDeleteSkuCode] = useState(undefined) // 门店维度商品删除skuCode
  const [total, setTotal] = useState(0) //
  const [uploadLoading, setUploadLoading] = useState(false) //

  // --------------
  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    keyword: undefined, // 关键词
    shopId: undefined, //门店id
    total: 0,
    //
    uploadType: 2,
    shopIdList: [], //导入的门店id
    fileName: "", // 文件名
    file: {}, // 文件
    lmFileName1: "", // 文件名
    lmFile1: {}, // 文件
    lmFileName2: "", // 文件名
    lmFile2: {}, // 文件
  })
  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })
  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // -----------------

  // 初始化
  useEffect(() => {
    setParamsInfo({ ...ParamsInfo, pageNum: 1, pageSize: 20 })
    // 门店列表
    getShopList()
    // 门店维度商品列表
    // setTimeout(getShopGoodsInfo, 0)
  }, [])

  // 动态获取高度
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 门店维度商品列表
   */
  const getShopGoodsInfo = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        pageNum: ParamsInfoRef.pageNum,
        pageSize: ParamsInfoRef.pageSize,
        code: ParamsInfoRef.keyword,
        shopId: ParamsInfoRef.shopId,
      }
      setLoading(true)
      const { code, msg, data } = await ShopmanageDevice.goods_shop_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.list) {
        setLoading(false)
        setGoodShopList(data.list)
        setTotal(data.total)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      setGoodShopList([])
      return
    }
  }

  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await ShopmanageDevice.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        // 数组深拷贝，增加首位为全部的值
        let NewSetList = [].concat(data?.list || [])
        NewSetList.unshift({
          shopName: "全部",
          shopId: "1",
        })
        setShopList(NewSetList)
        //接口原数据
        setAllShopList(data?.list)
        setParamsInfo({
          ...ParamsInfo,
          shopId: data?.list[0].shopId,
        })
        // 门店维度商品列表
        setTimeout(getShopGoodsInfo, 100)
      } else {
        setShopList([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 删除
   */
  const onManyDelete = async () => {
    try {
      const params = {
        shopId: ParamsInfo?.shopId,
        skuCode: deleteSkuCode,
      }
      const { code, msg, data } = await ShopmanageDevice.goods_shop_delete(
        params
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        setDelShow(false)
        setTimeout(getShopGoodsInfo, 0)
      }
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 查询
   */
  const handlSearch = () => {
    console.log({ ...ParamsInfo, pageNum: 1 })
    //页码为1
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    //门店列表接口，
    setTimeout(getShopGoodsInfo, 0)
  }

  /**
   * 重置
   */
  const handClearn = () => {
    setParamsInfo({
      pageNum: 1,
      pageSize: 20,
      keyword: undefined, // 关键词
      shopId: allShopList[0].shopId, //门店id
      //
      uploadType: 2,
      shopIdList: [], //导入的门店id
      fileName: "", // 文件名
      file: {}, // 文件
      lmFileName1: "", // 文件名
      lmFile1: {}, // 文件
      lmFileName2: "", // 文件名
      lmFile2: {}, // 文件
    })
    //门店列表接口
    setTimeout(getShopGoodsInfo, 0)
  }

  /**
   * 确定导入
   */
  const onSubmit = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      if (!ParamsInfoRef.lmFileName1 && ParamsInfoRef.uploadType == 2) {
        message.error("请上传门店数据文件")
        return
      }
      if (
        (!ParamsInfoRef.lmFileName2 && ParamsInfoRef.uploadType == 2) ||
        (!ParamsInfoRef.lmFileName2 && ParamsInfoRef.uploadType == 3)
      ) {
        message.error("请上传商品数据文件")
        return
      }
      const params = {
        templateType: ParamsInfoRef.uploadType, //3默认模版2,2乐檬模版1
      }
      if (params.templateType == 3) {
        // params.type = Number(ParamsInfoRef.shopIdList[0]) == 1 ? 1 : 2 // 1全部，2不全部
        // if (params.type == 2) {
        //   params.shopIds = ParamsInfoRef.shopIdList
        // }
        // params.file = ParamsInfoRef.file
        params.lmFile2 = ParamsInfoRef.lmFile2
      } else {
        params.lmFile1 = ParamsInfoRef.lmFile1
        params.lmFile2 = ParamsInfoRef.lmFile2
      }

      console.log(params)
      setUploadLoading(true) // 打开Loading
      const { code, data, msg } = await ShopmanageDevice.goods_import_excel(
        params
      )
      if (code != 200) {
        message.error(msg)
        setUploadLoading(false) // 关闭Loading
        return
      }
      if (data) {
        setOpenStatus(false)
        setUploadLoading(false) // 关闭Loading
        message.success("导入成功")
        // 门店维度商品列表
        setTimeout(getShopGoodsInfo, 0)
      }
    } catch (error) {
      console.log(error)
      setUploadLoading(false) // 关闭Loading
    }
  }

  //列表展示字段
  const columns = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "shopName",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "商品plu",
      dataIndex: "skuCode",
      key: "skuCode",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "商品条码",
      dataIndex: "barCode",
      ellipsis: { showTitle: true },
      key: "barCode",
      width: 200,
    },
    {
      title: "商品名称",
      dataIndex: "skuName",
      key: "skuName",
      width: 200,
      ellipsis: { showTitle: true },
    },
    {
      title: "商品单价（元）",
      dataIndex: "price",
      ellipsis: { showTitle: true },
      key: "price",
      width: 200,
    },
    // {
    //   title: "商品售价（分）",
    //   dataIndex: "",
    //   ellipsis: { showTitle: true },
    //   key: "",
    //   width: 200,
    // },
    {
      title: "计量方式",
      dataIndex: "priceUnit",
      ellipsis: { showTitle: true },
      key: "priceUnit",
      width: 200,
      render: (info) => {
        return info == 1 ? "个数" : info == 0 ? "重量" : ""
      },
    },
    // {
    //   title: "净含量（g）",
    //   dataIndex: "netWeight",
    //   ellipsis: { showTitle: true },
    //   key: "netWeight",
    //   width: 200,
    // },
    {
      title: "速记码",
      dataIndex: "shortCode",
      ellipsis: { showTitle: true },
      key: "shortCode",
      width: 200,
    },
    // {
    //   title: "商品ICON",
    //   dataIndex: "iconUrl",
    //   ellipsis: { showTitle: true },
    //   key: "iconUrl",
    //   width: 200,
    //   render: (info) => {
    //     return <Image width={100} src={info} style={{ objectFit: "cover" }} />
    //   },
    // },
    // {
    //   title: "公司编号",
    //   dataIndex: "",
    //   ellipsis: { showTitle: true },
    //   key: "",
    //   width: 200,
    // },
    // {
    //   title: "门店编号（乐檬门店编号）",
    //   dataIndex: "",
    //   ellipsis: { showTitle: true },
    //   key: "",
    //   width: 200,
    // },
    // {
    //   title: "操作",
    //   ellipsis: { showTitle: true },
    //   fixed: "right",
    //   width: 100,
    //   render: (info) => {
    //     return (
    //       <>
    //         <Button
    //           type="link"
    //           onClick={() => {
    //             setDeleteSkuCode("")
    //             setDelShow(true)
    //             setDeleteSkuCode(info.skuCode)
    //           }}
    //         >
    //           删除
    //         </Button>
    //       </>
    //     )
    //   },
    // },
  ]

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
    }
    return paginationProps
  }

  /**
   * 分页操作
   */
  const handleChange = (pagination, filters, sorter) => {
    setParamsInfo({
      ...ParamsInfo,
      pageNum: pagination.current,
    })

    setTimeout(getShopGoodsInfo, 0)
  }
  return (
    <div>
      <Card>
        <div className={styles.shop_goods_info_head}>
          <div className={styles.shop_goods_info_head_search}>
            <div className={styles.shop_goods_info_head_search_child}>
              关键词：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({ ...ParamsInfo, keyword: val })
                }}
                placeholder="商品plu/商品条码/商品名称"
                value={ParamsInfo.keyword}
                width="250px"
              />
            </div>
            <div className={styles.shop_goods_info_head_search_child}>
              门店：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择门店"
                style={{ width: 200 }}
                // allowClear
                value={ParamsInfo.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e,
                  })
                }}
              >
                {allShopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className={styles.shop_goods_info_head_btn}>
            <Button type="primary" onClick={() => handlSearch()}>
              查询
            </Button>
            <Button onClick={handClearn}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <div className={styles.shop_goods_info_head_btn_upload}>
          <Button
            type="primary"
            onClick={() => {
              setOpenStatus(true)
              setParamsInfo({
                ...ParamsInfo,
                uploadType: 2,
                shopIdList: [], //导入的门店id
                fileName: "", // 文件名
                file: {}, // 文件
                lmFileName1: "", // 文件名
                lmFile1: {}, // 文件
                lmFileName2: "", // 文件名
                lmFile2: {}, // 文件
              })
            }}
          >
            导入门店商品数据
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={goodShopList}
            rowKey="id"
            loading={loading}
            scroll={{ x: "max-content", y: `${WinHeight - 430}px` }}
            onChange={handleChange}
          />
        </div>
      </Card>

      {/* 删除二次提示框 */}
      <Modal
        open={DelShow}
        destroyOnClose
        keyboard
        title="系统提示"
        onCancel={() => {
          setDelShow(false)
        }}
        onOk={() => onManyDelete()}
      >
        <div className={styles.shopmanage_shop_accent_delete}>
          <QuestionCircleOutlined
            className={styles.shopmanage_shop_accent_delete_icon}
          />
          确定删除该商品数据吗？
        </div>
      </Modal>

      {/* 导入门店商品数据 */}
      <Modal
        width={"40%"}
        open={openStatus}
        destroyOnClose
        keyboard
        title="导入门店商品数据"
        onCancel={() => setOpenStatus(false)}
        okText="确认导入"
        footer={[
          <Button
            key="submit"
            loading={uploadLoading}
            disabled={uploadLoading}
            type="primary"
            onClick={() => {
              onSubmit()
            }}
          >
            确认
          </Button>,
          <Button
            key="back"
            onClick={() => {
              setOpenStatus(false)
              setUploadLoading(false) // 关闭Loading
            }}
          >
            取消
          </Button>,
        ]}
      >
        <Card>
          <div>
            目前只支持xls,xlsx格式，带*号的为必填项，为了保证导入的效率，程序建议一次准备500行数据
          </div>
          <div>确保条码的统一，不唯一会导致数据错乱</div>
          {/* <div className={styles.}shop_goods_info_modal_a">
            <a href="https://static.yoyo.link/template/goods-template.xls">
              下载（门店商品数据）示例文件
            </a>
          </div> */}
        </Card>
        <Card className={styles.shop_goods_info_modal_card}>
          {/* 选择类型 */}
          <div>
            选择类型：
            <Select
              placeholder="请选择选择类型"
              style={{ width: 300 }}
              value={ParamsInfo.uploadType}
              showSearch
              onChange={(e) => {
                setParamsInfo({
                  ...ParamsInfo,
                  uploadType: e,
                  shopIdList: [], //导入的门店id
                  fileName: "", // 文件名
                  file: {}, // 文件
                  lmFileName1: "", // 文件名
                  lmFile1: {}, // 文件
                  lmFileName2: "", // 文件名
                  lmFile2: {}, // 文件
                })
              }}
            >
              <Option value={2}>乐檬模版1</Option>
              <Option value={3}>乐檬模版2</Option>
              <Option value={4}>乐檬模版3</Option>
            </Select>
          </div>
          {/* 上传文件 */}

          {/* 类型2 */}
          {ParamsInfo.uploadType == 2 ? (
            <div className={styles.shop_goods_info_modal_card_div}>
              <span style={{ color: "red", marginRight: "5px" }}>*</span>
              <div className={styles.shop_goods_info_modal_card_div_title}>
                选择文件：
              </div>
              {/* 点击上传按钮选择文件 */}
              <Upload
                name="file"
                accept=".xlsx,.xls"
                maxCount={1}
                beforeUpload={(file, fileList) => {
                  console.log("文件上传", file)
                  console.log("文件上传", fileList)
                  // .originFileObj
                  setParamsInfo({
                    ...ParamsInfo,
                    lmFileName1: file.name,
                    lmFile1: file,
                  })
                  return false
                }}
              >
                <Button className={styles.card_div_btn}>
                  {ParamsInfo?.lmFileName1
                    ? ParamsInfo?.lmFileName1
                    : "门店数据文件上传"}
                </Button>
              </Upload>
              <Button
                type="link"
                danger
                onClick={() => {
                  setParamsInfo({
                    ...ParamsInfo,
                    lmFileName1: "",
                    lmFile1: {},
                  })
                }}
              >
                删除
              </Button>
            </div>
          ) : null}
          {ParamsInfo.uploadType == 2 ? (
            <div className={styles.shop_goods_info_modal_card_div}>
              <span style={{ color: "red", marginRight: "5px" }}>*</span>
              <div className={styles.shop_goods_info_modal_card_div_title}>
                选择文件：
              </div>
              <Upload
                name="file"
                accept=".xlsx,.xls"
                maxCount={1}
                beforeUpload={(file, fileList) => {
                  console.log("文件上传", file)
                  console.log("文件上传", fileList)
                  // .originFileObj
                  setParamsInfo({
                    ...ParamsInfo,
                    lmFileName2: file.name,
                    lmFile2: file,
                  })
                  return false
                }}
              >
                <Button className={styles.card_div_btn}>
                  {ParamsInfo?.lmFileName2
                    ? ParamsInfo?.lmFileName2
                    : "商品数据文件上传"}
                </Button>
              </Upload>
              <Button
                type="link"
                danger
                onClick={() => {
                  setParamsInfo({
                    ...ParamsInfo,
                    lmFileName2: "",
                    lmFile2: {},
                  })
                }}
              >
                删除
              </Button>
            </div>
          ) : null}

          {/* 类型1 */}
          {ParamsInfo.uploadType == 3 || ParamsInfo.uploadType == 4 ? (
            <div className={styles.shop_goods_info_modal_card_div}>
              <span style={{ color: "red", marginRight: "5px" }}>*</span>
              <div className={styles.shop_goods_info_modal_card_div_title}>
                选择文件：
              </div>
              {/* 点击上传按钮选择文件 */}
              <Upload
                name="file"
                accept=".xlsx,.xls"
                maxCount={1}
                beforeUpload={(file, fileList) => {
                  console.log("文件上传", file)
                  console.log("文件上传", fileList)
                  // .originFileObj
                  setParamsInfo({
                    ...ParamsInfo,
                    lmFileName2: file.name,
                    lmFile2: file,
                  })
                  return false
                }}
              >
                <Button className={styles.card_div_btn}>
                  {ParamsInfo?.lmFileName2
                    ? ParamsInfo?.lmFileName2
                    : "商品数据文件上传"}
                </Button>
              </Upload>
              <Button
                type="link"
                danger
                onClick={() => {
                  setParamsInfo({
                    ...ParamsInfo,
                    fileName: "",
                    file: "",
                  })
                }}
              >
                删除
              </Button>
            </div>
          ) : null}

          {/* 门店选择 */}
          {/* {ParamsInfo.uploadType == 1 ? (
            <div className={styles.}shop_goods_info_shop">
              <span className={styles.}shop_goods_info_shop_span">*</span>
              导入门店：
              <Select
                mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择 导入门店"
                style={{ width: 300 }}
                allowClear
                value={ParamsInfo?.shopIdList}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  console.log("门店选择", e)
                  if (e.length > 0 && e.includes("1")) {
                    setParamsInfo({
                      ...ParamsInfo,
                      shopIdList: ["1"],
                    })
                  } else {
                    setParamsInfo({
                      ...ParamsInfo,
                      shopIdList: e || [],
                    })
                  }
                }}
              >
                {shopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
          ) : null} */}
        </Card>
      </Modal>
    </div>
  )
}

export default Index
