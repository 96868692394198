import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import * as Congigdata from "../../services/configdata_summary"
import styles from "./Index.module.css"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"
import {
  Select,
  Button,
  message,
  Card,
  Tag,
  Table,
  Switch,
  Modal,
  Tooltip,
  Spin,
} from "antd"
import {
  ExclamationCircleOutlined,
  QuestionCircleFilled,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons"

const { Option } = Select

function Index() {
  const history = useHistory()
  //
  const [isShowDeOne, setIsShowDeOne] = useState("")
  const [isShowDeTwo, setIsShowDeTwo] = useState("")
  const [isShowLoading, setIsShowDeTwoLoading] = useState(false)
  //
  // 是否展示拉去记录
  const [isShowPage, setIsShowPage] = useState(false)
  //
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [openModal, setOpenModal] = useState(false) // 操作打开Modal
  const [openType, setOpenType] = useState("") // 操作打开Modal的类型，1清空，2删除
  const [id, setId] = useState("") // 操作id
  const [dataList, setDataList] = useState([]) // 数据集合列表
  const [dataLoading, setDataLoading] = useState(false) // 数据集合列表Loading
  const [total, setTotal] = useState(0) // 数据总数
  const [relevanceDeviceList, setRelevanceDeviceList] = useState([])
  const [GiveParams, setGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    startDate: "",
    endDate: "",
    timeArr: [],
  })

  //保证每次拿到的都是准确的值
  const AccountRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    AccountRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  /**
   * 初始化
   */
  useEffect(() => {
    // 获取数据集合列表
    setTimeout(getDataList, 0)
    if (sessionStorage.getItem("MENUS")) {
      let NewMenu = JSON.parse(sessionStorage.getItem("MENUS"))
      console.log(NewMenu)
      NewMenu.map((v) => {
        if (v.menuName === "识别管理") {
          v.childMenus.map((w) => {
            if (w.menuName === "拉取记录") {
              setIsShowPage(true)
              return
            }
          })
        }
      })
    }
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  // 获取数据集合列表
  const getDataList = async () => {
    try {
      const { GiveParamsRef } = AccountRef.current
      const params = {
        pageNum: GiveParamsRef.pageNum,
        pageSize: GiveParamsRef.pageSize,
        startDate: GiveParamsRef.startDate,
        endDate: GiveParamsRef.endDate,
      }
      const { code, data, msg } = await Congigdata.DataSetList(params)
      if (code != 200) {
        message.error(msg)
        setDataLoading(false)
        return
      }
      if (data && data.list) {
        setDataList(data.list || [])
        setTotal(data.total)
        setDataLoading(false)
      }
    } catch (error) {
      console.log(error)
      setDataLoading(false)
    }
  }
  /**
   * 查询
   */
  const onSearch = () => {
    setGiveParams({ ...GiveParams, pageNum: 1 })
    setTimeout(getDataList, 0)
  }

  /**
   * 重置
   */
  const onClearn = () => {
    setGiveParams({
      pageNum: 1,
      pageSize: 20,
      startDate: "",
      endDate: "",
      timeArr: [],
    })
    setIsShowDeOne("")
    setIsShowDeTwo("")
    setRelevanceDeviceList([])
    setTimeout(getDataList, 0)
  }

  //监听同步按钮
  const onSwitch = async (e, value) => {
    try {
      const params = {
        id: value,
        isOn: e ? 1 : 0,
      }
      const { code, data, msg } = await Congigdata.SetOpen(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      // 获取data列表
      setTimeout(getDataList, 0)

      message.success(e ? "开启成功" : "关闭成功")
    } catch (error) {
      console.log(error)
    }
    console.log(e)
    console.log(value)
  }

  /**
   * 操作提交 (1:清空，2：删除)
   */
  const onHandOk = async () => {
    try {
      // 删除
      if (openType == 2) {
        const { code, data, msg } = await Congigdata.DeleteData(id)
        if (code != 200) {
          message.error(msg)
          return
        }
        if (data) {
          message.success("删除成功")
          // 获取data列表
          setTimeout(getDataList, 0)
          // 关闭modal
          setOpenModal(false)
        } else {
          message.error("删除失败")
        }
      }

      // 清空
      if (openType == 1) {
        const { code, data, msg } = await Congigdata.ClearnData(id)
        if (code != 200) {
          message.error(msg)
          return
        }
        if (data) {
          message.success("清空成功")
          // 获取data列表
          setTimeout(getDataList, 0)
          // 关闭modal
          setOpenModal(false)
        } else {
          message.error("清空失败")
        }
      }

      /**
       * 设置默认集合
       */
      if (openType == 3) {
        const { code, data, msg } = await Congigdata.SetDefault(id)
        if (code != 200) {
          message.error(msg)
          return
        }
        if (data) {
          message.success("默认集合设置成功")
          // 获取data列表
          setTimeout(getDataList, 0)
          // 关闭modal
          setOpenModal(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "创建时间",
      dataIndex: "createTime",
      ellipsis: { showTitle: true },
      key: "createTime",
      width: 200,
    },
    {
      title: "数据汇总名称",
      dataIndex: "name",
      ellipsis: { showTitle: true },
      key: "name",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <div>
            {rowInfo?.isDefault == 1 ? <Tag color="green">默认集合</Tag> : ""}{" "}
            {info}
          </div>
        )
      },
    },
    {
      title: "识别模型",
      dataIndex: "aiModeName",
      ellipsis: { showTitle: true },
      key: "aiModeName",
      width: 200,
    },
    {
      title: "来源设备",
      dataIndex: "sourceDeviceShopRsps",
      ellipsis: { showTitle: true },
      key: "sourceDeviceShopRsps",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <div>
            {info.length > 0 && isShowDeOne != rowInfo.id ? (
              <div
                className={styles.configdata_table_select_body}
                onClick={() => {
                  setIsShowDeOne(rowInfo.id)
                  setIsShowDeTwo("")
                  getRelevanceDevice(rowInfo.id, 1)
                }}
              >
                <div style={{ width: "30px" }}>
                  <RightOutlined
                    className={styles.configdata_table_select_icon}
                  />
                </div>
                <Tooltip
                  color="#2EB998"
                  title={() => (
                    <div>
                      <div>秤号：{info[0].scaleNo}</div>
                      <div>激活码：{info[0].cdKeyCode}</div>
                      <div>门店名称：{info[0].shopName}</div>
                    </div>
                  )}
                >
                  <div className={styles.configdata_table_select_div}>
                    {info[0].cdKeyCode}
                  </div>
                </Tooltip>
              </div>
            ) : null}

            {info.length > 0 && isShowDeOne == rowInfo.id ? (
              <div>
                {isShowLoading ? <Spin size="small" /> : null}
                {relevanceDeviceList.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className={styles.configdata_table_select_body}
                      onClick={() => {
                        setIsShowDeOne("")
                        setRelevanceDeviceList([])
                      }}
                    >
                      <div style={{ width: "30px" }}>
                        {i == 0 ? (
                          <DownOutlined
                            className={styles.configdata_table_select_icon}
                          />
                        ) : null}
                      </div>
                      <Tooltip
                        color="#2EB998"
                        title={() => (
                          <div>
                            <div>秤号：{v.scaleNo}</div>
                            <div>激活码：{v.cdKeyCode}</div>
                            <div>门店名称：{v.shopName}</div>
                          </div>
                        )}
                      >
                        <div className={styles.configdata_table_select_div}>
                          {v.cdKeyCode}
                        </div>
                      </Tooltip>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>
        )
      },
    },
    {
      title: "接收设备",
      dataIndex: "acceptDeviceShopRsps",
      ellipsis: { showTitle: true },
      key: "acceptDeviceShopRsps",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <div>
            {info.length > 0 && isShowDeTwo != rowInfo.id ? (
              <div
                className={styles.configdata_table_select_body}
                onClick={() => {
                  setIsShowDeTwo(rowInfo.id)
                  setIsShowDeOne("")
                  getRelevanceDevice(rowInfo.id, 2)
                }}
              >
                <div style={{ width: "30px" }}>
                  <RightOutlined
                    className={styles.configdata_table_select_icon}
                  />
                </div>
                <Tooltip
                  color="#2EB998"
                  title={() => (
                    <div>
                      <div>秤号：{info[0].scaleNo}</div>
                      <div>激活码：{info[0].cdKeyCode}</div>
                      <div>门店名称：{info[0].shopName}</div>
                    </div>
                  )}
                >
                  <div className={styles.configdata_table_select_div}>
                    {info[0].cdKeyCode}
                  </div>
                </Tooltip>
              </div>
            ) : null}

            {info.length > 0 && isShowDeTwo == rowInfo.id ? (
              <div>
                {isShowLoading ? <Spin size="small" /> : null}
                {relevanceDeviceList.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className={styles.configdata_table_select_body}
                      onClick={() => {
                        setIsShowDeTwo("")
                        setRelevanceDeviceList([])
                      }}
                    >
                      <div style={{ width: "30px" }}>
                        {i == 0 ? (
                          <DownOutlined
                            className={styles.configdata_table_select_icon}
                          />
                        ) : null}
                      </div>

                      <Tooltip
                        color="#2EB998"
                        title={() => (
                          <div>
                            <div>秤号：{v.scaleNo}</div>
                            <div>激活码：{v.cdKeyCode}</div>
                            <div>门店名称：{v.shopName}</div>
                          </div>
                        )}
                      >
                        <div className={styles.configdata_table_select_div}>
                          {v.cdKeyCode}
                        </div>
                      </Tooltip>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>
        )
      },
    },
    {
      title: "自动同步开关",
      dataIndex: "isOn",
      ellipsis: { showTitle: true },
      key: "isOn",
      fixed: "right",
      width: 150,
      render: (info, rowInfo) => {
        return (
          <div>
            <Switch
              checked={info == 1 ? true : false}
              onChange={(e) => {
                onSwitch(e, rowInfo.id)
              }}
            />
          </div>
        )
      },
    },
    {
      title: "操作",
      fixed: "right",
      width: 280,
      render: (info, rowInfo) => {
        return (
          <div className={styles.configdata_caozuo}>
            <a
              onClick={() => {
                history.push("/admin/discernmanage/mergerecord")
                localStorage.setItem("discernmanage_id", rowInfo.id)
              }}
            >
              合并记录
            </a>
            <a
              onClick={() => {
                history.push(
                  `/admin/discernmanage/editdatasummary?id=${rowInfo.id}`
                )
              }}
            >
              编辑
            </a>
            <a
              onClick={() => {
                setOpenModal(true)
                setOpenType(1)
                setId(rowInfo.id)
              }}
            >
              清空
            </a>
            <a
              onClick={() => {
                setOpenModal(true)
                setOpenType(2)
                setId(rowInfo.id)
              }}
            >
              删除
            </a>
            <a
              style={{ color: rowInfo?.isDefault == 1 ? "grey" : "" }}
              onClick={() => {
                if (rowInfo?.isDefault == 1) {
                  message.error("该集合已经是默认集合，请选择其他集合")
                  return
                }
                setOpenModal(true)
                setOpenType(3)
                setId(rowInfo.id)
              }}
            >
              设置默认集合
            </a>
            {isShowPage ? (
              <a
                onClick={() => {
                  history.push("/admin/discernmanage/pullrecord")
                  localStorage.setItem("discernmanage_id", rowInfo.id)
                }}
              >
                拉取记录
              </a>
            ) : null}
          </div>
        )
      },
    },
  ]

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getDataList, 0)
      },
    }
    return paginationProps
  }
  /**
   * 数据集合关联设备
   */
  const getRelevanceDevice = async (datasetId, type) => {
    setIsShowDeTwoLoading(true)
    try {
      const params = {
        datasetId: datasetId,
        pageNum: 1,
        pageSize: 999999,
        type: type,
      }
      const { code, data, msg } = await Congigdata.RelevanceDevice(params)
      if (code != 200) {
        message.error(msg)
        setIsShowDeTwoLoading(false)
        return
      }
      setRelevanceDeviceList(data.list)
      setIsShowDeTwoLoading(false)
    } catch (error) {
      setIsShowDeTwoLoading(false)
      console.log(error)
    }
  }
  return (
    <div>
      <Card>
        <div className={styles.configdata_head}>
          <div>
            创建时间：
            <PubRangePicker
              value={GiveParams?.timeArr}
              onChange={(val) => {
                setGiveParams({
                  ...GiveParams,
                  timeArr: val.TotalTime,
                  startDate: val.startDate,
                  endDate: val.endDate,
                })
              }}
            />
          </div>
          <div>
            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
            <Button style={{ marginLeft: "20px" }} onClick={onClearn}>
              重置
            </Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        {/* 创建数据汇总 */}
        <div className={styles.configdata_add}>
          <Button
            type="primary"
            onClick={() => {
              history.push("/admin/discernmanage/createdatasummary")
            }}
          >
            创建数据汇总
          </Button>
          <Tag
            color="#FCF6EC"
            height={20}
            className={styles.configdata_tag}
            icon={<ExclamationCircleOutlined style={{ color: "#E6A23C" }} />}
          >
            <span style={{ color: "#E6A23C" }}>
              数据共享时，会占用设备资源，请选择设备空闲时进行
            </span>
          </Tag>
        </div>
        {/* 数据表格展示 */}
        <Table
          dataSource={dataList}
          loading={dataLoading}
          columns={columns}
          rowKey="id"
          scroll={{ x: "max-content", y: `${WinHeight - 420}px` }}
          pagination={onTableChange()}
        />
      </Card>

      <Modal
        title="系统提示"
        open={openModal}
        onOk={onHandOk}
        onCancel={() => {
          setOpenModal(false)
        }}
      >
        {openType == 1 ? (
          <div>
            <QuestionCircleFilled className={styles.configdata_caozuo_icon} />
            清空后，该数据汇总的数据将无法恢复(包括学习数据与商品图片)
            请谨慎操作
          </div>
        ) : openType == 2 ? (
          <div>
            <QuestionCircleFilled className={styles.configdata_caozuo_icon} />
            删除后，已关联该数据汇总的设备将无法执行自动同步
          </div>
        ) : openType == 3 ? (
          <div>
            <QuestionCircleFilled className={styles.configdata_caozuo_icon} />
            确定要设置该集合为默认集合吗？
          </div>
        ) : null}
      </Modal>
    </div>
  )
}

export default Index
