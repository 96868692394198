import { POST, GET } from "../../utils/request"
import request, { newRequest } from "../../utils/newRequest"
import { stringify } from "qs"

/**
 * 新增店内区域
 */
export const AddShopRegion = (params) =>
  POST("/data/center/v1/shop/region/add", params)

/**
 * 修改店内区域
 */
export const EditShopRegion = (params) =>
  POST("/data/center/v1/shop/region/edit", params)

/**
 * 获取店内区域分页列表
 */
export const ShopRegionListpage = (params) =>
  POST("/data/center/v1/shop/region/pageList", params)

/**
 * 删除店内区域
 */
export const ShopRegionDelete = (params) =>
  GET(`/data/center/v1/shop/region/delete?${stringify(params)}`)
