import { POST, GET } from "../../utils/request"
import { stringify } from "qs"

/**
 * 数据集合下拉接口
 */
export const DataPullList = () => GET(`/data/center/v1/setting/data/set/pull`)

/**
 * 门店列表查询接口
 */
export const Shop_List = (params) =>
  POST("/data/center/v1/goods/shop_list", params)

/**
 * 门店下设备下拉列表
 */
export const Shop_Device = (params) =>
  GET(`/data/center/v1/device/pull/list?shopId=${params}`)

/**
 * 列表查询
 */
export const Learn_List = (params) =>
  POST("/data/center/v1/share/learn/list", params)

/**
 * 图片查询
 */
export const img_List = (params) =>
  POST("/data/center/v1/share/learn/image/list", params)

/**
 * 设备管理列表查询接口
 */
export const new_device_List = (params) =>
  POST("/data/center/v1/device/list", params)

/**
 * 创建任务
 */
export const Task_Create = (params) =>
  POST("/data/center/v1/share/learn/task/create", params)

/**
 * 设备秤盘照片(近三个月最后一次打秤的图片)
 */
export const See_Img = (params) =>
  GET(`/data/center/v1/device/scale/image?deviceId=${params}`)

/**
 * 获取icon 匹配数据 （0）
 */
export const sku_list = (params) =>
  POST(`/data/center/v1/share/learn/sku/list`, params)

/**
 * 判断查看图片数据里是否有数据（3）
 */
export const icon_list = (params) =>
  GET(`/data/center/v1/goods/icon/list?${stringify(params)}`)
