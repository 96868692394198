import React, { useState, useEffect, useRef } from "react"
import { DataPullList } from "../../../services/datasetof"
import { PubRangePicker } from "../../../../components/PubRangePicker"
import {
  Button,
  Select,
  Table,
  Drawer,
  message,
  Badge,
  Tooltip,
  Card,
} from "antd"
import styles from "./Index.module.css"
import * as discernmanageSever from "../../../services/discernmanage"
const { Option } = Select

function Index() {
  const [open, setOpen] = useState(false) //侧边栏状态
  const [taskList, setTaskList] = useState([]) //记录列表
  const [taskListInfo, setTaskListInfo] = useState({}) //记录列表接口总数据
  const [deviceList, setDeviceList] = useState([]) //接收设备列表
  const [successRate, setSuccessRate] = useState("") //成功率
  const [drawerName, setDrawerName] = useState("") //侧边栏title
  const [loading, setLoading] = useState(false) // 表格loading
  const [deviceData, setDeviceData] = useState([]) // 设备数据来源列表
  const [setOfData, setDataSetList] = useState([]) // 集合数据来源列表
  /**
   * 表格列表展示页==>
   */
  const [GiveParams, setGiveParams] = useState({
    pageNo: 1,
    pageSize: 20,
    dataType: undefined, // 共享类型
    startDate: "", // 开始时间
    endDate: "", // 结束时间
    timeArr: [], // 总时间
    shareDeviceSn: undefined, // 设备数据来源列表下拉选中数据
    sourceDataSetId: undefined, // 集合数据来源列表下拉选中数据
  })

  //保证每次拿到的都是准确的值
  const AccountRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    AccountRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])
  //<==

  /**
   * 侧边栏的 ==>
   */
  const [NewGiveParams, setNewGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0,
    batchId: "",
  })

  //保证每次拿到的都是准确的值
  const NewAccountRef = useRef({
    NewGiveParamsRef: {},
  })

  useEffect(() => {
    NewAccountRef.current = {
      NewGiveParamsRef: NewGiveParams,
    }
  }, [NewGiveParams])
  //<===

  // 初始化
  useEffect(() => {
    getTaskList()

    // 集合数据来源列表
    getDataPullList()

    // 项目下所有的设备列表
    getSimplePull()
  }, [])

  // 获取记录列表
  const getTaskList = async () => {
    setLoading(true)
    try {
      const { GiveParamsRef } = AccountRef.current
      const params = {
        ...GiveParamsRef,
        timeArr: [],
      }
      const { code, data, msg } = await discernmanageSever.Task_List(params)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      if (data) {
        setTaskList(data?.list || [])
        setTaskListInfo(data || {})
      } else {
        setTaskList([])
        setTaskListInfo({})
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  //点击接收设备
  const handleDevice = (info, text) => {
    setDrawerName(text)
    const { id, successRate } = info
    setNewGiveParams({
      batchId: id,
      pageNum: 1,
      pageSize: 20,
      total: 0,
    })
    setSuccessRate(
      successRate == 0 ? successRate : (successRate * 100).toFixed(2)
    ) //成功率
    //调用接收设备函数
    showDrawer() //打开侧边栏
    setTimeout(getDeviceList, 0)
  }

  // 接收设备
  const getDeviceList = async () => {
    try {
      const { NewGiveParamsRef } = NewAccountRef.current
      const params = NewGiveParamsRef
      const { code, data, msg } = await discernmanageSever.Device_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setDeviceList(data.list || [])
      setNewGiveParams({
        ...NewGiveParamsRef,
        total: data.total,
      })
    } catch (error) {
      console.log(error)
    }
  }

  // 记录列表表格的columns
  const columns = [
    {
      title: "共享时间",
      dataIndex: "createdAt",

      key: "createdAt",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "共享批次号",
      dataIndex: "batchNo",

      key: "batchNo",
      ellipsis: { showTitle: true },
      width: 150,
    },
    {
      title: "共享数据来源",
      dataIndex: "shareDataSourceStr",

      key: "shareDataSourceStr",
      ellipsis: { showTitle: true },
      width: 300,
      render: (info) => {
        return (
          <Tooltip title={info}>
            <span>{info}</span>
          </Tooltip>
        )
      },
    },
    {
      title: "共享类型",
      dataIndex: "dataType",

      key: "dataType",
      ellipsis: { showTitle: true },
      width: 120,
      render: (info, rowInfo) => {
        return <div>{info == 0 ? "学习数据" : info == 1 ? "图片数据" : ""}</div>
      },
    },
    {
      title: "共享方式",
      dataIndex: "shareType",

      key: "shareType",
      ellipsis: { showTitle: true },
      width: 120,
      render: (info) => {
        return <>{info == 1 ? "追加" : "覆盖"}</>
      },
    },
    {
      title: "接收设备",
      dataIndex: "acceptNum",
      key: "acceptNum",
      ellipsis: { showTitle: true },
      width: 120,
      render: (info, rowInfo) => {
        return (
          <div
            className={styles.page_table_deviceList}
            onClick={() => handleDevice(rowInfo, "接收设备")}
          >
            {info}
          </div>
        )
      },
    },
    {
      title: "成功率",
      dataIndex: "successRate",
      key: "successRate",
      ellipsis: { showTitle: true },
      width: 120,
      render: (info, rowInfo) => {
        // return <>{info == 0 ? 0 : (info * 100).toFixed(2) + "%"}</>
        return (
          <div
            className={styles.page_table_deviceList}
            onClick={() => handleDevice(rowInfo, "成功率")}
          >
            {info == 0 ? 0 : (info * 100).toFixed(2) + "%"}
          </div>
        )
      },
    },
  ]
  // 接收设备列表表格的columns
  const deviceColumns = [
    {
      title: "接收设备",
      dataIndex: "deviceSn",

      key: "deviceSn",
      ellipsis: { showTitle: true },
      render: (info, infoAll) => {
        return (
          <>
            {!infoAll?.scaleNo ||
            (infoAll?.systemName !== 0 && infoAll?.systemName !== 1) ||
            !infoAll?.matchingRulesVersion
              ? infoAll?.cdKeyCode
              : infoAll?.scaleNo +
                "-" +
                (infoAll?.systemName == 0
                  ? "And"
                  : infoAll?.systemName == 1
                  ? "Win"
                  : "") +
                "-" +
                infoAll?.matchingRulesVersion}
          </>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "status",

      key: "status",
      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <>
            {" "}
            {info == 0 ? (
              <>
                <Badge status="warning" style={{ padding: "0 5px" }} />
                待共享
              </>
            ) : info == 1 ? (
              <>
                <Badge status="processing" style={{ padding: "0 5px" }} />
                成功
              </>
            ) : info == 2 ? (
              <>
                {" "}
                <Badge status="warning" style={{ padding: "0 5px" }} />
                共享中
              </>
            ) : info == 3 ? (
              <>
                {" "}
                <Badge status="error" style={{ padding: "0 5px" }} />
                失败
              </>
            ) : (
              ""
            )}
          </>
        )
      },
    },
    {
      title: "备注信息",
      dataIndex: "reason",

      key: "reason",
      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <Tooltip title={info}>
            <span>{info}</span>
          </Tooltip>
        )
      },
    },
  ]

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${taskListInfo?.total}条`,
      pageSize: GiveParams?.pageSize,
      current: GiveParams?.pageNo,
      total: taskListInfo?.total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNo: current,
        })
        setTimeout(getTaskList, 0)
      },
    }
    return paginationProps
  }

  //侧边栏分页
  const onNewTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${NewGiveParams?.total}条`,
      pageSize: NewGiveParams.pageSize,
      current: NewGiveParams.pageNum,
      total: NewGiveParams?.total,
      onChange: (current) => {
        setNewGiveParams({
          ...NewGiveParams,
          pageNum: current,
        })
        setTimeout(getDeviceList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 查询
   */
  const handlSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNo: 1,
    })
    setTimeout(getTaskList, 0)
  }
  /**
   * 重置
   */
  const onHandClearn = () => {
    setGiveParams({
      ...GiveParams,
      pageNo: 1,
      dataType: undefined,
      startDate: "",
      endDate: "",
      timeArr: [], // 总时间
      shareDeviceSn: undefined, // 设备数据来源列表下拉选中数据
      sourceDataSetId: undefined, // 集合数据来源列表下拉选中数据
    })
    setTimeout(getTaskList, 0)
  }

  // 抽屉打开
  const showDrawer = () => {
    setOpen(true)
  }
  // 抽屉关闭
  const onClose = () => {
    setOpen(false)
  }

  /**
   * 数据集合下拉接口
   */
  const getDataPullList = async () => {
    try {
      const { code, data, msg } = await DataPullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDataSetList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 项目下所有的设备列表
   */
  const getSimplePull = async () => {
    try {
      const { code, data, msg } = await discernmanageSever.SimplePullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDeviceData(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Card>
        <div className={styles.page_share_head}>
          <div className={styles.page_share_head_left}>
            <div className={styles.page_share_head_left_child}>
              日期：
              <PubRangePicker
                value={GiveParams?.timeArr}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    startDate: val.startDate, // 开始时间
                    endDate: val.endDate, // 结束时间
                    timeArr: val.TotalTime, // 总时间
                  })
                }}
              />
            </div>
            <div className={styles.page_share_head_left_child}>
              共享类型：
              <Select
                placeholder="请选择共享类型"
                style={{ width: 200 }}
                value={GiveParams?.dataType}
                allowClear
                onChange={(e) => {
                  setGiveParams({
                    ...GiveParams,
                    dataType: e,
                  })
                }}
              >
                <Option value="0">学习数据</Option>
                <Option value="1">图片数据</Option>
              </Select>
            </div>
            {/* 设备数据来源 */}
            <div className={styles.page_share_head_left_child}>
              设备-数据来源：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                placeholder="请选择设备的数据来源"
                style={{ width: 200 }}
                value={GiveParams?.shareDeviceSn}
                allowClear
                onChange={(e) => {
                  setGiveParams({
                    ...GiveParams,
                    shareDeviceSn: e,
                  })
                }}
              >
                {deviceData.map((v) => {
                  return (
                    <Option value={v.deviceSn} key={v.deviceSn}>
                      {v.cdKeyCode}
                    </Option>
                  )
                })}
              </Select>
            </div>

            {/* 数据汇总的数据来源 */}
            <div className={styles.page_share_head_left_child}>
              数据汇总-数据来源：
              <Select
                placeholder="请选择数据汇总的数据来源"
                style={{ width: 200 }}
                value={GiveParams?.sourceDataSetId}
                allowClear
                onChange={(e) => {
                  setGiveParams({
                    ...GiveParams,
                    sourceDataSetId: e,
                  })
                }}
              >
                {setOfData.map((v) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className={styles.page_share_head_right}>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
            <Button onClick={onHandClearn}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <Table
          dataSource={taskList}
          columns={columns}
          rowKey="id"
          pagination={onTableChange()}
          loading={loading}
          scroll={{ y: window.screen.height * 0.54, x: "max-content" }}
        />
      </Card>
      <Drawer
        title={drawerName}
        placement="right"
        onClose={onClose}
        open={open}
        width={500}
        extra={<div style={{ color: "#2EB998" }}>{successRate + "%"}</div>}
      >
        <Table
          dataSource={deviceList}
          columns={deviceColumns}
          pagination={onNewTableChange()}
          rowKey="id"
        />
      </Drawer>
    </div>
  )
}

export default Index
