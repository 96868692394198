import { POST, GET } from "../../utils/request"
import { stringify } from "qs"

/**
 * token
 */
export const Get_Token = () => GET("/data/center/v1/qiniuyun/token")

/**
 * 商品图片库icon列表
 */
export const Icon_List = (params) =>
  GET(`/data/center/v1/goods/icon/list?${stringify(params)}`)

/**
 * 商品图片库icon新增
 */
export const Icon_Add = (params) =>
  POST("/data/center/v1/goods/icon/add", params)

/**
 * 商品图片库icon删除
 */
export const Icon_Del = (params) =>
  POST("/data/center/v1/goods/icon/delete", params)

/**
 * 商品图片库icon编辑
 */
export const Icon_Edit = (params) =>
  POST("/data/center/v1/goods/icon/edit", params)

/**
 * 商品图片库标签列表
 */
export const Label_List = (params) =>
  GET(`/data/center/v1/goods/icon/label/list?${stringify(params)}`)

/**
 * 商品图片库标签新增
 */
export const Label_Add = (params) =>
  POST("/data/center/v1/goods/icon/label/add", params)

/**
 * 商品图片库标签删除
 */
export const Label_Del = (params) =>
  POST("/data/center/v1/goods/icon/label/delete", params)

/**
 * 商品图片库标签编辑
 */
export const Label_Edit = (params) =>
  POST("/data/center/v1/goods/icon/label/edit", params)

/**
 * 生成带标签的图片(加水印)
 */
export const Icon_Watermark = (params) =>
  POST("/data/center/v1/goods/icon/watermark", params)

/**
 * 角标颜色背景图列表
 */
export const Label_Color = (params) =>
  GET(`/data/center/v1/goods/icon/background/list`)
