import React, { useState, useEffect, useRef } from "react"
import { Select, Button, message } from "antd"
import {
  Info_Benchmark,
  Shop_List,
  Shop_Device,
  Set_Benchmark,
} from "../../services/devicemanage"
import "./Index.css"

const { Option } = Select
function Index() {
  const [infoBenchmark, SetInfoBenchmark] = useState({}) //信息基准门店数据
  const [shopList, SetShopList] = useState([]) //门店列表
  const [deviceList, SetDeviceList] = useState([]) //设备列表
  const [shopListValue, SetShopListValue] = useState("") //门店列表选中value
  const [deviceListValue, SetDeviceListValue] = useState("") //设备列表选中value

  useEffect(() => {
    getInfoBenchmark()
    getShopList()
  }, [])
  /**
   * 信息基准门店(查询是否设置过默认的门店和设备)
   */
  const getInfoBenchmark = () => {
    Info_Benchmark()
      .then((res) => {
        if (res.code == 200) {
          if (res.data) {
            SetInfoBenchmark(res.data || {})
            console.log("基础门店信息：", res.data)
            SetShopListValue(res.data?.id ? res.data?.id : "") //门店id有就用，没有就为‘ ’
            // SetDeviceListValue(res.data?.deviceId ? res.data?.deviceId : "") //设备id有就用，没有就为‘ ’
            //id存在的情况下根据id去查设备列表
            if (res.data.id) {
              getDeviceList(res?.data?.id, res.data) //调用设备列表接口
            }
          }
        }
      })
      .catch((err) => {})
  }

  /**
   * 门店列表查询接口
   */
  const getShopList = () => {
    Shop_List()
      .then((res) => {
        if (res.code == 200) {
          SetShopList(res.data || [])
          if (!res.data) {
            SetDeviceListValue("")
            SetDeviceList([])
          }
        }
      })
      .catch((err) => {})
  }

  /**
   * 门店下设备下拉列表
   */
  const getDeviceList = (id, info) => {
    const params = id
    Shop_Device(params)
      .then((res) => {
        if (res.code == 200) {
          if (res.data) {
            SetDeviceList(res.data)
            const IsCanShow = res.data.filter(
              (v) => v.deviceId === info?.deviceId
            )
            SetDeviceListValue(
              IsCanShow[0].deviceId ? IsCanShow[0].deviceId : ""
            )
          } else {
            SetDeviceListValue("")
          }
        } else {
          SetDeviceListValue("")
        }
      })
      .catch((err) => {
        SetDeviceListValue("")
      })
  }

  /**
   * 确定
   */
  const handleSubmit = () => {
    if (!shopListValue) {
      message.error("请选择门店")
      return false
    }
    if (!deviceListValue) {
      message.error("请选择设备")
      return false
    }
    const params = {
      deviceId: deviceListValue,
      shopId: shopListValue,
    }
    Set_Benchmark(params).then((res) => {
      if (res.code == 200 && res.data) {
        message.success("基准门店设置成功")
      }
    })
  }
  return (
    <div className="page_device_set">
      基准门店设置
      <Select
        style={{ width: 200, marginLeft: "20px" }}
        placeholder="请选择门店！"
        value={shopListValue ? shopListValue : undefined}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        showSearch
        filterOption={(i, o) => o.props.children.includes(i)}
        onChange={(e) => {
          SetShopListValue(e)
          SetDeviceListValue("") //清除设备value
          getDeviceList(e) //调用设备列表接口

          if (e == infoBenchmark?.id) {
            getDeviceList(infoBenchmark?.id, infoBenchmark) //调用设备列表接口
          }
        }}
      >
        {shopList.map((v) => {
          return (
            <Option value={v.id} key={v.id}>
              {v.name}
            </Option>
          )
        })}
      </Select>
      <Select
        style={{ width: 200, marginLeft: "20px" }}
        placeholder="请选择设备！"
        value={deviceListValue ? deviceListValue : undefined}
        onChange={(e) => {
          SetDeviceListValue(e)
        }}
      >
        {deviceList.map((v) => {
          return (
            <Option value={v.deviceId} key={v.deviceId}>
              {v.cdKeyCode}
            </Option>
          )
        })}
      </Select>
      <Button type="primary" onClick={handleSubmit}>
        确定
      </Button>
    </div>
  )
}

export default Index
