import axios from "axios"
import { getToken } from "./auth"
import { configs } from "../config/api.config.js"
import { message } from "antd"
import QS from "qs"

console.log(configs, "configs")
console.log(process.env, "process.env")
// 打开新窗口之后会用到
let jumpLastLoacl = localStorage.getItem("token")

const service = axios.create({
  baseURL: configs[process.env.REACT_APP_RUNTIME_TYPE].NEW_API_SERVER,
})
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token =
      window.localStorage.getItem("token") ||
      window.sessionStorage.getItem("token")
    //在每次的请求中添加token
    // config.data = Object.assign({}, config.data, {
    //   token: getToken(),
    // })
    //设置请求头
    if (config.headers == undefined) {
      config.headers = {}
    }
    if (config.headers["Content-Type"] == undefined) {
      config.headers["Content-Type"] = "application/json; charset=utf-8"
    }
    config.headers = {
      ...config.headers,
      "data-center-token": getToken() ? getToken() : jumpLastLoacl,
    }
    //序列化请求参数，不然post请求参数后台接收不正常
    // config.data = QS.stringify(config.data)
    return config
  },
  (error) => {
    return error
  }
)

// 响应拦截器
service.interceptors.response.use((response) => {
  //根据返回不同的状态码做不同的事情
  // 这里一定要和后台开发人员协商好统一的错误状态码
  if (response.data?.code) {
    switch (response.data.code) {
      case 200:
        return response.data
      case 400:
        //未登录处理方法
        message.error(response.data.msg)
        break
      case 401:
        //未登录处理方法
        message.error(response.data.msg)
        window.localStorage.removeItem("token")
        window.location = window.location.origin + "/#login"
        break
      case 403:
        //token过期处理方法
        message.error(response.data.msg)
        break
      default:
        message.error(response.data.msg)
    }
  } else {
    return response
  }
})

//最后把封装好的axios导出
// export default service
export const { get: GET, post: POST } = service

// console.log(configs[process.env.REACT_APP_RUNTIME_TYPE].NEW_API_SERVER)
// const instance = axios.create({
//   baseURL: configs[process.env.REACT_APP_RUNTIME_TYPE].NEW_API_SERVER,
//   timeout: 5000,
// })
// //全局请求拦截，发送请求之前拦截
// instance.interceptors.request.use(
//   function (config) {
//     config.headers["authorization"] = "Bearer" + getToken()
//     console.log(config)
//     return config
//   },
//   function (error) {
//     return Promise.reject(error)
//   }
// )
// //请求返回之后执行
// instance.interceptors.response.use(
//   function (response) {
//     return response.data
//   },
//   function (error) {
//     return Promise.reject(error)
//   }
// )

// // 解构导出并重命名
// export const { get: GET, post: POST } = axios
