import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { customRoutes } from "./routes"
import "./App.css"
import Frame from "./components/Frame/Index"
import { isLogined } from "./utils/auth"

function App() {
  /**
   * 动态监听浏览器宽高
   */
  const getWindowSize = () => ({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  })

  const [windowSize, setWindowSize] = useState(getWindowSize())

  const handleResize = () => {
    setWindowSize(getWindowSize())
  }

  useEffect(() => {
    // 监听
    window.addEventListener("resize", handleResize)

    // 销毁
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  sessionStorage.setItem("INNERHEIGHT", windowSize.innerHeight)
  sessionStorage.setItem("INNERWEIGHT", windowSize.innerWidth)

  /**
   * 监听整个页面的 copy 事件
   * 去除所有复制内容里的空格
   */
  document.addEventListener("copy", function (e) {
    let clipboardData = e.clipboardData || window.clipboardData
    if (!clipboardData) return
    //用户选中文本
    let text = window.getSelection().toString()
    if (text) {
      e.preventDefault()
      clipboardData.setData("text/plain", text.replace(/\s*/g, ""))
    }
  })

  /**
   * 清除告警记录新消息 - 只要路由在改价记录页面（#/admin/lossmanage/updateroad）
   */
  useEffect(() => {
    if (window.location.hash === "#/admin/lossmanage/policeroad") {
      sessionStorage.removeItem("MessNum")
    }
  }, [window.location.hash])

  const generateRoute = (item) => {
    // 有子路由
    if (item.children && item.children.length !== 0) {
      // 当前路由也要生成路由
      let routeView = (
        <Route
          key={item.path}
          path={item.path}
          exact={item.exact}
          render={(routeProps) => {
            // 路由对应的组件 <Route><组件名称></组件名称></Route> ,这样就不用写Link标签来指定的路由组件了
            return <item.component {...routeProps} />
          }}
        ></Route>
      )
      // 生成当前路由的子路由
      let children = item.children.map((route) => {
        return generateRoute(route)
      })
      children.push(routeView)
      return children
    }

    // 生成当前路由
    return (
      <Route
        key={item.path}
        path={item.path}
        exact={item.exact}
        render={(routeProps) => {
          return <item.component {...routeProps} />
        }}
      ></Route>
    )
  }

  // 全局判断是否登录
  return isLogined() ? (
    <Frame>
      <Switch>
        {customRoutes.map((item) => {
          return generateRoute(item)
        })}
        {/* 如果路由访问的地址是/admin则进入第一个路由 */}
        <Redirect to={customRoutes[0].path} />
        {/* 如果上面遍历中没有找到路由则会执行当前重定向路由404 */}
        <Redirect to="/404"></Redirect>
        {/* <Route key='/*' path='/*' component={notFound}></Route> */}
      </Switch>
    </Frame>
  ) : (
    <Redirect to="/login"></Redirect>
  )
}

export default App
