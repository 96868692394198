import Axios from "axios"

export const urlToFileObject = async (url, filename) => {
  try {
    const { data, headers } = await Axios.get(url, { responseType: "blob" })
    let blob = new Blob([data])
    return new File([blob], filename, { type: headers["content-type"] })
  } catch (err) {
    console.error(err)
    return null
  }
}
