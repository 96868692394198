import React, { useEffect, useState, useMemo, useRef } from "react"
import {
  Button,
  Card,
  Table,
  message,
  Modal,
  Select,
  Steps,
  Tag,
  Image,
} from "antd"
import styles from "./Index.module.css"
import { useHistory } from "react-router-dom"
import { ExclamationCircleOutlined, CheckCircleFilled } from "@ant-design/icons"
import * as Congigdata from "../../services/configdata_summary"
import PubInput from "../../../components/PubInput"

const { Option } = Select

function Index() {
  const history = useHistory()

  const [id, setId] = useState("") // id

  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [stepsNum, setStepsNum] = useState(0) // 步骤条
  const [dataList, setDataList] = useState([]) // 步骤2表格数据
  // 步骤条数据汇总名称
  const [name, setName] = useState("")
  //列表总数
  const [total, setTotal] = useState(0)
  // 选择数据来源设备选中的sn
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // 选中的数据来源设备信息
  const [checkDeviceInfo, setCheckDeviceInfo] = useState([])
  const [loading, setLoading] = useState(false)
  //
  //图片链接
  //
  const [imgUrl, setImgUrl] = useState("") //
  // 步骤3表格数据
  const [dataList3, setDataList3] = useState([])
  //列表3总数
  const [total3, setTotal3] = useState(0)
  // 选择接收设备选中的sn
  const [selectedRowKeys3, setSelectedRowKeys3] = useState([])
  // 选中的数据接收设备信息
  const [checkDeviceInfo3, setCheckDeviceInfo3] = useState([])
  const [loading3, setLoading3] = useState(false)

  /**
   * 选择数据来源设备列表
   */
  const [ChangeParams, setChangeParams] = useState({
    pageNum: 1,
    pageSize: 20,
    scaleNo: undefined,
    shopName: undefined,
    online: undefined,
    keyCode: undefined,
    systemVersionName: undefined,
    datasetId: history?.location?.search
      .split("?")[1]
      .split("$")[0]
      .split("=")[1],
  })

  //保证每次拿到的都是准确的值
  const CountRef = useRef({
    ChangeParamsRef: {},
  })

  useEffect(() => {
    CountRef.current = {
      ChangeParamsRef: ChangeParams,
    }
  }, [ChangeParams])

  /**
   * 选择数据来源设备列表
   */
  const [ChangeParams3, setChangeParams3] = useState({
    pageNum: 1,
    pageSize: 20,
    scaleNo: undefined,
    shopName: undefined,
    online: undefined,
    keyCode: undefined,
    systemVersionName: undefined,
    datasetId: history?.location?.search
      .split("?")[1]
      .split("$")[0]
      .split("=")[1],
  })

  //保证每次拿到的都是准确的值
  const CountRef3 = useRef({
    ChangeParamsRef3: {},
  })

  useEffect(() => {
    CountRef3.current = {
      ChangeParamsRef3: ChangeParams3,
    }
  }, [ChangeParams3])

  /**
   * 初始化
   */
  useEffect(() => {
    const NewInfo = history?.location?.search
      .split("?")[1]
      .split("$")[0]
      .split("=")[1]
    setId(NewInfo)
    console.log(NewInfo)
    setChangeParams({ ...ChangeParams, datasetId: NewInfo })
    setChangeParams3({ ...ChangeParams3, datasetId: NewInfo })

    // 数据集合详情(NewInfo)
    getDataInfoDetail(NewInfo)
    // 数据集合设备列表
    setTimeout(getDataDevicList, 0)
    // 数据集合设备列表3
    setTimeout(getDataDevicList3, 0)
  }, [])

  /**
   * 数据集合详情
   */
  const getDataInfoDetail = async (id) => {
    try {
      const { code, data, msg } = await Congigdata.DataInfoDetail(id)
      if (code != 200) {
        message.error(msg)
        return
      }
      console.log(data)
      if (data) {
        setName(data.name || "")
        setSelectedRowKeys(data.sourceDeviceIds || [])
        setSelectedRowKeys3(data.acceptDeviceIds || [])
        setChangeParams3({ ...ChangeParams, datasetId: data.id })
        setChangeParams({ ...ChangeParams3, datasetId: data.id })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 数据集合设备列表
   */
  const getDataDevicList = async () => {
    try {
      setLoading(true)
      const { ChangeParamsRef } = CountRef.current
      const { code, data, msg } = await Congigdata.DataDevicList(
        ChangeParamsRef
      )
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      if (data && data.list) {
        setTotal(data.total || 0)
        setDataList(data.list)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  /**
   * 数据集合设备列表3
   */
  const getDataDevicList3 = async () => {
    try {
      setLoading3(true)
      const { ChangeParamsRef3 } = CountRef3.current
      const { code, data, msg } = await Congigdata.DataDevicList(
        ChangeParamsRef3
      )
      if (code != 200) {
        message.error(msg)
        setLoading3(false)
        return
      }
      if (data && data.list) {
        setTotal3(data.total || 0)
        setDataList3(data.list)
        setLoading3(false)
      }
    } catch (error) {
      console.log(error)
      setLoading3(false)
    }
  }

  /**
   * 查询
   */
  const onSearch = () => {
    setChangeParams({
      ...ChangeParams,
      pageNum: 1,
    })
    setTimeout(getDataDevicList, 0)
  }

  /**
   * 查询
   */
  const onSearch3 = () => {
    setChangeParams3({
      ...ChangeParams3,
      pageNum: 1,
    })
    setTimeout(getDataDevicList3, 0)
  }

  /**
   * 重置
   */
  const onClearn = () => {
    setChangeParams({
      ...ChangeParams,
      pageNum: 1,
      pageSize: 20,
      scaleNo: undefined,
      shopName: undefined,
      online: undefined,
      keyCode: undefined,
      systemVersionName: undefined,
      // datasetId: undefined,
    })
    setTimeout(getDataDevicList, 0)
  }

  /**
   * 重置
   */
  const onClearn3 = () => {
    setChangeParams3({
      ...ChangeParams3,
      pageNum: 1,
      pageSize: 20,
      scaleNo: undefined,
      shopName: undefined,
      online: undefined,
      keyCode: undefined,
      systemVersionName: undefined,
      // datasetId: undefined,
    })
    setTimeout(getDataDevicList3, 0)
  }

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  const columns = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "shopName",

      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "是否在线",
      dataIndex: "online",
      key: "online",

      ellipsis: { showTitle: true },
      width: 130,
      render: (info) => {
        return info == 1 ? "在线" : info == 0 ? "离线" : ""
      },
    },
    {
      title: "设备秤号",
      dataIndex: "scaleNo",
      key: "scaleNo",
      ellipsis: { showTitle: true },
      width: 130,
    },
    {
      title: "设备激活码",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",
      ellipsis: { showTitle: true },
      width: 130,
    },

    {
      title: "产品线",
      dataIndex: "productLineName",
      key: "productLineName",
      ellipsis: { showTitle: true },
      width: 130,
    },
    {
      title: "产品线版本",
      dataIndex: "productLineTypeName",
      key: "productLineTypeName",
      ellipsis: { showTitle: true },
      width: 150,
    },
    {
      title: "版本号",
      dataIndex: "systemVersionName",
      key: "systemVersionName",
      ellipsis: { showTitle: true },
      width: 150,
    },
    {
      title: "识别模型",
      dataIndex: "aiModeName",
      key: "aiModeName",
      ellipsis: { showTitle: true },
      width: 150,
    },
    {
      title: "近7天识别率",
      dataIndex: "aiOkRate",
      key: "aiOkRate",
      ellipsis: { showTitle: true },
      width: 200,
      render: (info) => {
        return info == 0 || !info ? "0%" : (info * 100).toFixed(2) + "%"
      },
    },
    {
      title: "设备秤盘照片",

      ellipsis: { showTitle: true },
      width: 200,
      render: (info, rowInfo) => {
        return (
          <Button type="link" onClick={() => onSeePic(rowInfo)}>
            查看照片
          </Button>
        )
      },
    },
  ]

  /**
   * 查看照片
   */
  const onSeePic = async (rowInfo) => {
    try {
      const { deviceId } = rowInfo
      const { code, msg, data } = await Congigdata.See_Img(deviceId)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.scaleImageUrl) {
        setImgUrl(data.scaleImageUrl)
      } else {
        message.error("暂无图片")
        setImgUrl("")
      }
    } catch (error) {
      console.log(error)
      message.error("查看照片失败")
    }
  }
  // 选择数据来源设备
  //分页
  const onTableChange = () => {
    const paginationProps = {
      pageSizeOptions: [10, 20, 50, 100, 500, 1000],
      showSizeChanger: true,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ChangeParams.pageSize,
      current: ChangeParams.pageNum,
      total: total,
      onChange: (current, pageSize) => {
        setChangeParams({
          ...ChangeParams,
          pageNum: current,
          pageSize: pageSize,
        })
        setTimeout(getDataDevicList, 0)
      },
    }
    return paginationProps
  }

  const onSelectChange = (newSelectedRowKey, selectedRows) => {
    // 选中的数据来源设备信息
    setCheckDeviceInfo(selectedRows)
    // 选中的数据来源设备id
    setSelectedRowKeys(newSelectedRowKey)
    setChangeParams({
      ...ChangeParams,
      // datasetId: newSelectedRowKey,
    })
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({
      disabled: !record.isCheck,
    }),
  }

  // 选择数据接收设备
  //分页3
  const onTableChange3 = () => {
    const paginationProps = {
      pageSizeOptions: [10, 20, 50, 100, 500, 1000],
      showSizeChanger: true,
      showQuickJumper: false,
      showTotal: () => `共${total3}条`,
      pageSize: ChangeParams3.pageSize,
      current: ChangeParams3.pageNum,
      total: total3,
      onChange: (current, pageSize) => {
        setChangeParams3({
          ...ChangeParams3,
          pageNum: current,
          pageSize: pageSize,
        })
        setTimeout(getDataDevicList3, 0)
      },
    }
    return paginationProps
  }

  const onSelectChange3 = (newSelectedRowKey, selectedRows) => {
    setSelectedRowKeys3(newSelectedRowKey)
    // 选中的数据来源设备信息
    setCheckDeviceInfo3(selectedRows)
    setChangeParams3({
      ...ChangeParams3,
      // datasetId: newSelectedRowKey,
    })
  }
  const rowSelection3 = {
    selectedRowKeys: selectedRowKeys3,
    onChange: onSelectChange3,
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({
      disabled: !record.isCheck,
    }),
  }

  /**
   * 完成
   */
  const onOk = async () => {
    try {
      const params = {
        acceptDeviceIds: selectedRowKeys3,
        name: name,
        sourceDeviceIds: selectedRowKeys,
        id: id,
      }
      const { code, data, msg } = await Congigdata.EditData(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setStepsNum(3)
      }
    } catch (error) {
      // message.error("处理失败")
      console.log(error)
    }
  }

  return (
    // <Card style={{ height: `${WinHeight - 140}px` }}>
    <Card>
      {/* 顶部步骤条 */}
      <div className={styles.createpage_head}>
        {" "}
        <Steps
          className={styles.createpage_head_steps}
          current={stepsNum}
          items={[
            {
              title: "设置数据汇总名称",
            },
            {
              title: "选择数据来源设备",
            },
            {
              title: "选择数据接收设备",
            },
          ]}
        />
      </div>

      {/* 步骤条第一步 */}
      {stepsNum == 0 ? (
        <div className={styles.createpage_head_one}>
          <div className={styles.createpage_head_one_body}>
            数据汇总名称：
            <PubInput
              onChange={(val) => {
                setName(val)
              }}
              placeholder="数据汇总名称"
              value={name}
            />
          </div>
          <div className={styles.createpage_head_one_body}>
            <Button
              type="primary"
              disabled={name ? false : true}
              onClick={() => setStepsNum(1)}
            >
              下一步
            </Button>
          </div>
        </div>
      ) : stepsNum == 1 ? (
        // 步骤条第二步
        <div>
          <div className={styles.createpage_head_twotag}>
            <Tag
              color="#FCF6EC"
              height={20}
              className={styles.configdata_tag}
              icon={<ExclamationCircleOutlined style={{ color: "#E6A23C" }} />}
            >
              <span style={{ color: "#E6A23C" }}>
                数据共享时，会占用设备资源，请选择设备空闲时进行
              </span>
            </Tag>
            <div>数据接收设备已选 {selectedRowKeys.length} 台</div>
          </div>
          <div className={styles.createpage_head_two_search}>
            <div className={styles.createpage_head_two_inputbody}>
              <div className={styles.createpage_head_two_input}>
                门店名称：
                <PubInput
                  onChange={(val) => {
                    setChangeParams({
                      ...ChangeParams,
                      shopName: val,
                    })
                  }}
                  placeholder="门店名称"
                  value={ChangeParams.shopName}
                />
              </div>
              <div className={styles.createpage_head_two_input}>
                秤号：
                <PubInput
                  onChange={(val) => {
                    setChangeParams({
                      ...ChangeParams,
                      scaleNo: val,
                    })
                  }}
                  placeholder="秤号"
                  value={ChangeParams.scaleNo}
                />
              </div>
              <div className={styles.createpage_head_two_input}>
                激活码：
                <PubInput
                  onChange={(val) => {
                    setChangeParams({
                      ...ChangeParams,
                      keyCode: val,
                    })
                  }}
                  placeholder="激活码"
                  value={ChangeParams.keyCode}
                />
              </div>
              <div className={styles.createpage_head_two_input}>
                是否在线：
                <Select
                  style={{ width: 200 }}
                  placeholder="请选择是否在线"
                  value={ChangeParams.online}
                  onChange={(e) => {
                    setChangeParams({
                      ...ChangeParams,
                      online: e,
                    })
                  }}
                >
                  <Option value={"1"} key={"1"}>
                    在线
                  </Option>
                  <Option value={"0"} key={"0"}>
                    离线
                  </Option>
                </Select>
              </div>
              <div className={styles.createpage_head_two_input}>
                版本号：
                <PubInput
                  onChange={(val) => {
                    setChangeParams({
                      ...ChangeParams,
                      systemVersionName: val,
                    })
                  }}
                  placeholder="版本号"
                  value={ChangeParams.systemVersionName}
                />
              </div>
            </div>

            {/* 搜索重置按钮 */}
            <div className={styles.createpage_head_two_btn}>
              <Button type="primary" onClick={onSearch}>
                查询
              </Button>
              <Button style={{ marginLeft: "20px" }} onClick={onClearn}>
                重置
              </Button>
            </div>
          </div>
          {/* 表格数据展示 */}
          <Table
            dataSource={dataList}
            loading={loading}
            columns={columns}
            scroll={{ x: "max-content", y: `${WinHeight - 570}px` }}
            pagination={onTableChange()}
            rowSelection={rowSelection}
            rowKey="deviceId"
            onRow={(info) => {
              return {
                onClick: (e) => {
                  if (!info.isCheck) {
                    message.error(info.notCheckReason)
                  }
                },
              }
            }}
          />
          {/* 上一步下一步 */}
          <div className={styles.createpage_head_two_next}>
            <Button
              style={{ marginRight: "20px" }}
              onClick={() => setStepsNum(0)}
            >
              上一步
            </Button>
            <Button
              type="primary"
              disabled={selectedRowKeys.length == 0 ? true : false}
              onClick={() => {
                setStepsNum(2)
                // const NewInfo = checkDeviceInfo[0]
                // // productLineName 产品线,aiMode 设备最新学习文件,matchingRulesVersion 版本（匹配规则）,systemVersionName 版本号
                // const IsproductLineName = checkDeviceInfo.every(
                //   (item) => item.productLineName == NewInfo.productLineName
                // )
                // const IsaiMode = checkDeviceInfo.every((item) =>
                //   item.aiMode === null || NewInfo.aiMode === null
                //     ? true
                //     : item.aiMode == NewInfo.aiMode
                // )
                // const IsmatchingRulesVersion = checkDeviceInfo.every(
                //   (item) =>
                //     item.matchingRulesVersion == NewInfo.matchingRulesVersion
                // )
                // const IssystemVersionName = checkDeviceInfo.every(
                //   (item) => item.systemVersionName == NewInfo.systemVersionName
                // )
                // if (
                //   IsproductLineName &&
                //   IsaiMode &&
                //   IsmatchingRulesVersion &&
                //   IssystemVersionName
                // ) {
                //   setStepsNum(2)
                // } else {
                //   message.error(
                //     "勾选的设备产品线、识别模型、推荐版本、识别版本不一致"
                //   )
                // }
              }}
            >
              下一步
            </Button>
          </div>
        </div>
      ) : stepsNum == 2 ? (
        // ,第三步
        <div>
          <div className={styles.createpage_head_twotag}>
            <Tag
              color="#FCF6EC"
              height={20}
              className={styles.configdata_tag}
              icon={<ExclamationCircleOutlined style={{ color: "#E6A23C" }} />}
            >
              <span style={{ color: "#E6A23C" }}>
                数据共享时，会占用设备资源，请选择设备空闲时进行
              </span>
            </Tag>
            <div>数据接收设备已选 {selectedRowKeys3.length} 台</div>
          </div>
          <div className={styles.createpage_head_two_search}>
            <div className={styles.createpage_head_two_inputbody}>
              <div className={styles.createpage_head_two_input}>
                门店名称：
                <PubInput
                  onChange={(val) => {
                    setChangeParams3({
                      ...ChangeParams3,
                      shopName: val,
                    })
                  }}
                  placeholder="门店名称"
                  value={ChangeParams3.shopName}
                />
              </div>
              <div className={styles.createpage_head_two_input}>
                秤号：
                <PubInput
                  onChange={(val) => {
                    setChangeParams3({
                      ...ChangeParams3,
                      scaleNo: val,
                    })
                  }}
                  placeholder="秤号"
                  value={ChangeParams3.scaleNo}
                />
              </div>
              <div className={styles.createpage_head_two_input}>
                激活码：
                <PubInput
                  onChange={(val) => {
                    setChangeParams3({
                      ...ChangeParams3,
                      keyCode: val,
                    })
                  }}
                  placeholder="激活码"
                  value={ChangeParams3.keyCode}
                />
              </div>
              <div className={styles.createpage_head_two_input}>
                是否在线：
                <Select
                  style={{ width: 200 }}
                  placeholder="请选择是否在线"
                  value={ChangeParams3.online}
                  onChange={(e) => {
                    setChangeParams3({
                      ...ChangeParams3,
                      online: e,
                    })
                  }}
                >
                  <Option value={"1"} key={"1"}>
                    在线
                  </Option>
                  <Option value={"0"} key={"0"}>
                    离线
                  </Option>
                </Select>
              </div>
              <div className={styles.createpage_head_two_input}>
                版本号：
                <PubInput
                  onChange={(val) => {
                    setChangeParams3({
                      ...ChangeParams3,
                      systemVersionName: val,
                    })
                  }}
                  placeholder="版本号"
                  value={ChangeParams3.systemVersionName}
                />
              </div>
            </div>

            {/* 搜索重置按钮 */}
            <div className={styles.createpage_head_two_btn}>
              <Button type="primary" onClick={onSearch3}>
                查询
              </Button>
              <Button style={{ marginLeft: "20px" }} onClick={onClearn3}>
                重置
              </Button>
            </div>
          </div>
          {/* 表格数据展示 */}
          <Table
            dataSource={dataList3}
            loading={loading3}
            columns={columns}
            scroll={{ x: "max-content", y: `${WinHeight - 570}px` }}
            pagination={onTableChange3()}
            rowSelection={rowSelection3}
            rowKey="deviceId"
            onRow={(info) => {
              return {
                onClick: (e) => {
                  if (!info.isCheck) {
                    message.error(info.notCheckReason)
                  }
                },
              }
            }}
          />
          {/* 上一步下一步 */}

          <div className={styles.createpage_head_two_next}>
            <Button
              style={{ marginRight: "20px" }}
              onClick={() => setStepsNum(1)}
            >
              上一步
            </Button>
            <Button
              type="primary"
              disabled={selectedRowKeys3.length > 0 ? false : true}
              onClick={() => {
                onOk()
                // const NewInfo = checkDeviceInfo[0]
                // // productLineName 产品线,aiMode 设备最新学习文件,matchingRulesVersion 版本（匹配规则）,systemVersionName 版本号
                // const IsproductLineName = checkDeviceInfo3.every(
                //   (item) => item.productLineName == NewInfo.productLineName
                // )
                // const IsaiMode = checkDeviceInfo3.every((item) =>
                //   item.aiMode === null || NewInfo.aiMode === null
                //     ? true
                //     : item.aiMode == NewInfo.aiMode
                // )
                // const IsmatchingRulesVersion = checkDeviceInfo3.every(
                //   (item) =>
                //     item.matchingRulesVersion == NewInfo.matchingRulesVersion
                // )
                // const IssystemVersionName = checkDeviceInfo3.every(
                //   (item) => item.systemVersionName == NewInfo.systemVersionName
                // )
                // if (
                //   IsproductLineName &&
                //   IsaiMode &&
                //   IsmatchingRulesVersion &&
                //   IssystemVersionName
                // ) {
                //   onOk()
                // } else {
                //   message.error(
                //     "勾选的设备 与 数据来源设备的 产品线、识别模型、推荐版本、识别版本不一致"
                //   )
                // }
              }}
            >
              完成
            </Button>
          </div>
        </div>
      ) : stepsNum == 3 ? (
        // 步骤条第三步
        <div>
          <div className={styles.createpage_head_two_success}>
            <CheckCircleFilled
              className={styles.createpage_head_two_success_icon}
            />
            <span className={styles.createpage_head_two_success_span}>
              编辑成功
            </span>
          </div>
          <div className={styles.createpage_head_two_next}>
            {" "}
            <Button
              type="primary"
              style={{ width: "200px" }}
              onClick={() => window.history.back(-1)}
            >
              返回列表
            </Button>
            {/* <Button onClick={() => setStepsNum(3)}>查看共享记录</Button> */}
          </div>
        </div>
      ) : null}

      <Modal
        open={imgUrl ? true : false}
        onCancel={() => setImgUrl("")}
        footer={[]}
        width={"50%"}
        height={"80%"}
      >
        <Image src={imgUrl} className={styles.createpage_share_image} />
      </Modal>
    </Card>
  )
}

export default Index
