import moment from "moment"
import dayjs from "dayjs"
moment.locale("zh-cn")

/**
 * 周报选择只能选择近4周
 */
export const disabledDateWeek = (current) => {
  // 获取最新4周的日期范围
  const endDate = moment().endOf("day")
  const startDate = moment(endDate).subtract(4, "weeks")
  // 如果当前日期在范围之外，则禁用
  return current.isBefore(startDate) || current.isAfter(endDate)
}

/**
 * 日报选择只能选择上月和当月
 */
export const disabledDateDay = (current) => {
  return (
    (current && current > new Date()) ||
    current < new Date().setDate(new Date().getDate() - 30)
  )
}
