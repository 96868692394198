import React, { useEffect, useState, useRef } from "react"
import { sku_list, img_List } from "../../../services/discernmanage"
import { Table, Button, Drawer, Spin } from "antd"
import styles from "./index.module.css"
function Index(props) {
  const [deviceSn, setDeviceSn] = useState(
    window.localStorage.getItem("deviceSn")
  )
  const [scaleNo, setShopName] = useState(
    window.localStorage.getItem("scaleNo")
  )
  const [productLineName, setProductLineName] = useState(
    window.localStorage.getItem("productLineName")
  )
  const [productLineTypeName, setProductLineTypeName] = useState(
    window.localStorage.getItem("productLineTypeName")
  )
  const [matchingRulesVersion, setMatchingRulesVersion] = useState(
    window.localStorage.getItem("matchingRulesVersion")
  )
  const [name, setName] = useState(window.localStorage.getItem("shopName"))
  const pageNoRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [learnList, setLearnList] = useState([])
  const [total, setTotal] = useState(0)
  const [imgList, setImgList] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [loadingImg, setLoadingImg] = useState(false)

  useEffect(() => {
    pageNoRef.current = pageNo
  }, [pageNo])
  useEffect(() => {
    getLearnList()
  }, [])
  //列表
  const getLearnList = () => {
    let _pageNo = pageNoRef.current
    const params = {
      deviceSn: deviceSn,
      pageNo: _pageNo,
      pageSize: pageSize,
    }
    setLoadingList(true)
    sku_list(params)
      .then((res) => {
        if (res.code === 200) {
          if (res.data) {
            setLoadingList(false)
            console.log("icon数据：", res.data)
            setLearnList(res?.data?.list || [])
            setTotal(res?.data?.total || 0)
          } else {
            setLoadingList(false)
          }
        } else {
          setLoadingList(false)
        }
      })
      .catch((err) => {
        setLoadingList(false)
      })
  }
  // 图片
  // const getImgList = (info) => {
  //   const params = {
  //     count: info?.featLength,
  //     skuCode: info?.skuCode,
  //     deviceSn: deviceSn,
  //     limit: 10,
  //   }
  //   setLoadingImg(true)
  //   img_List(params)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         if (res.data) {
  //           setLoadingImg(false)
  //           setImgList(res?.data)
  //         } else {
  //           setLoadingImg(false)
  //         }
  //       } else {
  //         setLoadingImg(false)
  //       }
  //     })
  //     .catch((err) => {
  //       setLoadingImg(false)
  //     })
  // }
  const columns = [
    {
      title: "商品名称",
      dataIndex: "skuName",

      key: "skuName",
      ellipsis: { showTitle: true },
    },
    {
      title: "商品编码",
      dataIndex: "skuCode",

      key: "skuCode",
      ellipsis: { showTitle: true },
    },
    {
      title: "商品图片",
      dataIndex: "imageUrl",

      key: "imageUrl",
      ellipsis: { showTitle: true },
      render: (info, rowInfo) => {
        return (
          <div className={styles.page_icon_img}>
            <div className={styles.page_icon_imgchild}>
              <img src={info} />
            </div>
          </div>
        )
      },
    },
  ]
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNo,
      total: total,
      onChange: (current) => {
        setPageNo(current) //
        // getGoodsList("", current) //翻页时调用列表接口
        setTimeout(getLearnList, 0)
      },
    }
    return paginationProps
  }

  const onClose = () => {
    setOpen(false)
    setImgList([])
  }
  return (
    <div>
      <div className={styles.data_share}>
        <div>
          {" "}
          已选择：{name} - {scaleNo} - {productLineName} - {productLineTypeName}
          {matchingRulesVersion}
        </div>
        <Button
          type="primary"
          onClick={() => {
            window.history.back(-1)
          }}
        >
          返回上层
        </Button>
      </div>
      <Table
        pagination={onTableChange()}
        dataSource={learnList}
        columns={columns}
        loading={loadingList}
        rowKey="skuCode"
      />
      <Drawer
        width={500}
        title="查看学习数据"
        placement="right"
        onClose={onClose}
        open={open}
        loading={loadingImg}
      >
        {loadingImg ? (
          <div className={styles.loading}>
            <Spin size="large" />
          </div>
        ) : (
          <div className={styles.drawer_body}>
            {imgList.map((v) => {
              return <img src={v} />
            })}
          </div>
        )}
      </Drawer>
    </div>
  )
}

export default Index
