import React, { useEffect, useState, useRef } from "react"
import { Button, Table, Select, message, Modal, Form, Card } from "antd"
import * as AccoubtManage from "../../services/accoubt_manage"
import PubInput from "../../../components/PubInput"
import styles from "./Index.module.css"
const { Option } = Select

function Index() {
  const [form] = Form.useForm()
  const [MENUS, setMENUS] = useState([]) //菜单列表
  const [dataList, setDataList] = useState([]) //表格列表
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [total, setTotal] = useState() //每页显示的行数
  const [isModalOpen, setIsModalOpen] = useState(false) //新建编辑Modal状态
  const [modalTitle, setModalTitle] = useState("") // 新建编辑Modal-title
  const [isDeleteOpen, setSsDeleteOpen] = useState(false) //删除Modal状态
  const [isDisableEnable, setIsDisableEnable] = useState(false) //禁用启用Modal状态
  const [checkStrictly, setCheckStrictly] = useState(false) //checkable 状态下节点选择完全受控（父子数据选中状态不再关联）
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 新建编辑选中的菜单
  const [selectedParentRowKeys, setSelectedParentRowKeys] = useState([]) // 新建编辑选中的父级id
  const [formData, setFormData] = useState({}) // 表单提交的值
  const [tableInfo, setTableInfo] = useState({}) // 表格操作功能时临时缓存的数据
  const [roleName, setRoleName] = useState("") // 查询条件角色名称
  const [GiveParams, setGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    roleName: "",
  })

  //保证每次拿到的都是准确的值
  const RoleRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    RoleRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  /**
   * 初始化
   */
  useEffect(() => {
    //调用角色列表接口
    onGetUserList()
    // 获取菜单
    getUserInfoNew()
  }, [])

  /**
   * 获取菜单
   */
  const getUserInfoNew = async () => {
    try {
      const { code, data, msg } = await AccoubtManage.UserInfo()
      if (code != 200) {
        message.error(msg)
        return
      }
      const Menus = data?.menus || []
      if (Menus.length > 0) {
        Menus.map((v) => {
          v.key = v.menuId
          v.name = v.menuName
          v.children = v.childMenus

          v.children.map((w) => {
            w.key = w.menuId
            w.name = w.menuName
            w.children = w.childMenus
          })
        })
        setMENUS(Menus)
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 获取登录用户信息(主要是为了拿菜单树)
   */
  const getUserInfo = async () => {
    try {
      const { code, data, msg } = await AccoubtManage.UserInfo()
      if (code != 200) {
        message.error(msg)
        return
      }
      const Menus = data?.menus || []
      if (Menus.length > 0) {
        Menus.map((v) => {
          v.key = v.menuId
          v.name = v.menuName
          v.children = v.childMenus

          v.children.map((w) => {
            w.key = w.menuId
            w.name = w.menuName
            w.children = w.childMenus
          })
        })
        setMENUS(Menus)
      }
      //打开modal
      setIsModalOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 角色列表
   */
  const onGetUserList = async () => {
    try {
      const { GiveParamsRef } = RoleRef.current
      const params = GiveParamsRef
      const { code, data, msg } = await AccoubtManage.GetUserList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      const { list, total } = data
      setDataList(list)
      setTotal(total)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: total,
      onChange: (current) => {
        setPageNum(current)
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        // setSelectedRowKeys([])
        setTimeout(onGetUserList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 新增角色
   */
  const onAddRole = () => {
    //清空表单
    form.resetFields()
    //Modal-title赋值
    setModalTitle("新建角色")
    // //打开modal
    // setIsModalOpen(true)
    //清空表单
    setFormData({})
    //清空树形选中状态
    setSelectedRowKeys([])
    // 获取菜单
    getUserInfo()
  }

  /**
   * 编辑角色
   */
  const onEditRole = async (roleId) => {
    try {
      form.resetFields()
      const { code, data, msg } = await AccoubtManage.RoleInfo(roleId)
      if (code != 200) {
        message.error(msg)
        return
      }

      // 提取父级id
      const NewParentId = []
      MENUS.map((v) => {
        NewParentId.push(v.menuId)
      })
      // 只提取子集id
      const NewChildId = data.menuIds.filter((v) => !NewParentId.includes(v))
      // 给菜单树回显选中的默认值
      setSelectedRowKeys(NewChildId)
      //给form表单赋值
      setFormData(data)
      //Modal-title赋值
      setModalTitle("编辑角色")
      // //打开modal框
      // setIsModalOpen(true)
      // 获取菜单
      getUserInfo()
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 重置
   */
  const onHandClearn = () => {
    setRoleName(undefined)
    setPageNum(1)
    setGiveParams({ ...GiveParams, pageNum: 1, roleName: undefined })
    // 调用角色列表接口
    setTimeout(onGetUserList, 0)
  }

  /**
   * 查询
   */
  const onHandSearch = () => {
    console.log(roleName)
    setPageNum(1)
    setGiveParams({ ...GiveParams, pageNum: 1, roleName: roleName })
    // 调用角色列表接口
    setTimeout(onGetUserList, 0)
  }

  /**
   * 监听树形menu
   */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const NewParenntId = []
      //将所有选中菜单的父级id存起来，最后提交时，一块给后端
      selectedRows.map((v) => {
        if (v.parentId != 0) {
          NewParenntId.push(v.parentId)
        }
      })
      // 存储选中的父id
      setSelectedParentRowKeys([...new Set(NewParenntId)])
      //存储选中的菜单
      setSelectedRowKeys(selectedRowKeys)
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows)
    },
  }

  /**
   * 监听表单提交
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        //判断是否选择了菜单权限
        if (selectedRowKeys.length == 0) {
          message.error("请选择菜单权限")
          return
        }

        // //找出首页的menuId
        // let NewMenuId = ""
        // NewMenuId = MENUS.find((v) => v.router === "/admin/home").menuId
        // // 首页路由对象
        // const FirstPage = MENUS.find((v) => v.router === "/admin/home")
        // // 首页子id
        // let ChildrenId = []
        // FirstPage.children.map((v) => {
        //   ChildrenId.push(v.menuId)
        // })
        // // 判断首页子集是否都选中
        // const IsChangeHome = ChildrenId.every((v) => {
        //   return selectedRowKeys.includes(v)
        // })
        // // 为true时把首页的id加进selectedRowKeys
        // if (IsChangeHome) {
        //   selectedRowKeys.push(FirstPage.menuId)
        // }

        // //判断首页为必选
        // if (!selectedRowKeys.some((v) => v == NewMenuId)) {
        //   message.error("首页为必选")
        //   return
        // }

        const menuIds = [
          ...new Set(selectedRowKeys.concat(selectedParentRowKeys)),
        ]
        //新建 / 编辑接口传值
        const params = {
          menuIds: menuIds,
          roleName: values.roleName,
          roleId: formData.roleId ? formData.roleId : undefined,
        }
        //给存储的表单数据赋值
        // setFormData(params)

        //请求新建 / 编辑接口，values.roleId有的话是编辑，否则是新建
        const { code, msg, data } = formData.roleId
          ? await AccoubtManage.EditRole(params)
          : await AccoubtManage.AddRole(params)
        if (code != 200) {
          message.error(msg)
          return
        }

        //新建/ 编辑 成功或失败
        if (data) {
          //关闭modal
          setIsModalOpen(false)
          message.success(formData.roleId ? "角色编辑成功" : "角色新建成功")
          //清空存储的表单数据
          setFormData({})
          form.resetFields()
          // 调用角色列表接口
          setTimeout(onGetUserList, 0)
        } else {
          message.error(formData.roleId ? "角色编辑失败" : "角色新建失败")
        }
      })
      .catch((err) => {
        // 校验失败
      })
  }

  /**
   * 监听禁用启用确认按钮
   */
  const onChangeState = async () => {
    // (1:启用 2:停用)
    try {
      const params = {
        roleId: tableInfo?.roleId,
        status: tableInfo?.status == 1 ? 2 : 1,
      }
      const { code, data, msg } = await AccoubtManage.RoleStatus(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success(tableInfo?.status == 1 ? "禁用成功" : "启用成功")
        setIsDisableEnable(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监听删除按钮
   */
  const onDelete = async () => {
    try {
      const { code, data, msg } = await AccoubtManage.DeleteRole(
        tableInfo?.roleId
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        setSsDeleteOpen(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "角色名称",
      dataIndex: "roleName",
      ellipsis: { showTitle: true },
      key: "roleName",
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "角色状态",
      dataIndex: "status",
      ellipsis: { showTitle: true },
      key: "status",
      render: (info) => {
        return <>{info == 1 ? "启用" : info == 2 ? "禁用" : "-"}</>
      },
    },
    {
      title: "创建人",
      dataIndex: "creator",
      ellipsis: { showTitle: true },
      key: "creator",
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "创建时间",
      dataIndex: "createDate",
      ellipsis: { showTitle: true },
      key: "createDate",
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <div className={styles.role_table_but}>
            <a type="link" onClick={() => onEditRole(info?.roleId)}>
              编辑
            </a>
            <a
              type="link"
              onClick={() => {
                // 临时缓存数据
                setTableInfo(info)
                console.log(info, "info")
                setIsDisableEnable(true)
              }}
            >
              {info.status == 1 ? "禁用" : info.status == 2 ? "启用" : ""}
            </a>
            <a
              type="link"
              // danger
              onClick={() => {
                // 临时缓存数据
                setTableInfo(info)
                setSsDeleteOpen(true)
              }}
            >
              删除
            </a>
          </div>
        )
      },
    },
  ]

  const columnsMenu = [
    {
      title: "菜单名称",
      dataIndex: "menuName",
      key: "menuName",
    },
  ]
  const dataMenu = MENUS

  return (
    <div className={styles.role_page}>
      {/* 新建编辑角色Modal */}
      <Modal
        // forceRender
        getContainer={false}
        title={modalTitle}
        open={isModalOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
          //清空存储的表单数据
          setFormData({})
          form.resetFields()
        }}
        onOk={onFinish}
        width={"30%"}
      >
        <Form
          form={form}
          // labelCol={{ span: 0 }}
          // wrapperCol={{ span: 0 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="角色名称"
            name="roleName"
            initialValue={formData?.roleName || ""}
            rules={[
              {
                required: true,
                message: "角色名称不能为空!",
              },
              {
                // pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{1,10}$/, "g"),
                message: "支持中英文、数字、特殊字符，长度为10",
              },
            ]}
          >
            <PubInput placeholder="角色名称！" max={10} />
          </Form.Item>
          <div className={styles.role_menu_title}>
            <span style={{ color: "red", marginTop: "5px" }}>*</span>
            <span style={{ marginLeft: "5px" }}>菜单权限：</span>
          </div>
          <Form.Item>
            <Table
              columns={columnsMenu}
              rowSelection={{
                ...rowSelection,
                checkStrictly,
                selectedRowKeys,
              }}
              defaultExpandAllRows
              indentSize={20}
              dataSource={dataMenu}
              pagination={false}
              scroll={{ y: window.screen.height * 0.4 }}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* 删除 */}
      <Modal
        title="删除"
        open={isDeleteOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setSsDeleteOpen(false)
          //清空临时缓存数据
          setTableInfo()
        }}
        onOk={onDelete}
      >
        <div>确认删除【 角色数据：{tableInfo?.roleName} 】？</div>
        <div>删除后该角色下的账号都无法正常登录平台</div>
      </Modal>

      {/* 禁用启用 */}
      <Modal
        title={tableInfo?.status == 1 ? "禁用" : "启用"}
        open={isDisableEnable}
        destroyOnClose
        keyboard
        onCancel={() => {
          setIsDisableEnable(false)
          //清空临时缓存数据
          setTableInfo({})
        }}
        onOk={onChangeState}
      >
        <div>
          确认
          {tableInfo?.status == 1
            ? "禁用"
            : tableInfo?.status == 2
            ? "启用"
            : ""}
          【 角色数据：{tableInfo?.roleName} 】？
        </div>
        <div>
          {tableInfo?.status == 1
            ? "禁用后该角色下的账号都无法正常登录平台"
            : tableInfo?.status == 2
            ? "启用后该角色下的账号都可以正常登录平台"
            : ""}
        </div>
      </Modal>

      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.role_page_search}>
          <div className={styles.role_search_body}>
            <div className={styles.role_search_body_child}>
              <span>角色名称：</span>
              <PubInput
                onChange={(val) => {
                  setRoleName(val)
                }}
                placeholder="角色名称"
                value={roleName}
              />
            </div>
          </div>
          <div className={styles.role_search_button}>
            <Button type="primary" onClick={onHandSearch}>
              查询
            </Button>
            <Button onClick={onHandClearn}>重置</Button>
          </div>
        </div>
      </Card>
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        <Button type="primary" onClick={onAddRole}>
          新建角色
        </Button>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="roleId"
          loading={loading}
          scroll={{ y: window.screen.height * 0.49 }}
        />
      </Card>
    </div>
  )
}

export default Index
