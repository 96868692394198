import { Button, Table, Input, DatePicker, Select, message } from "antd"
import React, { useEffect, useState, useRef } from "react"
import locale from "antd/lib/date-picker/locale/zh_CN"
import dayjs from "dayjs"
import moment from "moment"
import "moment/locale/zh-cn"
import {
  GetDeviceExport,
  GetDevicenPage,
  Shop_List,
} from "../../services/datareport"
import "./Index.css"

const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = "YYYYMMDD"

moment.locale("zh-cn")

function Index(props) {
  const [timeArr, setTimeArr] = useState([
    dayjs(),
    dayjs().subtract(1, "month"),
  ]) //时间
  const [startTime, setStartTime] = useState(
    dayjs().subtract(1, "month").format("YYYYMMDD")
  ) //开始时间
  const [endTime, setEndTime] = useState(dayjs().format("YYYYMMDD")) //结束时间
  const [keyword, setKeyword] = useState(undefined) //关键词
  const [shopId, setShopId] = useState(undefined) //门店id
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(10) //每页显示的行数
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)

  const [isSearch, setIsSearch] = useState(false) //是否查询

  //
  const [shopList, setShopList] = useState([]) //门店设备列表
  const [dataList, setDataList] = useState([]) //表格列表

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    pageNoRef: "",
    startTimeRef: "",
    endTimeRef: "",
    shopIdRef: "",
    keywordRef: "",
    isSearchRef: "",
  })
  useEffect(() => {
    updataRef.current = {
      pageNoRef: pageNum,
      startTimeRef: startTime,
      endTimeRef: endTime,
      shopIdRef: shopId,
      keywordRef: keyword,
      isSearchRef: isSearch,
    }
  }, [pageNum, endTime, startTime, shopId, keyword, isSearch])

  useEffect(() => {
    if (
      !sessionStorage.getItem("ShopId") ||
      sessionStorage.getItem("ShopId") == 0 ||
      sessionStorage.getItem("ShopId") == null
    ) {
      setShopId(undefined)
    } else {
      const NewShopId = Number(sessionStorage.getItem("ShopId"))
      if (!isNaN(NewShopId)) {
        setShopId(NewShopId)
      } else {
        setShopId(undefined)
      }
    }
    //调用获取设备使用情况列表
    setTimeout(getTableList, 0)

    //请求门店列表接口
    getShopList()
  }, [])

  //获取设备使用情况列表
  const getTableList = () => {
    let _isSearch = updataRef.current.isSearchRef
    setLoading(true)
    const params = {
      endDate: _isSearch
        ? updataRef.current.endTimeRef
        : dayjs().format("YYYYMMDD"), //结束时间
      startDate: _isSearch
        ? updataRef.current.startTimeRef
        : dayjs().subtract(1, "month").format("YYYYMMDD"), //开始时间
      keyWord: _isSearch ? updataRef.current.keywordRef : "", //设备关键词
      pageNum: updataRef.current.pageNoRef,
      pageSize: pageSize,
      shopId: _isSearch
        ? updataRef.current.shopIdRef
        : sessionStorage.getItem("ShopId") == 0 ||
          !sessionStorage.getItem("ShopId")
        ? undefined
        : Number(sessionStorage.getItem("ShopId")), //门店id
    }
    GetDevicenPage(params)
      .then((res) => {
        if (res.code == 200) {
          setLoading(false)
          setTotal(res.data.total) //数据总条数
          setDataList(res.data.list || []) //数据列表
        }
      })
      .catch((err) => {
        setLoading(false)
        message.err(err.msg)
      })
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: total,
      onChange: (current) => {
        setPageNum(current)
        setTimeout(getTableList, 0)
      },
    }
    return paginationProps
  }

  //百分比计算
  const setNum = (value) => {
    return <>{value > 0 ? value.toFixed(2) * 100 + "%" : value}</>
  }

  //时间选择限制30天
  const disabledDate = (current) => {
    if (!timeArr) {
      return false
    }
    const tooLate = timeArr[0] && current.diff(timeArr[0], "days") >= 30
    const tooEarly = timeArr[1] && timeArr[1].diff(current, "days") >= 30
    return !!tooEarly || !!tooLate
  }

  const columns = [
    {
      title: "日期",
      dataIndex: "dateId",
      ellipsis: { showTitle: true },

      key: "dateId",
    },
    {
      title: "门店",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },

      key: "shopName",
    },
    {
      title: "激活码",
      dataIndex: "cdKey",
      ellipsis: { showTitle: true },

      key: "cdKey",
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },

      key: "scaleNo",
    },
    {
      title: "识别率",
      dataIndex: "aiOkRate",
      ellipsis: { showTitle: true },

      key: "aiOkRate",
      render: (info) => setNum(info),
    },
    {
      title: "使用量",
      dataIndex: "effectiveNum",
      ellipsis: { showTitle: true },

      key: "effectiveNum",
    },
    {
      title: "搜索占比",
      dataIndex: "optSearchRate",
      ellipsis: { showTitle: true },

      key: "optSearchRate",
      render: (info) => setNum(info),
    },
    {
      title: "Top1",
      dataIndex: "top1OkRate",
      ellipsis: { showTitle: true },

      key: "top1OkRate",
      render: (info) => setNum(info),
    },
  ]

  //关键词
  const keywordChange = (e) => {
    if (e) {
      setKeyword(e.target.value.replace(/(^\s+)|(\s+$)/g, ""))
    } else {
      setKeyword(undefined)
    }
  }
  //日期：
  const timeChange = (e) => {
    if (e) {
      setTimeArr(e) //存时间数组
      setStartTime(e[0].format("YYYYMMDD"))
      setEndTime(e[1].format("YYYYMMDD"))
    } else {
      setTimeArr([dayjs(), dayjs().subtract(1, "month")]) //存时间数组
      setStartTime(dayjs().subtract(1, "month").format("YYYYMMDD"))
      setEndTime(dayjs().format("YYYYMMDD"))
    }
  }

  //门店列表接口请求
  const getShopList = () => {
    const params = {}
    Shop_List(params).then((res) => {
      if (res.code == 200) {
        setShopList(res.data || [])
      }
    })
  }

  //门店change
  const shopChange = (e) => {
    setShopId(e)
  }

  //查询
  const handSearch = () => {
    setIsSearch(true)
    setPageNum(1)
    setTimeout(getTableList, 0)
  }
  //重置
  const handClear = () => {
    setIsSearch(false)
    setPageNum(1)
    setShopId(undefined)
    setKeyword(undefined)
    setTimeArr([dayjs(), dayjs().subtract(1, "month")]) //存时间数组
    setStartTime(dayjs().subtract(1, "month").format("YYYYMMDD"))
    setEndTime(dayjs().format("YYYYMMDD"))
    setTimeout(getTableList, 0)
  }

  //导出
  const getExport = () => {
    const params = {
      endDate: isSearch ? endTime : dayjs().format("YYYYMMDD"),
      startDate: isSearch
        ? startTime
        : dayjs().subtract(1, "month").format("YYYYMMDD"),
      keyWord: isSearch ? keyword : "",
      shopId: isSearch
        ? shopId
        : sessionStorage.getItem("ShopId") == 0 ||
          !sessionStorage.getItem("ShopId")
        ? undefined
        : Number(sessionStorage.getItem("ShopId")), //门店id
    }
    GetDeviceExport(params).then((res) => {
      if (res.code == 200) {
      }
    })
  }

  return (
    <div className="devicedetail_page">
      {/* 查询条件，查询，重置 */}
      <div className="devicedetail_page_search">
        <div className="devicedetail_search_body">
          <span>日期：</span>
          <RangePicker
            disabledDate={disabledDate}
            style={{ height: 38 }}
            locale={locale}
            value={
              timeArr.length == 0
                ? []
                : [dayjs(timeArr[0], dateFormat), dayjs(timeArr[1], dateFormat)]
            }
            onChange={(e) => timeChange(e)}
          />
          <span style={{ marginLeft: "20px" }}>门店：</span>
          <Select
            placeholder="请选择门店"
            allowClear
            style={{ width: 200 }}
            value={shopId}
            // showSearch
            // filterOption={(i, o) => o.props.children.includes(i)}
            onChange={(e) => shopChange(e)}
          >
            {shopList.map((v) => {
              return (
                <Option value={v.id} key={v.id}>
                  {v.name}
                </Option>
              )
            })}
          </Select>
          <span style={{ marginLeft: "20px" }}>设备：</span>
          <Input
            placeholder="请输入激活码/称号"
            onChange={(e) => keywordChange(e)}
            value={keyword}
            style={{ width: "200px", height: "38px" }}
          />
        </div>
        <div className="weightrecord_search_button">
          <Button onClick={() => handClear()}>重置</Button>
          <Button
            type="primary"
            style={{ marginLeft: "20px" }}
            onClick={() => handSearch()}
          >
            查询
          </Button>
          {/* 导出 */}
          <Button
            type="primary"
            style={{ marginLeft: "20px" }}
            onClick={getExport}
          >
            导出数据
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: "20px" }}
            onClick={() => {
              window.history.go(-1)
            }}
          >
            返回上层
          </Button>
        </div>
      </div>
      <div className="devicedetail_page_body">
        {/* 表格数据 */}
        <div className="devicedetail_body_table">
          <Table
            dataSource={dataList}
            columns={columns}
            rowKey="id"
            pagination={onTableChange()}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

export default Index
