import CryptoJS from 'crypto-js'
import dayjs from "dayjs"

/**
 * 文件路径的结构
 */
interface FilePath {
  dir: String // 目录部分
  base: String // 基础文件名（含后缀）
  ext: String // 文件后缀
  baseNotExt: String // 不含文件后缀的基础文件名
  paths: Array<String> // 文件分隔后的路径数组
}

/**
 * URL路径结构
 */
interface UrlPath {
  scheme: String // 使用的协议
  host: String // 使用的域名（包含端口号）
  relative: String // 相对路径（不包含参数）
}

/**
 * 解析文件路径
 * @param path 文件完整路径
 * @returns 文件路径对象
 */
export const ParseFilePath = (path: String): FilePath => {
  // 将路径拆分为数组
  const paths = path.split('/')
  // 提取基础文件名（含后缀）
  const base = paths[paths.length - 1]
  // 提取后缀
  let ext = ''
  const extDotIndex = base.lastIndexOf('.')
  if (extDotIndex >= 0) {
    ext = base.substring(extDotIndex)
  }
  // 提取不含后缀的文件名
  const baseNotExt = base.substring(0, extDotIndex)
  // 拼接目录部分
  const dir = paths.slice(0, paths.length - 1).join('/')
  // 返回结果
  return {
    dir: dir,
    base: base,
    ext: ext,
    baseNotExt: baseNotExt,
    paths: paths
  }
}

/**
 * 替换路径中的基础文件名
 * @param path 原始路径
 * @param newBase 新的基础文件名
 * @param keepOldExt 是否保留原来的后缀（默认保留）
 * @returns 替换后的文件路径
 */
export const ReplaceFilePathBase = (path: String, newBase: String, keepOldExt: Boolean = true): String => {
  // 解析路径
  const filepath = ParseFilePath(path)
  // 新的文件路径
  let newFilePath = ''
  // 是否保留文件后缀
  if (keepOldExt) {
    // 保留旧的后缀
    newFilePath = `${filepath.dir}/${newBase}${filepath.ext}`
  } else {
    // 不保留后缀
    newFilePath = `${filepath.dir}/${newBase}`
  }
  // 返回新的文件路径
  return newFilePath
}

/**
 * 计算文件路径的MD5
 * @param path 文件路径
 * @param upLetter 是否使用大写字母（默认小写）
 * @returns 文件路径的MD5
 */
export const CalcFilePathMD5 = (path: String, upLetter: Boolean = false): String => {
  if (upLetter) {
    return CryptoJS.MD5(path).toString().toUpperCase() + '' + dayjs().valueOf()
  }
  return CryptoJS.MD5(path).toString().toLowerCase() + '' + dayjs().valueOf()
}

/**
 * 获取URL中的相对路径
 * @param url URL路径
 * @returns URL路径结构
 */
export const parseURL = (url: String): UrlPath => {
  // 移除第一个'?'及其之后的内容
  const wIndex = url.indexOf('?')
  if (wIndex >= 0) {
    url = url.substring(0, wIndex)
  }
  // 查询'://'的位置
  const sIndex = url.indexOf('://')
  // 提取协议
  let scheme = ''
  // 提取主机信息
  let host = ''
  if (sIndex >= 0) {
    // 截取协议
    scheme = url.substring(0, sIndex)
    // 移除'://'及其之前的内容
    url = url.substring(sIndex + '://'.length)
    //查询第一个'/'
    const xIndex = url.indexOf('/')
    // 截取主机
    host = url.substring(0, xIndex)
  }

  // 移除第一个'/'之前的内容
  const xIndex = url.indexOf('/')
  if (xIndex >= 0) {
    url = url.substring(xIndex)
  }
  // 将'//'替换为'/'
  const relative = url.replace(new RegExp('//'), '/')

  // 返回URL路径对象
  return {
    scheme: scheme,
    host: host,
    relative: relative
  }
}