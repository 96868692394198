import React, { useEffect, useState, useRef } from "react"
import { FormOutlined } from "@ant-design/icons"
import {
  Card,
  Button,
  Form,
  Table,
  Modal,
  message,
  Popconfirm,
  Select,
  Cascader,
} from "antd"
import * as ShopmanageDevice from "../../../services/storemanage"
import { useHistory } from "react-router-dom"
import qs from "qs"
import PubInput from "../../../../components/PubInput"
import styles from "./Index.module.css"

const { Option } = Select

function Index(props) {
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const history = useHistory()
  const [form] = Form.useForm()
  const [shopId, setShopId] = useState("") // 门店id
  const [shopAccentList, setShopAccentList] = useState([]) //账号列表
  const [total, setTotal] = useState(0) // 总数
  const [loading, setLoading] = useState(false)
  const [addAccentOpen, setAddAccentOpen] = useState(false) // 添加账号打开关闭Modal状态
  const [roleList, setRoleList] = useState([]) //角色列表

  // --------------
  const [ParamsInfo, setParamsInfo] = useState({
    code: undefined, // 门店名称/门店账号/激活码.
    pageNum: 1,
    pageSize: 20,
  })
  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // -----------------
  // 初始化
  useEffect(() => {
    const param = history.location.search.replace(/^\?/, "")
    setShopId(qs.parse(param).shopId)
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    //账号列表接口，
    getShopAccountList(qs.parse(param).shopId)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 查询门店下的主账号
   */
  const getShopAccountList = async (SHOPID) => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
        // isSuper: 1,
        pageNum: ParamsInfoRef.pageNum,
        pageSize: ParamsInfoRef.pageSize,
        shopId: SHOPID ? SHOPID : shopId,
      }
      setLoading(true)
      const { code, data, msg } = await ShopmanageDevice.shop_account_list(
        params
      )
      if (code != 200 || data === null) {
        message.error(msg)
        setLoading(false)
        return
      }
      setShopAccentList(data.list || [])
      setTotal(data.total)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error(error)
    }
  }

  /**
   * 查询门店下的角色
   */
  const getRoleList = async () => {
    try {
      const params = {
        isSuper: 1,
        pageNum: 1,
        pageSize: 1000,
        shopId: shopId,
      }
      const { code, data, msg } = await ShopmanageDevice.role_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setRoleList(data.list)
    } catch (error) {
      console.log(error)
      message.error("角色列表获取失败")
    }
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      //账号列表接口，
      setTimeout(getShopAccountList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        code: undefined,
        pageNum: 1,
        pageSize: 20,
      })
      //账号列表接口，
      setTimeout(getShopAccountList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 删除账号
   */
  const shopAccect = async (info) => {
    const { id } = info
    try {
      const params = {
        id: id,
      }
      const { code, msg, data } = await ShopmanageDevice.delete_shop_account(
        params
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        //账号列表接口，
        setTimeout(getShopAccountList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "用户名",
      dataIndex: "nickname",
      key: "nickname",

      ellipsis: { showTitle: true },
      width: 300,
    },
    {
      title: "手机号",
      dataIndex: "username",
      key: "username",

      ellipsis: { showTitle: true },
      width: 300,
    },
    {
      title: "角色名称",
      dataIndex: "roleList",
      key: "roleList",

      ellipsis: { showTitle: true },
      width: 300,
      render: (info) => {
        return info.map((v, i) => {
          return v.roleName
        })
      },
    },
    {
      title: "操作",

      ellipsis: { showTitle: true },
      width: 300,

      render: (info) => {
        return (
          <Popconfirm
            placement="bottom"
            title="确认要删除该账号吗?"
            onConfirm={() => {
              shopAccect(info)
            }}
            okText="确认"
            cancelText="取消"
          >
            <a>删除</a>
          </Popconfirm>
        )
      },
    },
  ]
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({ ...ParamsInfo, pageNum: current })
        // 设备列表
        setTimeout(getShopAccountList, 0)
      },
    }
    return paginationProps
  }

  /**
   *添加账号Modal确认按钮
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.shopId = shopId
        ShopmanageDevice.add_shop_account(values).then((res) => {
          if (res.code != 200) {
            return
          }
          if (res.data) {
            message.success("创建成功")
            //调用查询门店下的主账号接口
            setTimeout(getShopAccountList, 0)
            setAddAccentOpen(false)
          }
        })
      })
      .catch((err) => {
        // 校验失败
      })
  }

  return (
    <div>
      <Card>
        <div className={styles.xappmanage_store_head}>
          <div className={styles.xappmanage_store_head_search}>
            <div className={styles.xappmanage_store_head_search_child}>
              用户名/手机号：
              <PubInput
                onChange={(val) => setParamsInfo({ ...ParamsInfo, code: val })}
                placeholder="用户名/手机号"
                value={ParamsInfo.code}
              />
            </div>
          </div>
          <div className={styles.xappmanage_store_head_btn}>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
            <Button onClick={handClearn}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 8 }}>
        <div style={{ marginBottom: "20px" }}>
          <Button
            type="primary"
            onClick={() => {
              setAddAccentOpen(true)
              // 查询门店下的角色
              getRoleList()
              form.resetFields()
            }}
            style={{ marginRight: "20px" }}
          >
            创建小程序账号
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={shopAccentList}
            rowKey="id"
            loading={loading}
            scroll={{ x: "max-content", y: `${WinHeight - 460}px` }}
          />
        </div>
      </Card>

      {/* 门店小程序添加账号Modal */}
      <Modal
        width={500}
        title="创建小程序账号"
        open={addAccentOpen}
        onOk={onFinish}
        onCancel={() => setAddAccentOpen(false)}
        okText="确认"
        cancelText="取消"
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="用户名"
            name="nickname"
            rules={[
              {
                required: true,
                message: "用户名不能为空!",
              },
            ]}
          >
            <PubInput placeholder="用户名！" max={15} />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="username"
            rules={[
              {
                required: true,
                message: "手机号不能为空!",
              },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: "请输入正确的手机号！",
              },
            ]}
          >
            <PubInput placeholder="手机号！" max={11} />
          </Form.Item>
          <Form.Item
            label="选择角色"
            name="roleId"
            rules={[
              {
                required: true,
                message: "角色不能为空!",
              },
            ]}
          >
            <Select
              style={{
                width: 200,
              }}
              allowClear
              placeholder="请选择角色"
              fieldNames={{ label: "roleName", value: "roleId" }}
              options={roleList}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Index
