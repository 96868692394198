import { POST, GET } from "../../utils/request"
import { stringify } from "qs"

/**
 * 数据集合列表
 */
export const DataSetList = (params) =>
  GET(`/data/center/v1/setting/data/set/list?${stringify(params)}`)

/**
 * 数据集合清空数据
 */
export const ClearnData = (id) =>
  GET(`/data/center/v1/setting/data/set/clear/data?id=${id}`)

/**
 * 数据集合删除
 */
export const DeleteData = (id) =>
  GET(`/data/center/v1/setting/data/set/delete?id=${id}`)

/**
 * 合并记录
 */
export const MergeLog = (params) =>
  GET(`/data/center/v1/setting/data/set/merge/log?${stringify(params)}`)

/**
 * 数据集合下拉接口
 */
export const DataPullList = () => GET(`/data/center/v1/setting/data/set/pull`)

/**
 * 数据集合设备列表
 */
export const DataDevicList = (params) =>
  GET(`/data/center/v1/setting/data/set/device/list?${stringify(params)}`)

/**
 * 设备秤盘照片(近三个月最后一次打秤的图片)
 */
export const See_Img = (params) =>
  GET(`/data/center/v1/device/scale/image?deviceId=${params}`)

/**
 * 创建数据集合
 */
export const CreateDataSet = (params) =>
  POST("/data/center/v1/setting/create/data/set", params)

/**
 * 编辑数据集合
 */
export const EditData = (params) =>
  POST("/data/center/v1/setting/edit/data/set", params)

/**
 * 开启/关闭自动同步
 */
export const SetOpen = (params) =>
  POST("/data/center/v1/setting/data/set/open", params)

/**
 * 数据集合详情
 */
export const DataInfoDetail = (id) =>
  GET(`/data/center/v1/setting/data/set/info?id=${id}`)

/**
 * 数据集合关联设备
 */
export const RelevanceDevice = (params) =>
  GET(`/data/center/v1/setting/data/set/relevance/device?${stringify(params)}`)

/**
 * 设置默认数据集合
 */
export const SetDefault = (id) =>
  GET(`/data/center/v1/setting/data/set/default?id=${id}`)

/**
 * 拉取记录列表
 */
export const PullLogList = (params) =>
  GET(`/data/center/v1/setting/data/set/pull/log?${stringify(params)}`)

/**
 * 拉取设备列表
 */
export const PullDevice = (params) =>
  GET(`/data/center/v1/setting/data/set/pull/device?${stringify(params)}`)
