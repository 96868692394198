import { Button, Table, DatePicker, Select, message, Card } from "antd"
import React, { useEffect, useState, useRef } from "react"
import locale from "antd/lib/date-picker/locale/zh_CN"
import dayjs from "dayjs"
import moment from "moment"
import "moment/locale/zh-cn"
import "./Index.css"
import { DataPullList, MergeLog } from "../../services/datasetof"

moment.locale("zh-cn")
const { RangePicker } = DatePicker
const { Option } = Select

const dateFormat = "YYYY-MM-DD"
//开始时间
let ST = dayjs().format(dateFormat) + " " + "00:00:00"
// 结束时间
let ET = dayjs().format(dateFormat) + " " + "23:59:59"
// 总时间
let AT = [ST, ET]
function Index(props) {
  // const [dataSetId, setDataSetId] = useState(
  //   sessionStorage.getItem("dataSetId")
  // ) //数据集合id
  const [timeArr, setTimeArr] = useState([ST, ET]) //时间
  const [startDate, setStartTime] = useState(ST) //开始时间
  const [endDate, setEndTime] = useState(ET) //结束时间
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  const [dataSetList, setDataSetList] = useState([]) // 数据集合下拉列表
  const [dataSetId, setDataSetOf] = useState(undefined) //数据集合选中数据
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [GiveParams, setGiveParams] = useState({
    startDate: ST,
    endDate: ET,
    pageNum: 1,
    pageSize: 20,
    dataSetId: dataSetId
      ? dataSetId
      : Number(sessionStorage.getItem("dataSetId")),
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  // 初始化请求接口
  useEffect(() => {
    // 数据集合下拉接口
    getDataPullList()

    // 获取表格列表数据
    setTimeout(getGoodsWeighDetail, 0)
  }, [])

  /**
   * 数据集合下拉接口
   */
  const getDataPullList = async () => {
    try {
      const { code, data, msg } = await DataPullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDataSetList(data || [])
      //数据集合选中数据默认值
      setDataSetOf(Number(sessionStorage.getItem("dataSetId")))
    } catch (error) {
      console.log(error)
    }
  }

  //时间选择限制30天
  // const disabledDate = (current) => {
  //   if (!timeArr) {
  //     return false
  //   }
  //   const tooLate = timeArr[0] && current.diff(timeArr[0], "days") >= 30
  //   const tooEarly = timeArr[1] && timeArr[1].diff(current, "days") >= 30
  //   return !!tooEarly || !!tooLate
  // }

  //获取表格列表数据
  const getGoodsWeighDetail = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      console.log(GiveParamsRef)
      setLoading(true)
      const { code, msg, data } = await MergeLog(GiveParamsRef)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: total,
      onChange: (current) => {
        setPageNum(current)
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getGoodsWeighDetail, 0)
      },
    }
    return paginationProps
  }

  const sourceDevices = (value) => {
    return value.map((v) => {
      return <p>{v.shopName + " / " + v.cdKeyCode}</p>
    })
  }

  const columns = [
    {
      title: "合并时间",
      dataIndex: "createAt",
      ellipsis: { showTitle: true },

      key: "createAt",
    },
    {
      title: "数据集合",
      dataIndex: "dataSetName",
      ellipsis: { showTitle: true },

      key: "dataSetName",
    },
    // {
    //   title: "全部设备",
    //   dataIndex: "sourceDevices",
    //   ellipsis: { showTitle: true },
    //
    //   key: "sourceDevices",
    //   render: (info) => {
    //     return (
    //       <Tooltip title={() => sourceDevices(info)}>
    //         <a>{info.length}</a>
    //       </Tooltip>
    //     )
    //   },
    // },
    {
      title: "生效的数据来源",
      dataIndex: "mergeDevice",
      ellipsis: { showTitle: true },

      key: "mergeDevice",
      render: (info) => {
        return info ? info.shopName + "/" + info.cdKeyCode : ""
      },
    },
  ]

  //日期：
  const timeChange = (e) => {
    //当时间选择框选中时间时，取选中时间，清除时间时回到默认时间
    if (e) {
      setTimeArr(e) //存时间数组
      setStartTime(dayjs(e[0]).format("YYYY-MM-DD") + " " + "00:00:00")
      setEndTime(dayjs(e[1]).format("YYYY-MM-DD") + " " + "23:59:59")

      //清空员工下拉框选中数据
    } else {
      setTimeArr(AT)
      setEndTime(ET)
      setStartTime(ST)
    }
  }

  //查询
  const handSearch = () => {
    setPageNum(1)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      startDate: startDate,
      endDate: endDate,
      dataSetId: dataSetId,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }
  //重置
  // 重置时将时间恢复默认
  // 开始时间
  ST = dayjs().format(dateFormat) + " " + "00:00:00"
  // 结束时间
  ET = dayjs().format(dateFormat) + " " + "23:59:59"
  const handClear = () => {
    setPageNum(1)
    setDataSetOf(Number(sessionStorage.getItem("dataSetId")))
    setTimeArr(AT)
    setEndTime(ET)
    setStartTime(ST)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      startDate: ST,
      endDate: ET,
      dataSetId: Number(sessionStorage.getItem("dataSetId")),
    })
    setTimeout(getGoodsWeighDetail, 0)
  }

  return (
    <div className="mergerecords_page">
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className="mergerecords_page_search">
          <div className="mergerecords_search_body">
            <div className="mergerecords_search_body_child">
              <span>操作日期：</span>
              <RangePicker
                locale={locale}
                // disabledDate={disabledDate}
                value={
                  timeArr.length == 0
                    ? []
                    : [
                        dayjs(timeArr[0], dateFormat),
                        dayjs(timeArr[1], dateFormat),
                      ]
                }
                onChange={(e) => timeChange(e)}
              />
            </div>

            <div className="mergerecords_search_body_child">
              <span>数据集合：</span>
              <Select
                style={{ width: 200 }}
                placeholder="请选择数据集合"
                value={dataSetId}
                onChange={(e) => {
                  setDataSetOf(e)
                }}
              >
                {dataSetList.map((v) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className="mergerecords_search_button">
            <Button onClick={() => handClear()}>重置</Button>
            <Button
              type="primary"
              style={{ marginLeft: "20px" }}
              onClick={() => handSearch()}
            >
              查询
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                window.history.back(-1)
              }}
            >
              返回上层
            </Button>
          </div>
        </div>
      </Card>
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          scroll={{ y: window.screen.height * 0.53 }}
        />
      </Card>
    </div>
  )
}

export default Index
