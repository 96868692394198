import { Button, message, Select, Spin } from "antd"
import React, { useEffect, useState, useRef } from "react"
import * as echarts from "echarts"
import "./Index.css"
import {
  GetDeviceChartData,
  GetDeviceCollection,
  GetDeviceStatus,
  Shop_List,
  Info_Benchmark,
} from "../../services/datareport"
import { LoadingOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

const { Option } = Select

function Index() {
  const [useNum, setUseNum] = useState("") //使用量统计数
  const [deviceStatus, setDeviceStatus] = useState("") //设备在线情况 offLineNum:离线设备数,onlineNum:在线设备数,total:总设备数据
  const [dayValue, setDayValue] = useState("1") //选择的天数id
  const [loading, setLoading] = useState(false)
  const [shopList, setShopList] = useState([]) //门店列表
  // const [defalutShop, setDefalutShop] = useState(undefined)//基准门店
  const [shopId, setShopId] = useState(undefined) //门店id

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    shopIdRef: "",
    shopListRef: [],
  })
  useEffect(() => {
    updataRef.current = {
      shopIdRef: shopId,
      shopListRef: shopList,
    }
  }, [shopId, shopList])

  useEffect(() => {
    //调用门店列表
    setTimeout(getShopList, 0)

    //使用量统计接口调用
    getUseStatistics()

    //调用设备在线情况
    getUseStatis()
  }, [])

  //门店列表接口请求
  const getShopList = async () => {
    const params = {}
    const res = await Shop_List(params)
    if (res.code == 200) {
      res.data.unshift({ id: "", name: "全部" })
      setShopList(res.data || [])
      //获取基准门店
      getInfoBenchmark()
    }
  }

  //获取基准门店
  const getInfoBenchmark = async () => {
    const res = await Info_Benchmark()
    const NewShopId = updataRef.current.shopListRef[1].id
    if (!res) {
      setShopId(NewShopId || undefined)
      //调用获取4个最近七天折线接口
      setTimeout(getDeviceChartData, 0)
      return false
    }
    if (res.code == 200) {
      if (res.data) {
        if (res.data.id) {
          setShopId(res.data.id)
        } else {
          setShopId(NewShopId || undefined)
        }
      } else {
        setShopId(NewShopId || undefined)
      }
      //调用获取4个最近七天折线接口
      setTimeout(getDeviceChartData, 0)
    } else {
      setShopId(NewShopId || undefined)
      //调用获取4个最近七天折线接口
      setTimeout(getDeviceChartData, 0)
    }
  }

  //门店列表change
  const shopChange = (e) => {
    setShopId(e)
    setTimeout(getDeviceChartData, 0)
  }

  //获取4个最近七天折线
  const getDeviceChartData = () => {
    const params = { shopId: updataRef.current.shopIdRef }
    GetDeviceChartData(params).then((res) => {
      let newTime = [] //时间
      let AiOkRat = []
      let EffectiveNu = []
      let OptRate = []
      let OptTop1 = []

      if (res.code == 200) {
        if (res.data) {
          const newDataArr = Object.entries(res.data)
          newDataArr.map((v) => {
            let NewAiOkRat = []
            let NewEffect = []
            let NewRate = []
            let Newop1 = []
            v[1].map((w) => {
              NewAiOkRat.push(w.aiOkRate * 100) // 近7日设备平均识别率走势图
              NewEffect.push(w.effectiveNum) // 近7日设备使用量走势图
              NewRate.push(w.optSearchRate * 100) //近7日设备搜索占比走势图
              Newop1.push(w.top1OkRate * 100) //近7日设备TOP1点选占比走势图
            })
            // 近7日设备平均识别率走势图
            AiOkRat.push({
              name: v[0],
              type: "line",
              data: NewAiOkRat,
              tooltip: {
                valueFormatter: (value) => value.toFixed(2) + "%",
              },
            })
            // 近7日设备使用量走势图
            EffectiveNu.push({
              name: v[0],
              type: "line",
              data: NewEffect,
            })
            // 近7日设备搜索占比走势图
            OptRate.push({
              name: v[0],
              type: "line",
              data: NewRate,
              tooltip: {
                valueFormatter: (value) => value.toFixed(2) + "%",
              },
            })
            // 近7日设备平均识别率走势图
            OptTop1.push({
              name: v[0],
              type: "line",
              data: Newop1,
              tooltip: {
                valueFormatter: (value) => value.toFixed(2) + "%",
              },
            })
          })

          const NewArr1 = newDataArr.length > 0 ? newDataArr[0][1] : [] //获取第一个数组
          //将时间单独提出来
          NewArr1.map((v) => {
            newTime.push(v.dateTime)
          })
          echartsShow("main", newTime, AiOkRat)
          echartsShow("main1", newTime, EffectiveNu)
          echartsShow("main2", newTime, OptRate)
          echartsShow("main3", newTime, OptTop1)
        }
      }
    })
  }

  //趋势图
  const echartsShow = async (classValue, time, NewSre) => {
    echarts.init(document.getElementById(classValue)).dispose()
    var chartDom = document.getElementById(classValue)
    if (chartDom !== null) {
      var myChart = echarts.init(chartDom)
      var option
      //解决图表不会自适应问题
      const chartObserver = new ResizeObserver(() => {
        myChart.resize()
      })
      chartObserver.observe(chartDom)

      option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          bottom: "5%",
          top: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: time,
        },
        yAxis: {
          type: "value",
        },
        series: NewSre,
      }

      myChart.setOption(option)
    }
  }

  //使用量统计
  const getUseStatistics = (value) => {
    setLoading(true)
    const params = { type: value ? value : "1" }
    setTimeout(
      () =>
        GetDeviceCollection(params)
          .then((res) => {
            setLoading(false)
            if (res.code == 200) {
              setUseNum(res.data)
            }
          })
          .catch((err) => {
            setLoading(false)
            message.error(err.msg)
          }),
      1000
    )
  }
  //设备在线情况
  const getUseStatis = () => {
    GetDeviceStatus().then((res) => {
      if (res.code == 200) {
        setDeviceStatus(res.data)
      }
    })
  }

  //查看更多
  const handLookMore = (value) => {
    return (
      <Link
        to={{
          pathname: `/admin/datareport/devicedetail`,
        }}
      >
        <Button
          type="link"
          onClick={() => {
            sessionStorage.setItem("ShopId", updataRef.current.shopIdRef)
          }}
        >
          查看更多
        </Button>
      </Link>
    )
  }

  return (
    <div className="device_page">
      {/* 顶部数据卡片展示 */}
      <div className="device_head_info">
        {/* 卡一 */}
        <div className="device_info_child">
          <div className="device_child_body">
            <div className="device_child_top">
              <div>使用量统计</div>
              {/* 1-当天 2-昨天 3-前天 */}
              <Select
                allowClear
                style={{ width: 100 }}
                onChange={(e) => {
                  if (e) {
                    setDayValue(e)
                    getUseStatistics(e)
                  } else {
                    setDayValue("1")
                    getUseStatistics("1")
                  }
                }}
                value={dayValue}
                placeholder="选择时间"
              >
                <Option value="1">当天</Option>
                <Option value="2">昨天</Option>
                <Option value="3">前天</Option>
              </Select>
            </div>
            <div className="device_child_bottom">
              <div className="device_bottom_body">
                <div className="device_bottom_title">使用量</div>
                <div className="device_bottom_value">
                  {loading ? (
                    <LoadingOutlined
                      style={{
                        fontSize: "15px",
                      }}
                      spin
                    />
                  ) : (
                    useNum
                  )}
                  <span style={{ fontSize: "15px", margin: "5px 0 0 3px" }}>
                    次
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 卡二 */}
        <div className="device_info_child">
          <div className="device_child_body">
            <div className="device_child_top">
              <div>设备在线情况</div>
            </div>
            <div className="device_child_bottom">
              <div className="device_bottom_body">
                <div className="device_bottom_title">全部设备</div>
                <div style={{ display: "flex" }}>
                  <div
                    className="device_bottom_value"
                    style={{ width: "90px" }}
                  >
                    {deviceStatus.total}
                    <span style={{ fontSize: "15px", margin: "5px 0 0 3px" }}>
                      台
                    </span>
                  </div>
                </div>
              </div>
              <div className="device_bottom_body">
                <div className="device_bottom_title">在线</div>
                <div style={{ display: "flex" }}>
                  <div
                    className="device_bottom_value"
                    style={{ width: "90px" }}
                  >
                    {deviceStatus.onlineNum}
                    <span style={{ fontSize: "15px", margin: "5px 0 0 3px" }}>
                      台
                    </span>
                  </div>
                </div>
              </div>
              <div className="device_bottom_body">
                <div className="device_bottom_title">离线</div>
                <div style={{ display: "flex" }}>
                  <div
                    className="device_bottom_value"
                    style={{ width: "90px" }}
                  >
                    {deviceStatus.offLineNum}
                    <span style={{ fontSize: "15px", margin: "5px 0 0 3px" }}>
                      台
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Select
          placeholder="请选择门店"
          // allowClear
          style={{ width: 200, margin: "20px 0 -20px 0" }}
          value={shopId}
          // showSearch
          // filterOption={(i, o) => o.props.children.includes(i)}
          onChange={shopChange}
        >
          {shopList.map((v) => {
            return (
              <Option value={v.id} key={v.id}>
                {v.name}
              </Option>
            )
          })}
        </Select>
      </div>
      {/* 图表部分 */}
      <div className="device_body">
        {/* 图表1 */}
        <div className="device_echarts">
          <div className="device_echarts_title">
            <span>近7日设备平均识别率走势图</span>
            {handLookMore(1)}
          </div>
          <div id="main" className="device_echarts_body"></div>
        </div>
        {/* 图表2 */}
        <div className="device_echarts" style={{ marginLeft: "1%" }}>
          <div className="device_echarts_title">
            <span>近7日设备使用量走势图</span>
            {handLookMore(2)}
          </div>
          <div id="main1" className="device_echarts_body"></div>
        </div>
        {/* 图表3 */}
        <div className="device_echarts">
          <div className="device_echarts_title">
            <span>近7日设备搜索占比走势图</span>
            {handLookMore(3)}
          </div>
          <div id="main2" className="device_echarts_body"></div>
        </div>
        {/* 图表4 */}
        <div className="device_echarts" style={{ marginLeft: "1%" }}>
          <div className="device_echarts_title">
            <span>近7日设备TOP1点选占比走势图</span>
            {handLookMore(4)}
          </div>
          <div id="main3" className="device_echarts_body"></div>
        </div>
      </div>
    </div>
  )
}

export default Index
