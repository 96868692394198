import { Button, Table, Select, message, Card } from "antd"
import * as Congigdata from "../../services/configdata_summary"
import React, { useEffect, useState, useRef } from "react"
import styles from "./Index.module.css"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"

const { Option } = Select

function Index(props) {
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  const [dataSetList, setDataSetList] = useState([]) // 数据集合下拉列表
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [GiveParams, setGiveParams] = useState({
    timeArr: HandDefaultTime?.TotalTime,
    startDate: HandDefaultTime?.startDate,
    endDate: HandDefaultTime?.endDate,
    pageNum: 1,
    pageSize: 20,
    dataSetId: localStorage.getItem("discernmanage_id")
      ? Number(localStorage.getItem("discernmanage_id"))
      : undefined,
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  // 初始化请求接口
  useEffect(() => {
    // 数据集合下拉接口
    getDataPullList()

    // 获取表格列表数据
    setTimeout(getGoodsWeighDetail, 0)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 数据集合下拉接口
   */
  const getDataPullList = async () => {
    try {
      const { code, data, msg } = await Congigdata.DataPullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDataSetList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  //时间选择限制30天
  // const disabledDate = (current) => {
  //   if (!timeArr) {
  //     return false
  //   }
  //   const tooLate = timeArr[0] && current.diff(timeArr[0], "days") >= 30
  //   const tooEarly = timeArr[1] && timeArr[1].diff(current, "days") >= 30
  //   return !!tooEarly || !!tooLate
  // }

  //获取表格列表数据
  const getGoodsWeighDetail = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const params = {
        ...GiveParamsRef,
        timeArr: null,
      }
      const { code, msg, data } = await Congigdata.MergeLog(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getGoodsWeighDetail, 0)
      },
    }
    return paginationProps
  }

  const sourceDevices = (value) => {
    return value.map((v) => {
      return <p>{v.shopName + " / " + v.cdKeyCode}</p>
    })
  }

  const columns = [
    {
      title: "合并时间",
      dataIndex: "createAt",
      ellipsis: { showTitle: true },
      key: "createAt",
    },
    {
      title: "数据汇总名称",
      dataIndex: "dataSetName",
      ellipsis: { showTitle: true },
      key: "dataSetName",
    },
    {
      title: "数据类型",
      dataIndex: "type",
      ellipsis: { showTitle: true },
      key: "type",
      render: (info) => {
        return info == 1 ? "学习数据" : info == 2 ? "icon数据" : ""
      },
    },
    // {
    //   title: "全部设备",
    //   dataIndex: "sourceDevices",
    //   ellipsis: { showTitle: true },
    //
    //   key: "sourceDevices",
    //   render: (info) => {
    //     return (
    //       <Tooltip title={() => sourceDevices(info)}>
    //         <a>{info.length}</a>
    //       </Tooltip>
    //     )
    //   },
    // },
    {
      title: "生效的数据来源",
      dataIndex: "mergeDevice",
      ellipsis: { showTitle: true },
      key: "mergeDevice",
      render: (info) => {
        return info ? info.shopName + "/" + info.cdKeyCode : ""
      },
    },
  ]

  //查询
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }
  //重置
  const handClear = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      timeArr: HandDefaultTime?.TotalTime,
      startDate: HandDefaultTime?.startDate,
      endDate: HandDefaultTime?.endDate,
      // dataSetId: undefined,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }

  return (
    <div className={styles.merge_records_page}>
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.merge_records_page_search}>
          <div className={styles.merge_records_search_body}>
            <div className={styles.merge_records_search_body_child}>
              <span>操作日期：</span>
              <PubRangePicker
                value={GiveParams?.timeArr}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    timeArr: val.TotalTime,
                    startDate: val.startDate,
                    endDate: val.endDate,
                  })
                }}
              />
            </div>

            <div className={styles.merge_records_search_body_child}>
              <span>数据汇总：</span>
              <Select
                style={{ width: 200 }}
                placeholder="请选择数据汇总"
                value={GiveParams.dataSetId}
                onChange={(e) => {
                  setGiveParams({ ...GiveParams, dataSetId: e })
                }}
              >
                {dataSetList.map((v) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <div className={styles.merge_records_search_button}>
            <Button onClick={() => handClear()}>重置</Button>
            <Button
              type="primary"
              style={{ marginLeft: "20px" }}
              onClick={() => handSearch()}
            >
              查询
            </Button>
          </div>
        </div>
      </Card>
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          scroll={{ y: `${WinHeight - 430}px` }}
        />
      </Card>
    </div>
  )
}

export default Index
