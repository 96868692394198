import React, { useEffect, useState, useRef } from "react"
import * as ShopmanageDevice from "../../services/goodmanage"
import PubInput from "../../../components/PubInput"
import { useHistory } from "react-router-dom"
import styles from "./Index.module.css"
import dayjs from "dayjs"
import {
  Card,
  Button,
  Cascader,
  Table,
  Modal,
  message,
  Image,
  Upload,
  Spin,
  Tooltip,
} from "antd"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"
import {
  CloudUploadOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons"
const { Dragger } = Upload
/**
 * 商品大小码管理
 */
function Index(props) {
  let history = useHistory()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [shopList, setShopList] = useState([]) //门店设备列表
  const [dataList, setDataList] = useState([]) //表格列表
  const [total, setTotal] = useState(0) //每页显示的行数
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false) // 导入商品大小码数据Modal
  const [uploadLoading, setUploadLoading] = useState(false) // 上传文件loading
  const [suspensionIndex, setSuspensionIndex] = useState("999999999999") // 鼠标悬浮时的下标

  const [GiveParams, setGiveParams] = useState({
    timeArr: [
      dayjs(HandDefaultTime?.OneDayStaDatStr),
      dayjs(HandDefaultTime?.OneDayEndDatStr),
    ],
    startDate: HandDefaultTime?.OneDayStaDatStr,
    endDate: HandDefaultTime?.OneDayEndDatStr,
    pageNum: 1,
    pageSize: 20,
    shopId: undefined,
    deviceSn: undefined,
    skuName: undefined,
    filesList: [], // 上传的数组列表
    filesListName: [], // 上传的数组列表名
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    //调用获取表格列表数据
    setTimeout(getTableList, 0)
    //调用门店设备
    getShopTreeList()
  }, [])

  /**
   * 自定义表格高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 门店设备列表
   */
  const getShopTreeList = async (value) => {
    try {
      const params = value
      // const params = {
      //   code: undefined,
      //   districtId: undefined,
      // }
      console.log(params)
      const res = await ShopmanageDevice.ShopTreeList(params)
      if (!res) {
        return false
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      console.log(data)
      data &&
        data.map((v) => {
          v.value = v.shopId
          v.label = v.name
          v.deviceList &&
            v.deviceList.map((w) => {
              w.value = w.deviceSn
              w.label = w.cdKeyCode
            })

          v.children = v.deviceList
        })
      // 合并原始数组与查询出的数组，并去重
      const NewArr = data.concat(shopList)
      const obj = {}
      let NewArr1 = []
      for (let i = 0; i < NewArr.length; i++) {
        if (!obj[NewArr[i]["shopId"]]) {
          obj[NewArr[i]["shopId"]] = 1
          NewArr1.push(NewArr[i])
        }
      }
      setShopList(NewArr1)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取表格列表数据
   */
  const getTableList = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const params = {
        shopId: GiveParamsRef.shopId,
        deviceSn: GiveParamsRef.deviceSn,
        code: GiveParamsRef.skuName,
        startDate: GiveParamsRef.startDate,
        endDate: GiveParamsRef.endDate,
        pageNum: GiveParamsRef.pageNum,
        pageSize: GiveParamsRef.pageSize,
      }
      const { code, msg, data } = await ShopmanageDevice.size_goodslog_list(
        params
      )
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  /**
   * 导出接口
   */
  const onExportDevice = async () => {
    try {
      if (dataList.length == 0) {
        message.error("没有可导出的数据")
        return
      }
      const { GiveParamsRef } = updataRef.current
      const params = {
        shopId: GiveParamsRef.shopId,
        deviceSn: GiveParamsRef.deviceSn,
        code: GiveParamsRef.skuName,
        startDate: GiveParamsRef.startDate,
        endDate: GiveParamsRef.endDate,
      }
      const { code, msg, data } = await ShopmanageDevice.size_goodslog_export(
        params
      )
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 查询
   */
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getTableList, 0)
  }

  /**
   * 重置
   */
  const handClear = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      timeArr: [
        dayjs(HandDefaultTime?.OneDayStaDatStr),
        dayjs(HandDefaultTime?.OneDayEndDatStr),
      ],
      startDate: HandDefaultTime?.OneDayStaDatStr,
      endDate: HandDefaultTime?.OneDayEndDatStr,
      shopId: undefined,
      deviceSn: undefined,
      skuName: undefined,
    })
    setTimeout(getTableList, 0)
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getTableList, 0)
      },
    }
    return paginationProps
  }
  /**
   * 查看导出记录
   */
  const onExportFile = () => {
    history.push(`/admin/goodsmanage/lookimportrecords`)
  }

  /**
   * 文件上传
   */
  const uploadInfo = {
    name: "file",
    multiple: false,
    accept: ".xlsx,.xls",
    maxCount: 1,
    beforeUpload: (file, fileList) => {
      setUploadLoading(true)
      console.log("文件上传", file)
      const { filesList, filesListName } = GiveParams
      if (filesList.length == 1) {
        // message.error("最大上传一个文件")
        filesList.splice(0, 1)
        filesListName.splice(0, 1)
        setUploadLoading(false)
        // return
      }
      filesList.push(file)
      filesListName.push(file.name)
      setGiveParams({
        ...GiveParams,
        filesList,
        filesListName,
      })
      setTimeout(() => {
        setUploadLoading(false)
      }, 1000)
      return false
    },
    onDrop(e) {
      const newFiles = e.dataTransfer.files[0]
      console.log("拖拽上传", newFiles)
      const { filesList, filesListName } = GiveParams
      if (filesList.length == 1) {
        // message.error("最大上传一个文件")
        filesList.splice(0, 1)
        filesListName.splice(0, 1)
        setUploadLoading(false)
        // return
      }
      filesList.push(newFiles)
      filesListName.push(newFiles.name)
      setGiveParams({
        ...GiveParams,
        filesList,
        filesListName,
      })
      return false
    },
  }

  /**
   * 文件上传删除时的鼠标悬浮
   */
  const onSuspension = (e) => {
    setSuspensionIndex(e)
  }
  /**
   * 文件删除
   */
  const onFileDelete = (e) => {
    const { filesList, filesListName } = GiveParams
    filesList.splice(e, 1)
    filesListName.splice(e, 1)
    setGiveParams({
      ...GiveParams,
      filesList,
      filesListName,
    })
  }
  /**
   * 打开上传文件弹窗
   */
  const onInportFile = () => {
    setGiveParams({
      ...GiveParams,
      filesList: [],
      filesListName: [],
    })
    setShowModal(true)
  }
  /**
   * 确定
   */
  const handleOk = async () => {
    const { filesList, filesListName } = GiveParams
    try {
      if (filesList.length == 0) {
        message.error("请上传文件")
        return
      }
      const params = {
        file: filesList[0],
      }
      const res = await ShopmanageDevice.import_size_goods(params)
      if (res && res?.code != 200) {
        message.error(res.msg)
        return
      }
      if (res?.data) {
        setShowModal(false)
        message.success("导入成功")
      }
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }
  /**
   * 取消
   */
  const handleCancel = () => {
    setShowModal(false)
  }

  const columns = [
    {
      title: "称重日期",
      dataIndex: "weightTime",
      ellipsis: { showTitle: true },
      key: "weightTime",
      width: 200,
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 150,
    },
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 150,
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },
      key: "scaleNo",
      width: 150,
    },
    {
      title: "主商品条码",
      dataIndex: "spuBarCode",
      ellipsis: { showTitle: true },
      key: "spuBarCode",
      width: 150,
    },
    {
      title: "主商品代码",
      dataIndex: "spuCode",
      ellipsis: { showTitle: true },
      key: "spuCode",
      width: 150,
    },
    {
      title: "主商品名称",
      dataIndex: "spuName",
      ellipsis: { showTitle: true },
      key: "spuName",
      width: 150,
    },
    {
      title: "主商品重量（g）",
      dataIndex: "saleWeight",
      ellipsis: { showTitle: true },
      key: "saleWeight",
      width: 150,
    },
    {
      title: "子商品名称",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },
      key: "skuName",
      width: 150,
    },
    {
      title: "子商品条码",
      dataIndex: "skuBarCode",
      ellipsis: { showTitle: true },
      key: "skuBarCode",
      width: 150,
    },
    {
      title: "子商品代码",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },
      key: "skuCode",
      width: 150,
    },
    {
      title: "子商品数量",
      dataIndex: "skuSaleNum",
      ellipsis: { showTitle: true },
      key: "skuSaleNum",
      width: 150,
    },
    {
      title: "称重照片",
      dataIndex: "imageUrl",
      ellipsis: { showTitle: true },
      key: "imageUrl",
      width: 150,
      render: (info) => {
        return (
          <div className={styles.code_size_table_img}>
            <Image src={info} />
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <Card>
        <div className={styles.code_size_head}>
          <div className={styles.code_size_head_body}>
            <div className={styles.code_size_head_search_body_child}>
              <span>称重时间：</span>
              <PubRangePicker
                value={GiveParams?.timeArr}
                disDate={30}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    timeArr: val.TotalTime,
                    startDate: val.startDate,
                    endDate: val.endDate,
                  })
                }}
              />
            </div>
            <div className={styles.code_size_head_search_body_child}>
              <span>门店/设备：</span>
              <Cascader
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                showSearch
                style={{ width: 220 }}
                changeOnSelect
                options={shopList}
                placeholder="请选择门店/设备"
                value={
                  GiveParams.shopId && GiveParams.deviceSn
                    ? [GiveParams.shopId, GiveParams.deviceSn]
                    : GiveParams.shopId
                    ? [GiveParams.shopId]
                    : []
                }
                onSearch={(e) => {
                  if (e.length >= 2) {
                    getShopTreeList(e)
                  }
                }}
                onChange={(e) => {
                  if (e) {
                    setGiveParams({
                      ...GiveParams,
                      shopId: e[0] ? e[0] : "",
                      deviceSn: e[1] ? e[1] : "",
                    })
                  } else {
                    setGiveParams({
                      ...GiveParams,
                      shopId: undefined,
                      deviceSn: undefined,
                    })
                  }
                }}
              />
            </div>
            <div className={styles.code_size_head_search_body_child}>
              <span>主或子商品名称/条码/代码：</span>
              <PubInput
                onChange={(val) =>
                  setGiveParams({
                    ...GiveParams,
                    skuName: val,
                  })
                }
                width="250px"
                placeholder="主或子商品名称/条码/代码"
                value={GiveParams.skuName}
              />
            </div>
          </div>
          <div className={styles.code_size_head_search_button_record}>
            <div>
              <Button type="primary" onClick={() => handSearch()}>
                查询
              </Button>
              <Button onClick={() => handClear()}>重置</Button>
            </div>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <div className={styles.code_size_buttons}>
          <div className={styles.code_size_buttons_left}>
            <Button type="primary" onClick={onInportFile}>
              导入商品大小码数据
            </Button>
            <Button
              className={styles.code_size_buttons_left_onbtn}
              onClick={onExportFile}
            >
              查看导入记录
            </Button>
            <Button
              type="link"
              href="https://static.yoyo.link/template/%E5%A4%A7%E5%B0%8F%E7%A0%81%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx"
            >
              下载模版
            </Button>
          </div>
          <div>
            <Button type="primary" onClick={() => onExportDevice()}>
              导出数据
            </Button>
          </div>
        </div>

        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          scroll={{
            x: "1000px",
            y: `${WinHeight - 425}px`,
          }}
        />
      </Card>
      <Modal
        title="导入商品大小码数据"
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        keyboard
        width={600}
      >
        <div className={styles.code_size_modal}>
          <div className={styles.code_size_modal_child}>
            <div className={styles.code_size_modal_child_doladbtn}>
              <Button
                type="link"
                href="https://static.yoyo.link/template/%E5%A4%A7%E5%B0%8F%E7%A0%81%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx"
              >
                下载
              </Button>
            </div>
            <Dragger {...uploadInfo}>
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined style={{ color: "#A8ABB2" }} />
              </p>
              <p className="ant-upload-text">
                将文件拖到此处，或<a>点击导入</a>
              </p>
            </Dragger>
            <div className={styles.code_size_modal_child_span}>
              只能上传 xls/xlsx 文件，为了保证导入的效率，建议一次准备500行
              数据，确保条码的统一，不唯一会导致数据错乱
            </div>
            <div className={styles.code_size_filelist}>
              {GiveParams.filesListName.map((v, i) => {
                return (
                  <div
                    className={styles.code_size_filelist_child}
                    onMouseEnter={() => onSuspension(i)}
                    onMouseLeave={() => setSuspensionIndex("999999999999")}
                    style={{
                      background:
                        suspensionIndex == i ? "rgb(245, 245, 244)" : "",
                    }}
                  >
                    <div className={styles.code_size_filelist_child_title}>
                      {v}
                    </div>
                    {suspensionIndex == i ? (
                      <CloseCircleFilled
                        style={{
                          fontSize: "15px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => onFileDelete(i)}
                      />
                    ) : uploadLoading &&
                      i == GiveParams.filesListName.length - 1 ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: "10px" }} spin />
                        }
                      />
                    ) : (
                      <CheckCircleFilled
                        style={{ fontSize: "15px", color: "#39BE87" }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Index
