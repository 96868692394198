import React, { useEffect, useState, useRef } from "react"
import {
  Select,
  Button,
  Table,
  Modal,
  message,
  Form,
  Card,
  Cascader,
  Tooltip,
  TreeSelect,
} from "antd"
import * as DeviceServices from "../../services/device_manage"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import PubInput from "../../../components/PubInput"
import styles from "./Index.module.css"

const { Option } = Select

/**
 * 设备列表
 */
function Index() {
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度

  //
  const [EditDeviceOpen, setEditDeviceOpen] = useState(false) // 编辑设备打开Modal
  const [FormValuie, setFormValuie] = useState({
    scaleNo: undefined,
    id: undefined,
    shopId: undefined,
    regionId: undefined,
  }) // 设备门店时的回显
  //监控设置
  const [form] = Form.useForm()
  const history = useHistory()
  //
  const [nameValue, setNameValue] = useState() // 1:海康，2：TP-LINK
  const [typeNum, setTypeNum] = useState("") // 1:海康，2：TP-LINK
  //
  const [monitorStatus, setMonitorStatus] = useState(false) // 监控Modal状态
  //
  const [editValue, setEditValue] = useState({})

  //排序类型
  const [sortedInfo, setSortedInfo] = useState({})
  //
  const [isModalOpen, setIsModalOpen] = useState(false) //modal弹窗状态
  const [shopList, setShopList] = useState({}) // 门店列表数据（父级）
  const [loading, setLoading] = useState(false) // 门店列表loading
  const [account, setAccount] = useState("") // 添加门店账号的value
  const [shopId, setShopId] = useState("") // 门店id（--------用来做门店账号添加的----------）
  const [modaltext, setModaltext] = useState("") // 动态添加/编辑Modal-title
  const [DeciverList, setDeciverList] = useState([]) // 产品线列表
  const [PullList, setPullList] = useState([]) // 门店下拉
  const [ShopListAuth, setShopListAuth] = useState([]) // 获取数据权限下的店内区域列表
  const [ShopListAuth1, setShopListAuth1] = useState([]) // 获取数据权限下的店内区域列表
  const [addressOptions, setAddressOptions] = useState([]) // 省市区树
  const [shopAuthList, setShopAuthList] = useState([]) // 门店权限列表
  const [districtAuthList, setDistrictAuthList] = useState([]) // 区域权限列表
  const [dataType, setDataType] = useState(undefined) // 数据类型（0：全部数据，1：按门店，2：按区域）

  const [ParamsInfo, setParamsInfo] = useState({
    ProvinceCityRegion: [], //province省,city市,region区
    keyCode: "",
    pullValue: window.localStorage.getItem("shopId")
      ? Number(window.localStorage.getItem("shopId"))
      : "",
    productValue: [],
    shopId: "", //门店id
    scaleNo: "",
    online: "", //在线状态（0：离线,1：在线）
    code: "",
    pageNum: 1,
    pageSize: 20,
    orderType: undefined, // 排序传值
    regionId: undefined, // 店内区域ID
    changeType: undefined,
    changeTypeChild: undefined,
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])
  //

  useEffect(() => {
    getPullList() //门店下拉列表
    getTreeList() //产品线类型树
    getAreaTree() // 省市区树
    getDataAuth() // 当前项目的数据权限信息
    setTimeout(getShopList, 0)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 当前项目的数据权限信息
   */
  const getDataAuth = async () => {
    try {
      const res = await DeviceServices.Data_Auth()
      if (!res) {
        return false
      }
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      console.log(res?.data)
      setDataType(res?.data?.dataType == 0 ? "0" : res?.data?.dataType)
      setDistrictAuthList(res?.data?.districtAuthList)
      setShopAuthList(res?.data?.shopAuthList)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const handlSearch = () => {
    //点击查询时将子集关闭，排除数据污染
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    setTimeout(getShopList, 0)
  }

  /**
   * 重置
   */
  const handClear = async () => {
    window.localStorage.removeItem("shopId")
    setParamsInfo({
      keyCode: "",
      pullValue: window.localStorage.getItem("shopId")
        ? Number(window.localStorage.getItem("shopId"))
        : "",
      ProvinceCityRegion: [], //province省,city市,region区
      productValue: [],
      shopId: "", //门店id
      scaleNo: "",
      online: "", //在线状态（0：离线,1：在线）
      code: "",
      pageNum: 1,
      pageSize: 20,
      orderType: undefined, // 排序传值
      regionId: undefined, // 店内区域ID
      changeType: undefined,
      changeTypeChild: undefined,
    })
    setSortedInfo("")
    setTimeout(getShopList, 0)
  }

  /**
   * 门店下拉列表
   */
  const getPullList = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 }
      const { code, data, msg } = await DeviceServices.Pull_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setPullList(data.list || [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取数据权限下的店内区域列表
   */
  const getShopListUnderAuth = async (shopId) => {
    try {
      const params = { shopId }
      const { code, data, msg } = await DeviceServices.ShopListUnderAuth(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setShopListAuth(data || [])
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 获取数据权限下的店内区域列表1
   */
  const getShopListUnderAuth1 = async (shopId) => {
    try {
      const params = { shopId }
      const { code, data, msg } = await DeviceServices.ShopListUnderAuth(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setShopListAuth1(data || [])
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 产品线类型树
   */
  const getTreeList = async () => {
    try {
      const { code, data, msg } = await DeviceServices.Tree_List()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.productLineTypeId = v.productLineId
          v.productLineTypeName = v.productLineName
        })
      }
      setDeciverList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 省市区树
   */
  const getAreaTree = async () => {
    try {
      const { code, data, msg } = await DeviceServices.Area_Tree()
      if (code != 200) {
        message.error(msg)
        return
      }
      setAddressOptions(data || [])
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 门店下设备列表
   */
  const getShopList = async () => {
    const { ParamsInfoRef } = refInfo?.current
    const params = {
      ...ParamsInfoRef,
      province:
        ParamsInfoRef.ProvinceCityRegion.length > 0
          ? ParamsInfoRef.ProvinceCityRegion[0] || ""
          : "",
      city:
        ParamsInfoRef.ProvinceCityRegion.length > 0
          ? ParamsInfoRef.ProvinceCityRegion[1] || ""
          : "",
      region:
        ParamsInfoRef.ProvinceCityRegion.length > 0
          ? ParamsInfoRef.ProvinceCityRegion[2] || ""
          : "",
      productLineId:
        ParamsInfoRef.productValue.length > 0
          ? ParamsInfoRef.productValue[0] || ""
          : "",
      productLineTypeId:
        ParamsInfoRef.productValue.length > 0
          ? ParamsInfoRef.productValue[1] || ""
          : "",
    }
    try {
      setLoading(true)
      const { code, data, msg } = await DeviceServices.Devices_List(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setShopList(data)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setShopList([])
    }
  }

  // 打开modal
  const showModal = (info) => {
    const { shopId, shopAccount } = info
    //判断，当shopAccount有值时为门店账号编辑，否则为添加
    if (shopAccount) {
      setModaltext("编辑")
      setAccount(shopAccount) //编辑时，数据回显
    } else {
      setModaltext("添加")
      setAccount("") //添加门店账号时，保险起见先清除一次输入框
    }
    //存储数据的shopid
    setShopId(shopId || "")
    setIsModalOpen(true)
  }
  //modal确认按钮
  const handleOk = () => {
    if (account.length < 11) {
      message.error("请输入11位门店账号")
      return false
    }
    const params = {
      account: account,
      shopId: shopId,
    }
    DeviceServices.Add_Account(params).then((res) => {
      if (res.code == 200 && res.data) {
        setShopId("") //清除shopid
        setAccount("") //清除门店账号
        setTimeout(getShopList, 0)
        setIsModalOpen(false)
        message.success(`门店账号${modaltext}成功`)
      }
    })
  }
  //关闭modal
  const handleCancel = () => {
    setShopId("") //清除shopid
    setAccount("") //清除门店账号
    setModaltext("") //清除modal-title
    setIsModalOpen(false)
  }

  /**
   * 编辑设备，打开Modal
   */
  const onHandOpenModal = (info) => {
    setEditDeviceOpen(true)
    getShopListUnderAuth(info.shopId || undefined)
    setFormValuie({
      ...FormValuie,
      shopId: info.shopId,
      regionId: info.regionId, // 所属区域
      scaleNo: info.scaleNo,
      id: info.deviceId,
    })
  }

  /**
   * 编辑设备，Modal确认按钮
   */
  const onSubmit = async () => {
    try {
      console.log(FormValuie)
      if (!FormValuie.scaleNo) {
        message.warning("请输入秤号")
        return
      }
      if (!FormValuie.shopId) {
        message.warning("请选择门店")
        return
      }
      const { code, msg, data } = await DeviceServices.Edit_Device(FormValuie)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("设备编辑成功")
        setEditDeviceOpen(false)
        setFormValuie({
          scaleNo: undefined,
          id: undefined,
          shopId: undefined,
        })
        setTimeout(getShopList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 打开监控设置Modal
   */
  const onOpenModal = async () => {
    try {
      const { code, data, msg } = await DeviceServices.New_Monito()
      if (code != 200) {
        message.error(msg)
        return
      }
      form.resetFields()
      setMonitorStatus(true)
      setNameValue(data.type) // 判断name1是海康还是name2TP-LINK
      setEditValue(data)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监控设置Modal确认按钮
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (editValue?.id) {
          values.id = editValue?.id
          DeviceServices.Edit_Monito(values).then((res) => {
            if (res.code != 200) {
              return
            }
            setMonitorStatus(false)
            if (res.data) {
              message.success("监控设置成功")
              //调用查询门店下的主账号接口
              setMonitorStatus(false)
            }
          })
        } else {
          DeviceServices.Add_Monitor(values).then((res) => {
            if (res.code != 200) {
              return
            }
            setMonitorStatus(false)
            if (res.data) {
              message.success("监控设置成功")
              //调用查询门店下的主账号接口
              setMonitorStatus(false)
            }
          })
        }
      })
      .catch((err) => {
        // 校验失败
      })
  }

  /**
   * 导出数据
   */
  const onExport = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
        province:
          ParamsInfoRef.ProvinceCityRegion.length > 0
            ? ParamsInfoRef.ProvinceCityRegion[0] || ""
            : "",
        city:
          ParamsInfoRef.ProvinceCityRegion.length > 0
            ? ParamsInfoRef.ProvinceCityRegion[1] || ""
            : "",
        region:
          ParamsInfoRef.ProvinceCityRegion.length > 0
            ? ParamsInfoRef.ProvinceCityRegion[2] || ""
            : "",
        productLineId:
          ParamsInfoRef.productValue.length > 0
            ? ParamsInfoRef.productValue[0] || ""
            : "",
        productLineTypeId:
          ParamsInfoRef.productValue.length > 0
            ? ParamsInfoRef.productValue[1] || ""
            : "",
        ProvinceCityRegion: "",
        productValue: "",
      }
      const res = await DeviceServices.Export_Device(params)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  //父级，门店列表接口
  const columns = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },
      key: "scaleNo",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "状态",
      dataIndex: "online",
      ellipsis: { showTitle: true },
      key: "online",
      width: 100,
      render: (info) => {
        return (
          <>
            {info == 0 ? (
              <div style={{ color: "red" }}>离线</div>
            ) : info == 1 ? (
              <div style={{ color: "#2EB998" }}>在线</div>
            ) : (
              "-"
            )}
          </>
        )
      },
    },
    {
      title: "称重笔数",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的称重笔数">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "outputNum",
      ellipsis: { showTitle: true },
      key: "outputNum",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "outputNum" ? sortedInfo?.order : null,
      render: (info) => {
        return <>{info ? info : "0"}</>
      },
    },
    {
      title: "总识别率",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的总识别率">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "aiOkRate",
      ellipsis: { showTitle: true },
      key: "aiOkRate",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "aiOkRate" ? sortedInfo?.order : null,
      render: (info) => {
        return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
      },
    },
    {
      title: "首位识别率",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的首位识别率">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "top1",
      ellipsis: { showTitle: true },
      key: "top1",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo?.columnKey === "top1" ? sortedInfo?.order : null,
      render: (info) => {
        return <>{info > 0 ? (info * 100).toFixed(2) + " %" : "0%"}</>
      },
    },
    {
      title: "搜索占比",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的搜索占比">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "optSearchRate",
      ellipsis: { showTitle: true },
      key: "optSearchRate",
      width: 150,
      render: (info) => {
        return <>{info > 0 ? (info * 100).toFixed(2) + " %" : "0%"}</>
      },
    },
    {
      title: "设备激活码",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",
      width: 150,
      ellipsis: { showTitle: true },
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "设备IP地址",
      dataIndex: "ip",
      ellipsis: { showTitle: true },
      key: "ip",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "设备SN",
      dataIndex: "deviceSn",
      ellipsis: { showTitle: true },
      key: "deviceSn",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "产品线 - 类型",
      dataIndex: "productLineName",
      ellipsis: { showTitle: true },
      key: "productLineName",
      width: 150,
      render: (info, rowInfo) => {
        return (
          <>
            {rowInfo.productLineName} - {rowInfo.productLineTypeName}
          </>
        )
      },
    },
    {
      title: "版本号",
      dataIndex: "systemVersionName",
      ellipsis: { showTitle: true },
      key: "systemVersionName",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "地理位置",
      dataIndex: "address",
      ellipsis: { showTitle: true },
      key: "address",
      width: 200,
      render: (info) => {
        return (
          <div className={styles.device_table_info}>
            <Tooltip title={info} placement="topLeft">
              <span>{info ? info : "-"}</span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: "使用区位",
      dataIndex: "regionName",
      ellipsis: { showTitle: true },
      key: "regionName",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "最近同步时间",
      dataIndex: "dataSyncDate",
      ellipsis: { showTitle: true },
      key: "dataSyncDate",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      render: (info) => {
        // console.log(info)
        return (
          <div className={styles.devicemanage_page_store_table_btn}>
            <a
              onClick={() => {
                history.push(
                  `/admin/devicemanage/lookGoods?deviceSn=${info?.deviceSn}$shopId=${info?.shopId}`
                )
              }}
            >
              查看商品
            </a>
            <a onClick={() => onHandOpenModal(info)}>编辑设备</a>
            <a
              onClick={() => {
                history.push(
                  `/admin/devicemanage/bindingCamera?deviceSn=${info?.deviceSn}$cdKeyCode=${info?.cdKeyCode}$scaleNo=${info?.scaleNo}`
                )
              }}
            >
              绑定监控设备
            </a>
            {/* <a
              type="link"
              style={{ color: "#19BC89" }}
              onClick={() => onHandOpenModal(info)}
            >
              编辑设备
            </a> */}
          </div>
        )
      },
    },
  ]

  /**
   * 排序
   */
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)

    // ascend:正序      descend：倒序
    if (sorter.field === "outputNum") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "aiOkRate") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 3 : sorter.order === "descend" ? 4 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "top1") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 6 : sorter.order === "descend" ? 5 : "",
        pageNum: pagination.current,
      })
    }
    if (!sorter.field) {
      setParamsInfo({
        ...ParamsInfo,
        pageNum: pagination.current,
      })
    }

    setTimeout(getShopList, 0)
  }
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${shopList?.total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: shopList?.total,
    }
    return paginationProps
  }
  return (
    <div>
      <Card>
        <div className={styles.device_list_head}>
          <div className={styles.device_list_head_search}>
            {dataType == 2 ? (
              <div className={styles.device_list_head_search_input_or_select}>
                区域选择：
                <Select
                  allowClear
                  style={{ width: 200 }}
                  value={ParamsInfo?.changeTypeChild || undefined}
                  placeholder="请选择区域名称"
                  onChange={(e) => {
                    setParamsInfo({ ...ParamsInfo, changeTypeChild: e })
                  }}
                >
                  {districtAuthList &&
                    districtAuthList.map((v) => {
                      return (
                        <Option value={v.districtId} key={v.districtId}>
                          {v.pathName && v.districtName
                            ? v.pathName + "/" + v.districtName
                            : !v.pathName && v.districtName
                            ? v.districtName
                            : v.pathName && !v.districtName
                            ? v.pathName
                            : ""}
                        </Option>
                      )
                    })}
                </Select>
              </div>
            ) : null}

            <div className={styles.device_list_head_search_input_or_select}>
              门店名称：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                style={{ width: 200 }}
                placeholder="请选择所属门店"
                allowClear
                value={ParamsInfo.shopId || undefined}
                onChange={(e) => {
                  if (!e) {
                    setParamsInfo({
                      ...ParamsInfo,
                      shopId: e || undefined,
                      regionId: undefined,
                    })
                    setShopListAuth1([])
                    return
                  }
                  //清除门店管理点击设备数跳转设备管理的门店id
                  window.localStorage.removeItem("shopId")
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                    regionId: undefined,
                  })
                  getShopListUnderAuth1(e)
                }}
              >
                {PullList.map((v) => {
                  return (
                    <Option value={v.shopId} key={v.shopId}>
                      {v.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              使用区位：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属区位"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo?.regionId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({ ...ParamsInfo, regionId: e || undefined })
                }}
              >
                {ShopListAuth1 &&
                  ShopListAuth1.map((item, index) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        {item?.name}
                      </Option>
                    )
                  })}
              </Select>
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              地理位置：
              <Cascader
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择地理位置"
                style={{ width: 200 }}
                fieldNames={{
                  label: "name",
                  value: "name",
                  children: "areaListRspList",
                }}
                options={addressOptions}
                value={ParamsInfo.ProvinceCityRegion}
                allowClear
                changeOnSelect
                onChange={(e) =>
                  setParamsInfo({ ...ParamsInfo, ProvinceCityRegion: e || "" })
                }
              />
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              秤号/设备SN/激活码：
              <PubInput
                onChange={(val) => setParamsInfo({ ...ParamsInfo, code: val })}
                width="220px"
                placeholder="秤号/设备SN/激活码"
                value={ParamsInfo.code}
              />
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              产品线：
              <Cascader
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择产品线/类型"
                style={{ width: 200 }}
                fieldNames={{
                  label: "productLineTypeName",
                  value: "productLineTypeId",
                  children: "productLineTypeList",
                }}
                options={DeciverList}
                value={ParamsInfo.productValue || []}
                allowClear
                changeOnSelect
                onChange={(e) =>
                  setParamsInfo({ ...ParamsInfo, productValue: e || "" })
                }
              />
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              状态：
              <Select
                placeholder="请选择状态"
                style={{ width: 200 }}
                value={ParamsInfo.online || undefined}
                allowClear
                onChange={(e) => {
                  if (e) {
                    setParamsInfo({ ...ParamsInfo, online: `${e}` || "" })
                  } else {
                    setParamsInfo({ ...ParamsInfo, online: undefined })
                  }
                }}
                options={[
                  { value: "1", label: "在线" },
                  { value: "0", label: "离线" },
                ]}
              />
            </div>
          </div>
          <div className={styles.device_list_head_right}>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
            <Button onClick={handClear}>重置</Button>
          </div>
        </div>
      </Card>

      <Card style={{ marginTop: "16px" }}>
        {/* 导出，监控设置 */}
        <div className={styles.device_list_caozo_btn}>
          {/* <Button type="primary" icon={<ToTopOutlined />}>
          导出
        </Button> */}
          {/* <Button type="primary" onClick={() => onOpenModal()}>
            监控设置
          </Button> */}
          <Button type="primary" onClick={onExport}>
            导出数据
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={shopList?.list ? shopList?.list : []}
            rowKey="deviceId"
            loading={loading}
            scroll={{ x: "max-content", y: `${WinHeight - 470}px` }}
            onChange={handleChange}
            // style={{ minHeight: `${WinHeight - 350}px` }}
          />
        </div>
      </Card>
      {/* 监控设置 */}

      <Modal
        title="监控设置"
        open={monitorStatus}
        onOk={onFinish}
        destroyOnClose
        onCancel={() => {
          setMonitorStatus(false)
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="摄像头品牌"
            name="type"
            initialValue={editValue?.type || undefined}
            rules={[
              {
                required: true,
                message: "摄像头品牌不能为空!",
              },
            ]}
          >
            <Select
              width={200}
              allowClear
              placeholder="请选择摄像头品牌！"
              onChange={(e) => {
                setNameValue(e)
              }}
            >
              {/* <Option key={1} value={1}>
                海康
              </Option> */}
              <Option key={2} value={2}>
                TP-LINK
              </Option>
            </Select>
          </Form.Item>

          {nameValue == 1 ? (
            <Form.Item
              label="IP"
              name="ip"
              initialValue={editValue?.ip || ""}
              rules={[
                {
                  required: true,
                  message: "IP不能为空!",
                },
              ]}
            >
              <PubInput placeholder="IP！" max={15} />
            </Form.Item>
          ) : null}
          {nameValue == 1 ? (
            <Form.Item
              label="端口"
              name="port"
              initialValue={editValue?.port || ""}
              rules={[
                {
                  required: true,
                  message: "端口不能为空!",
                },
              ]}
            >
              <PubInput placeholder="端口！" max={15} />
            </Form.Item>
          ) : null}

          {nameValue == 2 ? (
            <Form.Item
              label="客户端ID"
              name="terminalId"
              initialValue={editValue?.terminalId || ""}
              rules={[
                {
                  required: true,
                  message: "客户端ID不能为空!",
                },
              ]}
            >
              <PubInput placeholder="客户端ID！" max={30} />
            </Form.Item>
          ) : null}

          <Form.Item
            label="appKey"
            name="appKey"
            initialValue={editValue?.appKey || ""}
            rules={[
              {
                required: true,
                message: "appKey不能为空!",
              },
            ]}
          >
            <PubInput placeholder="appKey！" max={50} />
          </Form.Item>
          <Form.Item
            label="secret"
            name="secret"
            initialValue={editValue?.secret || ""}
            rules={[
              {
                required: true,
                message: "secret不能为空!",
              },
            ]}
          >
            <PubInput placeholder="secret！" max={50} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 编辑设备 */}
      <Modal
        title="编辑设备"
        open={EditDeviceOpen}
        keyboard
        maskClosable
        onOk={() => onSubmit()}
        onCancel={() => {
          setEditDeviceOpen(false)
        }}
      >
        <div className={styles.device_list_modal_edit_device}>
          <div className={styles.device_list_modal_edit_device_child}>
            <div className={styles.device_list_modal_edit_device_childname}>
              秤号： <span>*</span>
            </div>
            <PubInput
              onChange={(val) => setFormValuie({ ...FormValuie, scaleNo: val })}
              placeholder="秤号"
              value={FormValuie?.scaleNo}
              max={20}
              showCount={true}
            />
          </div>
          <div className={styles.page_store_select}>
            <div className={styles.device_list_modal_edit_device_child}>
              <div className={styles.device_list_modal_edit_device_childname}>
                所属门店： <span>*</span>
              </div>
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属门店"
                style={{ width: 200 }}
                allowClear
                value={FormValuie?.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setFormValuie({
                    ...FormValuie,
                    shopId: e,
                    regionId: undefined,
                  })
                  getShopListUnderAuth(e || undefined)
                }}
              >
                {PullList &&
                  PullList.map((item, index) => {
                    return (
                      <Option value={item?.shopId} key={index}>
                        {item?.shopName}
                      </Option>
                    )
                  })}
              </Select>
            </div>
          </div>
          <div className={styles.device_list_modal_edit_device_child}>
            <div className={styles.device_list_modal_edit_device_childname}>
              所属区位：
            </div>
            <Select
              // mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择所属区位"
              style={{ width: 200 }}
              allowClear
              value={FormValuie?.regionId}
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, regionId: e })
              }}
            >
              {ShopListAuth &&
                ShopListAuth.map((item, index) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      {item?.name}
                    </Option>
                  )
                })}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Index
