import { Button, Table, Select, message, Image as AntdImage, Card } from "antd"
import React, { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"
import * as JiaSaiServer from "../../services/jiasai"

const { Option } = Select
//开始时间
let ST = HandDefaultTime?.MonStaDatVal
// 结束时间
let ET = HandDefaultTime?.MonEndDatVal
// 总时间
let AT = [dayjs().subtract(1, "month"), dayjs()]
// 项目id
const projectId = localStorage.getItem("uid")

function Index(props) {
  //shopId：门店id,staffName:员工,skuCode:商品PLU||商品名称,recoredId:精确查询id,specific_time:时间
  let JumpToHereInfo = props.location.state
  //从其他页面跳转来带时间的话做处理  -----
  let JST = "" // 开始
  let JET = "" // 结束
  let JAT = "" // 所有
  if (JumpToHereInfo?.tabKey == 1) {
    //跳转开始时间
    // JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    //跳转结束时间
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("day").valueOf()
      : ST
    ET = JST ? dayjs(JumpToHereInfo?.specific_time).endOf("day").valueOf() : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("day"),
      dayjs(JumpToHereInfo?.specific_time).endOf("day"),
    ]
  } else if (JumpToHereInfo?.tabKey == 2) {
    const NewTime = JumpToHereInfo?.specific_time[1].split("-")
    JST = NewTime ? NewTime[0] : ""
    //跳转结束时间
    JET = NewTime ? NewTime[1] : ""
    ST = JST
      ? dayjs(dayjs(JST).format("YYYY-MM-DD") + " " + "00:00:00").valueOf()
      : ST
    ET = JET
      ? dayjs(dayjs(JET).format("YYYY-MM-DD") + " " + "23:59:59").valueOf()
      : ET
    JAT = [dayjs(JST), dayjs(JET)]
  } else if (JumpToHereInfo?.tabKey == 3) {
    //跳转开始时间
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("month").valueOf()
      : ST
    ET = JST
      ? dayjs(JumpToHereInfo?.specific_time).endOf("month").valueOf()
      : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("month"),
      dayjs(JumpToHereInfo?.specific_time).endOf("month"),
    ]
  } else {
    JAT = AT
  }
  //--------------------------
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [shopList, setShopList] = useState([]) //门店设备列表
  const [GiveParams, setGiveParams] = useState({
    timeArr: JAT,
    startDate: ST,
    endDate: ET,
    pageNum: 1,
    pageSize: 20,
    shopId: undefined, // 门店ID
    operator: undefined, // 员工工号
    skuName: undefined, // 商品
    type: undefined, // 类型
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    //调用门店接口
    getShopList()
    setTimeout(getRecordList)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 获取门店列表
   */
  const getShopList = async () => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await JiaSaiServer.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data?.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  //获取表格列表数据
  const getRecordList = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      const params = {
        ...GiveParamsRef,
        timeArr: [],
        startDate:
          dayjs(GiveParamsRef?.startDate).format("YYYY-MM-DD") + " 00:00:00",
        endDate:
          dayjs(GiveParamsRef?.endDate).format("YYYY-MM-DD") + " 23:59:59",
      }
      setLoading(true)
      const { code, msg, data } = await JiaSaiServer.Weight_Records_List(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      console.log(data)
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data?.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //导出
  const getExport = async () => {
    try {
      if (dataList.length == 0) {
        message.error("暂无可导出的数据！")
        return
      }
      const { GiveParamsRef } = updataRef.current
      const params = {
        ...GiveParamsRef,
        timeArr: [],
        startDate:
          dayjs(GiveParamsRef?.startDate).format("YYYY-MM-DD") + " 00:00:00",
        endDate:
          dayjs(GiveParamsRef?.endDate).format("YYYY-MM-DD") + " 23:59:59",
      }
      const res = await JiaSaiServer.ExpotRecords(params)
    } catch (error) {
      console.log(error)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getRecordList, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "商品",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },
      key: "skuName",
      width: 200,
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },
      key: "skuCode",
      width: 200,
    },
    {
      title: "商品单价",
      dataIndex: "price",
      ellipsis: { showTitle: true },
      key: "price",
      width: 200,
      render: (info) => {
        return <>{info == 0 ? 0 : (info / 100).toFixed(2)}元</>
      },
    },
    {
      title: "标签重量（kg）",
      dataIndex: "labelWeight",
      ellipsis: { showTitle: true },
      key: "labelWeight",
      width: 200,
      render: (info) => {
        return <>{info ? (info == 0 ? 0 : info / 1000) : 0}kg</>
      },
    },
    {
      title: "银台复核重量（kg）",
      dataIndex: "cashierReviewWeight",
      ellipsis: { showTitle: true },
      key: "cashierReviewWeight",
      width: 200,
      render: (info) => {
        return <>{info ? (info == 0 ? 0 : info / 1000) : 0}kg</>
      },
    },
    {
      title: "节省损耗（元）",
      dataIndex: "savingsLoss",
      ellipsis: { showTitle: true },
      key: "savingsLoss",
      width: 200,
      render: (info) => {
        return <>{info ? (info == 0 ? 0 : info / 100).toFixed(2) : 0}元</>
      },
    },
    {
      title: "类型",
      dataIndex: "type",
      ellipsis: { showTitle: true },
      key: "type",
      width: 200,
      render: (info) => {
        return (
          <>
            {info == 1
              ? "未复核—手动退出"
              : info == 2
              ? "未复核—自动退出"
              : info == 3
              ? "已复核—条码输入"
              : info == 4
              ? "已复核—条码未输入"
              : ""}
          </>
        )
      },
    },
    {
      title: "员工工号",
      dataIndex: "operator",
      ellipsis: { showTitle: true },
      key: "operator",
      width: 200,
    },
    {
      title: "所属门店",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
    },
    {
      title: "复核称重图片",
      dataIndex: "reviewimageurl",
      ellipsis: { showTitle: true },
      key: "reviewimageurl",
      width: 200,
      render: (info) => {
        return (
          <div className={styles.page_table_img}>
            {info ? <AntdImage src={info} /> : null}
          </div>
        )
      },
    },
    {
      title: "异常触发时间",
      dataIndex: "insertionTime",
      ellipsis: { showTitle: true },
      key: "insertionTime",
      width: 200,
    },
  ]

  /**
   * 查询
   */
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getRecordList, 0)
  }
  /**
   * 重置功能
   */
  const handClear = () => {
    // 重置时将时间恢复默认
    // 开始时间
    ST = HandDefaultTime?.MonStaDatVal
    // 结束时间
    ET = HandDefaultTime?.MonEndDatVal
    JumpToHereInfo = false
    setGiveParams({
      ...GiveParams,
      timeArr: AT,
      startDate: ST,
      endDate: ET,
      pageNum: 1,
      shopId: undefined, // 门店ID
      operator: undefined, // 员工工号
      skuName: undefined, // 商品
      type: undefined, // 类型
    })
    setTimeout(getRecordList, 0)
  }

  return (
    <div className={styles.policeroad_page}>
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.updateroad_policeroad_page_search}>
          <div className={styles.updateroad_policeroad_search_body}>
            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>所属门店：</span>
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属门店"
                style={{ width: 200 }}
                allowClear
                value={GiveParams.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setGiveParams({
                    ...GiveParams,
                    shopId: e || undefined,
                  })
                }}
              >
                {shopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>

            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>商品：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, skuName: val })
                }}
                placeholder="商品"
                value={GiveParams.skuName}
              />
            </div>

            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>异常触发时间：</span>
              <PubRangePicker
                value={GiveParams?.timeArr}
                // disDate={30}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    timeArr: val.TotalTime,
                    startDate: val.startDateValueOf,
                    endDate: val.endDateValueOf,
                  })
                }}
              />
            </div>

            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>员工工号：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, operator: val })
                }}
                placeholder="员工工号"
                value={GiveParams.operator}
              />
            </div>

            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>类型：</span>
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择类型"
                style={{ width: 200 }}
                allowClear
                value={GiveParams.type}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setGiveParams({
                    ...GiveParams,
                    type: e || undefined,
                  })
                }}
              >
                <Option value={1} key={1}>
                  未复核—手动退出
                </Option>
                <Option value={2} key={2}>
                  未复核—自动退出
                </Option>
                <Option value={3} key={3}>
                  已复核—条码输入
                </Option>
                <Option value={4} key={4}>
                  已复核—条码未输入
                </Option>
              </Select>
            </div>
          </div>
          <div className={styles.updateroad_policeroad_search_button}>
            <Button type="primary" onClick={() => handSearch()}>
              查询
            </Button>
            <Button onClick={() => handClear()}>重置</Button>
          </div>
        </div>
      </Card>
      {/* <div className={styles.}policeroad_page_body"> */}
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        {/* 导出 */}
        <Button type="primary" onClick={getExport}>
          导出数据
        </Button>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          // rowSelection={rowSelection}
          scroll={{ x: "max-content", y: `${WinHeight - 470}px` }}
        />
      </Card>
    </div>
  )
}

export default Index
