import React, { Component, useEffect, useState } from "react"
import { DownOutlined, BellOutlined } from "@ant-design/icons"
import {
  Layout,
  Menu,
  Breadcrumb,
  Dropdown,
  Space,
  Button,
  ConfigProvider,
  Input,
  message,
  Modal,
  Form,
  Badge,
  Upload,
} from "antd"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import "antd/dist/reset.css"
import logo from "./yunyingpingtailogopng.png"
import user from "../../images/userimg.png"
import {
  Login_Out,
  Change_Password,
  WarningMessage,
  GetUserHeadImg,
} from "../../pages/services/login"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Get_Token } from "../../pages/services/systemset"
import "./Index.css"
import { customRoutes, newCustomRoutes } from "../../routes"
import md5 from "md5"
import { GetLoginInfo } from "../../pages/services/login"
// import { handleRouter } from "../../utils/handleRouter"

const { Header, Content, Sider } = Layout
function Index(props) {
  const [form] = Form.useForm()
  const [breadname, setBreadName] = useState([]) // 面包屑title展示
  const [threeTitle, setThreeTitle] = useState([]) // 三级title
  const [collapsed, setCollapsed] = useState(false)
  const [phone, setPhone] = useState("登录昵称")
  const [show, setShow] = useState(false) //修改密码框
  const [openKeys, setOpenKeys] = useState([]) //当前展开的 SubMenu 菜单项 key 数组
  const [userInfoImg, setUserInfoImg] = useState("") //登录信息头像
  const [handleRouter, setHandleRouter] = useState([]) // 处理后的路由
  const [isShowTag, setIsShowTag] = useState(false) // 是否展示右上角的铃铛
  // 头像上传
  const [token, setToken] = useState("") //上传token
  const [uid, setUid] = useState([]) //uid
  const [domain, setDomain] = useState("") //域名
  const [imgLoading, setImgLoading] = useState(false) //上传loging
  const [imgUrl, setImgUrl] = useState("") //上传成功后返回的图片路径
  //
  const [isReduce, setIsReduce] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem("INNERWEIGHT") <= 1100) {
      setIsReduce(true)
    } else {
      setIsReduce(false)
    }
  }, [sessionStorage.getItem("INNERWEIGHT")])

  useEffect(() => {
    //调用获取告警记录新消息条数接口
    getWarningMessage()

    //获取用户登录 信息
    // onGetLoginInfo()

    //调用获取token接口
    gettoken()
    // console.log("处理前的菜单", customRoutes)
    // console.log("newCustomRoutes", newCustomRoutes)
  }, [])

  useEffect(() => {
    GetLoginInfo().then((res) => {
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      setUserInfoImg(res?.data.headPortrait) //头像
      setPhone(res?.data.realname) //真实姓名
      sessionStorage.setItem("MENUS", JSON.stringify(res?.data.menus))
      // })
      const ItemMenus =
        sessionStorage.getItem("MENUS") || JSON.stringify(res?.data.menus)
      // console.log(ItemMenus)
      if (!ItemMenus) {
        message.error("无菜单返回")
        localStorage.removeItem("token")
        window.location = window.location.origin + "/#login"

        //退出登录时清除缓存的后端数据菜单
        sessionStorage.removeItem("MENUS")
        // 清除 uid
        localStorage.removeItem("uid")
        // 退出登录时清除 localStorage缓存
        localStorage.clear()
        return
      }

      const MENUS = JSON.parse(ItemMenus)
      if (!MENUS || MENUS.length == 0) {
        message.error("无菜单返回")
        localStorage.removeItem("token")
        window.location = window.location.origin + "/#login"

        //退出登录时清除缓存的后端数据菜单
        sessionStorage.removeItem("MENUS")
        // 清除 uid
        localStorage.removeItem("uid")
        // 退出登录时清除 localStorage缓存
        localStorage.clear()
        return
      }
      // console.log("接口返回菜单", MENUS)

      // 是否展示右上角的铃铛
      MENUS.length > 0 &&
        MENUS.map((v) => {
          v.childMenus.map((w) => {
            if (w.router === "/admin/lossmanage/policeroad" && w.isShow == 1) {
              setIsShowTag(true)
              return
            }
          })
        })

      /**
       * 声明空数组来承接处理好的数据
       */
      let NewMenus = []
      /**
       * 拿后端数据来处理本地路由，
       */
      customRoutes.map((v, i) => {
        let tmpV = {
          path: v.path,
          component: v.component,
          isShow: v.isShow,
          exact: v.exact,
          title: v.title,
          icon: v.icon,
          children: [],
        }
        MENUS.length > 0 &&
          MENUS?.map((w, j) => {
            if (tmpV.path === w?.router) {
              //给路由赋值排序值
              tmpV.sortNo = w?.sortNo
              //判断是否展示一级
              tmpV.isShow = w?.isShow == 1 ? true : false
              //菜单名称
              tmpV.title = w.menuName
              if (v.children.length > 0) {
                // 匹配子路由
                // v.children = v.children.filter((filItem) =>
                //   w?.childMenus.some((someItem) =>
                //     Object.is(filItem.path, someItem.router)
                //   )
                // )
                // console.log("v.children", v.children)
                // console.log(" w?.childMenus", w?.childMenus)
                // v.children.map((v1, i1) => {
                //   if (v1.path === w?.childMenus[i1]?.router) {
                //     //给子路由赋值排序值
                //     v1.sortNo = w?.childMenus[i1]?.sortNo
                //     //判断是否展示二级
                //     v1.isShow = w?.childMenus[i1]?.isShow == 1 ? true : false
                //     //菜单名称
                //     v1.title = w?.childMenus[i1]?.menuName
                //   }
                // })
                // v.children.sort((a, b) => {
                //   return b?.sortNo - a?.sortNo
                // })
                v.children.map((v1, i) => {
                  w?.childMenus.map((w1, j1) => {
                    if (v1.path == w1.router) {
                      tmpV.children.push({
                        sortNo: w1?.sortNo,
                        path: v1.path,
                        isShow: w1?.isShow == 1 ? true : false,
                        exact: v1.exact,
                        builtIn: v1.builtIn,
                        title: w1?.menuName,
                        component: v1.component,
                      })
                    }
                  })
                })
                // 子路由排序
                tmpV.children.sort((a, b) => {
                  return b?.sortNo - a?.sortNo
                })
              }

              //存取符合条件的路由
              NewMenus.push(tmpV)
            }
          })
      })
      // console.log("最终路由信息", NewMenus)

      /**
       * 排序
       */
      NewMenus.sort((a, b) => {
        return b?.sortNo - a?.sortNo
      })
      // console.log("处理后的菜单", NewMenus)
      setHandleRouter(NewMenus)
    })
  }, [])

  const onGetLoginInfo = async () => {
    try {
      const { code, msg, data } = await GetLoginInfo()
      if (!200) {
        message.error(msg)
        return
      }
      setUserInfoImg(data.headPortrait) //头像
      setPhone(data.realname) //真实姓名
      const NewMenus = data.menus
      sessionStorage.setItem("MENUS", JSON.stringify(NewMenus))
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 处理路由
   */
  useEffect(() => {
    const propsPathname = props.location.pathname //当前路径
    // 主要是为了刷新时页面title消失做的操作
    customRoutes.map((v) => {
      if (v.path === propsPathname) {
        const nowPathinfo = [v]
        setBreadName(nowPathinfo)
        setThreeTitle([])
        return false
      } else {
        if (v.children && v.children.length) {
          v.children.map((w) => {
            if (w.path === propsPathname) {
              setThreeTitle([])
              let nowPathinfo = [v]
              nowPathinfo.push(w)
              setBreadName(nowPathinfo)
              if (v.path === "/admin/home" && !w.isShow) {
                setThreeTitle([w])
              }

              return false
            }
            // 这是为了给不展示二级的内置页面赋title
            if (w.path === propsPathname && w.builtIn) {
              setThreeTitle([])
              let nowPathinfo = [v]
              nowPathinfo.push(w)
              setBreadName(nowPathinfo)
              return false
            }

            //三级赋内置页面title
            if (w.children && w.children.length) {
              w.children.map((z) => {
                if (z.path === propsPathname) {
                  setThreeTitle([z])
                  let nowPathinfo = [v]
                  nowPathinfo.push(w)
                  nowPathinfo.push(z)
                  setBreadName(nowPathinfo)
                  return false
                }
              })
            } else {
              return false
            }
          })
        } else {
          return false
        }
      }
    })
  }, [props])
  useEffect(() => {
    if (window.innerWidth <= 1400) {
      setCollapsed(!collapsed)
    }
  }, [window.innerWidth])

  /**
   * 获取告警记录新消息条数
   */
  const getWarningMessage = async () => {
    sessionStorage.removeItem("MessNum")
    try {
      const { code, data, msg } = await WarningMessage()
      if (code != 200) {
        message.error(msg)
        return
      }
      //告警记录新消息条数
      sessionStorage.setItem("MessNum", data?.number || 0)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 退出登录
   */
  const logOut = () => {
    Login_Out()
      .then((res) => {
        if (res.code === 200) {
          localStorage.removeItem("token")
          window.location = window.location.origin + "/#login"

          //退出登录时清除缓存的后端数据菜单
          sessionStorage.removeItem("MENUS")
          // 清除 uid
          localStorage.removeItem("uid")
          // 退出登录时清除 localStorage缓存
          localStorage.clear()
          message.success("退出成功")
        } else {
          message.success("退出失败")
        }
      })
      .catch((err) => {
        message.success("退出失败")
      })
  }

  /**
   * 右上角功能按钮
   */
  const items = [
    {
      label: (
        <a
          onClick={() => {
            setShow(true)
            form.resetFields()
          }}
        >
          修改密码
        </a>
      ),
      key: "0",
    },
    {
      label: <a onClick={logOut}>退出登录</a>,
      key: "1",
    },
  ]

  /**
   * 修改密码提交
   */
  const onFinish = (values) => {
    form
      .validateFields()
      .then(async (values) => {
        if (!(values?.newpassword === values?.reallypassword)) {
          message.error("确认密码与新密码不一致")
          return false
        }
        const params = {
          newPassword: md5(values?.newpassword),
          oldPassword: md5(values?.oldpassword),
        }
        Change_Password(params).then((res) => {
          if (res.code == 200) {
            setShow(false)
            if (res.data) {
              message.success("密码修改成功,请重新登录")
              setTimeout(() => {
                localStorage.removeItem("token")
                window.location = window.location.origin + "/#login"
              }, 1000)
            }
          }
        })
      })
      .catch((err) => {
        // 校验失败
      })
  }

  /**
   * 递归处理左侧菜单栏
   */
  const renderMenu = (data) => {
    if (!data) {
      return null
    }

    let resList = []

    data.map((item) => {
      // const fullMenuName = `${fatherName}${fatherName ? " / " : " "}${
      //   item.title
      // }`
      // menuNameMap[item.path] = fullMenuName
      // 如果有子节点，继续递归调用，直到没有子节点
      if (item.isShow) {
        //没有子节点就返回当前的父节点
        resList.push({
          key: item.path,
          children:
            item.children && item.children.length > 0 && item.children[0].isShow
              ? // ? renderMenu(fullMenuName, item.children)
                renderMenu(item.children)
              : null,
          label: item.title,
          icon: item.icon ? item.icon : "",
        })
      }
    })

    // 返回菜单数组
    return resList
  }

  /**
   * 监听菜单点击
   */
  const onMenuClick = (e) => {
    // setBreadName(menuNameMap[e.key])
    props.history.push(e.key)
    window.localStorage.removeItem("radioValue")
    //清除门店管理点击设备数跳转设备管理的门店id
    window.localStorage.removeItem("shopId")
    //清除商品图片库跳转到数据共享的标识
    sessionStorage.removeItem("page")
    //清除从商品管理带来的sn
    sessionStorage.removeItem("sn")
  }

  /**
   * 菜单展开时存储展开路径
   */
  const onOpenChange = (keys) => {
    //当keys的长度为1表示展开一组，超过1时，删除旧的展开菜单，表示只能展开一个菜单
    if (keys.length > 1) {
      keys.shift()
    }
    //当keys的长度为0 ，表示菜单全关闭
    if (keys.length == 0) {
      // 清空SubMenu 菜单项 key 数组
      setOpenKeys([])
      return false
    }
    //当前展开的菜单父级路径
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    //找到当前菜单的children
    const nowRoutes = handleRouter.find((v) => v.path === latestOpenKey)
    if (nowRoutes.children.length > 0) {
      //菜单展开默认选中当前菜单的第一个
      props.history.push(nowRoutes.children[0].path)

      if (nowRoutes.children.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys)
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
      }
    }
  }

  /**
   * 获取 token
   */
  const gettoken = async () => {
    try {
      const { code, msg, data } = await Get_Token()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setToken(data.token)
        setDomain(data?.domain || "")
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 上传
   */
  const uploadProps = {
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(e)
        fileReader.onload = (ee) => {
          var _md5only = md5(ee.target.result)
          resolve({
            token,
            key: `icons/project/${uid}/_${_md5only}.${e.type.split("/")[1]}`,
          })
        }
        fileReader.onerror = (err) => {
          reject(err)
        }
      })
    },
    beforeUpload: async (e) => {
      try {
        const sizeQualified = await handleFilebeforeUpload(e)
        // message.success(sizeQualified)
        setImgLoading(true)
        return true
      } catch (err) {
        message.error("上传失败,请按建议上传")
        setImgLoading(false)
        return false
      }
    },
    async onChange(info) {
      const _file = info.file
      if (_file.status === "done") {
        // console.log(_file, "上传成功的数据")
        setImgUrl(domain + "" + _file?.response?.key || "")

        try {
          const { code, data, msg } = await GetUserHeadImg(
            domain + "" + _file?.response?.key || ""
          )
          if (code != 200) {
            message.error(msg)
          }
          message.success("头像上传成功")
          setImgLoading(false)

          // 头像上传成功后刷新用户信息
          onGetLoginInfo()
        } catch (error) {
          console.log(error)
          setImgLoading(false)
        }
      }
      if (_file.status === "error") {
        setImgLoading(false)
        message.error(_file?.response.error)
      }
    },
  }

  /**
   * 限制宽高类型
   */
  const handleFilebeforeUpload = (file) => {
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader()
      filereader.onload = (e) => {
        let src = e.target.result
        const image = new Image()
        image.onload = function () {
          if (
            (file.type === "image/png" ||
              file.type === "image/jpeg" ||
              file.type === "image/jpg") &&
            file.size < 512000
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve("上传成功")
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = src
      }
      filereader.readAsDataURL(file)
    })
  }

  return (
    <Layout>
      {/* 这是顶部内容 */}
      <Header className="page_count_header">
        {/* logo */}
        <div className="page_count_header_logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="page_count_user">
          {/* 标题 */}
          <Breadcrumb className="page_count_breadcrumb" items={breadname} />
          {/* // */}
          <div className="page_count_icon">
            {isShowTag ? (
              <Link
                to={{
                  pathname: `/admin/lossmanage/policeroad`,
                }}
              >
                <Badge
                  count={sessionStorage.getItem("MessNum")}
                  className="page_count_user_icon"
                  size="small"
                  showZero={false}
                >
                  <BellOutlined
                    style={{
                      fontSize: "18px",
                      marginTop: "5px",
                      color: "rgb(51, 49, 49)",
                    }}
                  />
                </Badge>
                <span style={{ color: "black", marginLeft: "7px" }}>
                  消息中心
                </span>
              </Link>
            ) : null}

            <div className="page_count_icon_fenge">|</div>

            {/* 头像上传 */}
            {/* <img src={userInfoImg ? userInfoImg : user} alt="logo" /> */}
            <Upload
              action="https://up-z0.qiniup.com"
              name="file"
              showUploadList={false}
              multiple={false}
              {...uploadProps}
            >
              <img
                src={userInfoImg ? userInfoImg : user}
                alt="logo"
                style={{ cursor: "pointer", width: "23px", height: "23px" }}
              />
            </Upload>

            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <Button type="link" onClick={(e) => e.preventDefault()}>
                <Space>
                  {phone}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Layout hasSider className="page_all_body">
        {/*---------------- 左侧menu导航 ------------------------*/}
        <Sider
          trigger={null}
          collapsible
          collapsed={isReduce}
          width={isReduce ? "70px" : "170px"}
          style={{
            height: "100%",
            background: "linear-gradient(137deg, #f1fbf3 0%, #e5f7fb 100%)",
          }}
        >
          <Menu
            mode="inline"
            className="page_menu"
            selectedKeys={[props.location.pathname]}
            onClick={onMenuClick}
            items={renderMenu(handleRouter)}
            onOpenChange={onOpenChange} //菜单展开关闭监听
            openKeys={openKeys}
          ></Menu>
        </Sider>
        {/*----------------------- 右侧主体布局 --------------------*/}
        <div
          className="page_count"
          style={{
            width: isReduce ? "calc(100% - 80px)" : "calc(100% - 170px)",
          }}
        >
          {/* 三级标题带返回 */}
          {threeTitle.length > 0 ? (
            <div className="page_count_threepage">
              <div
                className="page_count_breadcrumb_goback"
                onClick={() => {
                  window.history.back(-1)
                }}
              >
                <ArrowLeftOutlined style={{ marginRight: "5px" }} />
                返回
              </div>
              <Breadcrumb
                items={threeTitle}
                style={{ fontWeight: "bold" }}
              ></Breadcrumb>
            </div>
          ) : (
            <div style={{ height: "16px" }}></div>
          )}

          {/* 页面展示区 */}
          <Layout
            className="page_count_layout"
            style={{
              height: `${
                threeTitle.length > 0
                  ? "calc(100% - 72px)"
                  : "calc(100% - 32px)"
              }`,
            }}
          >
            <Content className="page_count_content">{props.children}</Content>
          </Layout>
        </div>
      </Layout>
      <Modal
        title="修改密码"
        open={show}
        destroyOnClose
        onCancel={() => {
          setShow(false)
        }}
        onOk={onFinish}
      >
        <div className="upload_pass">
          <Form
            form={form}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 10 }}
            onFinish={onFinish}
          >
            <Form.Item
              label="旧密码"
              name="oldpassword"
              rules={[
                {
                  required: true,
                  message: "密码不能为空!",
                },
                {
                  pattern: new RegExp(
                    /^[\da-zA-Z@#\$&-+\?\.:;,<>]{5,15}$/,
                    "g"
                  ),
                  message: "支持英文、数字、特殊字符，长度为5到15",
                },
              ]}
            >
              <Input.Password
                placeholder="请输入旧密码！"
                style={{ width: "200px" }}
                maxLength={15}
                pass
              />
            </Form.Item>
            <Form.Item
              label="新密码"
              name="newpassword"
              rules={[
                {
                  required: true,
                  message: "密码不能为空!",
                },
                {
                  pattern: new RegExp(
                    /^[\da-zA-Z@#\$&-+\?\.:;,<>]{5,15}$/,
                    "g"
                  ),
                  message: "支持英文、数字、特殊字符，长度为5到15",
                },
              ]}
            >
              <Input.Password
                placeholder="请输入新密码！"
                style={{ width: "200px" }}
                maxLength={15}
              />
            </Form.Item>
            <Form.Item
              label="确认密码"
              name="reallypassword"
              rules={[
                {
                  required: true,
                  message: "密码不能为空!",
                },
                {
                  pattern: new RegExp(
                    /^[\da-zA-Z@#\$&-+\?\.:;,<>]{5,15}$/,
                    "g"
                  ),
                  message: "支持英文、数字、特殊字符，长度为5到15",
                },
              ]}
            >
              <Input.Password
                placeholder="再次输入新密码！"
                style={{ width: "200px" }}
                maxLength={15}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </Layout>
  )
}

export default withRouter(Index)
