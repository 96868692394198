import { POST, GET } from "../../utils/request"

// export const LoginPlace = (params) => {
//   return post("/admin/v1/user/login", params)
// }
// export const LoginPlace = (params) => POST("/admin/v1/user/login", params)
/**
 * 获取图形验证码
 */
export const Get_Captcha = (params) => GET("/data/center/v1/login/captcha")

/**
 * 获取短信验证码
 */
export const Get_Send = (params) =>
  POST("/data/center/v1/login/sms/send", params)

/**
 * 短信验证码校验
 */
export const Login_Init = (params) =>
  POST("/data/center/v1/login/sms/login", params)

/**
 * 退出登录
 */
export const Login_Out = (params) =>
  POST("/data/center/v1/login/sms/loginOut", params)

/**
 * 账号密码登录
 */
export const Account_Login = (params) =>
  POST("/data/center/v1/login/account/login", params)

/**
 * 修改密码
 */
export const Change_Password = (params) =>
  POST("/data/center/v1/login/change/password", params)

/**
 * 找回密码
 */
export const Retrieve_Password = (params) =>
  POST("/data/center/v1/login/retrieve/password", params)

/**
 * 获取告警记录新消息条数
 */
export const WarningMessage = () =>
  GET("/data/center/v1/loss/prevention/warning/message")

/**
 * 获取登录用户信息
 */
export const GetLoginInfo = () => GET("/data/center/v1/user/login/info")

/**
 * 修改头像
 */
export const GetUserHeadImg = (params) =>
  GET(`/data/center/v1/user/edit/head?headPortrait=${params}`)
