import Login from "../pages/login/Login"
import PageNotFound from "../pages/PageNotFound"
import GoodManage from "../pages/admin/goodmanage/Index"
import DeviceManage from "../pages/admin/devicemanage/Index"
import StoreManage from "../pages/admin/storemanage/storemanage/Index"
import StoreFather from "../pages/admin/storemanage/Index"
import EquipmentManage from "../pages/admin/storemanage/equipmentmanage/Index"

import DiscernManage from "../pages/admin/discernmanage/Index"
import DataShare from "../pages/admin/discernmanage/datashare/Index"
import ShareRecord from "../pages/admin/discernmanage/sharerecord/Index"
import TellData from "../pages/admin/discernmanage/telldata/Index"
import IconInfo from "../pages/admin/discernmanage/iconinfo/Index"
import DataReport from "../pages/datareport/Index"
import DeviceData from "../pages/datareport/devicedata/Index"
import WeightData from "../pages/datareport/weightdata/Index"
import WeightDataDetail from "../pages/datareport/weight_data_detail/Index"
import WeightRecord from "../pages/datareport/weightrecord/Index"
import DeviceDetail from "../pages/datareport/devicedetail/Index"
// import ShopurlLirrary from "../pages/shoping/shopurllirrary/Index"
// import LableManage from "../pages/shoping/lablemanage/Index"
import Home from "../pages/home/homePage/Index"
import ShopDetail from "../pages/home/shopDetail/Index"
import GoodDetail from "../pages/home/goodDetail/Index"
import PayManage from "../pages/paymanage/Index"
import StaWeiSide from "../pages/paymanage/staffWeightOutSide/Index"
import LossManage from "../pages/lossmanage/Index"
import GoodsUpdate from "../pages/lossmanage/goodsUpdate/Index"
import SysteMset from "../pages/systemset/Index"
import ShopSet from "../pages/systemset/shopSet/Index"
import RoleSet from "../pages/systemset/roleSet/Index"
import AccountSet from "../pages/systemset/accountSet/Index"
// 防损管理
import LossSet from "../pages/systemset/lossSet/Index"
import AddLoss from "../pages/systemset/lossSet/add_loss/Index"
import EditLoss from "../pages/systemset/lossSet/edit_loss/Index.js"
import PoliceRoad from "../pages/lossmanage/policeRoad/Index"
import UpdateRoad from "../pages/lossmanage/updateRoad/Index"
import ErrorWeight from "../pages/lossmanage/errorWeight/Index"
import LossAnalysis from "../pages/lossmanage/lossAnalysis/Index"
import OrphanGoods from "../pages/lossmanage/orphanGoods/Index"
import OrphanGoodsRules from "../pages/lossmanage/orphanGoodsRules/Index"
import OrphanAddRules from "../pages/lossmanage/orphanGoodsRules/addRules/Index"
import OrphanEditRules from "../pages/lossmanage/orphanGoodsRules/editRules/Index"
import JiaSaiGoodsRules from "../pages/lossmanage/jiasaiGoodsRules/Index"
import AntiJiaSaiRecord from "../pages/lossmanage/antiJiaSaiRecord/Index"

//
import DataSetof from "../pages/systemset/dataSetof/Index"
import MergeRecords from "../pages/systemset/mergeRecords/Index"

//商品管理
import Goods_Image from "../pages/goods_manage/goods_image/Index"
import Subscript_Manage from "../pages/goods_manage/lable_manage/Index"
import Shop_GoodsInfo from "../pages/goods_manage/shop_goodsinfo/Index"
import Goods_Codesize_Manage from "../pages/goods_manage/goods_codesize_manage/Index"
import Look_Import_Records from "../pages/goods_manage/look_import_records/Index"

// 设备管理
import Device_List from "../pages/device_manage/device_list/Index"
import Look_Goods from "../pages/device_manage/look_goods/Index"
import BindingCamera from "../pages/device_manage/binding_camera/Index"
import Goods_Manage from "../pages/device_manage/old_page/goods_manage/Index"
import Shop_List from "../pages/device_manage/shop_manage/Index"
import Xapp_Manage from "../pages/device_manage/shop_manage/xapp_account_manage/Index"

// 识别管理
import ConfigdataSummary from "../pages/admin/configdata_summary/Index"
import CreatedataSummary from "../pages/admin/createdata_summary/Index"
import EditdataSummary from "../pages/admin/editdata_summary/Index"
import MergeRecord from "../pages/admin/merge_record/Index"
import PullRecord from "../pages/admin/pull_record/Index"
import NewDataShare from "../pages/data_share/Index"
import ViewData from "../pages/data_share/view_data/Index"
import PictureData from "../pages/data_share/Picture_data/Index"

// 系统设置
import RoleManage from "../pages/account_manage/role_manage/Index"
import StoreSet from "../pages/account_manage/store_set/Index"
import AccountManage from "../pages/account_manage//account_manage/Index"
import StoreArea from "../pages/account_manage/store_area/Index"
import OrganStructure from "../pages/account_manage/organ_structure/Index"
import RelatedShops from "../pages/account_manage/organ_structure/related_shops/Index"

import {
  CodeSandboxOutlined,
  DesktopOutlined,
  SettingOutlined,
  ScanOutlined,
  SafetyOutlined,
  BarChartOutlined,
  HomeOutlined,
  UserOutlined,
  ShoppingOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons"
export const mainRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/404",
    component: PageNotFound,
  },
]

const customRoutes = [
  {
    path: "/admin/home",
    component: Home,
    isShow: true,
    exact: true,
    title: "首页",
    icon: <HomeOutlined />,
    children: [
      {
        path: "/admin/home/shopdetail",
        isShow: false,
        exact: true,
        builtIn: true,
        title: "门店数据统计",
        component: ShopDetail,
      },
      {
        path: "/admin/home/gooddetail",
        isShow: false,
        exact: true,
        title: "商品数据统计",
        builtIn: true,
        component: GoodDetail,
      },
    ],
  },
  {
    path: "/admin/newdevicemanage",
    component: Device_List,
    isShow: true,
    exact: true,
    title: "设备管理",
    icon: <DesktopOutlined />,
    children: [
      {
        path: "/admin/devicemanage/devicelist",
        isShow: true,
        exact: true,
        title: "设备列表",
        component: Device_List,
        children: [
          {
            path: "/admin/devicemanage/bindingCamera",
            component: BindingCamera,
            isShow: false,
            exact: true,
            title: "绑定监控设备",
          },
          {
            path: "/admin/devicemanage/lookGoods",
            component: Look_Goods,
            isShow: false,
            exact: true,
            title: "查看商品",
          },
        ],
      },
      {
        path: "/admin/devicemanage/shopmanage",
        isShow: true,
        exact: true,
        title: "门店列表",
        component: Shop_List,
        children: [
          {
            path: "/admin/devicemanage/shopmanag/xappmanage",
            isShow: false,
            exact: true,
            title: "小程序账号管理",
            component: Xapp_Manage,
          },
        ],
      },
    ],
  },
  {
    path: "/admin/goodsmanage",
    component: Goods_Image,
    isShow: true,
    exact: true,
    title: "商品管理",
    icon: <ShoppingOutlined />,
    children: [
      {
        path: "/admin/goodsmanage/goodsimage",
        component: Goods_Image,
        isShow: true,
        exact: true,
        title: "商品图片",
      },
      {
        path: "/admin/goodsmanage/subscriptmanage",
        component: Subscript_Manage,
        isShow: true,
        exact: true,
        title: "角标管理",
      },
      {
        path: "/admin/goodsmanage/shopgoodsinfo",
        component: Shop_GoodsInfo,
        isShow: true,
        exact: true,
        title: "门店商品数据",
      },
      {
        path: "/admin/goodsmanage/goodscodesizemanage",
        component: Goods_Codesize_Manage,
        isShow: true,
        exact: true,
        title: "商品大小码管理",
        children: [
          {
            path: "/admin/goodsmanage/lookimportrecords",
            isShow: false,
            exact: true,
            title: "查看导入记录",
            component: Look_Import_Records,
          },
        ],
      },
    ],
  },
  {
    path: "/admin/discernmanage",
    component: DiscernManage,
    isShow: true,
    exact: true,
    title: "识别管理",
    icon: <ClockCircleOutlined />,
    children: [
      {
        path: "/admin/discernmanage/configdatasummary",
        isShow: true,
        exact: true,
        title: "配置数据汇总",
        component: ConfigdataSummary,
        children: [
          {
            path: "/admin/discernmanage/createdatasummary",
            component: CreatedataSummary,
            isShow: false,
            exact: true,
            title: "创建数据汇总",
          },
          {
            path: "/admin/discernmanage/editdatasummary",
            component: EditdataSummary,
            isShow: false,
            exact: true,
            title: "编辑数据汇总",
          },
          {
            path: "/admin/discernmanage/mergerecord",
            component: MergeRecord,
            isShow: false,
            exact: true,
            title: "合并记录",
          },
          {
            path: "/admin/discernmanage/pullrecord",
            component: PullRecord,
            isShow: false,
            exact: true,
            title: "拉取记录",
          },
        ],
      },
      {
        path: "/admin/newdatashare",
        isShow: true,
        exact: true,
        title: "数据共享",
        component: NewDataShare,
        children: [
          {
            path: "/admin/newdatashare/viewdata",
            component: ViewData,
            isShow: false,
            exact: true,
            title: "查看识别数据",
          },
          {
            path: "/admin/newdatashare/picturedata",
            component: PictureData,
            isShow: false,
            exact: true,
            title: "查看图片数据",
          },
        ],
      },
      {
        path: "/admin/discernmanage/sharerecord",
        isShow: true,
        exact: true,
        title: "共享记录",
        component: ShareRecord,
      },

      {
        path: "/admin/discernmanage/telldata",
        isShow: false,
        exact: true,
        title: "查看识别数据",
        component: TellData,
      },
      {
        path: "/admin/discernmanage/incoinfo",
        isShow: false,
        exact: true,
        title: "查看icon的数据",
        component: IconInfo,
      },
    ],
  },
  {
    path: "/admin/paymanage",
    component: PayManage,
    isShow: true,
    exact: true,
    title: "绩效管理",
    icon: <BarChartOutlined />,
    children: [
      {
        path: "/admin/datareport/weightdata",
        isShow: true,
        exact: true,
        title: "绩效统计",
        component: WeightData,
        // children: [
        //   {
        //     path: "/admin/datareport/weightdatadetail",
        //     isShow: false,
        //     exact: true,
        //     title: "称重记录详情",
        //     component: WeightDataDetail,
        //   },
        // ],
      },
      {
        path: "/admin/datareport/weightrecord",
        isShow: true,
        exact: true,
        title: "称重记录",
        component: WeightRecord,
      },
    ],
  },
  {
    path: "/admin/lossmanage",
    component: PoliceRoad,
    isShow: true,
    exact: true,
    title: "防损管理",
    icon: <SafetyOutlined />,
    children: [
      {
        path: "/admin/lossmanage/policeroad",
        isShow: true,
        exact: true,
        title: "异常记录",
        component: PoliceRoad,
      },
      {
        path: "/admin/lossmanage/updateroad",
        isShow: true,
        exact: true,
        title: "改价记录",
        component: UpdateRoad,
      },
      {
        path: "/admin/lossmanage/lossanalysis",
        isShow: true,
        exact: true,
        title: "损耗分析",
        component: LossAnalysis,
      },
      {
        path: "/admin/systemset/lossset",
        isShow: true,
        exact: true,
        title: "防损规则",
        component: LossSet,
        children: [
          {
            path: "/admin/lossset/addloss",
            isShow: false,
            exact: true,
            title: "新建防损规则",
            component: AddLoss,
          },
          {
            path: "/admin/lossset/editloss",
            isShow: false,
            exact: true,
            title: "编辑防损规则",
            component: EditLoss,
          },
        ],
      },
      {
        path: "/admin/lossmanage/errorweight",
        isShow: true,
        exact: true,
        title: "员工异常称重",
        component: ErrorWeight,
      },
      {
        path: "/admin/lossmanage/orphangoods",
        isShow: true,
        exact: true,
        title: "孤儿商品",
        component: OrphanGoods,
      },
      {
        path: "/admin/lossmanage/orphangoodsrules",
        isShow: true,
        exact: true,
        title: "孤儿商品规则",
        component: OrphanGoodsRules,
        children: [
          {
            path: "/admin/orphan/addrules",
            isShow: false,
            exact: true,
            title: "新建规则",
            component: OrphanAddRules,
          },
          {
            path: "/admin/orphan/editrules",
            isShow: false,
            exact: true,
            title: "编辑规则",
            component: OrphanEditRules,
          },
        ],
      },
      {
        path: "/admin/lossmanage/jiasaigoodsrules",
        isShow: true,
        exact: true,
        title: "加塞商品规则",
        component: JiaSaiGoodsRules,
      },
      {
        path: "/admin/lossmanage/antijiaSsirecord",
        isShow: true,
        exact: true,
        title: "防加塞复核称重记录",
        component: AntiJiaSaiRecord,
      },
      // {
      //   path: "/admin/lossmanage/goodsupdate",
      //   isShow: true,
      //   exact: true,
      //   title: "商品改价记录",
      //   component: GoodsUpdate,
      // },
    ],
  },
  // {
  //   path: "/admin/systemset",
  //   component: SysteMset,
  //   isShow: true,
  //   exact: true,
  //   title: "系统设置",
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       path: "/admin/devicemanage",
  //       isShow: true,
  //       exact: true,
  //       title: "门店设置",
  //       component: DeviceManage,
  //     },
  //     {
  //       path: "/admin/systemset/roleset",
  //       isShow: true,
  //       exact: true,
  //       title: "角色管理",
  //       component: RoleSet,
  //     },
  //     {
  //       path: "/admin/systemset/accountset",
  //       isShow: true,
  //       exact: true,
  //       title: "账号管理",
  //       component: AccountSet,
  //     },
  // {
  //   path: "/admin/systemset/lossset",
  //   isShow: true,
  //   exact: true,
  //   title: "防损列表",
  //   component: LossSet,
  //   children: [
  //     {
  //       path: "/admin/lossset/addloss",
  //       isShow: false,
  //       exact: true,
  //       title: "新建防损规则",
  //       component: AddLoss,
  //     },
  //     {
  //       path: "/admin/lossset/editloss",
  //       isShow: false,
  //       exact: true,
  //       title: "编辑防损规则",
  //       component: EditLoss,
  //     },
  //   ],
  // },
  //     {
  //       path: "/admin/systemset/datasetof",
  //       isShow: true,
  //       exact: true,
  //       title: "数据集合",
  //       component: DataSetof,
  //     },
  //     {
  //       path: "/admin/systemset/mergerecords",
  //       isShow: false,
  //       exact: true,
  //       title: "合并记录",
  //       component: MergeRecords,
  //     },
  //   ],
  // },
  {
    path: "/admin/accountmanage",
    component: RoleManage,
    isShow: true,
    exact: true,
    title: "系统管理",
    icon: <UserOutlined />,
    children: [
      {
        path: "/admin/accountmanage/rolemanage",
        isShow: true,
        exact: true,
        title: "角色管理",
        component: RoleManage,
      },
      {
        path: "/admin/accountmanage/storeset",
        isShow: true,
        exact: true,
        title: "门店设置",
        component: StoreSet,
      },
      {
        path: "/admin/accountmanage/accountmanage",
        isShow: true,
        exact: true,
        title: "账号管理",
        component: AccountManage,
      },
      {
        path: "/admin/accountmanage/storearea",
        isShow: true,
        exact: true,
        title: "门店区位",
        component: StoreArea,
      },
      {
        path: "/admin/accountmanage/organstructure",
        isShow: true,
        exact: true,
        title: "组织架构",
        component: OrganStructure,
        children: [
          {
            path: "/admin/accountmanage/relatedshops",
            isShow: false,
            exact: true,
            title: "关联门店",
            component: RelatedShops,
          },
        ],
      },
    ],
  },
]

let newCustomRoutes = []
for (var i = 0; i < customRoutes.length; i++) {
  newCustomRoutes.push(customRoutes[i])
}
export { customRoutes }
export { newCustomRoutes }
