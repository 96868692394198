import React, { useState, useEffect } from "react"
import NewBack from "../../images/newback.png"
import NewLogo from "../../images/newlogo.png"

import { setToken } from "../../utils/auth"
import {
  Get_Captcha,
  Get_Send,
  Login_Init,
  Account_Login,
  Retrieve_Password,
} from "../services/login"
import {
  UserOutlined,
  UnlockOutlined,
  PictureOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons"

import { Button, Input, message, Tabs } from "antd"
import { connect } from "react-redux"
import { ArrowLeftOutlined } from "@ant-design/icons"
import styles from "./Login.module.css"
import md5 from "md5"
const { TabPane } = Tabs

var countdown = 60
function Login(props) {
  const [phone, SetPhone] = useState(undefined) //用户名
  const [captText, SetCaptText] = useState(undefined) //图形码
  const [verificat, SetVerificat] = useState(undefined) //验证码
  const [passCode, setPassCode] = useState(undefined) //密码
  const [getCapthcha, setGetCapthcha] = useState("") //验证码数据（code，image）
  const [secondTime, setSecondTime] = useState("获取验证码") //短信倒计时text
  const [isSecondTime, setIsSecondTime] = useState(false) //短信倒计时按钮是否可以点击
  const [isForget, setIsForget] = useState(false) //忘记密码
  const [loginType, setLoginType] = useState(1) //1是账号密码登录，2是验证码登录
  const [nextNum, setNextNum] = useState(1) //1是找回密码第一步，手机号验证码验证，2是重新输入密码
  const [newPassWord, setNewPassWord] = useState(undefined) //新密码
  const [reallyPassWord, setReallyPassWord] = useState(undefined) //确认新密码
  const [diffentText, setDiffentText] = useState(undefined) //密码不一样提示
  const [passcodeErr, setPasscodeErr] = useState(undefined) //密码校验提示
  const [passcodeErr1, setPasscodeErr1] = useState(undefined) //密码校验提示

  useEffect(() => {
    //获取图形验证码
    handGetCaptcha()
    sessionStorage.removeItem("MENUS")
  }, [])

  /**
   * 登录按钮
   */
  const handSubmit = () => {
    if (!phone) {
      if (loginType == 1) {
        message.error("请输入登录账号！")
      } else {
        message.error("请输入手机号！")
      }

      return false
    }
    if (!passCode && loginType == 1) {
      message.error("请输入密码！")
      return false
    }
    if (!captText) {
      message.error("请输入图形码！")
      return false
    }
    if (!verificat && loginType == 2) {
      message.error("请输入验证码！")
      return false
    }
    let params = {}
    //短信验证码请求字段
    if (loginType == 2) {
      params = {
        code: verificat, //验证码
        phone: phone, //手机号
      }
    }
    //账号密码请求字段
    if (loginType == 1) {
      params = {
        code: getCapthcha.code, //图形验证码返回的code
        password: md5(passCode), //
        verificationCode: captText, //图形码
        account: phone,
      }
    }

    //短信验证码登录
    if (loginType == 2) {
      Login_Init(params)
        .then((res) => {
          if (res?.code === 200) {
            setToken(res.data?.token)
            localStorage.setItem("uid", res?.data?.uid)
            const NewMenus = res.data.menus

            // 判断菜单树是否有值
            if (NewMenus.length > 0) {
              // 存储路由菜单
              sessionStorage.setItem("MENUS", JSON.stringify(NewMenus))
              //判断菜单树的第一个菜单是否展示,不显示就显示二级第一个
              props.history.replace(onFirstMenu(NewMenus))
            } else {
              sessionStorage.setItem("MENUS", false)
              props.history.replace("/")
            }
            message.success("登录成功！")
            props.history.push("/")
          } else {
            handGetCaptcha()
          }
        })
        .catch((err) => {
          handGetCaptcha()
        })
    }
    if (loginType == 1) {
      if (passcodeErr) {
        return false
      }
      Account_Login(params)
        .then((res) => {
          if (res?.code === 200) {
            console.log("后端返回菜单", res.data)
            setToken(res.data?.token)
            localStorage.setItem("uid", res?.data?.uid)
            const NewMenus = res.data.menus

            // 判断菜单树是否有值
            if (NewMenus.length > 0) {
              // 存储路由菜单
              sessionStorage.setItem("MENUS", JSON.stringify(NewMenus))
              //判断菜单树的第一个菜单是否展示,不显示就显示二级第一个
              props.history.replace(onFirstMenu(NewMenus))
            } else {
              sessionStorage.setItem("MENUS", false)
              props.history.replace("/")
            }

            message.success("登录成功！")
          } else {
            handGetCaptcha()
          }
        })
        .catch((err) => {
          handGetCaptcha()
        })
    }
  }

  const onFirstMenu = (MM) => {
    console.log(MM)
    for (let index = 0; index < MM.length; index++) {
      if (MM[index].router == "/admin/home" && MM[index].isShow == 1) {
        return MM[index].router
      }
      for (let i = 0; i < MM[index].childMenus.length; i++) {
        if (MM[index].childMenus[i].isShow == 1) {
          return MM[index].childMenus[i].router
        }
      }
    }
  }

  /**
   * 获取图形验证码
   */
  const handGetCaptcha = () => {
    Get_Captcha().then((res) => {
      if (res.code == 200) {
        setGetCapthcha(res?.data)
      }
    })
  }

  /**
   * 获取短信验证码
   */
  const handGetCode = () => {
    if (!captText) {
      message.error("请输入图形码！")
      return false
    }
    if (!phone) {
      message.error("请输入手机号！")
      return false
    }
    //调用倒计时
    const params = {
      code: getCapthcha?.code,
      phone: phone,
      verificationCode: captText,
    }
    console.log(params)
    Get_Send(params)
      .then((res) => {
        if (res?.code === 200) {
          if (res.data) {
            message.success(res.msg)
            settime()
          }
        } else {
          handGetCaptcha()
        }
      })
      .catch((err) => {
        console.log(err)
        handGetCaptcha()
      })
  }

  /**
   * 倒计时
   */
  const settime = (secondTime) => {
    if (countdown == 0) {
      setIsSecondTime(false)
      secondTime = "获取验证码"
      countdown = 60
    } else {
      setIsSecondTime(true)
      secondTime = "重新发送(" + countdown + ")"
      countdown--
      setTimeout(function () {
        settime(secondTime)
      }, 1000)
    }
    setSecondTime(secondTime)
  }

  //下一步
  const handNext = () => {
    if (!phone) {
      message.error("请输入手机号！")
      return false
    }
    if (!captText) {
      message.error("请输入图形码！")
      return false
    }
    if (!verificat) {
      message.error("请输入验证码！")
      return false
    }
    const params = {
      code: verificat, //验证码
      phone: phone, //手机号
    }
    Retrieve_Password(params).then((res) => {
      if (res.code == 200) {
        if (res.data) {
          setNewPassWord(undefined)
          setReallyPassWord(undefined)
          setDiffentText(undefined)
          setNextNum(2)
        }
      }
    })
  }

  //修改密码确认
  const handReally = () => {
    if (newPassWord !== reallyPassWord) {
      setDiffentText("两次输入的密码不一致，请重新输入")
      return false
    }
    if (!newPassWord) {
      message.error("请设置新密码")
      return false
    }
    if (!reallyPassWord) {
      message.error("请再次输入密码")
      return false
    }
    if (newPassWord === reallyPassWord) {
      const params = {
        code: verificat, //验证码
        phone: phone, //手机号
        password: md5(reallyPassWord),
      }
      if (passcodeErr || passcodeErr1) {
        return false
      }
      Retrieve_Password(params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            message.success("你已成功设置密码,请用新密码登录")
            setTimeout(() => {
              setNextNum(1)
              setLoginType(1)
              setIsForget(false)
              SetPhone(undefined)
              SetCaptText(undefined)
              SetVerificat(undefined)
              setPassCode(undefined)
            }, 1000)
          }
        }
      })
    }
  }
  //左上角找回密码按钮
  const handRetrieve = () => {
    handGetCaptcha()
    setPasscodeErr(undefined)
    setPasscodeErr1(undefined)
    if (isForget && nextNum == 1) {
      setIsForget(false)
      SetPhone(undefined)
      SetCaptText(undefined)
      SetVerificat(undefined)
      setPassCode(undefined)
    }
    if (isForget && nextNum == 2) {
      setNewPassWord(undefined)
      setReallyPassWord(undefined)
      setDiffentText(undefined)
      setNextNum(1)
    }
  }
  return (
    <div className={styles.page_login}>
      <div className={styles.page_login_left_body}>
        {/* 图形码、验证码登录 */}

        {!isForget ? (
          <div className={styles.page_login_body}>
            <div className={styles.page_login_right}>
              <div className={styles.page_login_leflogo}>
                <img src={NewLogo} alt="" />
              </div>
              {/* // */}
              <div className={styles.page_login_title}>
                欢迎使用由图非标品运营平台
              </div>
              <div className={styles.page_login_user}>
                <div className={styles.page_login_tabs}>
                  <div className={styles.page_login_tabs_child}>
                    <Tabs
                      defaultActiveKey={loginType}
                      onChange={(e) => {
                        handGetCaptcha()
                        setLoginType(e)
                        SetPhone(undefined)
                        SetCaptText(undefined)
                        SetVerificat(undefined)
                        setPassCode(undefined)
                        setPasscodeErr(undefined)
                        setPasscodeErr(undefined)
                      }}
                    >
                      <TabPane tab="密码登录" key="1"></TabPane>
                      <TabPane tab="验证码登录" key="2"></TabPane>
                    </Tabs>
                  </div>
                </div>
                <div className={styles.page_user_input}>
                  <div className={styles.page_user_body}>
                    <div className={styles.page_user_child}>
                      {/* 输入用户名 */}
                      {loginType == 1 ? (
                        <Input
                          prefix={<UserOutlined style={{ color: "#999999" }} />}
                          placeholder="请输入登录账号"
                          maxLength={15}
                          value={phone}
                          onChange={(e) => {
                            SetPhone(e.target.value)
                          }}
                          style={{ width: "320px" }}
                        />
                      ) : (
                        <Input
                          prefix={
                            <PhoneOutlined style={{ color: "#999999" }} />
                          }
                          placeholder="请输入手机号"
                          maxLength={11}
                          value={phone}
                          style={{ width: "320px" }}
                          onChange={(e) => {
                            SetPhone(e.target.value)
                          }}
                        />
                      )}
                    </div>
                    {/* loginType==1输入密码，loginType==2输入图形验证码 */}
                    {loginType == 1 ? (
                      <div className={styles.page_user_child}>
                        <Input.Password
                          prefix={
                            <UnlockOutlined style={{ color: "#999999" }} />
                          }
                          style={{ width: "320px" }}
                          placeholder="请输入密码"
                          maxLength={15}
                          value={passCode}
                          onChange={(e) => {
                            const reg = new RegExp(
                              /^[\w\$\?\.@#$&-+*%/:;,<>]{5,15}$/,
                              "g"
                            )
                            if (e.target.value) {
                              if (reg.test(e.target.value)) {
                                setPasscodeErr(undefined)
                              } else {
                                setPasscodeErr(
                                  "支持英文、数字、特殊字符，长度为5到15"
                                )
                              }
                            } else {
                              setPasscodeErr(undefined)
                            }

                            setPassCode(e.target.value)
                          }}
                        />
                      </div>
                    ) : null}
                    {passcodeErr ? (
                      <span className={styles.err_text_password}>
                        {
                          <span className={styles.err_text_password}>
                            {passcodeErr}
                          </span>
                        }
                      </span>
                    ) : null}
                    {loginType == 2 || loginType == 1 ? (
                      <div
                        className={styles.page_user_child}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Input
                          prefix={
                            <PictureOutlined style={{ color: "#999999" }} />
                          }
                          placeholder="请输入图形码"
                          maxLength={4}
                          value={captText}
                          onPressEnter={handSubmit}
                          onChange={(e) => {
                            SetCaptText(e.target.value)
                          }}
                          style={{ width: "180px" }}
                        />
                        <div
                          className={styles.page_userchild_image}
                          onClick={handGetCaptcha}
                        >
                          <img src={getCapthcha?.image} alt="" />
                        </div>
                      </div>
                    ) : null}

                    {/* 输入短信验证码 */}
                    {loginType == 2 ? (
                      <div className={styles.page_user_child}>
                        <Input
                          prefix={<MailOutlined style={{ color: "#999999" }} />}
                          placeholder="请输入验证码"
                          maxLength={6}
                          value={verificat}
                          onPressEnter={handSubmit}
                          style={{ width: "180px" }}
                          onChange={(e) => {
                            SetVerificat(e.target.value)
                          }}
                        />

                        <div className={styles.page_userchild_btn}>
                          <Button
                            type="primary"
                            onClick={handGetCode}
                            disabled={isSecondTime}
                          >
                            {secondTime}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.page_user_btn}>
                  <div className={styles.page_login_other}>
                    <a
                      onClick={() => {
                        handGetCaptcha()
                        setIsForget(true)
                        SetPhone(undefined)
                        SetCaptText(undefined)
                        SetVerificat(undefined)
                        setPassCode(undefined)
                      }}
                    >
                      忘记密码
                    </a>
                  </div>
                  <Button type="primary" onClick={handSubmit}>
                    登录
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* 忘记密码 */}
        {isForget ? (
          <div className={styles.page_forgetLogin}>
            <div className={styles.page_forgetLogin_head}>
              <div onClick={handRetrieve} style={{ cursor: "pointer" }}>
                <ArrowLeftOutlined style={{ margin: "0 10px 0 20px" }} />
                找回密码
              </div>
            </div>
            {nextNum == 1 ? (
              <div className={styles.page_forgetLogin_body}>
                <div className={styles.page_forgrt_input}>
                  <div className={styles.page_user_body}>
                    <div className={styles.page_user_child}>
                      {/* 输入用户名 */}
                      <Input
                        placeholder="请输入手机号"
                        prefix={<PhoneOutlined style={{ color: "#999999" }} />}
                        maxLength={11}
                        value={phone}
                        style={{ width: "300px" }}
                        onChange={(e) => {
                          SetPhone(e.target.value)
                        }}
                      />
                    </div>
                    {/* 输入图形验证码 */}
                    <div className={styles.page_user_child}>
                      <Input
                        placeholder="请输入图形码"
                        prefix={
                          <PictureOutlined style={{ color: "#999999" }} />
                        }
                        maxLength={4}
                        style={{ width: "180px" }}
                        value={captText}
                        onChange={(e) => {
                          SetCaptText(e.target.value)
                        }}
                      />
                      <div
                        className={styles.page_userchild_image}
                        onClick={handGetCaptcha}
                      >
                        <img src={getCapthcha?.image} alt="" />
                      </div>
                    </div>
                    {/* 输入短信验证码 */}
                    <div className={styles.page_user_child}>
                      <Input
                        placeholder="请输入验证码"
                        prefix={<MailOutlined style={{ color: "#999999" }} />}
                        maxLength={6}
                        value={verificat}
                        style={{ width: "180px" }}
                        onChange={(e) => {
                          SetVerificat(e.target.value)
                        }}
                      />
                      <div className={styles.page_userchild_btn}>
                        <Button
                          type="primary"
                          onClick={handGetCode}
                          disabled={isSecondTime}
                        >
                          {secondTime}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 下一步 */}
                <div className={styles.page_forget_btn}>
                  {phone && captText && verificat ? (
                    <Button onClick={handNext} type="primary">
                      下一步
                    </Button>
                  ) : (
                    <Button disabled={true}>下一步</Button>
                  )}
                </div>
              </div>
            ) : null}
            {nextNum == 2 ? (
              <div className={styles.page_forgetLogin_body}>
                <div className={styles.page_forgrt_input}>
                  <div className={styles.page_user_body}>
                    <div className={styles.page_user_child}>
                      <div className={styles.page_userchild_body}>
                        <Input.Password
                          placeholder="设置新密码"
                          prefix={
                            <UnlockOutlined style={{ color: "#999999" }} />
                          }
                          maxLength={15}
                          value={newPassWord}
                          style={{ width: "300px" }}
                          onChange={(e) => {
                            setNewPassWord(e.target.value)
                            const reg = new RegExp(
                              /^[\w\$\?\.@#$&-+*%/:;,<>]{5,15}$/,
                              "g"
                            )
                            if (e.target.value) {
                              if (reg.test(e.target.value)) {
                                setPasscodeErr(undefined)
                              } else {
                                setPasscodeErr(
                                  "支持英文、数字、特殊字符，长度为5到15"
                                )
                              }
                            } else {
                              setPasscodeErr(undefined)
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.err_text}>{passcodeErr}</div>
                    <div className={styles.page_user_child}>
                      <div className={styles.page_userchild_body}>
                        <Input.Password
                          placeholder="确认新密码"
                          prefix={
                            <UnlockOutlined style={{ color: "#999999" }} />
                          }
                          maxLength={15}
                          style={{ width: "300px" }}
                          value={reallyPassWord}
                          onChange={(e) => {
                            setDiffentText(undefined)
                            setReallyPassWord(e.target.value)
                            const reg = new RegExp(
                              /^[\w\$\?\.@#$&-+*%/:;,<>]{5,15}$/,
                              "g"
                            )
                            if (e.target.value) {
                              if (reg.test(e.target.value)) {
                                setPasscodeErr1(undefined)
                              } else {
                                setPasscodeErr1(
                                  "支持英文、数字、特殊字符，长度为5到15"
                                )
                              }
                            } else {
                              setPasscodeErr1(undefined)
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.err_text}>
                      {diffentText ? diffentText : passcodeErr1}
                    </div>
                  </div>
                </div>
                {/* 确认 */}
                <div className={styles.page_forget_btn}>
                  {newPassWord && reallyPassWord ? (
                    <Button onClick={handReally} type="primary">
                      确认
                    </Button>
                  ) : (
                    <Button disabled={true}>确认</Button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className={styles.page_login_right_body}>
        <img src={NewBack} alt="" />
      </div>
    </div>
  )
}

export default connect((state) => state)(Login)
