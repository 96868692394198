import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import moment from "moment"
import request, { newRequest } from "../../utils/newRequest"

/**
 * 新建角色
 */
export const AddRole = (params) => POST("/data/center/v1/user/role/add", params)

/**
 * 编辑角色
 */
export const EditRole = (params) =>
  POST("/data/center/v1/user/role/edit", params)

/**
 * 角色详情
 */
export const RoleInfo = (roleId) =>
  GET(`/data/center/v1/user/role/info?roleId=${roleId}`)

/**
 * 角色列表
 */
export const GetUserList = (params) =>
  GET(`/data/center/v1/user/role/list?${stringify(params)}`)

/**
 * 角色删除
 */
export const DeleteRole = (roleId) =>
  GET(`/data/center/v1/user/role/delete?roleId=${roleId}`)

/**
 * 角色禁用启用
 */
export const RoleStatus = (params) =>
  POST(`/data/center/v1/user/role/status`, params)

/**
 * 获取登录用户信息
 */
export const UserInfo = () => GET(`/data/center/v1/user/login/info`)

/**
 * 新建账号
 */
export const AddAccount = (params) =>
  POST(`/data/center/v1/user/account/add`, params)

/**
 * 编辑账号
 */
export const EditAccount = (params) =>
  POST(`/data/center/v1/user/account/edit`, params)

/**
 * 账号详情
 */
export const AccountInfo = (accountId) =>
  GET(`/data/center/v1/user/account/info?accountId=${accountId}`)

/**
 * 账号列表
 */
export const GetAccountList = (params) =>
  GET(`/data/center/v1/user/account/list?${stringify(params)}`)

/**
 * 账号删除
 */
export const DeleteAccount = (accountId) =>
  GET(`/data/center/v1/user/account/delete?accountId=${accountId}`)

/**
 * 账号禁用启用
 */
export const AccountStatus = (params) =>
  POST(`/data/center/v1/user/account/status`, params)

/**
 * 下拉角色列表
 */
export const RolePullList = (status) =>
  GET(`/data/center/v1/user/role/pull/list?status=${status}`)

/**
 * token
 */
export const Get_Token = () => GET("/data/center/v1/qiniuyun/token")

/**
 * 重置密码
 */
export const ResetPassword = (accountId) =>
  GET(`/data/center/v1/login/reset/password?accountId=${accountId}`)

/**
 * 信息基准门店
 */
export const Info_Benchmark = () =>
  GET("/data/center/v1/setting/info-benchmark-shop")

/**
 * 设置基准门店
 */
export const Set_Benchmark = (params) =>
  POST("/data/center/v1/setting/set-benchmark-shop", params)

/**
 * 门店下设备下拉列表
 */
export const Shop_Device = (params) =>
  GET(`/data/center/v1/device/pull/list?shopId=${params}`)

/**
 * 门店列表查询接口
 */
export const Shop_List = (params) =>
  POST("/data/center/v1/goods/shop_list", params)

/**
 * 组织架构树形列表
 */
export const District_TreeList = () => GET(`/data/center/v1/district/tree/list`)

/**
 * 当前项目的数据权限信息
 */
export const Auth_All = (params) => GET(`/data/center/v1/user/data/auth/all`)

/**
 * 门店下拉列表
 */
export const Pull_list = (params) =>
  GET(`/data/center/v1/shop/pull/list?${stringify(params)}`)

/**
 * 获取店内区域列表
 */
export const Shop_Region_List = (shopId) =>
  GET(`/data/center/v1/shop/region/list?shopId=${shopId}`)

/**
 * /data/center/v1/shop/region/tree
 */
export const Shop_Region_Tree = (params) =>
  GET(`/data/center/v1/shop/region/tree`)
