import {
  Table,
  Button,
  message,
  Select,
  DatePicker,
  Card,
  Cascader,
  Row,
  Col,
  Badge,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
import * as echarts from "echarts"
import styles from "./Index.module.css"
import dayjs from "dayjs"
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons"
import * as lossServer from "../../services/lossmanage"
import { disabledDateDay } from "../../../utils/util"

const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = "YYYY-MM-DD"
const DTIME = dayjs().format("YYYYMMDD")
const tabs = [
  { name: "日报", value: 1 },
  { name: "周报", value: 2 },
  { name: "月报", value: 3 },
]

function Index(props) {
  //specific_time:时间
  let JumpToHereInfo = props.location.state
  // 时间处理
  let DT = "" //天
  let WT = [] // 周
  let MT = "" //月
  let TK = 1
  if (JumpToHereInfo?.specific_time) {
    const { specific_time } = JumpToHereInfo
    TK = JumpToHereInfo.tabKey
    if (JumpToHereInfo.tabKey == 1) {
      DT = dayjs(specific_time).format("YYYYMMDD")
      WT = []
      MT = ""
    } else if (JumpToHereInfo.tabKey == 2) {
      DT = ""
      WT = specific_time
      MT = ""
    } else if (JumpToHereInfo.tabKey == 3) {
      DT = ""
      WT = []
      MT = dayjs(specific_time).format("YYYYMM")
    }
  }

  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [sortedInfo, setSortedInfo] = useState({}) // 表格排序用的

  const [alarmInfo, setAlarmInfo] = useState({}) // 触发损耗的类型占比数据
  const [PullList, setPullList] = useState([]) //门店下拉
  const [dataList, setDataList] = useState([]) // 触发告警商品排名列表
  const [loading, setLoading] = useState(false) // 触发告警商品排名列表Loading
  const [weekList, setWeekList] = useState([]) //周报要展示的列表
  const [monthList, setMonthList] = useState([]) //月要展示的列表
  const [dateValue, setDateValue] = useState("") //日报选择的日期值
  const [weekValue, setWeekValue] = useState([]) //周报选择的日期值
  const [monthValue, setMonthValue] = useState("") //月报选择的日期值
  const [ParamsInfo, setParamsInfo] = useState({
    shopId: "", //门店id
    DateTime: DT ? DT : dayjs().format("YYYYMMDD"),
    tabKey: TK,
    alarmNumSort: "", // (图表) 告警次数排序:1-降序 2-升序
    alarmNumSortTable: "", // 告警次数排序:0:不排序 1-降序 2-升序
    alarmRateSort: "", // 损耗率:0:不排序 1-降序 2-升序
    countSort: "", // 称重次数:1-降序 2-升序
    pageNum: 1,
    pageSize: 20,
    total: 0, // 总数
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])
  //

  /**
   * 初始化
   */
  useEffect(() => {
    getPullList() // 门店下拉列表
    onGetWeek() //(调用获取周列表接口方法)
    onGetMonth() //(调用获取月列表接口方法)
    setTimeout(getAlarmStatistics, 0) // 触发告警类型统计(百分比以及异常次数)
    setTimeout(getAlarmShopList, 0) // 触发告警门店排名
    setTimeout(getAlarmGoodsList, 0) // 触发告警商品排名

    // 延迟调用门店列表
    // setTimeout(onGetShopList, 0)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 门店下拉列表
   */
  const getPullList = () => {
    const params = {
      pageNum: 1,
      pageSize: 9999999,
    }
    lossServer
      .Pull_list(params)
      .then((res) => {
        if (res.code == 200) {
          if (res.data) {
            const NewDataList = res.data.list
            NewDataList.unshift({ shopId: "", shopName: "全部" })
            setPullList(res.data.list || [])
          }
        } else {
          setPullList([])
        }
      })
      .catch((err) => {
        setPullList([])
      })
  }

  /**
   * 获取周列表
   */
  const onGetWeek = async () => {
    try {
      const { code, data, msg } = await lossServer.LastWeek()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.label = v.year
          v.value = v.year
          v.children = v.lastWeekRspList
          v.children.map((w) => {
            w.value =
              dayjs(w.weekStartDate).format("YYYYMMDD") +
              "-" +
              dayjs(w.weekEndDate).format("YYYYMMDD")
            w.label =
              w.weekStartDate + "-" + w.weekEndDate + `（第${w.weekOfYear}）周`
          })
        })
      }
      setWeekList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取月列表
   */
  const onGetMonth = async () => {
    try {
      const { code, data, msg } = await lossServer.LastMonth()
      if (code != 200) {
        message.error(msg)
        return
      }
      setMonthList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 当tab值切换时就会useEffect
   */
  useEffect(() => {
    let DateTime = ""
    // 日报
    if (ParamsInfo.tabKey == 1) {
      setDateValue(DT ? DT : dayjs().format(dateFormat))
      // 统计数据传值
      DateTime = DT ? DT : DTIME
    }
    // 周报
    if (ParamsInfo.tabKey == 2) {
      setWeekValue(
        WT.length > 0
          ? WT
          : [
              weekList[0].value,
              weekList[0].children[weekList[0].children.length - 1].value,
            ]
      )
      // 统计数据传值
      DateTime =
        WT.length > 0
          ? WT
          : weekList[0].children[weekList[0].children.length - 1].value
    }
    // 月报
    if (ParamsInfo.tabKey == 3) {
      setMonthValue(
        MT
          ? dayjs(MT).format("YYYY-MM")
          : monthList[monthList.length - 1].yearMonth
      )
      // 统计数据传值
      DateTime = MT
        ? MT
        : dayjs(monthList[monthList.length - 1].yearMonth).format("YYYYMM")
    }
    setParamsInfo({ ...ParamsInfo, DateTime: DateTime })
  }, [ParamsInfo.tabKey])

  /**
   * 触发告警类型统计(百分比以及异常次数)
   */
  const getAlarmStatistics = async () => {
    const { ParamsInfoRef } = refInfo?.current
    try {
      const params = {
        date: ParamsInfoRef.DateTime,
        dateType: ParamsInfoRef.tabKey,
        shopId: ParamsInfoRef.shopId,
      }
      const { code, msg, data } = await lossServer.Alarm_Statistics(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setAlarmInfo(data)
      } else {
        setAlarmInfo({})
      }

      echarts.init(document.getElementById("piehart")).dispose()
      var chartDom = document.getElementById("piehart")
      if (chartDom !== null) {
        var myChart = echarts.init(chartDom)
        var option
        //解决图表不会自适应问题
        const chartObserver = new ResizeObserver(() => {
          myChart.resize()
        })
        chartObserver.observe(chartDom)
        option = {
          tooltip: {
            trigger: "item",
          },
          // legend: {
          //   // orient: "vertical",
          //   // left: "ceter",
          //   orient: "vertical",
          //   width: "auto",
          //   left: "left",
          // },
          graphic: [
            {
              type: "text",
              left: "center",
              top: "43%",
              style: {
                text: `${data?.alarmWarnNum || 0}次`,
                textAlign: "center",
                fill: "#000000",
                fontSize: 23,
                fontWeight: 500,
              },
            },
            {
              //环形图中间添加文字
              type: "text", //通过不同top值可以设置上下显示
              left: "center",
              top: "52%",
              style: {
                text: "触发次数",
                textAlign: "center",
                fill: "#999999", //文字的颜色
                fontSize: 14,
                // lineHeight: 16,
              },
            },
          ],
          series: [
            {
              // name: "Access From",
              type: "pie",
              radius: ["50%", "70%"],
              center: ["50%", "50%"],
              data: [
                {
                  value: data?.monitorCount || 0,
                  name: "监控指定商品被点选告警次数",
                  itemStyle: { color: "#5D7092" },
                },
                // {
                //   value: data?.monitorCountPercent || 0,
                //   name: "监控指定商品被点选告警次数百分比",
                // },
                {
                  value: data?.nonIdentifyCount || 0,
                  name: "AI监控：商品被低价出售告警次数",
                  itemStyle: { color: "#5AD8A6" },
                },
                // {
                //   value: data?.nonIdentifyCountPercent || 0,
                //   name: "AI监控：商品被低价出售告警次数百分比",
                // },
                {
                  value: data?.priceErrCount || 0,
                  name: "监控改价出售告警次数",
                  itemStyle: { color: "#5B8FF9" },
                },
                // {
                //   value: data?.priceErrCountPercent || 0,
                //   name: "监控改价出售告警次数百分比",
                // },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              labelLine: {
                show: false,
              },
              label: {
                show: false,
                position: "center",
                // normal: {
                //   show: true,
                //   formatter: "{b} : {c}%", // 格式化数值百分比输出
                //   formatter: (params) => {
                //     return params.name + " : " + params.value + "次"
                //   },
                // },
              },
            },
          ],
        }

        myChart.setOption(option)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 触发告警门店排名
   */
  const getAlarmShopList = async () => {
    const { ParamsInfoRef } = refInfo?.current
    let Xdata = []
    let Ydata = []
    try {
      const params = {
        date: ParamsInfoRef?.DateTime,
        dateType: ParamsInfoRef?.tabKey,
        alarmNumSort: ParamsInfoRef?.alarmNumSort,
      }
      const { code, msg, data } = await lossServer.Alarm_ShopList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.length > 0) {
        data.map((v) => {
          Xdata.push(v.shopName)
          Ydata.push(v.abnormalNum)
        })
      } else {
        Xdata = []
        Ydata = []
      }
      // 开始画图表
      echarts.init(document.getElementById("shopChart")).dispose()
      var chartDom = document.getElementById("shopChart")
      if (chartDom !== null) {
        var myChart = echarts.init(chartDom)
        var option
        //解决图表不会自适应问题
        const chartObserver = new ResizeObserver(() => {
          myChart.resize()
        })
        chartObserver.observe(chartDom)
        option = {
          tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 12 }, //调节tip文字大小
            backgroundColor: "rgba(255,255,255,0.8)",
          },
          legend: {},
          grid: {
            right: "15%",
            bottom: "-20",
            top: "5%",
            left: 0,
            containLabel: true,
          },
          xAxis: {
            type: "value",
            // boundaryGap: [0, 0.1],
            show: false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
          },
          yAxis: {
            type: "category",
            inverse: true,
            data: Xdata,
            axisTick: {
              show: false, // 不显示坐标轴刻度线
            },
            axisLine: {
              show: false, // 不显示坐标轴刻度线
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#000",
                fontSize: "15",
                itemSize: "",
              },
              formatter: (value, index) => {
                if (value.length > 20) {
                  return value.substring(0, 20) + "..."
                } else {
                  return index + 1 + "   " + value
                }
              },
            },
          },
          series: [
            {
              // name: "2012",
              type: "bar",
              data: Ydata,
              barWidth: "20px",
              showBackground: true,
              backgroundStyle: {
                color: "#ebf8f3",
                borderRadius: 20,
              },
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: "right", //在上方显示
                    textStyle: {
                      //数值样式
                      color: "black",
                      fontSize: 12,
                    },
                    formatter: (params) => {
                      return params.value + "次"
                    },
                  },
                  barBorderRadius: 20,
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    { offset: 0, color: "#38BF87" },
                    { offset: 1, color: "#7EECBF" },
                  ]),
                },
              },
              emphasis: {
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    { offset: 0, color: "#38BF87" },
                    { offset: 0.5, color: "#7EECBF" },
                    { offset: 1, color: "#7EECBF" },
                  ]),
                },
              },
            },
          ],
        }

        myChart.setOption(option)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 触发告警商品排名
   */
  const getAlarmGoodsList = async () => {
    const { ParamsInfoRef } = refInfo?.current
    try {
      const params = {
        ...ParamsInfoRef,
        alarmNumSort: ParamsInfoRef?.alarmNumSortTable, // 告警次数排序:0:不排序 1-降序 2-升序
        date: ParamsInfoRef?.DateTime, // 日期：1日报格式：20230409，2周报格式：20230403-20230409，3月报格式：202304
        dateType: ParamsInfoRef?.tabKey, // 日期类型1：日报，2：周报，3：月报
      }
      setLoading(true)
      const { code, msg, data } = await lossServer.Alarm_GoodsList(params)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      if (data && data.list) {
        setDataList(data.list)
        setLoading(false)
        setParamsInfo({
          ...ParamsInfoRef,
          total: data.total,
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "排名",
      dataIndex: "sortNum",
      ellipsis: { showTitle: true },

      key: "sortNum",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },

      key: "skuName",
      width: 150,
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },

      key: "skuCode",
      width: 150,
    },
    {
      title: "触发次数",
      dataIndex: "abnormalNum",
      ellipsis: { showTitle: true },

      key: "abnormalNum",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo.columnKey === "abnormalNum" ? sortedInfo.order : null,
    },
    {
      title: "称重次数",
      dataIndex: "count",
      ellipsis: { showTitle: true },

      key: "count",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo.columnKey === "count" ? sortedInfo.order : null,
    },
    {
      title: "损耗率",
      dataIndex: "alarmRate",
      ellipsis: { showTitle: true },

      key: "alarmRate",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo.columnKey === "alarmRate" ? sortedInfo.order : null,
      render: (info) => {
        return info ? (info * 100).toFixed(2) + "%" : "0%"
      },
    },
  ]

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${ParamsInfo?.total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: ParamsInfo?.total,
      onChange: (current) => {
        setParamsInfo({
          ...ParamsInfo,
          pageNum: current,
        })
        setTimeout(getAlarmGoodsList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 排序
   */
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)
    // ascend:正序      descend：倒序
    if (sorter.field === "abnormalNum") {
      // 告警次数排序:0:不排序 1-降序 2-升序
      setParamsInfo({
        ...ParamsInfo,
        alarmNumSortTable:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "count") {
      // 称重次数:1-降序 2-升序
      setParamsInfo({
        ...ParamsInfo,
        countSort:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "alarmRate") {
      // 损耗率:0:不排序 1-降序 2-升序
      setParamsInfo({
        ...ParamsInfo,
        alarmRateSort:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      })
    }
    if (!sorter.field) {
      setParamsInfo({
        ...ParamsInfo,
        pageNum: pagination.current,
      })
    }

    setTimeout(getAlarmGoodsList, 0)
  }

  return (
    <div>
      <Card>
        <Row>
          <Col span={20} style={{ display: "flex" }}>
            <Select
              style={{ width: 120 }}
              onChange={(e) => {
                setParamsInfo({ ...ParamsInfo, tabKey: e })
              }}
              placeholder="请选择"
              value={ParamsInfo.tabKey}
            >
              {tabs.map((v) => {
                return (
                  <Option value={v.value} key={v.value}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
            {/* 日期选择 */}
            <div className={styles.shopDetail_page_time}>
              {/* 日期选择框 */}
              {ParamsInfo.tabKey == 1 ? (
                <DatePicker
                  style={{ width: 200 }}
                  onChange={(e) => {
                    setDateValue(dayjs(e).format(dateFormat))
                    setParamsInfo({
                      ...ParamsInfo,
                      DateTime: dayjs(e).format("YYYYMMDD"),
                    })
                  }}
                  value={dayjs(dateValue)}
                  allowClear={false}
                  // suffixIcon={<div></div>}
                  disabledDate={disabledDateDay}
                />
              ) : null}

              {/* 周报选择框 */}
              {ParamsInfo.tabKey == 2 ? (
                <Cascader
                  style={{ width: "auto" }}
                  options={weekList}
                  value={weekValue}
                  onChange={(e) => {
                    setWeekValue(e)
                    setParamsInfo({
                      ...ParamsInfo,
                      DateTime: e[1],
                    })
                  }}
                  placeholder="请选择周"
                />
              ) : null}

              {/* 月报选择框 */}
              {ParamsInfo.tabKey == 3 ? (
                <Select
                  style={{ width: 200 }}
                  value={monthValue}
                  onChange={(e) => {
                    setMonthValue(e)
                    setParamsInfo({
                      ...ParamsInfo,
                      DateTime: dayjs(e).format("YYYYMM"),
                    })
                  }}
                  placeholder="请选择月"
                >
                  {monthList.map((v) => {
                    return (
                      <Option key={v.yearMonth} value={v.yearMonth}>
                        {v.yearMonth}
                      </Option>
                    )
                  })}
                </Select>
              ) : null}
            </div>
          </Col>
          <Col span={4}>
            <div className={styles.loss_analysis_head_right}>
              <Button
                type="primary"
                onClick={() => {
                  setTimeout(getAlarmStatistics, 0)
                  setTimeout(getAlarmShopList, 0)
                  setTimeout(getAlarmGoodsList, 0)
                }}
              >
                查询
              </Button>
              <Button
                onClick={() => {
                  TK = 1
                  setParamsInfo({
                    shopId: "", //门店id
                    DateTime: DT ? DT : dayjs().format("YYYYMMDD"),
                    tabKey: 1,
                    alarmNumSort: "", // (图表) 告警次数排序:1-降序 2-升序
                    alarmNumSortTable: "", // 告警次数排序:0:不排序 1-降序 2-升序
                    alarmRateSort: "", // 损耗率:0:不排序 1-降序 2-升序
                    countSort: "", // 称重次数:1-降序 2-升序
                    pageNum: 1,
                    pageSize: 20,
                    total: 0, // 总数
                  })
                  setAlarmInfo({})
                  setSortedInfo("")
                  setTimeout(getAlarmStatistics, 0)
                  setTimeout(getAlarmShopList, 0)
                  setTimeout(getAlarmGoodsList, 0)
                }}
              >
                重置
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
      <div className={styles.loss_analysis_chart}>
        <Card style={{ width: "49.5%" }} title="触发损耗的类型占比">
          <div className={styles.loss_analysis_chartleft}>
            <div className={styles.loss_analysis_chartleftone}>
              <div style={{ marginBottom: "40px" }}>
                门店名称：
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  showSearch
                  filterOption={(i, o) => o.props.children.includes(i)}
                  style={{ maxWidth: 200, minWidth: 150 }}
                  placeholder="请选择所属门店"
                  allowClear
                  value={ParamsInfo.shopId || undefined}
                  onChange={(e) => {
                    //清除门店管理点击设备数跳转设备管理的门店id
                    window.localStorage.removeItem("shopId")
                    setParamsInfo({ ...ParamsInfo, shopId: e || undefined })
                    setTimeout(getAlarmStatistics, 0)
                  }}
                >
                  {PullList.map((v) => {
                    return (
                      <Option value={v.shopId} key={v.shopId}>
                        {v.shopName}
                      </Option>
                    )
                  })}
                </Select>
              </div>
              <div className={styles.loss_analysis_chartleftone_body}>
                <div className={styles.chartleftone_body_title}>
                  <Badge color="#5B8FF9" text="监控改价出售" />
                </div>
                <div className={styles.chartleftone_body_text}>
                  共触发{alarmInfo?.priceErrCount || 0}次
                </div>
                <div className={styles.chartleftone_body_text}>
                  占比
                  {alarmInfo?.priceErrCountPercent
                    ? (alarmInfo?.priceErrCountPercent * 100).toFixed(2)
                    : 0 || 0}
                  %
                </div>
              </div>
              <div className={styles.loss_analysis_chartleftone_body}>
                <div className={styles.chartleftone_body_title}>
                  <Badge color="#5AD8A6" text="AI监控：商品被低价出售" />
                </div>
                <div className={styles.chartleftone_body_text}>
                  共触发{alarmInfo?.nonIdentifyCount || 0}次
                </div>
                <div className={styles.chartleftone_body_text}>
                  占比
                  {alarmInfo?.nonIdentifyCountPercent
                    ? (alarmInfo?.nonIdentifyCountPercent * 100).toFixed(2)
                    : 0 || 0}
                  %
                </div>
              </div>
              <div className={styles.loss_analysis_chartleftone_body}>
                <div className={styles.chartleftone_body_title}>
                  <Badge color="#5D7092" text="监控指定商品被点选" />
                </div>
                <div className={styles.chartleftone_body_text}>
                  共触发{alarmInfo?.monitorCount || 0}次
                </div>
                <div className={styles.chartleftone_body_text}>
                  占比
                  {alarmInfo?.monitorCountPercent
                    ? (alarmInfo?.monitorCountPercent * 100).toFixed(2)
                    : 0 || 0}
                  %
                </div>
              </div>
            </div>
            <div className={styles.loss_analysis_chartlefttwo}>
              <div id="piehart" style={{ height: "380px" }}></div>
            </div>
          </div>
        </Card>
        <Card
          style={{ width: "49.5%" }}
          title={
            <div
              className={styles.loss_analysis_chartlefttwo_title}
              onClick={() => {
                setParamsInfo({
                  ...ParamsInfo,
                  alarmNumSort:
                    ParamsInfo.alarmNumSort + 1 > 2
                      ? 0
                      : ParamsInfo.alarmNumSort + 1,
                })

                setTimeout(getAlarmShopList, 0)
              }}
            >
              触发损耗的门店排名
              <div className={styles.loss_analysis_chartlefttwo_title_sort}>
                <CaretUpOutlined
                  style={{
                    marginBottom: "-3px",
                    color: ParamsInfo.alarmNumSort == 2 ? "#2eb998" : "",
                  }}
                />
                <CaretDownOutlined
                  style={{
                    marginTop: "-3px",
                    color: ParamsInfo.alarmNumSort == 1 ? "#2eb998" : "",
                  }}
                />
              </div>
            </div>
          }
        >
          <div id="shopChart" style={{ height: "380px" }} />
        </Card>
      </div>

      <Card title={"触发损耗的商品排名"}>
        <Table
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="sortNum"
          loading={loading}
          onChange={handleChange}
          scroll={{ x: "max-content", y: `${WinHeight - 300}px` }}
        />
      </Card>
    </div>
  )
}

export default Index
