import React, { useEffect, useState, useRef } from "react"
import { ToTopOutlined, VideoCameraAddOutlined } from "@ant-design/icons"
import { Input, Select, Button, Table, Modal, message, Form } from "antd"
import * as storeSever from "../../../services/storemanage"
import { Link, useHistory } from "react-router-dom"
import "./Index.css"
const { Option } = Select

function Index() {
  //
  const [EditDeviceOpen, setEditDeviceOpen] = useState(false) // 编辑设备打开Modal
  const [FormValuie, setFormValuie] = useState({
    scaleNo: undefined,
    id: undefined,
    shopId: undefined,
  }) // 设备门店时的回显
  //监控设置
  const [form] = Form.useForm()
  const history = useHistory()
  const [monitorStatus, setMonitorStatus] = useState(false) // 监控Modal状态
  //
  const [editValue, setEditValue] = useState({})
  //
  const [nameValue, setNameValue] = useState() // 1:海康，2：TP-LINK

  //
  const [isModalOpen, setIsModalOpen] = useState(false) //modal弹窗状态
  const [shopList, setShopList] = useState({}) //门店列表数据（父级）
  const [loading, setLoading] = useState(false) //门店列表loading
  const [account, setAccount] = useState("") //添加门店账号的value
  const [shopId, setShopId] = useState("") //门店id（--------用来做门店账号添加的----------）
  const [code, setCode] = useState("") //门店名称/门店账号/激活码
  const [scaleNo, setScaleNo] = useState("") // 秤号
  const [modaltext, setModaltext] = useState("") //动态添加/编辑Modal-title
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [DeciverList, setDeciverList] = useState([]) //产品线列表
  const [DeciverValue, setDeciverValue] = useState("") //选中的产品线
  const [TypeList, setTypeList] = useState([]) //类型列表
  const [TypeValue, setTypeValue] = useState("") //选中的类型
  // const [OnLine, setOnLine] = useState("") //状态
  const [PullList, setPullList] = useState([]) //门店下拉
  const [PullValue, setPullValue] = useState(
    window.localStorage.getItem("shopId")
      ? Number(window.localStorage.getItem("shopId"))
      : ""
  ) //门店value
  const changeSelectRef = useRef({
    codeRef: "",
    deciverValueRef: "",
    typeValueRef: "",
    pullValueRef: "",
    scaleNoRef: "",
  })
  useEffect(() => {
    changeSelectRef.current = {
      codeRef: code,
      deciverValueRef: DeciverValue,
      typeValueRef: TypeValue,
      pullValueRef: PullValue,
      scaleNoRef: scaleNo,
    }
  }, [code, DeciverValue, TypeValue, PullValue, scaleNo])

  useEffect(() => {
    getPullList() //门店下拉列表
    getTreeList() //产品线类型树
    getShopList() //门店列表接口
  }, [])

  /**
   * 查询
   */
  const handlSearch = () => {
    //点击查询时将子集关闭，排除数据污染
    getShopList(1) //门店列表接口，默认为1页码
    setPageNum(1) //页码为1
  }

  /**
   * 重置
   */
  const handClear = async () => {
    //点击查询时将子集关闭，排除数据污染
    setPullValue("")
    setTypeValue("")
    setTypeList([])
    setDeciverValue("")
    setCode("")
    setScaleNo("")
    setPageNum(1) //页码为1
    // getShopList(1) //门店列表接口，默认为1页码
    setTimeout(getShopList, 0)
    //清除门店管理点击设备数跳转设备管理的门店id
    window.localStorage.removeItem("shopId")
  }

  /**
   * 门店下拉列表
   */
  const getPullList = () => {
    const params = {
      pageNum: 1,
      pageSize: 9999999,
    }
    storeSever
      .Pull_list(params)
      .then((res) => {
        if (res.code == 200) {
          if (res.data) {
            setPullList(res.data.list || [])
          }
        } else {
          setPullList([])
        }
      })
      .catch((err) => {
        setPullList([])
      })
  }

  /**
   * 产品线类型树
   */
  const getTreeList = () => {
    storeSever
      .Tree_List()
      .then((res) => {
        if (res.code == 200) {
          setDeciverList(res.data || [])
        } else {
          setDeciverList([])
        }
      })
      .catch((err) => {
        setDeciverList([])
      })
  }

  /**
   * 门店下设备列表
   */
  const getShopList = (info) => {
    let _code = changeSelectRef.current?.codeRef
    let _DeciverValue = changeSelectRef.current?.deciverValueRef
    let _TypeValue = changeSelectRef.current?.typeValueRef
    let _PullValue = changeSelectRef.current?.pullValueRef
    let _ScaleNo = changeSelectRef.current?.scaleNoRef

    const params = {
      keyCode: _code, //激活码
      productLineId: _DeciverValue, //产品线id
      productLineTypeId: _TypeValue, //产品线类型id
      shopId: _PullValue, //门店id
      pageNum: info ? info : pageNum,
      pageSize: pageSize,
      scaleNo: _ScaleNo,
    }
    setLoading(true)
    storeSever
      .Devices_List(params)
      .then((res) => {
        if (res.code == 200) {
          setLoading(false)
          setShopList(res.data)
        }
      })
      .catch((err) => {
        setLoading(false)
        setShopList([])
      })
  }
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${shopList?.total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: shopList?.total,
      onChange: (current) => {
        setPageNum(current)
        getShopList(current)
      },
    }
    return paginationProps
  }

  // 打开modal
  const showModal = (info) => {
    const { shopId, shopAccount } = info
    //判断，当shopAccount有值时为门店账号编辑，否则为添加
    if (shopAccount) {
      setModaltext("编辑")
      setAccount(shopAccount) //编辑时，数据回显
    } else {
      setModaltext("添加")
      setAccount("") //添加门店账号时，保险起见先清除一次输入框
    }
    //存储数据的shopid
    setShopId(shopId || "")
    setIsModalOpen(true)
  }
  //modal确认按钮
  const handleOk = () => {
    if (account.length < 11) {
      message.error("请输入11位门店账号")
      return false
    }
    const params = {
      account: account,
      shopId: shopId,
    }
    storeSever.Add_Account(params).then((res) => {
      if (res.code == 200 && res.data) {
        setShopId("") //清除shopid
        setAccount("") //清除门店账号
        getShopList() //门店列表接口
        setIsModalOpen(false)
        message.success(`门店账号${modaltext}成功`)
      }
    })
  }
  //关闭modal
  const handleCancel = () => {
    setShopId("") //清除shopid
    setAccount("") //清除门店账号
    setModaltext("") //清除modal-title
    setIsModalOpen(false)
  }

  /**
   * 编辑设备，打开Modal
   */
  const onHandOpenModal = (info) => {
    setEditDeviceOpen(true)
    setFormValuie({
      ...FormValuie,
      shopId: info.shopId,
      scaleNo: info.scaleNo,
      id: info.deviceId,
    })
  }

  /**
   * 编辑设备，Modal确认按钮
   */
  const onSubmit = async () => {
    try {
      console.log(FormValuie)
      if (!FormValuie.scaleNo) {
        message.warning("请输入秤号")
        return
      }
      if (!FormValuie.shopId) {
        message.warning("请选择门店")
        return
      }
      const { code, msg, data } = await storeSever.Edit_Device(FormValuie)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("设备编辑成功")
        setEditDeviceOpen(false)
        setFormValuie({
          scaleNo: undefined,
          id: undefined,
          shopId: undefined,
        })
        setPageNum(1) //页码为1
        setTimeout(getShopList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 打开监控设置Modal
   */
  const onOpenModal = async () => {
    try {
      const { code, data, msg } = await storeSever.New_Monito()
      if (code != 200) {
        message.error(msg)
        return
      }
      form.resetFields()
      console.log(data)
      setMonitorStatus(true)
      setNameValue(data.type) // 判断name1是海康还是name2TP-LINK
      setEditValue(data)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监控设置Modal确认按钮
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (editValue?.id) {
          values.id = editValue?.id
          storeSever.Edit_Monito(values).then((res) => {
            if (res.code != 200) {
              return
            }
            setMonitorStatus(false)
            if (res.data) {
              message.success("监控设置成功")
              //调用查询门店下的主账号接口
              setMonitorStatus(false)
            }
          })
        } else {
          storeSever.Add_Monitor(values).then((res) => {
            if (res.code != 200) {
              return
            }
            setMonitorStatus(false)
            if (res.data) {
              message.success("监控设置成功")
              //调用查询门店下的主账号接口
              setMonitorStatus(false)
            }
          })
        }
      })
      .catch((err) => {
        // 校验失败
      })
  }

  //父级，门店列表接口
  const columns = [
    {
      title: "设备SN",
      dataIndex: "deviceSn",

      key: "deviceSn",
      ellipsis: { showTitle: true },
    },
    {
      title: "激活码",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",

      ellipsis: { showTitle: true },
    },
    {
      title: "IP地址",
      dataIndex: "ip",

      ellipsis: { showTitle: true },
      key: "ip",
    },
    {
      title: "所属门店",
      dataIndex: "shopName",

      ellipsis: { showTitle: true },
      key: "shopName",
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",

      ellipsis: { showTitle: true },
      key: "scaleNo",
      width: 100,
    },
    {
      title: "TOP1",
      width: 90,
      dataIndex: "top1",

      ellipsis: { showTitle: true },
      key: "top1",
      width: 100,
      render: (info) => {
        return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
      },
    },
    {
      title: "识别率",
      width: 90,
      dataIndex: "aiOkRate",

      ellipsis: { showTitle: true },
      key: "aiOkRate",
      width: 100,
      render: (info) => {
        return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
      },
    },
    {
      title: "搜索占比",
      dataIndex: "optSearchRate",

      ellipsis: { showTitle: true },
      key: "optSearchRate",
      width: 100,
      render: (info) => {
        return <>{info > 0 ? (info * 100).toFixed(2) + " %" : 0}</>
      },
    },
    {
      title: "使用量",
      width: 80,
      dataIndex: "total",

      ellipsis: { showTitle: true },
      key: "total",
      width: 100,
    },
    {
      title: "产品线",
      dataIndex: "productLineName",

      ellipsis: { showTitle: true },
      key: "productLineName",
      width: 100,
    },
    {
      title: "类型",
      dataIndex: "productLineTypeName",

      ellipsis: { showTitle: true },
      key: "productLineTypeName",
      width: 100,
    },
    {
      title: "版本号",
      dataIndex: "systemVersionName",

      ellipsis: { showTitle: true },
      key: "systemVersionName",
    },
    {
      title: "状态",
      dataIndex: "online",

      ellipsis: { showTitle: true },
      key: "online",
      width: 100,
      render: (info) => {
        return (
          <>
            {info == 0 ? (
              <div style={{ color: "red" }}>离线</div>
            ) : info == 1 ? (
              <div style={{ color: "#2EB998" }}>在线</div>
            ) : (
              ""
            )}
          </>
        )
      },
    },
    {
      title: "操作",

      ellipsis: { showTitle: true },
      fixed: "right",
      render: (info) => {
        // console.log(info)
        return (
          <div className="devicemanage_page_store_table_btn">
            <Link to={{ pathname: "/admin/goodmanage", state: { data: info } }}>
              <div style={{ color: "#2EB998" }}>管理商品</div>
            </Link>
            <a
              style={{ color: "#2EB998" }}
              onClick={() => onHandOpenModal(info)}
            >
              编辑设备
            </a>
            <a
              style={{ color: "#2EB998" }}
              onClick={() => {
                history.push(
                  `/admin/storemanage/bindingCamera?deviceSn=${info?.deviceSn}$cdKeyCode=${info?.cdKeyCode}$scaleNo=${info?.scaleNo}`
                )
              }}
            >
              绑定摄像头
            </a>
            {/* <a
              type="link"
              style={{ color: "#19BC89" }}
              onClick={() => onHandOpenModal(info)}
            >
              编辑设备
            </a> */}
          </div>
        )
      },
    },
  ]
  return (
    <div>
      <div className="devicemanage_page_store_head">
        <div className="devicemanage_page_page_store_search">
          <div className="devicemanage_page_store_head_store_search">
            激活码：
            <Input
              style={{ width: "200px" }}
              placeholder="请输入激活码"
              value={code ? code : undefined}
              onChange={(e) => {
                if (e) {
                  setCode(e.target.value)
                } else {
                  setCode("")
                }
              }}
            />
          </div>
          <div className="devicemanage_page_store_head_store_search">
            所属门店：
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
              style={{ width: 200 }}
              placeholder="请选择所属门店"
              allowClear
              value={PullValue ? PullValue : undefined}
              onChange={(e) => {
                //清除门店管理点击设备数跳转设备管理的门店id
                window.localStorage.removeItem("shopId")
                setPullValue(e ? e : "")
              }}
            >
              {PullList.map((v) => {
                return (
                  <Option value={v.shopId} key={v.shopId}>
                    {v.shopName}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className="devicemanage_page_store_head_store_search">
            产品线：
            <Select
              style={{ width: 200 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
              placeholder="请选择产品线"
              value={DeciverValue ? DeciverValue : undefined}
              allowClear
              onChange={(e) => {
                setDeciverValue(e ? e : "")
                //选择产品线时现将类型的所有清空
                setTypeValue("")
                setTypeList([])
                //通过选中的产品线来找对应的类型
                if (e) {
                  DeciverList.map((v) => {
                    if (v.productLineId == e) {
                      setTypeList(v.productLineTypeList || [])
                    }
                  })
                } else {
                  setTypeList([])
                }
              }}
            >
              {DeciverList.map((v) => {
                return (
                  <Option value={v.productLineId} key={v.productLineId}>
                    {v.productLineName}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className="devicemanage_page_store_head_store_search">
            类型：
            <Select
              style={{ width: 200 }}
              allowClear
              placeholder="请选择类型"
              value={TypeValue ? TypeValue : undefined}
              onChange={(e) => {
                setTypeValue(e ? e : "")
              }}
            >
              {TypeList.map((v) => {
                return (
                  <Option value={v.productLineTypeId} key={v.productLineTypeId}>
                    {v.productLineTypeName}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className="devicemanage_page_store_head_store_search">
            秤号查询：
            <Input
              style={{ width: "200px" }}
              placeholder="请输入秤号"
              value={scaleNo ? scaleNo : undefined}
              onChange={(e) => {
                if (e) {
                  setScaleNo(e.target.value)
                } else {
                  setScaleNo("")
                }
              }}
            />
          </div>
        </div>
        <div className="devicemanage_page_store_btn">
          <Button type="primary" onClick={handlSearch}>
            查询
          </Button>
          <Button onClick={handClear}>重置</Button>
        </div>
      </div>
      {/* 导出，监控设置 */}
      <div className="devicemanage_page_store_caozuo">
        {/* <Button type="primary" icon={<ToTopOutlined />}>
          导出
        </Button> */}
        <Button
          type="primary"
          icon={<VideoCameraAddOutlined />}
          onClick={() => onOpenModal()}
        >
          监控设置
        </Button>
      </div>
      <div>
        <Table
          columns={columns}
          pagination={onTableChange()}
          dataSource={shopList?.list ? shopList?.list : []}
          rowKey="deviceId"
          loading={loading}
          scroll={{ x: "max-content", y: window.screen.height * 0.49 }}
        />
      </div>

      {/* 监控设置 */}

      <Modal
        title="监控设置"
        open={monitorStatus}
        onOk={onFinish}
        destroyOnClose
        onCancel={() => {
          setMonitorStatus(false)
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="摄像头品牌"
            name="type"
            initialValue={editValue?.type || undefined}
            rules={[
              {
                required: true,
                message: "摄像头品牌不能为空!",
              },
            ]}
          >
            <Select
              width={200}
              allowClear
              placeholder="请选择摄像头品牌"
              onChange={(e) => {
                setNameValue(e)
              }}
            >
              {/* <Option key={1} value={1}>
                海康
              </Option> */}
              <Option key={2} value={2}>
                TP-LINK
              </Option>
            </Select>
          </Form.Item>
          {nameValue == 1 ? (
            <Form.Item
              label="IP"
              name="ip"
              initialValue={editValue?.ip || ""}
              rules={[
                {
                  required: true,
                  message: "IP不能为空!",
                },
              ]}
            >
              <Input placeholder="请输入IP！" width={200} maxLength={15} />
            </Form.Item>
          ) : null}
          {nameValue == 1 ? (
            <Form.Item
              label="端口"
              name="port"
              initialValue={editValue?.port || ""}
              rules={[
                {
                  required: true,
                  message: "端口不能为空!",
                },
              ]}
            >
              <Input placeholder="请输入端口！" width={200} maxLength={15} />
            </Form.Item>
          ) : null}

          {nameValue == 2 ? (
            <Form.Item
              label="客户端ID"
              name="terminalId"
              initialValue={editValue?.terminalId || ""}
              rules={[
                {
                  required: true,
                  message: "客户端ID不能为空!",
                },
              ]}
            >
              <Input
                placeholder="请输入客户端ID！"
                width={200}
                maxLength={30}
              />
            </Form.Item>
          ) : null}

          <Form.Item
            label="appKey"
            name="appKey"
            initialValue={editValue?.appKey || ""}
            rules={[
              {
                required: true,
                message: "appKey不能为空!",
              },
            ]}
          >
            <Input placeholder="请输入appKey！" width={200} maxLength={50} />
          </Form.Item>
          <Form.Item
            label="secret"
            name="secret"
            initialValue={editValue?.secret || ""}
            rules={[
              {
                required: true,
                message: "secret不能为空!",
              },
            ]}
          >
            <Input placeholder="请输入secret！" width={200} maxLength={50} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 添加、编辑门店账号 */}
      <Modal
        width={500}
        title={modaltext + "门店账号"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确认"
        cancelText="取消"
      >
        <div className="page_store_modal">
          门店账号：
          <Input
            placeholder="请输入门店账号"
            maxLength={11}
            value={account}
            onChange={(e) => {
              setAccount(e.target.value)
            }}
          />
        </div>
      </Modal>

      {/* 编辑设备 */}
      <Modal
        title="编辑设备"
        open={EditDeviceOpen}
        keyboard
        maskClosable
        onOk={() => onSubmit()}
        onCancel={() => {
          setEditDeviceOpen(false)
        }}
      >
        <div className="page_edit_device_modal">
          <div className="page_edit_device_modal_child">
            <div className="page_edit_device_modal_childname">
              秤号： <span>*</span>
            </div>
            <Input
              placeholder="请输入秤号"
              style={{ width: "200px" }}
              value={FormValuie?.scaleNo}
              showCount
              maxLength={20}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, scaleNo: e.target.value })
              }}
            />
          </div>
          <div className="page_store_select">
            <div className="page_edit_device_modal_child">
              <div className="page_edit_device_modal_childname">
                所属门店： <span>*</span>
              </div>
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属门店"
                style={{ width: 200 }}
                allowClear
                value={FormValuie?.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setFormValuie({ ...FormValuie, shopId: e })
                }}
              >
                {PullList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Index
