import {
  Button,
  Table,
  DatePicker,
  Select,
  message,
  Popconfirm,
  Card,
  Tooltip,
  InputNumber,
  Modal,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import { QuestionCircleOutlined, SettingOutlined } from "@ant-design/icons"
import * as datareportSever from "../../services/datareport"
import PubInput from "../../../components/PubInput"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"
import styles from "./Index.module.css"
import { render } from "@testing-library/react"
import { disabledDateWeek, disabledDateDay } from "../../../utils/util"
const { RangePicker } = DatePicker
const { Option } = Select
moment.locale("zh-cn")

const today = dayjs()
const sixtyDaysAgo = today.subtract(60, "day")
console.log(sixtyDaysAgo)
/**
 * 绩效统计
 */
function Index() {
  const history = useHistory()
  //排序类型
  const [sortedInfo, setSortedInfo] = useState({})
  //
  const [weekList, setWeekList] = useState([]) //周报要展示的列表
  const [monthList, setMonthList] = useState([]) //月要展示的列表
  const [total, setTotal] = useState() //每页显示的行数
  const [totalAgain, setTotalAgain] = useState() //每页显示的行数导出记录
  const [loading, setLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) //表格选中数据
  const [conveAmount, setConveAmount] = useState(null) // 折算金额
  const [popconfirmOpen, setPopconOpen] = useState(false) // 折算金额弹窗状态
  const [exportRecordOpen, setExportRecordOpen] = useState(false) // 导出记录弹窗状态
  const [exportRecordLoding, setExportRecordLoding] = useState(false) /// 导出记录列表Loading
  const [customTimeExport, setCustomTimeExport] = useState(false) // 自定义时间导出弹窗状态
  const [exportRecordList, setExportRecordList] = useState([]) // 导出记录列表
  const [shopList, setShopList] = useState([]) //门店列表
  const [dataList, setDataList] = useState([]) //表格列表
  const [GiveParams, setGiveParams] = useState({
    operator: undefined,
    shopId: undefined,
    pageSize: 20,
    pageNum: 1,
    ids: [],
    reportType: 1,
    new_dateId: dayjs().format("YYYYMMDD"),
    orderType: undefined, // 排序传值
    new_dateId_week: "", // 周日期（单独领出来）
  })
  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  /**
   * 导出记录字段 ===>
   */
  const [GiveParamsAgain, setGiveParamsAgain] = useState({
    timeArr: HandDefaultTime?.TotalTime,
    startDate: HandDefaultTime?.startDate,
    endDate: HandDefaultTime?.endDate,
    status: undefined, // 状态
    pageNum: 1,
    pageSize: 20,
    total: 0,
    exportTypes: [2, 3],
  })

  //保证每次拿到的都是准确的值
  const updataRefAgain = useRef({
    GiveParamsRefAgain: {},
  })

  useEffect(() => {
    updataRefAgain.current = {
      GiveParamsRefAgain: GiveParamsAgain,
    }
  }, [GiveParamsAgain])

  //<===

  /**
   * 自定义导出字段 ===>
   */
  const [CustomTimeParams, setCustomTimeParams] = useState({
    timeArr: HandDefaultTime?.TotalTime,
    startDate: HandDefaultTime?.startDateValueOf,
    endDate: HandDefaultTime?.endDateValueOf,
  })

  //保证每次拿到的都是准确的值
  const updataRefCustom = useRef({
    CustomTimeParamsRef: {},
  })

  useEffect(() => {
    updataRefCustom.current = {
      CustomTimeParamsRef: CustomTimeParams,
    }
  }, [CustomTimeParams])

  /**
   * 导出
   */
  const onExportData = async () => {
    try {
      const { CustomTimeParamsRef } = updataRefCustom.current
      const params = {
        ...CustomTimeParamsRef,
        timeArr: [],
      }
      console.log(params)
      const { code, data, msg } = await datareportSever.Export_Staff_Report(
        params
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("自定义时间导出成功")
        setCustomTimeExport(false)
        setGiveParamsAgain({
          timeArr: HandDefaultTime?.TotalTime,
          startDate: HandDefaultTime?.startDate,
          endDate: HandDefaultTime?.endDate,
          status: undefined, // 状态
          pageNum: 1,
          pageSize: 20,
          exportTypes: [2, 3],
        })
        setTimeout(getStaffWeightList, 0)
        setExportRecordOpen(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //<===

  useEffect(() => {
    const tabKey = GiveParams.reportType
    let DateTime = ""
    // 日报
    if (tabKey == 1) {
      // 统计数据传值
      DateTime = dayjs().format("YYYYMMDD")
    }
    // 周报
    if (tabKey == 2) {
      // 统计数据传值
      DateTime = dayjs().format("YYYYMMDD")
      setTimeout(getWeekStartOrEnd, 0)
    }
    // 月报
    if (tabKey == 3) {
      // 统计数据传值
      // DateTime = monthList[monthList.length - 1].yearMonth
      DateTime = dayjs().format("YYYY-MM")
    }
    setGiveParams({ ...GiveParams, new_dateId: DateTime })
  }, [GiveParams.reportType])

  useEffect(() => {
    //请求门店列表接口
    getShopList()
    //表格数据列表接口请求
    getStaffWeight()
    // // 获取周列表
    // getWeek()
    // // 获取月列表
    // getMonth()
  }, [])

  /**
   * 获取周列表
   */
  // const getWeek = async () => {
  //   try {
  //     const { code, data, msg } = await homeSever.LastWeek()
  //     if (code != 200) {
  //       message.error(msg)
  //       return
  //     }
  //     if (data.length > 0) {
  //       data.map((v) => {
  //         v.label = v.year
  //         v.value = v.year
  //         v.children = v.lastWeekRspList
  //         v.children.map((w) => {
  //           w.value =
  //             dayjs(w.weekStartDate).format("YYYYMMDD") +
  //             "-" +
  //             dayjs(w.weekEndDate).format("YYYYMMDD")
  //           w.label =
  //             w.weekStartDate + "-" + w.weekEndDate + `（第${w.weekOfYear}）周`
  //         })
  //       })
  //     }
  //     setWeekList(data || [])
  //   } catch (error) {
  //     console.log(error)
  //     message.error("周列表获取失败")
  //   }
  // }

  /**
   * 获取月列表
   */
  // const getMonth = async () => {
  //   try {
  //     const { code, data, msg } = await homeSever.LastMonth()
  //     if (code != 200) {
  //       message.error(msg)
  //       return
  //     }
  //     setMonthList(data || [])
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  //门店列表接口请求
  const getShopList = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 }
      const res = await datareportSever.Shop_List(params)
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data?.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取绩效折算金额
   */
  const getConverted_Amount = async () => {
    try {
      const { code, data, msg } = await datareportSever.GetConverted_Amount()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        if (data?.convertedAmount || data?.convertedAmount == 0) {
          setConveAmount(`${data?.convertedAmount}` || null)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 设置单笔称重折算确认按钮
   */
  const onConveAmount = async () => {
    try {
      console.log(conveAmount)
      if (!conveAmount) {
        message.error("请输入单笔称重折算金额")
        return
      }
      const params = {
        convertedAmount: conveAmount,
      }
      const { code, data, msg } = await datareportSever.SetConverted_Amount(
        params
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setPopconOpen(false)
        message.success("设置单笔称重折算金额成功！")
        setTimeout(getStaffWeight, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *
   * @returns 导出记录列表数据
   */
  const getStaffWeightList = async () => {
    try {
      const { GiveParamsRefAgain } = updataRefAgain.current
      const params = {
        ...GiveParamsRefAgain,
        timeArr: [],
      }
      setExportRecordLoding(true)
      const { code, data, msg } = await datareportSever.Export_Work_List(params)
      if (code != 200) {
        message.error(msg)
        setExportRecordLoding(false)
        return
      }
      setExportRecordLoding(false)
      setExportRecordList(data.list || [])
      setTotalAgain(data.total || 0)
    } catch (error) {
      console.log(error)
      setExportRecordLoding(false)
    }
  }

  //表格数据列表接口请求
  const getStaffWeight = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      let params = GiveParamsRef
      if (GiveParamsRef.reportType == 1) {
        params.dateId = GiveParamsRef.new_dateId
      }
      if (GiveParamsRef.reportType == 2) {
        params.dateId = GiveParamsRef.new_dateId_week
      }
      if (GiveParamsRef.reportType == 3) {
        params.dateId = GiveParamsRef.new_dateId.replace("-", "")
      }
      setLoading(true)
      const { code, msg, data } =
        await datareportSever.GetStaffWeightReportPage(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setLoading(false)
      if (!data) {
        setTotal(0)
        setDataList([])
        return
      }
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //导出
  const getExport = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      let params = GiveParamsRef
      if (GiveParamsRef.reportType == 1) {
        params.dateId = GiveParamsRef.new_dateId
      }
      if (GiveParamsRef.reportType == 2) {
        params.dateId = GiveParamsRef.new_dateId_week
      }
      if (GiveParamsRef.reportType == 3) {
        params.dateId = GiveParamsRef.new_dateId.replace("-", "")
      }
      const res = await datareportSever.ExportStaffReport(params)
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      if (res?.data) {
        message.success("导出成功")
        // 导出成功后，导出记录Modal打开
        setGiveParamsAgain({
          timeArr: HandDefaultTime?.TotalTime,
          startDate: HandDefaultTime?.startDate,
          endDate: HandDefaultTime?.endDate,
          status: undefined, // 状态
          pageNum: 1,
          pageSize: 20,
          exportTypes: [2, 3],
        })
        setTimeout(getStaffWeightList, 0)
        setExportRecordOpen(true)
      }
    } catch (error) {
      console.log(error)
      // message.error(error)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
    }
    return paginationProps
  }

  /**
   * 导出记录分页
   */
  const onTableChangeAgain = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${totalAgain}条`,
      pageSize: GiveParamsAgain?.pageSize,
      current: GiveParamsAgain?.pageNum,
      total: totalAgain,
    }
    return paginationProps
  }
  const onHandleChangeAgain = (pagination) => {
    setGiveParamsAgain({
      ...GiveParamsAgain,
      pageNum: pagination.current,
    })
    setTimeout(getStaffWeightList, 0)
  }

  /**
   * 排序
   */
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)

    // ascend:正序      descend：倒序
    if (sorter.field === "count") {
      setGiveParams({
        ...GiveParams,
        orderType:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "abnormalNum") {
      setGiveParams({
        ...GiveParams,
        orderType:
          sorter.order === "ascend" ? 4 : sorter.order === "descend" ? 3 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "weight") {
      setGiveParams({
        ...GiveParams,
        orderType:
          sorter.order === "ascend" ? 6 : sorter.order === "descend" ? 5 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "num") {
      setGiveParams({
        ...GiveParams,
        orderType:
          sorter.order === "ascend" ? 8 : sorter.order === "descend" ? 7 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "selectDuration") {
      setGiveParams({
        ...GiveParams,
        orderType:
          sorter.order === "ascend" ? 10 : sorter.order === "descend" ? 9 : "",
        pageNum: pagination.current,
      })
    }
    if (!sorter.field) {
      setGiveParams({
        ...GiveParams,
        ids: [],
        pageNum: pagination.current,
      })
      setSelectedRowKeys([])
    }

    setTimeout(getStaffWeight, 0)
  }

  /**
   * 获取当前选中日期的周开始日期与结束日期
   */
  const getWeekStartOrEnd = (e) => {
    // 获取当前日期
    var currentDate = e ? new Date(e) : new Date()

    // 获取当前日期对应的星期几
    var dayOfWeek = currentDate.getDay()

    // 计算本周第一天的日期
    if (dayOfWeek !== 0) {
      // 若今天不是星期天
      var firstDayOfWeek = new Date(currentDate)
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() - dayOfWeek + 1)
    } else {
      // 若今天就是星期天
      var firstDayOfWeek = new Date(currentDate)
    }

    // 计算本周最后一天的日期
    var lastDayOfWeek = new Date(firstDayOfWeek)
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)

    // 输出结果
    console.log("本周起始时间：" + dayjs(firstDayOfWeek).format("YYYYMMDD"))
    console.log("本周结束时间：" + dayjs(lastDayOfWeek).format("YYYYMMDD"))
    setGiveParams({
      ...GiveParams,
      new_dateId_week:
        dayjs(firstDayOfWeek).format("YYYYMMDD") +
        "-" +
        dayjs(lastDayOfWeek).format("YYYYMMDD"),
      new_dateId: dayjs(e).format("YYYYMMDD"),
    })
  }

  const columns = [
    {
      title: "员工工号",
      dataIndex: "staffName",
      ellipsis: { showTitle: true },
      key: "staffName",
      width: 200,
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
    },
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 200,
    },
    {
      title: "所属区域",
      dataIndex: "owningRegion",
      ellipsis: { showTitle: true },
      key: "owningRegion",
      width: 200,
    },
    {
      title: "称重笔数",
      dataIndex: "count",
      ellipsis: { showTitle: true },
      key: "count",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo?.columnKey === "count" ? sortedInfo?.order : null,
    },
    {
      title: "绩效折算金额",
      filterDropdown: <div></div>,
      filterIcon: (
        <Popconfirm
          open={popconfirmOpen}
          icon={null}
          title={() => {
            return (
              <div className={styles.weightdata_table_single}>
                设置单笔称重折算为
                <InputNumber
                  keyboard
                  min={0}
                  maxLength={9}
                  addonAfter="元"
                  style={{ width: 150, marginLeft: "20px" }}
                  placeholder="绩效折算金额"
                  value={conveAmount}
                  onChange={(value) => {
                    var regex = /^\d+(\.\d{1,3})?$/
                    var isValid = regex.test(value)
                    if (!isValid) {
                      message.error("请输入数字，最多小数点后三位")
                      return
                    }
                    setConveAmount(`${value}`)
                  }}
                />
              </div>
            )
          }}
          onConfirm={onConveAmount}
          onCancel={() => setPopconOpen(false)}
          okText="确定"
          // showCancel={false}
          destroyTooltipOnHide
          onClick={(info, rowInfo) => {
            setConveAmount(null)
          }}
        >
          <SettingOutlined
            style={{ color: "grey", fontSize: "16px" }}
            onClick={() => {
              // 获取绩效折算金额
              setTimeout(getConverted_Amount, 0)
              setPopconOpen(true)
            }}
          />
        </Popconfirm>
      ),
      dataIndex: "convertedAmount",
      ellipsis: { showTitle: true },
      key: "convertedAmount",
      width: 160,
      render: (info) => {
        return <>{info}</>
      },
    },
    {
      title: "损耗触发次数",
      dataIndex: "abnormalNum",
      ellipsis: { showTitle: true },
      key: "abnormalNum",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "abnormalNum" ? sortedInfo?.order : null,
    },
    {
      title: "称重重量（KG）",
      filterDropdown: <div></div>,
      width: 180,
      filterIcon: (
        <Tooltip placement="top" title="计重商品，商品打秤的总重量">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "weight",
      ellipsis: { showTitle: true },
      key: "weight",
      width: 160,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo?.columnKey === "weight" ? sortedInfo?.order : null,
      render: (info) => {
        return <>{info > 0 ? (info / 1000).toFixed(2) : 0}</>
      },
    },
    {
      title: "称重数量",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="计数商品，商品打秤的总数量">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "num",
      ellipsis: { showTitle: true },
      key: "num",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo?.columnKey === "num" ? sortedInfo?.order : null,
    },
    {
      title: "平均点击时长（毫秒）",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip
          placement="top"
          title="从识别结果展示-点击或者搜索的时间的平均值"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "selectDuration",
      ellipsis: { showTitle: true },
      key: "selectDuration",
      width: 200,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "selectDuration" ? sortedInfo?.order : null,
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 100,
      fixed: "right",
      render: (info, rowInfo) => {
        return (
          <>
            {/* <a
              style={{ color: "#2EB998" }}
              onClick={(info) => {
                console.log(rowInfo)
                history.push(
                  `/admin/datareport/weightrecord?time=${GiveParams.new_dateId}&timeType=${GiveParams.reportType}&newOperator=${rowInfo.staffName}`
                )
              }}
            >
              查看详情
            </a> */}
            <Link
              to={{
                pathname: "/admin/datareport/weightrecord",
                state: {
                  tabKey: GiveParams.reportType,
                  specific_time: GiveParams.new_dateId,
                  staffName: rowInfo.staffName,
                  shopId: rowInfo?.shopId,
                },
              }}
            >
              查看详情
            </Link>
          </>
        )
      },
    },
  ]

  const columnsAgain = [
    {
      title: "操作人",
      dataIndex: "userName",
      ellipsis: { showTitle: true },
      key: "userName",
      width: 200,
    },
    {
      title: "操作时间",
      dataIndex: "createdAt",
      ellipsis: { showTitle: true },
      key: "createdAt",
      width: 200,
    },
    {
      title: "文件生成时间",
      dataIndex: "fileCreateAt",
      ellipsis: { showTitle: true },
      key: "fileCreateAt",
      width: 200,
    },
    {
      title: "文件失效时间",
      dataIndex: "fileLoseAt",
      ellipsis: { showTitle: true },
      key: "fileLoseAt",
      width: 200,
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="文件只保留72小时">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
    },
    {
      title: "文件生成类型",
      dataIndex: "exportType",
      ellipsis: { showTitle: true },
      key: "exportType",
      width: 200,
      render: (info) => {
        return (
          <div>
            {info == 1
              ? "损耗记录导出"
              : info == 2
              ? "绩效统计导出"
              : info == 3
              ? "自定义绩效统计导出"
              : ""}
          </div>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: { showTitle: true },
      key: "status",
      width: 200,
      render: (info) => {
        return (
          <div
            style={{
              color:
                info == 1 || info == 2
                  ? "orange"
                  : info == 3
                  ? "#2eb998"
                  : info == 4
                  ? "red"
                  : "",
            }}
          >
            {info == 1
              ? "等待中"
              : info == 2
              ? "处理中"
              : info == 3
              ? "处理完成"
              : info == 4
              ? "处理失败"
              : ""}
          </div>
        )
      },
    },
    {
      title: "操作",
      dataIndex: "fileUrl",
      ellipsis: { showTitle: true },
      key: "fileUrl",
      width: 200,
      render: (info) => {
        return (
          <Button disabled={info ? false : true} type="link" href={info}>
            下载文件
          </Button>
        )
      },
    },
    {
      title: "失败消息",
      dataIndex: "message",
      ellipsis: { showTitle: true },
      key: "message",
      width: 200,
    },
  ]

  //查询
  const handSearch = () => {
    //给接口传的参数赋值
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getStaffWeight, 0)
  }

  //重置
  const handClear = () => {
    setSelectedRowKeys([])
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      operator: undefined,
      shopId: undefined,
      ids: [],
      reportType: 1,
      new_dateId: dayjs().format("YYYYMMDD"),
      orderType: undefined, // 排序传值
      new_dateId_week: "", // 周日期（单独领出来）
    })
    setTimeout(getStaffWeight, 0)
  }

  return (
    <div className={styles.performance_page}>
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.weightdatabody_performance_page_search}>
          <div className={styles.weightdatabody_performance_search_body}>
            {/* 日报周报月报 */}
            <div className={styles.performance_search_body_child}>
              <div
                className={
                  GiveParams.reportType == 1 ? styles.ribao_check : styles.ribao
                }
                onClick={() => {
                  setGiveParams({ ...GiveParams, reportType: 1 })
                }}
              >
                日报
              </div>
              <div
                className={
                  GiveParams.reportType == 2 ? styles.ribao_check : styles.ribao
                }
                onClick={() => {
                  setGiveParams({ ...GiveParams, reportType: 2 })
                }}
              >
                周报
              </div>
              <div
                className={
                  GiveParams.reportType == 3 ? styles.ribao_check : styles.ribao
                }
                onClick={() => {
                  setGiveParams({ ...GiveParams, reportType: 3 })
                }}
              >
                月报
              </div>
              {GiveParams.reportType == 1 ? (
                <DatePicker
                  disabledDate={disabledDateDay}
                  style={{ width: 200, marginLeft: "20px" }}
                  value={dayjs(GiveParams.new_dateId)}
                  allowClear={false}
                  onChange={(e) => {
                    setGiveParams({
                      ...GiveParams,
                      new_dateId: dayjs(e).format("YYYYMMDD"),
                    })
                  }}
                />
              ) : GiveParams.reportType == 2 ? (
                <DatePicker
                  disabledDate={disabledDateWeek}
                  style={{ width: 200, marginLeft: "20px" }}
                  value={dayjs(GiveParams.new_dateId, "YYYYMMDD")}
                  placeholder="请选择周"
                  allowClear={false}
                  onChange={(date) => {
                    getWeekStartOrEnd(date)
                  }}
                  picker="week"
                />
              ) : GiveParams.reportType == 3 ? (
                // <Select
                //   style={{ width: 200, marginLeft: "20px" }}
                //   value={GiveParams.new_dateId}
                //   onChange={(e) => {
                //     setGiveParams({
                //       ...GiveParams,
                //       new_dateId: e,
                //     })
                //   }}
                //   placeholder="请选择月"
                // >
                //   {monthList.map((v) => {
                //     return (
                //       <Option key={v.yearMonth} value={v.yearMonth}>
                //         {v.yearMonth}
                //       </Option>
                //     )
                //   })}
                // </Select>
                <DatePicker
                  style={{ width: 200, marginLeft: "20px" }}
                  value={dayjs(GiveParams.new_dateId, "YYYY-MM")}
                  placeholder="请选择月"
                  allowClear={false}
                  onChange={(e) => {
                    setGiveParams({
                      ...GiveParams,
                      new_dateId: dayjs(e).format("YYYY-MM"),
                    })
                  }}
                  picker="month"
                />
              ) : null}
            </div>
            <div className={styles.performance_search_body_child}>
              <span>所属门店：</span>
              <Select
                placeholder="请选择门店"
                allowClear
                style={{ width: 200 }}
                value={GiveParams.shopId}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) =>
                  setGiveParams({ ...GiveParams, shopId: e || undefined })
                }
              >
                {shopList.map((v) => {
                  return (
                    <Option value={v.shopId} key={v.shopId}>
                      {v.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div className={styles.performance_search_body_child}>
              <span>员工工号：</span>
              <PubInput
                onChange={(val) =>
                  setGiveParams({
                    ...GiveParams,
                    operator: val,
                  })
                }
                placeholder="员工工号"
                value={GiveParams.operator}
              />
            </div>
          </div>
          <div className={styles.weightdatabody_performance_search_button}>
            <Button type="primary" onClick={() => handSearch()}>
              查询
            </Button>
            <Button onClick={() => handClear()}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <div className={styles.performance_page_body}>
          {/* 导出 */}
          <Button
            type="primary"
            onClick={getExport}
            style={{ marginBottom: "20px" }}
          >
            导出数据
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setCustomTimeParams({
                timeArr: HandDefaultTime?.TotalTime,
                startDate: HandDefaultTime?.startDateValueOf,
                endDate: HandDefaultTime?.endDateValueOf,
              })
              setCustomTimeExport(true)
            }}
            style={{ marginBottom: "20px", marginLeft: "20px" }}
          >
            自定义导出
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setGiveParamsAgain({
                timeArr: HandDefaultTime?.TotalTime,
                startDate: HandDefaultTime?.startDate,
                endDate: HandDefaultTime?.endDate,
                status: undefined, // 状态
                pageNum: 1,
                pageSize: 20,
                exportTypes: [2, 3],
              })
              setTimeout(getStaffWeightList, 0)
              setExportRecordOpen(true)
            }}
            style={{ marginBottom: "20px", marginLeft: "20px" }}
          >
            导出记录
          </Button>
          {/* 表格数据 */}
          <Table
            dataSource={dataList}
            columns={columns}
            pagination={onTableChange()}
            rowKey="id"
            loading={loading}
            scroll={{ x: "max-content", y: window.screen.height * 0.49 }}
            onChange={handleChange}
          />
        </div>
      </Card>
      {/* 导出记录Modal */}
      <Modal
        title="导出记录"
        open={exportRecordOpen}
        onCancel={() => setExportRecordOpen(false)}
        width="80%"
        keyboard
        footer={null}
      >
        <div>
          <div className={styles.weightdatabody_performance_page_search}>
            <div className={styles.weightdatabody_performance_search_body}>
              <div className={styles.performance_search_body_child}>
                <span>导出时间：</span>
                <PubRangePicker
                  disDate={30}
                  value={GiveParamsAgain?.timeArr}
                  onChange={(val) => {
                    setGiveParamsAgain({
                      ...GiveParamsAgain,
                      timeArr: val.TotalTime,
                      startDate: val.startDate,
                      endDate: val.endDate,
                    })
                  }}
                />
              </div>
              <div className={styles.performance_search_body_child}>
                <span>状态：</span>
                <Select
                  style={{ width: 200 }}
                  allowClear
                  onChange={(e) => {
                    setGiveParamsAgain({
                      ...GiveParamsAgain,
                      status: e,
                    })
                  }}
                  value={GiveParamsAgain.status}
                  placeholder="请选择状态"
                >
                  <Option value="1">等待中</Option>
                  <Option value="2">处理中</Option>
                  <Option value="3">处理完成</Option>
                  <Option value="4">处理失败</Option>
                </Select>
              </div>
            </div>
            <div className={styles.weightdatabody_performance_search_button}>
              <Button
                type="primary"
                onClick={() => {
                  setGiveParamsAgain({
                    ...GiveParamsAgain,
                    pageNum: 1,
                    pageSize: 20,
                  })
                  setTimeout(getStaffWeightList, 0)
                }}
              >
                查询
              </Button>
              <Button
                onClick={() => {
                  setGiveParamsAgain({
                    timeArr: HandDefaultTime?.TotalTime,
                    startDate: HandDefaultTime?.startDate,
                    endDate: HandDefaultTime?.endDate,
                    status: undefined, // 状态
                    pageNum: 1,
                    pageSize: 20,
                    exportTypes: [2, 3],
                  })
                  setTimeout(getStaffWeightList, 0)
                }}
              >
                重置
              </Button>
            </div>
          </div>
          <Table
            rowKey="id"
            dataSource={exportRecordList}
            columns={columnsAgain}
            style={{ marginTop: "20px" }}
            loading={exportRecordLoding}
            pagination={onTableChangeAgain()}
            onChange={onHandleChangeAgain}
            scroll={{ x: "max-content", y: window.screen.height * 0.4 }}
          />
        </div>
      </Modal>
      {/* 自定义导出Modal */}
      <Modal
        title="自定义导出"
        open={customTimeExport}
        onCancel={() => setCustomTimeExport(false)}
        footer={null}
        keyboard
      >
        <div className={styles.custom_time_export}>
          <div>
            导出时间：
            <PubRangePicker
              value={CustomTimeParams?.timeArr}
              disDate={124}
              onChange={(val) => {
                setCustomTimeParams({
                  ...CustomTimeParams,
                  timeArr: val.TotalTime,
                  startDate: val.startDateValueOf,
                  endDate: val.endDateValueOf,
                })
              }}
            />
          </div>
          <Button type="primary" onClick={() => onExportData()}>
            导出
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default Index
