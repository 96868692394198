import axios from "axios"
import { message, notification } from "antd"
import moment from "moment"
import { getToken } from "./auth"
import { configs } from "../config/api.config.js"

/* export const baseAuthURL = `${configs[process.env.API_ENV].API_SERVER}/api`; */
export const baseAuthURL = `${
  configs[process.env.REACT_APP_RUNTIME_TYPE].API_SERVER
}`
export const new_baseAuthURL = `${
  configs[process.env.REACT_APP_RUNTIME_TYPE].NEW_API_SERVER
}`

function checkAccessTokenExpires(expiresAt) {
  const now = moment().unix()
  if (expiresAt - now <= 0) {
    return -1
  }
  if (expiresAt - now <= 600) {
    return 0
  }
  return 1
}

async function getAccessToken() {
  const tokenInfo = getToken()
  if (!tokenInfo) {
    return ""
  }
  // console.log(tokenInfo);

  return `${tokenInfo}`
}

export default async function request(url, options, newApi) {
  let baseURL = baseAuthURL
  if (newApi) baseURL = new_baseAuthURL
  if (url.indexOf("/eapi") == 0) {
    baseURL = ""
    console.log(baseURL)
  }

  let showNotify = true
  const opts = {
    baseURL,
    url,
    validateStatus() {
      return true
    },
    ...options,
  }
  if (opts.notNotify) {
    showNotify = false
  }

  const defaultHeader = {
    "data-center-token": await getAccessToken(),
  }
  if (opts.method === "POST" || opts.method === "PUT") {
    defaultHeader["Content-Type"] = "application/json; charset=utf-8"
    opts.data = opts.body
  }

  opts.headers = { ...defaultHeader, ...opts.headers }
  if (opts.responseType === "blob") {
    return axios.request(opts).then((response) => {
      const { status, data } = response
      if (data.type === "application/json") {
        var jsonReader = new FileReader()
        jsonReader.onload = function (event) {
          var content = jsonReader.result //内容就在这里
          const json = JSON.parse(content)
          message.error(json.msg || `请求失败`, 5)
        }
        jsonReader.readAsText(data)
        return
      }
      if (status >= 200 && status < 300) {
        let fileName = opts.defaultFileName
        if (response.headers["content-disposition"]) {
          let contentDisposition =
            response.headers["content-disposition"].split(";")
          let contentDispositionMap = {}
          for (const item of contentDisposition) {
            let tmp = item.split("=")
            if (tmp.length > 1) {
              contentDispositionMap[tmp[0]] = tmp.slice(1).join("=")
            } else {
              contentDispositionMap[tmp[0]] = ""
            }
          }
          if (contentDispositionMap["filename"]) {
            fileName = decodeURI(contentDispositionMap["filename"]).trim(" ")
          }
        }
        let elink = document.createElement("a")
        elink.download = fileName
        elink.href = window.URL.createObjectURL(response.data)
        elink.click()
        window.URL.revokeObjectURL(elink.href) // 释放URL 对象
        elink = null
        return data
      }

      const reader = new FileReader() // 创建读取文件对象
      reader.onload = (e) => {
        const json = JSON.parse(e.target.result) // 返回的数据
        if (status === 401) {
          const {
            error: { code },
          } = json
          if (code === 9999) {
            // 清空访问令牌
            window.localStorage.removeItem("token")
            window.location = window.location.origin + "/#login"
            return {}
          }
        }
        const error = {
          code: 0,
          message: "服务器发生错误",
        }

        if (status === 504) {
          error.message = "未连接到服务器"
        } else if (json) {
          const {
            error: { message, code },
          } = json
          error.message = message
          error.code = code
        } else if (status >= 400 && status < 500) {
          error.message = "请求发生错误"
        }
        if (showNotify) {
          notification.error({
            message: `ERROR\n${opts.baseURL}${opts.url}`,
            description: error.message,
          })
        }
        return { error, status }
      }
      reader.readAsText(data, "utf-8") // 设置读取的数据以及返回的数据类型为utf-8
      return {}
    })
  }

  return axios
    .request(opts)
    .then((response) => {
      const { status, data } = response
      if (status >= 200 && status < 300) {
        if (data.code !== 200 && data.msg) {
          notification.error({
            message: `请求失败\n${opts.url.match(/^[^\?]*/)[0]}`,
            description: data.msg,
          })
          if (data.code === 401) {
            // 清空访问令牌
            window.localStorage.removeItem("token")
            window.location = window.location.origin + "/#login"
            return {}
          }
        }
        return data
      }
      if (status === 401) {
        const {
          error: { code },
        } = data
        if (code === 9999) {
          // 清空访问令牌
          window.localStorage.removeItem("token")
          window.location = window.location.origin + "/#login"
          return {}
        }
      }

      const error = {
        code: 0,
        message: "服务器发生错误",
      }
      if (status === 504) {
        error.message = "未连接到服务器"
      } else if (data) {
        const {
          error: { message, code },
        } = data
        error.message = message
        error.code = code
      } else if (status >= 400 && status <= 500) {
        error.message = "请求发生错误"
      }
      if (showNotify) {
        notification.error({
          message: `ERROR\n${opts.baseURL}${opts.url}`,
          description: error.message,
        })
      }
      return { error, status }
    })
    .catch((err) => {
      console.log()
    })
}
export async function newRequest(url, options) {
  return request(url, options, true)
}
