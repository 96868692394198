import React, { useEffect, useState, useRef, Children } from "react"
import {
  Select,
  Button,
  Table,
  Modal,
  message,
  Card,
  Cascader,
  TreeSelect,
  Input,
} from "antd"
import styles from "./Index.module.css"
import { useHistory } from "react-router-dom"
import * as OrginServer from "../../../services/orgin"
import { QuestionCircleFilled } from "@ant-design/icons"

const { Option } = Select

function Index(props) {
  let history = useHistory()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [id] = useState(props.location.search.split("=")[1] || undefined)

  const [addressOptions, setAddressOptions] = useState([]) //省市区树
  const [PullList, setPullList] = useState([]) //门店下拉
  const [treeData, setTreeData] = useState([]) // 区域树
  const [qyName, setQyName] = useState(
    props.location.search.split("=")[1] || undefined
  ) // 上级区域

  const [total, setTotal] = useState(0) // 表格数据总数
  const [loading, setLoading] = useState(false) // 表格数据loading
  const [tableList, setTableList] = useState([]) // 表格数据列表
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 表格选中
  const [openStatus, setOpenStatus] = useState(false) // 保存二次提交

  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 100,
    code: undefined, // 门店名称
    ProvinceCityRegion: undefined, // 地理位置
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])
  //

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 初始化
   */
  useEffect(() => {
    // 省市区树
    getAreaTree()
    // 组织架构树形列表组
    getDistrictTreeList()
    // 门店列表
    getShopList()
    // 获取表格数据
    setTimeout(getTableList)
  }, [])

  /**
   * 组织架构树形列表组
   */
  const getDistrictTreeList = async () => {
    try {
      const res = await OrginServer.District_TreeList()
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      let NewArr = [
        {
          id: "0",
          name: "总部",
          subList: res?.data || [],
        },
      ]
      setTreeData(res?.data)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 省市区树
   */
  const getAreaTree = async () => {
    try {
      const { code, data, msg } = await OrginServer.Area_Tree()
      if (code != 200) {
        message.error(msg)
        return
      }
      setAddressOptions(data)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 门店列表
   */
  const getShopList = async () => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 9999999,
      }
      const { code, data, msg } = await OrginServer.Pull_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setPullList(data.list || [])
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 获取表格数据
   */
  const getTableList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        pageSize: ParamsInfoRef.pageSize,
        pageNum: ParamsInfoRef.pageNum,
        code: ParamsInfoRef.code, // 门店名称
        shopId: "",
        province:
          ParamsInfoRef?.ProvinceCityRegion?.length > 0
            ? ParamsInfoRef?.ProvinceCityRegion[0]
            : "", // 省
        city:
          ParamsInfoRef?.ProvinceCityRegion?.length > 0
            ? ParamsInfoRef?.ProvinceCityRegion[1]
            : "", // 市
        region:
          ParamsInfoRef?.ProvinceCityRegion?.length > 0
            ? ParamsInfoRef?.ProvinceCityRegion[2]
            : "", // 区
      }
      setLoading(true)
      const { code, data, msg } = await OrginServer.District_ShopList(params)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLoading(false)
      setTableList(data.list)
      setTotal(data.total)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const onSearch = () => {
    setParamsInfo({
      ...ParamsInfo,
      pageNum: 1,
    })
    setTimeout(getTableList)
  }
  /**
   * 重置
   */
  const onClearn = () => {
    setParamsInfo({
      pageNum: 1,
      pageSize: 100,
      code: undefined, // 门店名称
      ProvinceCityRegion: undefined, // 地理位置
    })
    setSelectedRowKeys([])
    setTimeout(getTableList)
  }

  /**
   * table表格columns
   */
  const columns = [
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "所属区域",
      dataIndex: "district",
      ellipsis: { showTitle: true },
      key: "district",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "地理位置",
      dataIndex: "address",
      ellipsis: { showTitle: true },
      key: "address",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
  ]

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["100", "200", "500", "1000"], // 自定义分页条数选项
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current, pageSize) => {
        setParamsInfo({
          ...ParamsInfo,
          pageNum: current,
          pageSize: pageSize,
        })
        setTimeout(getTableList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 表格选中功能
   */
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  /**
   * 保存功能
   */
  const onSave = async () => {
    try {
      const params = {
        districtId: qyName,
        shopIds: selectedRowKeys,
      }
      const res = await OrginServer.District_Shop(params)
      if (!res) {
        return
      }
      if (res.code != 200) {
        message.error(res.msg)
        return
      }
      if (res.data) {
        if (!id) {
          window.history.back(-1)
        }
        setOpenStatus(false)
        message.success("保存成功")
        setSelectedRowKeys([])
      } else {
        message.success("保存失败")
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <Card style={{ marginBottom: "16px" }}>
        <div className={styles.device_list_head}>
          <div className={styles.device_list_head_search}>
            <div className={styles.device_list_head_search_input_or_select}>
              地理位置：
              <Cascader
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属区域"
                style={{ width: 200 }}
                fieldNames={{
                  label: "name",
                  value: "name",
                  children: "areaListRspList",
                }}
                options={addressOptions}
                value={ParamsInfo.ProvinceCityRegion}
                allowClear
                changeOnSelect={true}
                onChange={(e) =>
                  setParamsInfo({ ...ParamsInfo, ProvinceCityRegion: e || "" })
                }
              />
            </div>
            <div className={styles.device_list_head_search_input_or_select}>
              选择门店：
              <Input
                placeholder="请输入门店名或编号"
                style={{ width: 200 }}
                value={ParamsInfo?.code}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    code: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.device_list_head_right}>
            <Button
              type="primary"
              onClick={() => {
                onSearch()
              }}
            >
              查询
            </Button>
            <Button
              onClick={() => {
                onClearn()
              }}
            >
              重置
            </Button>
          </div>
        </div>
      </Card>
      <Card>
        <div
          className={styles.page_table_list}
          style={{ height: `${WinHeight - 300}px` }}
        >
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={tableList}
            rowKey="shopId"
            loading={loading}
            rowSelection={rowSelection}
            scroll={{ x: "max-content", y: `${WinHeight - 420}px` }}
          />
          <div className={styles.page_save}>
            关联至区域：
            <TreeSelect
              showSearch
              filterTreeNode={(input, treeNode) =>
                treeNode.props.name
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) !== -1
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              style={{ width: "200px" }}
              value={qyName}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="请选择区域"
              allowClear
              treeDefaultExpandAll
              onChange={(e) => {
                setQyName(e)
              }}
              treeData={treeData}
              onPopupScroll={() => {}}
              fieldNames={{ label: "name", value: "id", children: "subList" }}
            />
            <Button
              type="primary"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                if (selectedRowKeys.length == 0) {
                  message.error("请选择门店")
                  return
                }
                if (!qyName) {
                  message.error("请选择关联区域")
                  return
                }
                setOpenStatus(true)
              }}
            >
              保存
            </Button>
          </div>
        </div>
      </Card>

      {/* 保存所选门店 */}
      <Modal
        title="系统提示"
        open={openStatus}
        onCancel={() => {
          setOpenStatus(false)
        }}
        footer={[
          <Button
            onClick={() => {
              setOpenStatus(false)
            }}
          >
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={() => onSave()}>
            保存
          </Button>,
        ]}
      >
        <QuestionCircleFilled
          style={{ color: "#E6A23C", marginRight: "10px" }}
        />
        是否保存当前所选门店？
      </Modal>
    </div>
  )
}

export default Index
