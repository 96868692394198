import {
  Button,
  Table,
  Select,
  message,
  Card,
  Drawer,
  Badge,
  Tooltip,
} from "antd"
import * as Congigdata from "../../services/configdata_summary"
import React, { useEffect, useState, useRef } from "react"
import styles from "./Index.module.css"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"
import dayjs from "dayjs"

const { Option } = Select
function Index(props) {
  const [open, setOpen] = useState(false) //侧边栏状态
  const [successRate, setSuccessRate] = useState("") //成功率
  const [drawerName, setDrawerName] = useState("") //侧边栏title
  const [deviceList, setDeviceList] = useState([]) //接收设备列表
  //
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  const [dataSetList, setDataSetList] = useState([]) // 数据集合下拉列表
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [GiveParams, setGiveParams] = useState({
    timeArr: [
      dayjs(HandDefaultTime?.OneDayStaDatStr),
      dayjs(HandDefaultTime?.OneDayEndDatStr),
    ],
    startDate: HandDefaultTime?.OneDayStaDatStr,
    endDate: HandDefaultTime?.OneDayEndDatStr,
    pageNum: 1,
    pageSize: 20,
    dataSetId: localStorage.getItem("discernmanage_id")
      ? Number(localStorage.getItem("discernmanage_id"))
      : undefined,
    pullType: undefined, // 1 手动拉取 ，2 自动拉取
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  /**
   * 侧边栏的 ==>
   */
  const [NewGiveParams, setNewGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0,
    logId: "",
  })

  //保证每次拿到的都是准确的值
  const NewAccountRef = useRef({
    NewGiveParamsRef: {},
  })

  useEffect(() => {
    NewAccountRef.current = {
      NewGiveParamsRef: NewGiveParams,
    }
  }, [NewGiveParams])
  //<===

  // 初始化请求接口
  useEffect(() => {
    // 数据集合下拉接口
    getDataPullList()

    // 获取表格列表数据
    setTimeout(getGoodsWeighDetail, 0)
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 数据集合下拉接口
   */
  const getDataPullList = async () => {
    try {
      const { code, data, msg } = await Congigdata.DataPullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDataSetList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  //获取表格列表数据
  const getGoodsWeighDetail = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const params = {
        ...GiveParamsRef,
        timeArr: null,
      }
      const { code, msg, data } = await Congigdata.PullLogList(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.list || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getGoodsWeighDetail, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "集合名称",
      dataIndex: "dataSetName",
      ellipsis: { showTitle: true },
      key: "dataSetName",
    },
    {
      title: "时间",
      dataIndex: "dateId",
      ellipsis: { showTitle: true },
      key: "dateId",
    },
    {
      title: "获取方式",
      dataIndex: "pullType",
      ellipsis: { showTitle: true },
      key: "pullType",
      render: (info) => {
        return info == 1 ? "手动拉取" : info == 2 ? "自动拉取" : ""
      },
    },
    {
      title: "共享类型",
      dataIndex: "dataType",
      ellipsis: { showTitle: true },
      key: "dataType",
      render: (info) => {
        return info == "0" ? "学习数据" : info == "1" ? "icon数据" : ""
      },
    },
    {
      title: "共享方式",
      dataIndex: "updateType",
      ellipsis: { showTitle: true },
      key: "updateType",
      render: (info) => {
        return info == 1 ? "追加" : info == "0" ? "覆盖" : ""
      },
    },
    {
      title: "接收设备",
      dataIndex: "acceptNum",
      ellipsis: { showTitle: true },
      key: "acceptNum",
      render: (info, rowInfo) => {
        return (
          <div
            style={{ color: "#2eb998", cursor: "pointer" }}
            onClick={() => handleDevice(rowInfo, "接收设备")}
          >
            {info}
          </div>
        )
      },
    },
    {
      title: "成功率",
      dataIndex: "successRate",
      ellipsis: { showTitle: true },
      key: "successRate",
      render: (info, rowInfo) => {
        return (
          <div
            style={{ color: "#2eb998", cursor: "pointer" }}
            onClick={() => handleDevice(rowInfo, "成功率")}
          >
            {info == 0 ? 0 : info * 100 + "%"}
          </div>
        )
      },
    },
  ]
  // 接收设备列表表格的columns
  const deviceColumns = [
    {
      title: "接收设备",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",
      ellipsis: { showTitle: true },
      render: (info) => {
        return <>{info}</>
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <>
            {info == 1 ? (
              <>
                <Badge status="warning" style={{ padding: "0 5px" }} />
                处理中
              </>
            ) : info == 2 ? (
              <>
                {" "}
                <Badge status="processing" style={{ padding: "0 5px" }} />
                成功
              </>
            ) : info == 3 ? (
              <>
                {" "}
                <Badge status="error" style={{ padding: "0 5px" }} />
                失败
              </>
            ) : (
              ""
            )}
          </>
        )
      },
    },
    {
      title: "备注信息",
      dataIndex: "msg",
      key: "msg",
      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <Tooltip title={info}>
            <span>{info}</span>
          </Tooltip>
        )
      },
    },
  ]

  //查询
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }
  //重置
  const handClear = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      timeArr: [
        dayjs(HandDefaultTime?.OneDayStaDatStr),
        dayjs(HandDefaultTime?.OneDayEndDatStr),
      ],
      startDate: HandDefaultTime?.OneDayStaDatStr,
      endDate: HandDefaultTime?.OneDayEndDatStr,
      // dataSetId: undefined,
      pullType: undefined, //1 手动拉取 ，2 自动拉取
    })
    setTimeout(getGoodsWeighDetail, 0)
  }

  //点击接收设备
  const handleDevice = (info, text) => {
    setDrawerName(text)
    const { id, successRate } = info
    setNewGiveParams({
      logId: id,
      pageNum: 1,
      pageSize: 20,
      total: 0,
    })
    setSuccessRate(
      successRate == 0 ? successRate : (successRate * 100).toFixed(2)
    ) //成功率
    //调用接收设备函数
    showDrawer() //打开侧边栏
    setTimeout(getDeviceList, 0)
  }

  // 接收设备
  const getDeviceList = async () => {
    try {
      const { NewGiveParamsRef } = NewAccountRef.current
      const params = NewGiveParamsRef
      const { code, data, msg } = await Congigdata.PullDevice(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setDeviceList(data.list || [])
      setNewGiveParams({
        ...NewGiveParamsRef,
        total: data.total,
      })
    } catch (error) {
      console.log(error)
    }
  }
  // 抽屉打开
  const showDrawer = () => {
    setOpen(true)
  }
  // 抽屉关闭
  const onClose = () => {
    setOpen(false)
  }
  //侧边栏分页
  const onNewTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${NewGiveParams?.total}条`,
      pageSize: NewGiveParams.pageSize,
      current: NewGiveParams.pageNum,
      total: NewGiveParams?.total,
      onChange: (current) => {
        setNewGiveParams({
          ...NewGiveParams,
          pageNum: current,
        })
        setTimeout(getDeviceList, 0)
      },
    }
    return paginationProps
  }
  return (
    <div className={styles.merge_records_page}>
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.merge_records_page_search}>
          <div className={styles.merge_records_search_body}>
            <div className={styles.merge_records_search_body_child}>
              <span>操作日期：</span>
              <PubRangePicker
                value={GiveParams?.timeArr}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    timeArr: val.TotalTime,
                    startDate: val.startDate,
                    endDate: val.endDate,
                  })
                }}
              />
            </div>

            <div className={styles.merge_records_search_body_child}>
              <span>数据汇总：</span>
              <Select
                style={{ width: 200 }}
                placeholder="请选择数据汇总"
                value={GiveParams.dataSetId}
                onChange={(e) => {
                  setGiveParams({ ...GiveParams, dataSetId: e })
                }}
                allowClear
              >
                {dataSetList.map((v) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </div>

            <div className={styles.merge_records_search_body_child}>
              <span>获取方式：</span>
              <Select
                style={{ width: 200 }}
                placeholder="请选择获取方式"
                value={GiveParams.pullType}
                onChange={(e) => {
                  setGiveParams({ ...GiveParams, pullType: e })
                }}
                allowClear
              >
                <Option value={1} key={1}>
                  手动拉取
                </Option>
                <Option value={2} key={2}>
                  自动拉取
                </Option>
              </Select>
            </div>
          </div>
          <div className={styles.merge_records_search_button}>
            <Button onClick={() => handClear()}>重置</Button>
            <Button
              type="primary"
              style={{ marginLeft: "20px" }}
              onClick={() => handSearch()}
            >
              查询
            </Button>
          </div>
        </div>
      </Card>
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          scroll={{ y: `${WinHeight - 430}px` }}
        />
      </Card>
      {/* 接收设备 ， 成功率抽屉 */}
      <Drawer
        title={drawerName}
        placement="right"
        onClose={onClose}
        open={open}
        width={500}
        extra={<div style={{ color: "#2EB998" }}>{successRate + "%"}</div>}
      >
        <Table
          dataSource={deviceList}
          columns={deviceColumns}
          pagination={onNewTableChange()}
          rowKey="deviceId"
        />
      </Drawer>
    </div>
  )
}

export default Index
