import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Table,
  Input,
  Select,
  message,
  Modal,
  Form,
  Upload,
  Spin,
  Tag,
  Tooltip,
} from "antd"
import {
  AddAccount,
  EditAccount,
  AccountInfo,
  GetAccountList,
  DeleteAccount,
  AccountStatus,
  RolePullList,
  Get_Token,
  ResetPassword,
} from "../../services/systemset"
import "./Index.css"
import md5 from "md5"
import { PlusOutlined } from "@ant-design/icons"

const { Option } = Select

function Index() {
  const [form] = Form.useForm()
  const [token, setToken] = useState("") //上传token
  const [uid, setUid] = useState([]) //uid
  const [domain, setDomain] = useState("") //域名
  const [dataList, setDataList] = useState([]) //表格列表
  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false) //上传loging
  const [imgUrl, setImgUrl] = useState("") //上传成功后返回的图片路径
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [total, setTotal] = useState() //每页显示的行数
  const [isModalOpen, setIsModalOpen] = useState(false) //新建编辑Modal状态
  const [modalTitle, setModalTitle] = useState("") // 新建编辑Modal-title
  const [isDeleteOpen, setSsDeleteOpen] = useState(false) //删除Modal状态
  const [isResetOpen, setIsResetOpen] = useState(false) //重置密码Modal状态
  const [isDisableEnable, setIsDisableEnable] = useState(false) //禁用启用Modal状态
  const [formData, setFormData] = useState({}) // 表单提交的值
  const [tableInfo, setTableInfo] = useState({}) // 表格操作功能时临时缓存的数据
  const [account, setAccount] = useState("") // 查询条件登录账号
  const [roleId, setRoleId] = useState(undefined) // 角色id
  const [roleIdList, setRoleIdList] = useState([]) // 所属角色列表
  const [GiveParams, setGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    account: "", // 登录账号
    roleId: "", // 角色id
  })

  //保证每次拿到的都是准确的值
  const AccountRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    AccountRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  /**
   * 初始化
   */
  useEffect(() => {
    //调用账号列表接口
    onGetUserList()
    //调用所属角色列表
    onRolePullList()
    //调用获取token接口
    gettoken()

    setUid(window.localStorage.getItem("uid").toString().padStart(8, "0"))
  }, [])

  /**
   * 获取 token
   */
  const gettoken = async () => {
    try {
      const { code, msg, data } = await Get_Token()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setToken(data.token)
        setDomain(data?.domain || "")
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 所属角色列表
   */
  const onRolePullList = async () => {
    try {
      const params = ""
      const { code, msg, data } = await RolePullList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setRoleIdList(data)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 账号列表
   */
  const onGetUserList = async () => {
    try {
      setLoading(true)
      const { GiveParamsRef } = AccountRef.current
      const params = GiveParamsRef
      const { code, data, msg } = await GetAccountList(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      const { list, total } = data
      setDataList(list)
      setTotal(total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: total,
      onChange: (current) => {
        setPageNum(current)
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(onGetUserList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 新增账号
   */
  const onAddAccount = () => {
    //清空表单
    form.resetFields()
    //Modal-title赋值
    setModalTitle("新建账号")
    //打开modal
    setIsModalOpen(true)
    //清空表单
    setFormData({})
    // 清空图片
    setImgUrl("")
  }

  /**
   * 编辑账号
   */
  const onEditAccount = async (accountId) => {
    try {
      // 先清空
      form.resetFields()
      setImgUrl("")

      const { code, data, msg } = await AccountInfo(accountId)
      if (code != 200) {
        message.error(msg)
        return
      }
      //给form表单赋值
      setFormData(data)
      // 给图片赋值
      setImgUrl(data?.headPortrait || "")
      //Modal-title赋值
      setModalTitle("编辑账号")
      //打开modal框
      setIsModalOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 重置
   */
  const onHandClearn = () => {
    setAccount(undefined)
    setRoleId(undefined)
    setPageNum(1)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      account: undefined,
      roleId: undefined,
    })
    // 调用账号列表接口
    setTimeout(onGetUserList, 0)
  }

  /**
   * 查询
   */
  const onHandSearch = () => {
    console.log(account)
    setPageNum(1)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      account: account,
      roleId: roleId,
    })
    // 调用账号列表接口
    setTimeout(onGetUserList, 0)
  }

  /**
   * 监听表单提交
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        //新建 / 编辑接口传值
        const params = {
          ...values,
          headPortrait: imgUrl,
          accountId: formData.accountId ? formData.accountId : undefined,
          password: values.password ? md5(values.password) : "",
        }
        //给存储的表单数据赋值
        // setFormData(params)

        //请求新建 / 编辑接口，values.accountId有的话是编辑，否则是新建
        const { code, msg, data } = formData.accountId
          ? await EditAccount(params)
          : await AddAccount(params)
        if (code != 200) {
          message.error(msg)
          return
        }
        //新建/ 编辑 成功或失败
        if (data) {
          //关闭modal
          setIsModalOpen(false)
          message.success(formData.accountId ? "账号编辑成功" : "账号新建成功")
          //清空存储的表单数据
          setFormData({})
          form.resetFields()
          // 调用账号列表接口
          setTimeout(onGetUserList, 0)
        } else {
          message.error(formData.accountId ? "账号编辑失败" : "账号新建失败")
        }
      })
      .catch((err) => {
        // 校验失败
      })
  }

  /**
   * 监听禁用启用确认按钮
   */
  const onChangeState = async () => {
    // (1:启用 2:停用)
    try {
      const params = {
        accountId: tableInfo?.accountId,
        status: tableInfo?.status == 1 ? 2 : 1,
      }
      const { code, data, msg } = await AccountStatus(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success(tableInfo?.status == 1 ? "禁用成功" : "启用成功")
        setIsDisableEnable(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监听删除按钮
   */
  const onDelete = async () => {
    try {
      const { code, data, msg } = await DeleteAccount(tableInfo?.accountId)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        setSsDeleteOpen(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监听重置密码
   */
  const setReseyOk = async () => {
    try {
      const { code, data, msg } = await ResetPassword(tableInfo?.accountId)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("密码重置成功")
        setIsResetOpen(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 上传
   */
  const uploadProps = {
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(e)
        fileReader.onload = (ee) => {
          var _md5only = md5(ee.target.result)
          resolve({
            token,
            key: `icons/project/${uid}/_${_md5only}.${e.type.split("/")[1]}`,
          })
        }
        fileReader.onerror = (err) => {
          reject(err)
        }
      })
    },
    beforeUpload: async (e) => {
      try {
        const sizeQualified = await handleFilebeforeUpload(e)
        // message.success(sizeQualified)
        setImgLoading(true)
        return true
      } catch (err) {
        message.error("上传失败,请按建议上传")
        setImgLoading(false)
        return false
      }
    },
    onChange(info) {
      const _file = info.file
      if (_file.status === "done") {
        console.log(_file, "上传成功的数据")
        setImgLoading(false)
        setImgUrl(domain + "" + _file?.response?.key || "")
      }
      if (_file.status === "error") {
        setImgLoading(false)
        message.error(_file?.response.error)
      }
    },
  }

  /**
   * 限制宽高类型
   */
  const handleFilebeforeUpload = (file) => {
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader()
      filereader.onload = (e) => {
        let src = e.target.result
        const image = new Image()
        image.onload = function () {
          if (
            (file.type === "image/png" ||
              file.type === "image/jpeg" ||
              file.type === "image/jpg") &&
            file.size < 512000
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve("上传成功")
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = src
      }
      filereader.readAsDataURL(file)
    })
  }

  const columns = [
    {
      title: "真实姓名",
      dataIndex: "realname",
      ellipsis: { showTitle: true },

      key: "realname",
    },
    {
      title: "登录账号",
      dataIndex: "account",
      ellipsis: { showTitle: true },

      key: "account",
    },
    {
      title: "所属角色",
      dataIndex: "roleStr",
      ellipsis: { showTitle: true },

      key: "roleStr",
      render: (info) => {
        return info.map((v, i) => {
          return <div key={i}>{v}</div>
        })
      },
    },
    {
      title: "账号状态",
      dataIndex: "status",
      ellipsis: { showTitle: true },

      key: "status",
      render: (info) => {
        return <>{info == 1 ? "启用" : info == 2 ? "禁用" : ""}</>
      },
    },
    {
      title: "创建人",
      dataIndex: "creator",
      ellipsis: { showTitle: true },

      key: "creator",
    },
    {
      title: "创建时间",
      dataIndex: "createDate",
      ellipsis: { showTitle: true },

      key: "createDate",
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },

      render: (info) => {
        return (
          <div>
            <Button
              type="link"
              style={{
                color: info?.isSuper == 1 ? "rgba(0, 0, 0, 0.25)" : "#2EB998",
                width: "20px",
                marginRight: "10px",
              }}
              onClick={() => onEditAccount(info?.accountId)}
              disabled={info?.isSuper == 1 ? true : false}
            >
              编辑
            </Button>
            <Button
              type="link"
              disabled={info?.isSuper == 1 ? true : false}
              style={{ width: "20px", marginRight: "10px" }}
              onClick={() => {
                // 临时缓存数据
                setTableInfo(info)
                setIsDisableEnable(true)
              }}
            >
              {info.status == 1 ? "禁用" : info.status == 2 ? "启用" : ""}
            </Button>
            <Button
              type="link"
              danger
              disabled={info?.isSuper == 1 ? true : false}
              onClick={() => {
                // 临时缓存数据
                setTableInfo(info)
                setSsDeleteOpen(true)
              }}
              style={{ width: "20px", marginRight: "10px" }}
            >
              删除
            </Button>
            <Button
              type="link"
              disabled={info?.isSuper == 1 ? true : false}
              onClick={() => {
                // 临时缓存数据
                setTableInfo(info)
                setIsResetOpen(true)
              }}
            >
              重置密码
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <div className="account_page">
      {/* 新建编辑账号Modal */}
      <Modal
        // forceRender
        getContainer={false}
        title={modalTitle}
        open={isModalOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setIsModalOpen(false)
          //清空存储的表单数据
          setFormData({})
          form.resetFields()
        }}
        onOk={onFinish}
        width={"30%"}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          // wrapperCol={{ span: 9 }}
          onFinish={onFinish}
        >
          {/* 真实姓名 */}
          <Form.Item
            label="真实姓名"
            name="realname"
            initialValue={formData?.realname || ""}
            rules={[
              { required: true, message: "真实姓名不能为空!" },
              // { pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{1,10}$/, "g"), message: "支持中英文、数字、特殊字符，长度为10" },
            ]}
          >
            <Input
              placeholder="输入用户的真实姓名"
              style={{ width: "80%" }}
              maxLength={10}
            />
          </Form.Item>

          {/* 登录账号 */}
          <Form.Item
            label="登录账号"
            name="account"
            initialValue={formData?.account || ""}
            rules={[
              { required: true, message: "登录账号不能为空!" },
              // { pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{1,10}$/, "g"), message: "支持中英文、数字、特殊字符，长度为10" },
            ]}
          >
            <Input
              placeholder="输入登录该平台的账号"
              style={{ width: "80%" }}
              maxLength={15}
            />
          </Form.Item>

          {/* 手机号 */}
          <Form.Item
            label="手机号"
            name="phone"
            initialValue={formData?.phone || ""}
            rules={[
              {
                pattern: new RegExp(/^1[3456789]\d{9}$/, "g"),
                message: "请输入正确的手机号",
              },
            ]}
          >
            <Input
              placeholder="用于忘记密码找回"
              style={{ width: "80%" }}
              maxLength={11}
            />
          </Form.Item>

          {/* 密码 */}
          <Form.Item
            label="登录密码"
            name="password"
            initialValue={formData?.password || ""}
            rules={[
              {
                required: formData.accountId ? false : true,
                message: "密码不能为空!",
              },
              {
                pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{5,15}$/, "g"),
                message: "支持英文、数字、特殊字符，长度为5到15",
              },
            ]}
          >
            <Input
              placeholder="输入登录该平台的密码"
              style={{ width: "80%" }}
              maxLength={15}
            />
          </Form.Item>

          {/* 所属角色 */}
          <Form.Item
            label="所属角色"
            name="roleIdList"
            initialValue={formData?.roleIdList || []}
            rules={[
              {
                required: true,
                message: "所属角色不能为空!",
              },
            ]}
            className="account_form_item"
          >
            {/* <Select
              className="account_select"
              allowClear
              showSearch={false}
              placeholder="选择该账号所属的角色"
              style={{ width:'80%' }}
              mode="multiple"
              fieldNames={{ label: "roleName", value: "roleId" }}
              showArrow
              options={roleIdList}
            /> */}
            <Select
              placeholder="选择该账号所属的角色"
              mode="multiple"
              style={{ width: "80%" }}
              showSearch={false}
              showArrow
            >
              {roleIdList.length > 0 &&
                roleIdList.map((v) => {
                  return (
                    <Option key={v.roleId} value={v.roleId}>
                      <div className="account_form_option">
                        <div className="account_form_optionname">
                          <Tooltip title={v.roleName}>{v.roleName}</Tooltip>
                        </div>
                        <div>
                          {v.status == 2 ? (
                            <Tag color="#f50">已禁用</Tag>
                          ) : null}
                        </div>
                      </div>
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>

          {/* 头像 */}
          {/* <Form.Item label="头像">
            <Upload
              action="https://up-z0.qiniup.com"
              name="file"
              showUploadList={false}
              multiple={false}
              {...uploadProps}
            >
              <div className="account_upload_btn">
                {imgLoading ? (
                  <Spin />
                ) : imgUrl ? (
                  <div className="account_upload_img">
                    <img src={imgUrl} alt="" />
                  </div>
                ) : (
                  <div className="account_upload_icon">
                    <PlusOutlined style={{ fontSize: "30px" }} />
                    上传图片
                  </div>
                )}
              </div>
            </Upload>
          </Form.Item> */}
        </Form>
      </Modal>

      {/* 重置密码 */}
      <Modal
        title="重置密码"
        open={isResetOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setIsResetOpen(false)
          //清空临时缓存数据
          setTableInfo()
        }}
        onOk={setReseyOk}
      >
        <div>重置后密码为：yoyo123456</div>
        <div>是否确认重置？</div>
      </Modal>

      {/* 删除 */}
      <Modal
        title="删除"
        open={isDeleteOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setSsDeleteOpen(false)
          //清空临时缓存数据
          setTableInfo()
        }}
        onOk={onDelete}
      >
        <div>确认删除【 账号数据：{tableInfo?.account} 】？</div>
        <div>删除后该账号将不存在</div>
      </Modal>

      {/* 禁用启用 */}
      <Modal
        title={tableInfo?.status == 1 ? "禁用" : "启用"}
        open={isDisableEnable}
        destroyOnClose
        keyboard
        onCancel={() => {
          setIsDisableEnable(false)
          //清空临时缓存数据
          setTableInfo({})
        }}
        onOk={onChangeState}
      >
        <div>
          确认
          {tableInfo?.status == 1
            ? "禁用"
            : tableInfo?.status == 2
            ? "启用"
            : ""}
          【 账号数据：{tableInfo?.account} 】？
        </div>
        <div>
          {tableInfo?.status == 1
            ? "禁用后该账号无法正常登录平台"
            : tableInfo?.status == 2
            ? "启用后该账号可以正常登录平台"
            : ""}
        </div>
      </Modal>

      {/* 查询条件，查询，重置 */}
      <div className="account_page_search">
        <div className="account_search_body">
          <div className="account_search_body_child">
            <span>角色名称：</span>
            <Select
              style={{ width: 200, marginRight: "20px" }}
              onChange={(e) => {
                setRoleId(e)
              }}
              value={roleId}
              placeholder="请选择角色名称"
            >
              {roleIdList.map((v) => {
                return (
                  <Option value={v.roleId} key={v.roleId}>
                    {v.roleName}
                  </Option>
                )
              })}
            </Select>
            <span>登录账号：</span>
            <Input
              placeholder="请输入账号名称"
              style={{ width: "200px" }}
              value={account}
              onChange={(e) => {
                setAccount(
                  e.target.value
                    ? e.target.value.replace(/(^\s+)|(\s+$)/g, "")
                    : undefined
                )
              }}
            />
          </div>
        </div>
        <div className="account_search_button">
          <Button onClick={onHandClearn}>重置</Button>
          <Button type="primary" onClick={onHandSearch}>
            查询
          </Button>
          {/* 新建 */}
          <Button type="primary" onClick={onAddAccount}>
            新建账号
          </Button>
        </div>
      </div>
      {/* 表格数据 */}
      <Table
        style={{ marginTop: "20px" }}
        dataSource={dataList}
        columns={columns}
        pagination={onTableChange()}
        rowKey="accountId"
        loading={loading}
        scroll={{ y: window.screen.height * 0.55 }}
      />
    </div>
  )
}

export default Index
