import React, { useState, useEffect, useMemo } from "react"
import {
  Steps,
  Radio,
  message,
  Select,
  Tree,
  Card,
  Input,
  Badge,
  Checkbox,
  Button,
  Tooltip,
  Modal,
} from "antd"
import { CheckOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { ContainerOutlined } from "@ant-design/icons"
import "./Index.css"
import { DataPullList } from "../../../services/datasetof"
import {
  Shop_List,
  Shop_Device,
  Tree_List,
  Info_Benchmark,
  Learn_List,
  sku_list,
  Task_Create,
} from "../../../services/discernmanage"
import { Icon_List } from "../../../services/shoping"

const { Option } = Select
// 识别管理 / 数据共享
function Index(props) {
  const [shareDataRadio, setShareDataRadio] = useState("0") // 共享数据 (0:学习数据,1:icon数据)
  const [dataSourceRadio, setDataSourceRadio] = useState(undefined) // 数据来源 数据来源(0:共享数据，1:备份数据，2:仿真数据，3:数据集合)

  const [dataSetList, setDataSetList] = useState([]) // 数据集合下拉列表
  const [shopList, setShopList] = useState([]) // 门店列表
  const [deviceList, setDeviceList] = useState([]) // 设备列表
  const [treeList, setTreeList] = useState([]) // 树列表
  const [treeAllKey, setTreeAllKey] = useState([]) // 树形数据所有的key
  const [treeAllChildKey, setTreeAllChildKey] = useState([]) // 树形数据所有子级的key
  const [checkedKeys, setCheckedKeys] = useState([]) // 树形选中参数
  const [childCheckedKeys, setChildCheckedKeys] = useState([]) // 树形选中参数（剔除父级后）

  const [dataSetId, setDataSetId] = useState(undefined) // 数据集合下拉列表选中id
  const [shopId, setShopId] = useState(undefined) // 门店选中id
  const [deviceId, setDeviceId] = useState(undefined) // 设备选中id
  const [searchValue, setSearchValue] = useState("") // 树形查询框
  const [isShowTree, setIsShowTree] = useState(false) // 是否展示树列表
  const [infoReferenceShop, setInfoReferenceShop] = useState() // 信息基准门店数据
  const [deviceInfoData, setDeviceInfoData] = useState({}) //设备选中的数据
  const [shopName, setShopName] = useState("") // 门店名
  const [shareWay, setShareWay] = useState("") // 共享方式(1:追加，0:覆盖)
  const [subLoading, setSubLoading] = useState(false) // 开始共享loading
  const [openModal, setOpenModal] = useState(false) // 共享成功后的modal框状态

  const [stepsCurrent, setStepsCurrent] = useState(1) // 步骤条进度

  /**
   * 默认初始化
   */
  useEffect(() => {
    // 调用数据集合下拉接口
    getDataPullList()

    // 调用数据集合下拉接口
    getShopList()

    // 初始化树列表
    getTreeList()

    //信息基准门店
    getInfoReferenceShop()
  }, [])

  // 获取树形数组的所有id,提前为自定义全选做准备
  useEffect(() => {
    // 存储所有父级id创建的空数组
    let AllId = []
    let AllChildId = []
    if (treeList.length > 0) {
      treeList.map((v) => {
        AllId.push(v.key)
        if (v.children && v.children.length > 0) {
          v.children.map((w) => {
            if (w.disableCheckbox === false) {
              AllId.push(w.key)
            }
            AllChildId.push(w.key)
          })
        }
      })
    }
    setTreeAllKey(AllId)
    setTreeAllChildKey(AllChildId)
  }, [treeList])

  /**
   * 监听checkedKeys，树列表选中，为了拿到剔除父级后的选中id
   */
  useEffect(() => {
    const childCheck = checkedKeys.filter((v) =>
      treeList.every((item) => !Object.is(v, item.key))
    )
    setChildCheckedKeys(childCheck)

    // if (shareDataRadio == 0) {
    //   // childCheck长度大于0 表示已选中，步骤条赋值为3，
    //   if (childCheck.length > 0) {
    //     setStepsCurrent(3)
    //   }
    //   if (childCheck.length == 0 && stepsCurrent == 3) {
    //     setStepsCurrent(2)
    //   }
    // }
    if (shareDataRadio == 1 || shareDataRadio == 0) {
      // childCheck长度大于0 表示已选中，步骤条赋值为3，
      if (childCheck.length > 0) {
        setStepsCurrent(3)
      }
      if (childCheck.length == 0 && (stepsCurrent == 3 || stepsCurrent == 4)) {
        setStepsCurrent(2)
        // 共享方式置空
        setShareWay(undefined)
      }
    }
  }, [checkedKeys])

  /**
   * 获取信息基准门店数据
   */
  const getInfoReferenceShop = async () => {
    try {
      const { code, msg, data } = await Info_Benchmark()
      if (code != 200) {
        message.error(msg)
        return
      }
      setInfoReferenceShop(data)
      // 门店名
      setShopName(data.name)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 数据集合下拉接口
   */
  const getDataPullList = async () => {
    try {
      const { code, data, msg } = await DataPullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDataSetList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 商品图片库icon列表
   * @param  dataSetId
   */
  const getIconList = async (dataSetId) => {
    try {
      const params = { pageNum: 1, pageSize: 20, dataSetId: dataSetId }
      const { code, data, msg } = await Icon_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data == null) {
        setIsShowTree(false)
      }
      if (data && data.total == 0) {
        setIsShowTree(false)
        message.error("暂无数据")
      }
      if (data && data.total > 0) {
        setIsShowTree(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查看识别数据列表
   * @param deviceSn
   */
  const getSkulist = async (deviceSn) => {
    try {
      const params = { pageNo: 1, pageSize: 10, deviceSn: deviceSn }
      const { code, data, msg } = await sku_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }

      if (data == null) {
        setIsShowTree(false)
      }
      if (data && data.total == 0) {
        setIsShowTree(false)
        message.error("暂无数据")
      }
      if (data && data.total > 0) {
        setIsShowTree(true)
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * （学习识别数据）列表查询
   * @param deviceSn
   * @param dataSetId
   */
  const getearnList = async (dataSetId, deviceSn) => {
    try {
      const params = {
        pageNo: 1,
        pageSize: 10,
        deviceSn: deviceSn,
        dataSetId: dataSetId,
      }
      const { code, data, msg } = await Learn_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data == null || data.total == 0) {
        setIsShowTree(false)
      } else {
        if (data.total > 0) {
          setIsShowTree(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 门店列表
   */
  const getShopList = async () => {
    try {
      const { code, data, msg } = await Shop_List()
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 设备列表
   */
  const getDeviceList = async (shopId, infoReferenceShopCdKeyCode) => {
    try {
      const { code, data, msg } = await Shop_Device(shopId)
      if (code != 200) {
        message.error(msg)
        return
      }
      setDeviceList(data || [])

      // 基准门店数据有激活码时会触发,infoReferenceShopCdKeyCode,基准门店拿到的设备激活码
      if (infoReferenceShopCdKeyCode) {
        // 清空树形选中
        setCheckedKeys([])
        // 赋值
        setDeviceId(infoReferenceShopCdKeyCode)
        // 选择设备判断是否可以展示树列表
        onShowTree(infoReferenceShopCdKeyCode, "0")
        // 存储基准门店拿到的设备激活码所对应的设备数据
        let deviceInfo = {}
        if (data && data.length > 0) {
          data.map((v) => {
            console.log(v)
            if (v.deviceSn === infoReferenceShopCdKeyCode) {
              deviceInfo = v
              // 设备选中的数据赋值
              setDeviceInfoData(v)
            }
          })
          let updateTreeList = []
          // shareDataRadio为0是学习数据，为1是icon数据，icon数据时不做任何校验
          treeList.map((v) => {
            v.children.map((w) => {
              w.disableCheckbox =
                shareDataRadio == 0
                  ? w.matchingRulesVersion ===
                      deviceInfo?.matchingRulesVersion &&
                    w.productLineName === deviceInfo?.productLineName &&
                    w.productLineTypeName === deviceInfo?.productLineTypeName &&
                    w.deviceSn !== deviceInfo?.deviceSn
                    ? false
                    : true
                  : false
            })
            updateTreeList.push(v)
          })

          setTreeList(updateTreeList)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取树列表
   */
  const getTreeList = async () => {
    try {
      // 请求树列表时，清空树选中数据
      setCheckedKeys([])
      // 清除剔除父id后的选中子id
      setChildCheckedKeys([])
      //
      const { code, data, msg } = await Tree_List()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.length > 0) {
        data.map((v) => {
          v.title = v.name
          v.key = v.shopId
          v.children = v.deviceList
          if (v.children.length > 0) {
            v.children.map((w) => {
              const title =
                !w.scaleNo ||
                (w.systemName !== 0 && w.systemName !== 1) ||
                !w.matchingRulesVersion
                  ? w.cdKeyCode
                  : w.scaleNo +
                    "-" +
                    (w.systemName == 0
                      ? "And"
                      : w.systemName == 1
                      ? "Win"
                      : "") +
                    "-" +
                    w.matchingRulesVersion
              w.title = title
              w.key = w.deviceSn
            })
          }
        })
      }
      setTreeList(data)
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 树形查询框
   */
  const onChange = (e) => {
    const { value } = e.target
    setSearchValue(value)
  }

  // 树形全选按钮
  const onColModalLeftTitle = () => {
    return (
      <Checkbox
        checked={
          checkedKeys.length == treeAllKey.length && treeAllKey.length != 0
            ? true
            : false
        }
        onChange={(e) => {
          if (e.target.checked) {
            setCheckedKeys(treeAllKey)
          } else {
            setCheckedKeys([])
          }
        }}
      >
        全选
      </Checkbox>
    )
  }

  /**
   * 树形数据处理
   */
  const treeData = useMemo(() => {
    // 查询输入框内的值，并高亮
    const loop = (data) =>
      data.map((item, i) => {
        let title = ""
        if (item.cdKeyCode && item.cdKeyCode == searchValue) {
          title = (
            <div>
              {item.online != undefined ? (
                <Badge
                  color={item.online == 1 ? "#3eb998" : "red"}
                  style={{ marginRight: "5px" }}
                />
              ) : null}

              <span className="site_tree_search_value">{item.title}</span>
            </div>
          )
        } else {
          const strTitle = item.title
          const index = strTitle.indexOf(searchValue)
          const beforeStr = strTitle.substring(0, index)
          const afterStr = strTitle.slice(index + searchValue.length)
          title = (
            <div>
              {item.online != undefined ? (
                <Badge
                  color={item.online == 1 ? "#3eb998" : "red"}
                  style={{ marginRight: "5px" }}
                />
              ) : null}

              {index > -1 ? (
                item?.disableCheckbox ? (
                  <Tooltip title={item.disableCheckboxText || ""}>
                    <span>
                      {beforeStr}
                      <span className="site_tree_search_value">
                        {searchValue}
                      </span>
                      {afterStr}
                    </span>
                  </Tooltip>
                ) : (
                  <span>
                    {beforeStr}
                    <span className="site_tree_search_value">
                      {searchValue}
                    </span>
                    {afterStr}
                  </span>
                )
              ) : (
                <span>{strTitle}</span>
              )}
            </div>
          )
        }

        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
          }
        }

        return {
          title,
          key: item.key,
          disableCheckbox: item.disableCheckbox,
        }
      })
    return loop(treeList)
  }, [searchValue, treeList])

  /**
   * 门店列表下拉框change事件
   * 门店下拉框触发change时要用门店id去请求设备列表，找到该门店下的设备
   * 门店下拉框触发change时清空设备列表，重新请求
   */
  const onShopChange = (e, option) => {
    // 存储门店id
    setShopId(e)
    // 请求设备列表
    getDeviceList(e)
    // 清空设备列表
    setDeviceList([])
    // 清空设备选中数据
    setDeviceId(undefined)

    const { info } = option
    // 门店名赋值
    setShopName(info?.name)
  }

  /**
   * 共享数据单选框change事件
   */
  const onSharedata = (e) => {
    // 清空树形选中
    setCheckedKeys([])
    // 存储共享数据选中数据
    setShareDataRadio(e.target.value)
    // 清空数据来源选中
    setDataSourceRadio(undefined)
    // 清空数据集合选中id
    setDataSetId(undefined)
    // 清空门店选中id
    setShopId(undefined)
    // 清空设备选中数据
    setDeviceId(undefined)
    // 清空设备列表
    setDeviceList([])
    // 步骤条赋值为1
    setStepsCurrent(1)
    // 共享方式清空
    setShareWay("")
  }

  /**
   * 数据来源单选框change事件
   */
  const onDatasourceChang = (e) => {
    // 是否展示树这状态赋为false
    setIsShowTree(false)
    // 清空树形选中
    setCheckedKeys([])
    // 存储数据来源选中数据
    setDataSourceRadio(e.target.value)
    // 清空数据集合选中id
    setDataSetId(undefined)
    // 清空门店选中id
    setShopId(undefined)
    // 清空设备选中数据
    setDeviceId(undefined)
    // 清空设备列表
    setDeviceList([])
    // 步骤条赋值为1
    setStepsCurrent(1)
    // 共享方式清空
    setShareWay("")

    // infoReferenceShop 基准门店数据
    if (e.target.value == 0) {
      // 如果基准门店数据中存在门店id，就赋值
      if (infoReferenceShop?.id) {
        setShopId(infoReferenceShop?.id)
        // 请求设备列表,将门店id和设备激活码一起传过去
        getDeviceList(
          infoReferenceShop?.id,
          infoReferenceShop?.deviceSn ? infoReferenceShop?.deviceSn : undefined
        )
      }
      // 如果基准门店数据中存在激活码，就赋值
      if (infoReferenceShop?.deviceSn) {
        setDeviceId(infoReferenceShop?.deviceSn)
      }
    }
  }

  /**
   * 监听数据集合下拉框选中
   * @param {*} e
   * @param {*} option
   */
  const onDataSetChange = (e, option) => {
    // 步骤条赋值为2
    if (e) {
      setStepsCurrent(2)
    }
    // 清空树形选中
    setCheckedKeys([])
    // 赋值
    setDataSetId(e)
    // 选择数据集合判断是否可以展示树列表
    onShowTree(e, 3)

    // 给树形子集添加条件选择aiMode，aiVersion
    let updateTreeList = []
    const { info } = option
    treeList.map((v) => {
      v.children.map((w) => {
        // 不可选中的条件
        w.disableCheckbox =
          shareDataRadio == 0
            ? info?.aiMode === null && info?.aiVersion === ""
              ? false
              : w?.aiMode === null && w?.aiVersion === ""
              ? false
              : w?.aiMode == info?.aiMode && w?.aiVersion === info?.aiVersion
              ? false
              : true
            : false
        // 不可选中的原因
        w.disableCheckboxText =
          shareDataRadio == 0
            ? w?.aiMode != info?.aiMode
              ? "识别模型不一致"
              : w?.aiVersion !== info?.aiVersion
              ? "识别模型版本不一致"
              : ""
            : ""
      })
      updateTreeList.push(v)
    })
    setTreeList(updateTreeList)
  }

  /**
   * 监听设备列表下拉框选中
   * @param {*} e
   * @param {*} option
   */
  const onDeviceChange = (e, option) => {
    // 步骤条赋值为2
    if (e) {
      setStepsCurrent(2)
    }
    // 清空树形选中
    setCheckedKeys([])
    // 赋值
    setDeviceId(e)
    // 选择设备判断是否可以展示树列表
    onShowTree(e, "0")

    // 给树形子集添加条件选择

    let updateTreeList = []
    const { info } = option
    // 设备选中的数据赋值
    setDeviceInfoData(info)
    //shareDataRadio为0是学习数据，为1是icon数据，icon数据时不做任何校验
    treeList.map((v) => {
      v.children.map((w) => {
        // 不可选中的条件
        w.disableCheckbox =
          shareDataRadio == 0
            ? w.matchingRulesVersion === info?.matchingRulesVersion &&
              w.productLineName === info?.productLineName &&
              w.productLineTypeName === info?.productLineTypeName &&
              w.deviceSn !== info?.deviceSn
              ? false
              : true
            : false
        // 不可选中的原因
        w.disableCheckboxText =
          shareDataRadio == 0
            ? w.matchingRulesVersion !== info?.matchingRulesVersion
              ? "版本不一致"
              : w.productLineName !== info?.productLineName
              ? "产品线不一致"
              : w.productLineTypeName !== info?.productLineTypeName
              ? "类型不一致"
              : w.deviceSn === info?.deviceSn
              ? "共享设备不可与接收设备一致"
              : ""
            : ""
      })
      updateTreeList.push(v)
    })
    setTreeList(updateTreeList)
  }

  /**
   * 选择数据集合判断是否可以展示树列表
   * @param {*} id
   * @param {*} type 0:设备，9：数据集合
   */
  const onShowTree = (id, type) => {
    // console.log("id", id)
    // console.log("type", type)
    // 请求树列表
    // setIsShowTree(true)
    // shareDataRadio==0时学习数据，shareDataRadio==1时icon数据
    if (shareDataRadio == 0) {
      // 数据集合
      if (type == 3) {
        // 请求（学习识别数据）列表查询接口
        getearnList(id, "")
      }
      // 设备下拉选择
      if (type == 0) {
        // 请求（学习识别数据）列表查询接口
        getearnList("", id)
      }
    }
    if (shareDataRadio == 1) {
      // 数据集合
      if (type == 3) {
        // 请求商品图片库 icon列表接口
        getIconList(id)
      }
      // 设备下拉选择
      if (type == 0) {
        // 请求查看识别数据列表接口
        getSkulist(id)
      }
    }
  }

  /**
   * 开始共享
   */
  const onSubmit = async () => {
    setSubLoading(true)

    try {
      const params = {
        dataSetId: dataSetId, // 数据集合id
        dataSource: dataSourceRadio, // 数据来源(0:共享数据，1:备份数据，2:仿真数据，3:数据集合)
        dataType: shareDataRadio, // 数据类型(0:学习数据，1:icon数据)
        deviceSns: childCheckedKeys, // 分享设备sn集合
        projectId: window.localStorage.getItem("uid"), // 项目Id
        selectAll: 1, // 是否全选是否全选(1:是)
        shareDeviceSn: deviceId, // 共享设备sn
        shareType: shareWay ? shareWay : 1, // 共享方式(1:追加，0:覆盖)
        skuCodes: [], // 商品skuCode（selectAll为1时，此参数无效）
      }
      console.log(params)
      const { code, msg, data } = await Task_Create(params)
      if (code != 200) {
        message.error(msg)
        setSubLoading(false)
        return
      }
      // 打开Modal
      setOpenModal(true)
      // loading结束
      setSubLoading(false)
    } catch (error) {
      setSubLoading(false)
      console.log(error)
    }
  }

  /**
   * 树列表刷新功能
   */
  const onRefresh = () => {
    // 请求树列表
    setCheckedKeys([])
  }

  /**
   * 步骤4，title布局
   */
  const onTitleBody = () => {
    return (
      <Button
        type="primary"
        disabled={
          // (stepsCurrent == 3 && shareDataRadio == 0) ||
          // (shareDataRadio == 1 && stepsCurrent == 4)
          //   ? false
          //   : true
          stepsCurrent == 4 ? false : true
        }
        block
        style={{ width: "200px" }}
        loading={subLoading}
        onClick={onSubmit}
      >
        开始共享
      </Button>
    )
  }

  /**
   * 跳转共享记录
   */
  const handleOk = () => {
    props.history.push({
      pathname: "/admin/discernmanage/sharerecord",
    })
  }

  /**
   * 关闭Modal
   */
  const handleCancel = () => {
    setOpenModal(false)
    //关闭Modal后，重置参数
    setShareWay("") // 重置共享方式(1:追加，0:覆盖)
    setChildCheckedKeys([]) // 重置树形选中参数（剔除父级后）
    setCheckedKeys([]) // 重置树形选中参数(不剔除父级)
    setDataSetId(undefined) // 重置数据集合下拉列表选中id
    setShopId(undefined) // 重置门店选中id
    setDeviceId(undefined) // 重置设备选中id
    setDataSourceRadio("") // 重置数据来源
    setShareDataRadio("0") // 重置共享数据
    setStepsCurrent(1) // 步骤条进度归1
  }

  return (
    <div className="newdatashare_page">
      <Steps
        direction="vertical"
        current={stepsCurrent}
        items={[
          // 步骤一
          {
            title: "选择共享数据",
            description: (
              <div className="newdatashare_page_sharedata">
                <Radio.Group
                  style={{ width: "300px" }}
                  onChange={onSharedata}
                  value={shareDataRadio}
                >
                  <Radio value={"0"}>学习数据</Radio>
                  <Radio value={"1"}>icon数据</Radio>
                </Radio.Group>

                {/* 右侧提示 */}
                <div className="page_icon_tab">
                  {shareDataRadio == 0 ? (
                    <div style={{ padding: "10px" }}>
                      学习数据共享说明：
                      接收数据的设备需与共享设备保持产品线一致、版本号一致，否则无法勾选
                    </div>
                  ) : shareDataRadio == 1 ? (
                    <div style={{ padding: "10px" }}>
                      图片数据共享说明：接收设备需与共享设备需要保证商品数据一致，否则无法接收共享的ICON
                    </div>
                  ) : null}
                </div>
              </div>
            ),
          },
          // 步骤二
          {
            title: "选择数据来源",
            description: (
              <div className="newdatashare_page_datasource">
                <Radio.Group
                  style={{ width: "300px" }}
                  onChange={onDatasourceChang}
                  value={dataSourceRadio}
                >
                  <Radio value={"3"}>数据集合</Radio>
                  <Radio value={"0"}>门店/设备</Radio>
                </Radio.Group>
                <div style={{ marginTop: "20px" }}>
                  {/* 数据集合 */}
                  {dataSourceRadio == 3 ? (
                    <div>
                      <Select
                        style={{ width: "200px" }}
                        onChange={(e, option) => {
                          onDataSetChange(e, option)
                        }}
                        placeholder="请选择数据集合！"
                        value={dataSetId}
                      >
                        {dataSetList.map((v) => {
                          return (
                            <Option value={v.id} key={v.id} info={v}>
                              {v.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                  ) : null}

                  {/* 门店 */}
                  {dataSourceRadio == 0 ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        showSearch
                        filterOption={(i, o) => o.props.children.includes(i)}
                        style={{ width: "200px" }}
                        onChange={(e, option) => {
                          onShopChange(e, option)
                        }}
                        placeholder="请选择门店！"
                        value={shopId}
                      >
                        {shopList.map((v) => {
                          return (
                            <Option value={v.id} key={v.id} info={v}>
                              {v.name}
                            </Option>
                          )
                        })}
                      </Select>
                      {/* 设备 */}
                      <Select
                        style={{ width: "200px", marginLeft: "20px" }}
                        onChange={(e, option) => {
                          onDeviceChange(e, option)
                        }}
                        placeholder="请选择设备！"
                        value={deviceId}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        showSearch
                        filterOption={(i, o) => o.props.children.includes(i)}
                      >
                        {deviceList.map((v) => {
                          return (
                            <Option
                              value={v.deviceSn}
                              key={v.deviceSn}
                              info={v}
                            >
                              {v.cdKeyCode}
                            </Option>
                          )
                        })}
                      </Select>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "20px",
                        }}
                      >
                        已选择：
                        {shopName ? shopName + "-" : ""}
                        {deviceInfoData?.scaleNo
                          ? deviceInfoData?.scaleNo + "-"
                          : ""}
                        {deviceInfoData?.productLineName
                          ? deviceInfoData?.productLineName + "-"
                          : ""}
                        {deviceInfoData?.productLineTypeName
                          ? deviceInfoData?.productLineTypeName + "-"
                          : ""}
                        {deviceInfoData?.matchingRulesVersion
                          ? deviceInfoData?.matchingRulesVersion + "-"
                          : ""}
                        {deviceInfoData?.aiModeName
                          ? deviceInfoData?.aiModeName
                          : ""}
                        <Link
                          to={{
                            pathname:
                              shareDataRadio == 0
                                ? `/admin/discernmanage/telldata`
                                : `/admin/discernmanage/incoinfo`,
                          }}
                        >
                          <Button
                            type="link"
                            style={{ color: "#2EB998" }}
                            onClick={() => {
                              window.localStorage.setItem(
                                "shopName",
                                shopName || ""
                              )
                              window.localStorage.setItem(
                                "scaleNo",
                                deviceInfoData?.scaleNo || ""
                              )
                              window.localStorage.setItem(
                                "productLineName",
                                deviceInfoData?.productLineName || ""
                              )
                              window.localStorage.setItem(
                                "productLineTypeName",
                                deviceInfoData?.productLineTypeName || ""
                              )
                              window.localStorage.setItem(
                                "matchingRulesVersion",
                                deviceInfoData?.matchingRulesVersion || ""
                              )
                              window.localStorage.setItem(
                                "deviceSn",
                                deviceId || ""
                              )
                            }}
                          >
                            {shareDataRadio == 0
                              ? "查看识别数据"
                              : shareDataRadio == 1
                              ? "查看icon数据"
                              : ""}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ),
          },
          // 步骤三
          {
            title: "选择接收设备",
            description: (
              <div className="newdatashare_page_receive_device">
                {isShowTree && (dataSetId || deviceId) ? (
                  <>
                    {/* Card顶部数据展示 */}
                    <div
                      style={{
                        width: "59%",
                      }}
                      className="page_card_head"
                    >
                      <div style={{ display: "flex" }}>
                        <h2>接收设备：{treeAllChildKey.length}个</h2>
                        <h2 style={{ marginLeft: "20px" }}>
                          选择设备：{childCheckedKeys.length}个
                        </h2>
                      </div>
                      <div>
                        <Badge
                          status="success"
                          text="在线"
                          style={{ marginRight: "20px" }}
                        />
                        <Badge
                          status="error"
                          text="离线"
                          style={{ marginRight: "20px" }}
                        />
                        <Button type="primary" size="small" onClick={onRefresh}>
                          刷新
                        </Button>
                      </div>
                    </div>
                    {/* 树列表 */}
                    <Card
                      title={onColModalLeftTitle()}
                      extra={
                        <Input
                          placeholder="请输入门店名称"
                          onChange={onChange}
                          value={searchValue}
                        />
                      }
                      style={{ width: "60%" }}
                    >
                      <div className="datasetof_card_modal">
                        <Tree
                          expandedKeys={treeAllChildKey}
                          showIcon
                          checkable
                          onCheck={(checkedKeysValue) => {
                            setCheckedKeys(checkedKeysValue)
                          }}
                          treeData={treeData}
                          autoExpandParent={true}
                          defaultExpandParent={true}
                          defaultExpandAll={true}
                          checkedKeys={checkedKeys}
                        />
                      </div>
                    </Card>
                  </>
                ) : (
                  <Card style={{ width: "60%" }}>
                    <div className="newdatashare_page_nodata">
                      {" "}
                      <ContainerOutlined
                        style={{ fontSize: "50px", marginBottom: "10px" }}
                      />
                      暂无数据
                    </div>
                  </Card>
                )}
              </div>
            ),
          },
          // 步骤四
          // shareDataRadio == 1
          //   ? {
          //       title: "共享方式",
          //       description: (
          //         <div className="newdatashare_page_datasource">
          //           <Radio.Group
          //             onChange={(e) => {
          //               if (e.target.value) {
          //                 setStepsCurrent(4)
          //               }
          //               setShareWay(e.target.value)
          //             }}
          //             value={shareWay}
          //             style={{ width: "100%" }}
          //             disabled={
          //               (stepsCurrent == 3 || stepsCurrent == 4) &&
          //               shareDataRadio == 1
          //                 ? false
          //                 : true
          //             }
          //           >
          //             <Radio value={"0"}>覆盖（覆盖原有商品图片）</Radio>
          //             <Radio value={"1"}>
          //               追加（保留原有商品图片，新增无图图片）
          //             </Radio>
          //           </Radio.Group>
          //         </div>
          //       ),
          //     }
          //   : {
          //       title: onTitleBody(),
          //     },
          {
            title: "共享方式",
            description: (
              <div className="newdatashare_page_datasource">
                <Radio.Group
                  onChange={(e) => {
                    if (e.target.value) {
                      setStepsCurrent(4)
                    }
                    setShareWay(e.target.value)
                  }}
                  value={shareWay}
                  style={{ width: "100%" }}
                  // disabled={
                  //   (stepsCurrent == 3 || stepsCurrent == 4) &&
                  //   shareDataRadio == 1
                  //     ? false
                  //     : true
                  // }
                  disabled={
                    stepsCurrent == 3 || stepsCurrent == 4 ? false : true
                  }
                >
                  <Radio value={"0"}>
                    覆盖{shareDataRadio == 1 ? "（覆盖原有商品图片）" : ""}
                  </Radio>
                  <Radio value={"1"}>
                    追加
                    {shareDataRadio == 1
                      ? "（保留原有商品图片，新增无图图片）"
                      : ""}
                  </Radio>
                </Radio.Group>
              </div>
            ),
          },

          // 步骤五
          // shareDataRadio == 1
          //   ? {
          //       title: shareDataRadio == 1 ? onTitleBody() : null,
          //     }
          //   : null,
          // 步骤五
          {
            title: onTitleBody(),
          },
        ]}
      />
      <Modal
        title=""
        destroyOnClose={true}
        closable={true}
        open={openModal}
        onCancel={handleCancel}
        footer={[]}
      >
        <div className="newdatashare_page_steps_modal">
          <div className="newdatashare_page_modal_body">
            <div className="newdatashare_page_okicon">
              <CheckOutlined />
            </div>
            <div>
              {shareDataRadio == 0 ? "学习数据共享成功" : "图片数据共享成功"}
            </div>
            <Button onClick={handleOk}>查看共享记录</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Index
