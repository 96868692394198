import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Table,
  message,
  Select,
  Image as AntdImage,
  Modal,
} from "antd"
import * as OrphanServer from "../../services/orphan"
import { useHistory } from "react-router-dom"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"
import { PubRangePicker } from "../../../components/PubRangePicker"
import dayjs from "dayjs"
import Hls from "hls.js"
const { Option } = Select
//////////////////////////
// 播放器实例
var player = null
window.__SHOW_LOG__ = true

// Promise封装实例释放
function playerDestroyPromise() {
  return new Promise((resolve, reject) => {
    if (!player) {
      resolve("OK")
    }
    player.stop()
    player
      .destroy()
      .then(() => {
        resolve("OK")
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// 同步方式等待释放
async function playerDestroy() {
  try {
    await playerDestroyPromise()
    player = null
  } catch (e) {
    console.error(e)
  }
}

//////////////////////////
function Index(props) {
  const myRef = useRef(null)
  var hls = new Hls()
  let history = useHistory()
  const [typeNum, setTypeNum] = useState("")
  const [orphanList, setOrthanList] = useState([]) // 孤儿商品列表
  const [shopList, setShopList] = useState([]) // 门店列表
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false) // 查看录像Modal框状态
  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    skuName: undefined, // 商品名称
    shopId: undefined, // 门店ID
    mark: undefined, // 标记
    timeArr: [], // 总时间
    startDate: "", // 开始时间
    endDate: "", // 结束时间
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // 初始化
  useEffect(() => {
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    //孤儿列表接口，
    setTimeout(getOrphan, 0)
    // 门店列表
    getShopList()
  }, [])

  // 销毁视cavas
  useEffect(() => {
    if (!isModalOpen) {
      // 销毁视cavas
      console.log(222)
      return playerDestroy
    }
  }, [!isModalOpen])

  /**
   * 孤儿商品列表
   */
  const getOrphan = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
        timeArr: null,
      }
      setLoading(true)
      const { code, data, msg } = await OrphanServer.orphan_good_list(params)
      if (code != 200 || data === null) {
        message.error(msg)
        setLoading(false)
        return
      }
      setOrthanList(data.list)
      setTotal(data.total)
      setLoading(false)
    } catch (error) {
      message.error("防损规则列表获取失败")
      setLoading(false)
    }
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      //孤儿商品列表
      setTimeout(getOrphan, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await OrphanServer.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data?.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        pageNum: 1,
        pageSize: 20,
        skuName: undefined, // 商品名称
        shopId: undefined, // 门店ID
        mark: undefined, // 标记
        startDate: "", // 开始时间
        endDate: "", // 结束时间
        timeArr: [], // 总时间
      })
      //孤儿商品列表
      setTimeout(getOrphan, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({ ...ParamsInfo, pageNum: current })
        // 孤儿商品列表
        setTimeout(getOrphan, 0)
      },
    }
    return paginationProps
  }

  /**
   * 监控回放
   */
  const getPlaybackCamera = async (params) => {
    try {
      const { code, data, msg } = await OrphanServer.PlaybackCamera(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setTypeNum(data.type)
      //  海康
      if (data && data.playUrl && data.type == 1) {
        console.log(data)
        if (Hls.isSupported()) {
          hls.loadSource(data.playUrl)
          // hls.loadSource(
          //   "https://hw-m-l.cztv.com/channels/lantian/channel002/1080p.m3u8"
          // )
          hls.attachMedia(myRef.current)
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            myRef.current.play()
          })
        } else {
          message.error("不支持HLS播放器")
        }
      } else if (data && data.playUrl && data.type == 2) {
        // TP-LINK商用云平台
        var TumsPlayer = window["tums-player"].default
        player = new TumsPlayer("video-container", {
          type: "relay", // 协议类型，rtsp/relay
          url: data.playUrl ? data.playUrl : "", // 取流地址
          pluginPath: "https://smbcloud.tp-link.com.cn/static",
          streamType: "sdvod",
          autoplay: true,
          eventType: [1, 2],
          startTime: params.startTime, // 跳转到指定时间开始回放
          endTime: params.endTime,
          userId: data.userId,
        })
      } else {
        //异常
        message.error("异常")
      }
    } catch (error) {
      console.log(error)
      message.error(error)
    }
  }

  /**
   * 标记操作
   */
  const onGetorphanGoodMark = async (mark, id) => {
    try {
      const params = { id, mark }
      const { code, msg, data } = await OrphanServer.orphan_good_mark(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("标记成功")
        //页码为1
        // setParamsInfo({ ...ParamsInfo, pageNum: 1 })
        //孤儿商品列表
        setTimeout(getOrphan, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "生成时间",
      dataIndex: "createTime",
      key: "createTime",
      ellipsis: { showTitle: true },
      width: 160,
    },
    {
      title: "商品名称",
      dataIndex: "skuName",
      key: "skuName",
      ellipsis: { showTitle: true },
      width: 160,
    },
    {
      title: "商品名称PLU",
      dataIndex: "skuCode",
      key: "skuCode",
      ellipsis: { showTitle: true },
      width: 160,
    },
    {
      title: "标签码",
      dataIndex: "barCode",
      key: "barCode",
      ellipsis: { showTitle: true },
      width: 160,
    },
    {
      title: "打秤时间",
      dataIndex: "weightTime",
      key: "weightTime",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "收银时间",
      dataIndex: "cashierTime",
      ellipsis: { showTitle: true },
      key: "cashierTime",
      width: 200,
    },
    {
      title: "超时x分未收银为孤儿商品",
      dataIndex: "timeoutMinute",
      ellipsis: { showTitle: true },
      key: "timeoutMinute",
      width: 200,
    },
    {
      title: "所属门店",
      dataIndex: "shopName",
      key: "shopName",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "打秤设备激活码",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "称重图片",
      dataIndex: "imageUrl",
      key: "imageUrl",
      ellipsis: { showTitle: true },
      width: 160,
      render: (info) => {
        return (
          <div className={styles.page_table_img}>
            <AntdImage src={info} />
          </div>
        )
      },
    },
    {
      title: "打秤设备录像",
      dataIndex: "weightTime",
      key: "weightTime",
      ellipsis: { showTitle: true },
      width: 160,
      render: (info, rowInfo) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setTypeNum(2)
                let NowDate = dayjs(dayjs(info)) || dayjs()
                let newStart = NowDate.subtract(1, "minute").toDate().valueOf()
                let newEnd = NowDate.add(1, "minute").toDate().valueOf()
                const params = {
                  startTime: newStart,
                  endTime: newEnd,
                  deviceSn: rowInfo?.deviceSn || "",
                }
                setIsModalOpen(true)
                setTimeout(() => {
                  getPlaybackCamera(params)
                }, 100)
              }}
            >
              查看
            </Button>
          </>
        )
      },
    },
    {
      title: "标记",
      dataIndex: "mark",
      key: "mark",
      ellipsis: { showTitle: true },
      width: 160,
      render: (info, rowInfo) => {
        return (
          <>
            <Select
              className={info == 1 ? styles.page_table_select : ""}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择标记"
              style={{ width: 120 }}
              // allowClear
              value={info}
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
              onChange={(e) => {
                onGetorphanGoodMark(e, rowInfo?.id)
              }}
            >
              <Option value={1} key={1} style={{ color: "red" }}>
                未找回
              </Option>
              <Option value={2} key={2}>
                无需找回
              </Option>
              <Option value={3} key={3}>
                已找回
              </Option>
            </Select>
          </>
        )
      },
    },
  ]
  return (
    // 防损列表
    <div>
      <Card>
        <div className={styles.loss_prevention_list_head}>
          <div className={styles.loss_prevention_list_head_search}>
            <div className={styles.loss_prevention_list_head_search_child}>
              商品名称：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    skuName: val,
                  })
                }}
                placeholder="商品名称"
                value={ParamsInfo.skuName}
              />
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              门店名称：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择门店"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                  })
                }}
              >
                {shopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              生成时间：
              <PubRangePicker
                value={ParamsInfo?.timeArr}
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    startDate: val.startDate, // 开始时间
                    endDate: val.endDate, // 结束时间
                    timeArr: val.TotalTime, // 总时间
                  })
                }}
              />
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              标记：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择标记"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.mark}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    mark: e || undefined,
                  })
                }}
              >
                <Option value={1} key={1}>
                  未找回
                </Option>
                <Option value={2} key={2}>
                  无需找回
                </Option>
                <Option value={3} key={3}>
                  已找回
                </Option>
              </Select>
            </div>
          </div>
          <div className={styles.loss_prevention_list_head_btn}>
            <Button onClick={handClearn}>重置</Button>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={orphanList}
            rowKey="id"
            loading={loading}
            scroll={{ x: 1000, y: window.screen.height * 0.53 }}
          />
        </div>
      </Card>

      <Modal
        keyboard
        title="查看录像"
        open={isModalOpen}
        maskClosable
        footer={[]}
        width={"60%"}
        onCancel={() => {
          setIsModalOpen(false)
        }}
      >
        <div className={styles.policeroad_imgmodal}>
          <div className={styles.policeroad_imgmodal_left}>
            {typeNum == 1 ? (
              <video
                ref={myRef}
                width="100%"
                height="100%"
                muted
                controls
                autoPlay
              ></video>
            ) : typeNum == 2 ? (
              <div
                id="video-container"
                style={{ width: "100%", height: "100%" }}
                onClick={() => {
                  player?.fullscreen()
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Index
