import { Button, Table, Select, message, Cascader, Card } from "antd"
import React, { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"

import {
  PreventionChange,
  ExportStaffChange,
  ShopTreeList,
} from "../../services/lossmanage"

const { Option } = Select
//开始时间
let ST = HandDefaultTime?.MonStaDatVal
// 结束时间
let ET = HandDefaultTime?.MonEndDatVal
// 总时间
let AT = [dayjs().subtract(1, "month"), dayjs()]
// 项目id
const projectId = localStorage.getItem("uid")

function Index(props) {
  //shopId：门店id,staffName:员工,skuCode:商品PLU||商品名称,recoredId:精确查询id,specific_time:时间
  let JumpToHereInfo = props.location.state
  //从其他页面跳转来带时间的话做处理  -----
  let JST = "" // 开始
  let JET = "" // 结束
  let JAT = "" // 所有
  if (JumpToHereInfo?.tabKey == 1) {
    //跳转开始时间
    // JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    //跳转结束时间
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("day").valueOf()
      : ST
    ET = JST ? dayjs(JumpToHereInfo?.specific_time).endOf("day").valueOf() : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("day"),
      dayjs(JumpToHereInfo?.specific_time).endOf("day"),
    ]
  } else if (JumpToHereInfo?.tabKey == 2) {
    const NewTime = JumpToHereInfo?.specific_time[1].split("-")
    JST = NewTime ? NewTime[0] : ""
    //跳转结束时间
    JET = NewTime ? NewTime[1] : ""
    ST = JST
      ? dayjs(dayjs(JST).format("YYYY-MM-DD") + " " + "00:00:00").valueOf()
      : ST
    ET = JET
      ? dayjs(dayjs(JET).format("YYYY-MM-DD") + " " + "23:59:59").valueOf()
      : ET
    JAT = [dayjs(JST), dayjs(JET)]
  } else if (JumpToHereInfo?.tabKey == 3) {
    //跳转开始时间
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("month").valueOf()
      : ST
    ET = JST
      ? dayjs(JumpToHereInfo?.specific_time).endOf("month").valueOf()
      : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("month"),
      dayjs(JumpToHereInfo?.specific_time).endOf("month"),
    ]
  } else {
    JAT = AT
  }
  //--------------------------
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(JumpToHereInfo?.recoredId || undefined) //精确查询的id
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [shopList, setShopList] = useState([]) //门店设备列表
  const [GiveParams, setGiveParams] = useState({
    timeArr: JAT,
    startDate: ST,
    endDate: ET,
    pageNum: 1,
    pageSize: 20,
    priceType: undefined, // 改价类型: 1正常价格 2临时改价 3永久改价 4阶梯变价
    shopId: JumpToHereInfo?.shopId || undefined,
    deviceSn: "",
    opeator: JumpToHereInfo?.staffName || "",
    code: JumpToHereInfo?.skuCode || "",
    projectId: projectId, //项目id
    id: JumpToHereInfo?.recoredId || undefined, //精确查询的id
    // ids: [],
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    //调用门店设备
    getShopTreeList()
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 门店设备
   */
  const getShopTreeList = async (value) => {
    try {
      const params = {
        code: value,
        districtId: "",
      }
      const { code, data, msg } = await ShopTreeList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.value = v.shopId
          v.label = v.name
          if (v.deviceList) {
            v.deviceList.map((w) => {
              w.value = w.deviceSn
              w.label = w.cdKeyCode
            })
          }
          v.children = v.deviceList
        })

        //判断是否有这个门店,如何传入的shopId在门店列表中存在的话
        // 就给门店/设备赋默认值，并且表格接口得传入数据也传入shopID，否则为空
        const IsHave = data.some((v) => v.shopId == JumpToHereInfo?.shopId)
        setGiveParams({
          ...GiveParams,
          shopId: IsHave ? Number(JumpToHereInfo?.shopId) : "",
        })

        // 合并原始数组与查询出的数组，并去重
        const NewArr = data.concat(shopList)
        const obj = {}
        let NewArr1 = []
        for (let i = 0; i < NewArr.length; i++) {
          if (!obj[NewArr[i]["shopId"]]) {
            obj[NewArr[i]["shopId"]] = 1
            NewArr1.push(NewArr[i])
          }
        }

        //存储门店/设备列表
        setShopList(NewArr1)

        //调用获取表格列表数据
        setTimeout(getGoodsWeighDetail, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //获取表格列表数据
  const getGoodsWeighDetail = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      const params = {
        ...GiveParamsRef,
        timeArr: [],
      }
      setLoading(true)
      const { code, msg, data } = await PreventionChange(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.rows || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //导出
  const getExport = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      const params = {
        ...GiveParamsRef,
        timeArr: [],
      }
      const res = await ExportStaffChange(params)
    } catch (error) {
      console.log(error)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getGoodsWeighDetail, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "改价时间",
      dataIndex: "weighDateYear",
      ellipsis: { showTitle: true },

      key: "weighDateYear",
      width: 200,
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },

      key: "shopName",
      width: 200,
    },
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },

      key: "shopCode",
      width: 200,
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },

      key: "scaleNo",
      width: 100,
    },
    {
      title: "员工工号",
      dataIndex: "operator",
      ellipsis: { showTitle: true },

      key: "operator",
      width: 100,
    },
    {
      title: "商品名称",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },

      key: "skuName",
      width: 200,
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },

      key: "skuCode",
      width: 100,
    },
    {
      title: "改价前",
      dataIndex: "agoPrice",
      ellipsis: { showTitle: true },

      key: "agoPrice",
      width: 180,
      render: (_, rowInfo) => {
        return (
          <div>
            {rowInfo?.priceChange == 1
              ? "单价："
              : rowInfo?.priceChange == 2
              ? "总价："
              : ""}
            {rowInfo?.agoPrice > 0 ? (rowInfo?.agoPrice / 100).toFixed(2) : "0"}
            {rowInfo?.priceChange == 1
              ? rowInfo?.priceUnit == 0
                ? " 元 / kg"
                : rowInfo?.priceUnit == 1
                ? " 元 / 个"
                : ""
              : "元"}
          </div>
        )
      },
    },
    {
      title: "改价后",
      dataIndex: "price",
      ellipsis: { showTitle: true },

      key: "price",
      width: 180,
      render: (_, rowInfo) => {
        return (
          <div>
            {rowInfo?.priceChange == 1
              ? "单价："
              : rowInfo?.priceChange == 2
              ? "总价："
              : ""}
            {rowInfo?.price > 0 ? (rowInfo?.price / 100).toFixed(2) : 0}
            {rowInfo?.priceChange == 1
              ? rowInfo?.priceUnit == 0
                ? " 元 / kg"
                : rowInfo?.priceUnit == 1
                ? " 元 / 个"
                : ""
              : "元"}
          </div>
        )
      },
    },
    {
      title: "改价类型",
      dataIndex: "priceType",
      ellipsis: { showTitle: true },

      key: "priceType",
      width: 100,
      render: (info) => {
        return (
          <>
            {info == 1
              ? "正常价格"
              : info == 2
              ? "临时改价"
              : info == 3
              ? "永久改价"
              : info == 4
              ? "阶梯变价"
              : ""}
          </>
        )
      },
    },
  ]

  //查询
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }
  //重置
  // 重置时将时间恢复默认
  // 开始时间
  ST = HandDefaultTime?.MonStaDatVal
  // 结束时间
  ET = HandDefaultTime?.MonEndDatVal
  const handClear = () => {
    JumpToHereInfo = false
    setGiveParams({
      ...GiveParams,
      timeArr: AT,
      pageNum: 1,
      startDate: ST,
      endDate: ET,
      priceType: undefined,
      deviceSn: "",
      shopId: "",
      code: "",
      opeator: "",
      id: "",
      // ids: [],
    })
    setTimeout(getGoodsWeighDetail, 0)
  }

  return (
    <div className={styles.policeroad_page}>
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.updateroad_policeroad_page_search}>
          <div className={styles.updateroad_policeroad_search_body}>
            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>改价时间：</span>
              <PubRangePicker
                value={GiveParams?.timeArr}
                disDate={30}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    timeArr: val.TotalTime,
                    startDate: val.startDateValueOf,
                    endDate: val.endDateValueOf,
                  })
                }}
              />
            </div>

            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>门店/设备：</span>
              <Cascader
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                showSearch
                style={{ width: 220 }}
                changeOnSelect
                options={shopList}
                placeholder="请选择门店/设备"
                value={
                  GiveParams?.shopId && GiveParams?.deviceSn
                    ? [GiveParams?.shopId, GiveParams?.deviceSn]
                    : GiveParams?.shopId
                    ? [GiveParams?.shopId]
                    : []
                }
                onSearch={(e) => {
                  if (e.length >= 2) {
                    getShopTreeList(e)
                  }
                }}
                onChange={(e) => {
                  if (e) {
                    setGiveParams({
                      ...GiveParams,
                      shopId: e[0] ? e[0] : "", //商品编号
                      deviceSn: e[1] ? e[1] : "", //操作设备
                    })
                  } else {
                    setGiveParams({
                      ...GiveParams,
                      shopId: undefined, //商品编号
                      deviceSn: undefined, //操作设备
                    })
                  }
                }}
              />
            </div>

            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>商品名称/PLU：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, code: val })
                }}
                placeholder="商品名称/PLU"
                value={GiveParams.code}
              />
            </div>

            <div className={styles.updateroad_policeroad_search_body_child}>
              <span>员工工号：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, opeator: val })
                }}
                placeholder="员工工号"
                value={GiveParams.opeator}
              />
            </div>
          </div>
          <div className={styles.updateroad_policeroad_search_button}>
            <Button type="primary" onClick={() => handSearch()}>
              查询
            </Button>
            <Button onClick={() => handClear()}>重置</Button>
          </div>
        </div>
      </Card>
      {/* <div className={styles.}policeroad_page_body"> */}
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        {/* 导出 */}
        <Button type="primary" onClick={getExport}>
          导出数据
        </Button>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          // rowSelection={rowSelection}
          scroll={{ x: "max-content", y: `${WinHeight - 425}px` }}
        />
      </Card>
    </div>
  )
}

export default Index
