import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  Radio,
  Steps,
  Tag,
  Select,
  message,
  Table,
  Button,
  Modal,
  Image,
} from "antd"
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons"
import styles from "./Index.module.css"
import { useHistory } from "react-router-dom"
import * as DataShareServer from "../services/data_share"
import PubInput from "../../components/PubInput"

const { Option } = Select

function Index() {
  const history = useHistory()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [stepsCurrent, setStepsCurrent] = useState(0) // 步骤条
  const [dataSetList, setDataSetList] = useState([]) // 数据集合下拉列表
  const [deviceList, setDeviceList] = useState([]) // 设备列表
  const [shopList, setShopList] = useState([]) // 门店列表
  //
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选择接收设备选中的sn
  //
  const [newDeviceList, setNewDeviceList] = useState([]) // 设备管理列表查询数组
  const [total, setTotal] = useState(0) // 设备管理列表查询数组总数
  //是否展示接收设备表格
  const [isShowTable, setIsShowTable] = useState(false) //
  //共享成功弹窗状态
  const [isShowModal, setIsShowModal] = useState(false) //
  //图片链接
  const [imgUrl, setImgUrl] = useState("") //
  //  查看图片数据或者查看识别数据的总数

  const [isShowTitle, setIsShowTitle] = useState(false) // 新增是否展示查看识别数据

  // const [seeTotal, setSeeTotal] = useState(0)

  const [GetTableParams, setGetTableParams] = useState({
    pageNum: 1,
    pageSize: 10,
    online: undefined,
    cdKeyCode: undefined,
    scaleNo: undefined,
    shopName: undefined,
  })

  //保证每次拿到的都是准确的值
  const BccountRef = useRef({
    GetTableRef: {},
  })

  useEffect(() => {
    BccountRef.current = {
      GetTableRef: GetTableParams,
    }
  }, [GetTableParams])

  const [GiveParams, setGiveParams] = useState({
    dataType: "0", // 数据类型(0:学习数据，1:图片数据)
    dataSource: undefined, //选择数据来源 0:门店/设备,3:数据集合
    dataSetId: undefined, // 数据集合id
    shopId: undefined, // 门店id
    deviceId: undefined, // 设备SN
    shareType: undefined, // 共享方式(1:追加，0:覆盖)
    deviceSns: [], //  分享设备sn集合
    shopName: undefined, // 门店名称 （用来展示）
    scaleNo: undefined, // 秤号 （用来展示）
    productLineName: undefined, // window还是安卓（用来展示）
    productLineTypeName: undefined, // 版本 （用来展示）
    matchingRulesVersion: undefined, // 版本号 （用来展示）
    INFO: undefined, //
    projectId: window.localStorage.getItem("uid"), // 项目Id
    selectAll: 1,
  })

  //保证每次拿到的都是准确的值
  const AccountRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    AccountRef.current = {
      GiveParamsRef: GiveParams,
    }
    //一步骤
    if (GiveParams.dataType) {
      setStepsCurrent(1)
    }
    // 二步骤
    if (GiveParams.dataType && (GiveParams.dataSetId || GiveParams.deviceId)) {
      setStepsCurrent(2)
    }
    // 三步骤
    if (
      GiveParams?.deviceSns?.length > 0 &&
      GiveParams.dataType &&
      (GiveParams.dataSetId || GiveParams.deviceId)
    ) {
      setStepsCurrent(3)
    }
    // 四步骤
    if (
      GiveParams?.deviceSns?.length > 0 &&
      GiveParams.dataType &&
      (GiveParams.dataSetId || GiveParams.deviceId) &&
      GiveParams.shareType
    ) {
      setStepsCurrent(4)
    }
  }, [GiveParams])

  /**
   * 默认初始化
   */
  useEffect(() => {
    // 调用数据集合下拉接口
    getDataPullList()

    // 调用数据集合下拉接口
    getShopList()

    // 数据集合下拉接口
    // getNewDeviceList()
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 设备管理列表查询接口
   */
  const getNewDeviceList = async () => {
    try {
      const { GetTableRef } = BccountRef.current
      const { GiveParamsRef } = AccountRef.current

      const { code, data, msg } = await DataShareServer.new_device_List(
        GetTableRef
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      // info有值时作比较
      console.log(GiveParamsRef)
      if (
        GiveParamsRef.dataType == 0 &&
        GiveParamsRef.INFO &&
        GiveParamsRef.dataSource == 0
      ) {
        const { INFO } = GiveParamsRef
        if (data && data?.list) {
          setTotal(data.total)
          data.list.map((v) => {
            v.disabled =
              v.matchingRulesVersion === INFO?.matchingRulesVersion &&
              v.productLineName === INFO?.productLineName &&
              v.productLineTypeName === INFO?.productLineTypeName &&
              v.deviceSn !== INFO?.deviceSn
                ? false
                : true
            v.reason =
              v.matchingRulesVersion !== INFO?.matchingRulesVersion
                ? "版本号不一致"
                : v.productLineName !== INFO?.productLineName
                ? "产品线不一致"
                : v.productLineTypeName !== INFO?.productLineTypeName
                ? "版本不一致"
                : v.deviceSn === INFO?.deviceSn
                ? "来源设备接收设备相同"
                : ""
          })
          setNewDeviceList(data.list)
        }
      } else if (
        GiveParamsRef.dataType == 0 &&
        GiveParamsRef.INFO &&
        GiveParamsRef.dataSource == 3
      ) {
        const { INFO } = GiveParamsRef
        if (data && data?.list) {
          setTotal(data.total)
          data.list.map((v) => {
            v.disabled =
              INFO?.aiMode === null && INFO?.aiVersion === ""
                ? false
                : v?.aiMode === null && v?.aiVersion === ""
                ? false
                : v?.aiMode == INFO?.aiMode && v?.aiVersion === INFO?.aiVersion
                ? false
                : true
            v.reason =
              v?.aiMode != INFO?.aiMode
                ? "识别模型不一致"
                : v?.aiVersion !== INFO?.aiVersion
                ? "识别模型版本不一致"
                : ""
          })
          setNewDeviceList(data.list)
        }
      } else {
        if (data && data?.list) {
          setTotal(data.total)
          data.list.map((v) => {
            v.disabled = false
          })
          setNewDeviceList(data.list)
        }
      }
    } catch (error) {
      // message.error("设备管理列表获取失败")
      return
    }
  }

  /**
   * 数据集合下拉接口
   */
  const getDataPullList = async () => {
    try {
      const { code, data, msg } = await DataShareServer.DataPullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setDataSetList(data || [])
    } catch (error) {
      console.log(error)
      // message.error("数据汇总列表请求失败")
    }
  }

  // 列表查询,查询是否要展示底部的设备列表
  const getLearnList = async (newDeviceSn, newDataSetId) => {
    try {
      const params = {
        pageNo: 1,
        pageSize: 10,
        deviceSn: newDeviceSn,
        dataSetId: newDataSetId,
      }
      const { code, data, msg } = await DataShareServer.Learn_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      console.log(data)
      if (data && data.total == -1) {
        setIsShowTitle(false)
        setIsShowTable(true)
      } else {
        setIsShowTitle(true)
        if (data && data.total > 0) {
          setIsShowTable(true)
        } else {
          setIsShowTable(false)
        }
      }
    } catch (error) {
      console.log(error)
      setIsShowTable(false)
      // message.error("列表查询列表请求失败")
    }
  }

  /**
   * 门店列表
   */
  const getShopList = async () => {
    try {
      const { code, data, msg } = await DataShareServer.Shop_List()
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data || [])
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 监听门店列表选中值变化时，触发门的列表
   */
  useEffect(() => {
    if (GiveParams.shopId) {
      getDeviceList()
      setGiveParams({ ...GiveParams, deviceId: undefined })
    } else {
      setDeviceList([])
      setGiveParams({ ...GiveParams, deviceId: undefined })
    }
  }, [GiveParams.shopId])

  /**
   * 设备
   */
  const getDeviceList = async () => {
    try {
      const { code, data, msg } = await DataShareServer.Shop_Device(
        GiveParams.shopId
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      setDeviceList(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 1 选择 数据单选
   * 学习数据：0
   * 图片数据：1
   */
  const onDataType = (e) => {
    setGiveParams({
      ...GiveParams,
      dataType: e.target.value,
      dataSource: undefined,
      dataSetId: undefined, // 切换单选框时，数据集合选中id
      shopId: undefined, // 门店id置空
      deviceId: undefined, // 设备id置空
      INFO: undefined,
      deviceSns: [],
    })
    setNewDeviceList([])
    setTotal(0)
    setGetTableParams({
      pageNum: 1,
      pageSize: 10,
      online: undefined,
      cdKeyCode: undefined,
      scaleNo: undefined,
      shopName: undefined,
    })
    //清空表格选中
    setSelectedRowKeys([])
  }

  /**
   * 2 选择 数据来源单选
   *  数据汇总：3
   *  门店/设备：0
   */
  const onDataSources = (e) => {
    setGiveParams({
      ...GiveParams,
      dataSource: e.target.value,
      dataSetId: undefined, // 切换单选框时，数据集合选中id
      shopId: undefined, // 门店id置空
      deviceId: undefined, // 设备id置空
    })
    setNewDeviceList([])
    setTotal(0)
    setGetTableParams({
      pageNum: 1,
      pageSize: 10,
      online: undefined,
      cdKeyCode: undefined,
      scaleNo: undefined,
      shopName: undefined,
    })
    //清空表格选中
    setSelectedRowKeys([])
    console.log(GiveParams.dataType)
  }

  // 判断是否要继续往下执行
  /**
   * 商品图片库icon列表
   * @param  dataSetId
   */
  const getIconList = async (dataSetId) => {
    try {
      const params = { pageNum: 1, pageSize: 20, dataSetId: dataSetId }
      const { code, data, msg } = await DataShareServer.icon_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.total > 0) {
        setIsShowTable(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查看识别数据列表
   * @param deviceSn
   */
  const getSkulist = async (deviceSn) => {
    try {
      const params = { pageNo: 1, pageSize: 10, deviceSn: deviceSn }
      const { code, data, msg } = await DataShareServer.sku_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.total > 0) {
        getLearnList(deviceSn, "")
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 2 - 1 选择数据集合，下拉选择
   */
  const onDataSourcesSelect = (e, option) => {
    setGiveParams({
      ...GiveParams,
      dataSetId: e,
      INFO: option?.info,
      deviceSns: [],
    })
    //清空表格选中
    setSelectedRowKeys([])

    //  设备管理列表查询接口
    setTimeout(getNewDeviceList, 0)

    //选择数据为图片数据时
    if (e && GiveParams.dataType == 1) {
      getIconList(e)
    } else {
      // 列表查询,有数据才可以展示下面的设备列表
      getLearnList("", e)
      //
    }
  }

  /**
   * 2 - 2 选择数门店，下拉选择
   */
  const onShopChange = (e, option) => {
    setGiveParams({ ...GiveParams, shopId: e, shopName: option?.info?.name })
  }

  /**
   *  2 - 2 门店下设备，下拉选择
   */
  const onShopDeviceChange = (e, option) => {
    window.localStorage.setItem("deviceSn", option.deviceSn)
    setGiveParams({
      ...GiveParams,
      deviceId: e,
      scaleNo: option?.info?.scaleNo
        ? " - " + option?.info?.scaleNo
        : undefined, // 秤号 （用来展示）
      productLineName: option?.info?.productLineName
        ? " - " + option?.info?.productLineName
        : undefined, // window还是安卓（用来展示）
      productLineTypeName: option?.info?.productLineTypeName
        ? " - " + option?.info?.productLineTypeName
        : undefined, // 版本 （用来展示）
      matchingRulesVersion: option?.info?.matchingRulesVersion
        ? " - " + option?.info?.matchingRulesVersion
        : undefined, // 版本号 （用来展示）
      INFO: option?.info,
      deviceSns: [],
    })
    //清空表格选中
    setSelectedRowKeys([])

    //  设备管理列表查询接口
    setTimeout(getNewDeviceList, 0)

    //选择数据为图片数据时
    if (option?.info.deviceSn && GiveParams.dataType == 1) {
      getSkulist(option?.info.deviceSn)
    } else {
      // 列表查询,有数据才可以展示下面的设备列表
      getLearnList(option?.info.deviceSn, "")
      //
    }
  }

  /**
   * 3 选择接收设备
   */
  const onSelectChange = (newSelectedRowKey, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKey)
    setGiveParams({
      ...GiveParams,
      deviceSns: newSelectedRowKey,
    })
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({
      disabled: record.disabled,
    }),
  }

  /**
   * 4 共享方式
   */
  const onShareWay = (e) => {
    setGiveParams({ ...GiveParams, shareType: e.target.value })
  }

  /**
   * 5 开始共享
   */
  const onBeginShare = async () => {
    try {
      const { GiveParamsRef } = AccountRef.current
      let params = GiveParamsRef
      if (!params.dataType) {
        message.error("请选择共享类型！")
        return
      }
      params.shareDeviceSn = params.deviceId
      const { code, data, msg } = await DataShareServer.Task_Create(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setIsShowModal(true)
    } catch (error) {
      console.log(error)
      message.error("共享失败")
    }
  }

  /**
   * 关闭弹窗
   */
  const onHandCancal = () => {
    setIsShowModal(false)
    // 清空页面
    setGiveParams({
      dataType: "0", // 数据类型(0:学习数据，1:图片数据)
      dataSource: undefined, //选择数据来源 0:门店/设备,3:数据集合
      dataSetId: undefined, // 数据集合id
      shopId: undefined, // 门店id
      deviceId: undefined, // 设备SN
      shareType: undefined, // 共享方式(1:追加，0:覆盖)
      deviceSns: [], //  分享设备sn集合
      shopName: undefined, // 门店名称 （用来展示）
      scaleNo: undefined, // 秤号 （用来展示）
      productLineName: undefined, // window还是安卓（用来展示）
      productLineTypeName: undefined, // 版本 （用来展示）
      matchingRulesVersion: undefined, // 版本号 （用来展示）
      INFO: undefined, //
      projectId: window.localStorage.getItem("uid"), // 项目Id
      selectAll: 1,
    })
    setGetTableParams({
      pageNum: 1,
      pageSize: 10,
      online: undefined,
      cdKeyCode: undefined,
      scaleNo: undefined,
      shopName: undefined,
    })
    setSelectedRowKeys([])
    setIsShowTable(false)
    setIsShowTitle(false)
  }

  /**
   * 查看共享记录
   */
  const onHandJumpRecord = () => {
    history.push(`/admin/discernmanage/sharerecord`)
  }

  const columns = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      key: "shopName",
      width: 120,
      ellipsis: { showTitle: true },
    },
    {
      title: "是否在线",
      dataIndex: "online",
      key: "online",
      width: 120,
      ellipsis: { showTitle: true },
      render: (info) => {
        return <>{info == 0 ? "离线" : info == 1 ? "在线" : ""}</>
      },
    },
    {
      title: "设备秤号",
      dataIndex: "scaleNo",
      key: "scaleNo",
      width: 120,
      ellipsis: { showTitle: true },
    },
    {
      title: "设备激活码",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",
      width: 120,
      ellipsis: { showTitle: true },
    },
    {
      title: "产品线",
      dataIndex: "productLineName",
      key: "productLineName",
      width: 120,
      ellipsis: { showTitle: true },
    },
    {
      title: "产品线版本",
      dataIndex: "productLineTypeName",
      key: "productLineTypeName",
      width: 120,
      ellipsis: { showTitle: true },
    },
    {
      title: "版本号",
      dataIndex: "systemVersionName",
      key: "systemVersionName",
      width: 120,
      ellipsis: { showTitle: true },
    },
    {
      title: "识别模型",
      dataIndex: "aiModeName",
      key: "aiModeName",
      width: 120,
      ellipsis: { showTitle: true },
    },
    {
      title: "近7天识别率",
      dataIndex: "aiOkRate",
      key: "aiOkRate",
      width: 150,
      ellipsis: { showTitle: true },
    },
    {
      title: "设备秤盘照片",
      width: 120,
      ellipsis: { showTitle: true },
      render: (info, rowInfo) => {
        return (
          <Button type="link" onClick={() => onSeePic(rowInfo)}>
            查看照片
          </Button>
        )
      },
    },
  ]

  /**
   * 查看照片
   */
  const onSeePic = async (rowInfo) => {
    try {
      const { deviceId } = rowInfo
      const { code, msg, data } = await DataShareServer.See_Img(deviceId)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data.scaleImageUrl) {
        setImgUrl(data.scaleImageUrl)
      } else {
        message.error("暂无图片")
        setImgUrl("")
      }
    } catch (error) {
      console.log(error)
      message.error("查看照片失败")
    }
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    if (isShowTable) {
      const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${total}条`,
        pageSize: GetTableParams.pageSize,
        current: GetTableParams.pageNum,
        total: total,
        onChange: (current, pageSize) => {
          setGetTableParams({
            ...GetTableParams,
            pageNum: current,
            pageSize: pageSize,
          })
          setTimeout(getNewDeviceList, 0)
        },
      }
      return paginationProps
    }
  }

  return (
    <Card>
      <Steps
        direction="vertical"
        current={stepsCurrent}
        items={[
          // 选择数据
          {
            description: (
              <div className={styles.data_share_lable}>
                <div className={styles.data_share_lable_title}>
                  <span>*</span>
                  选择数据
                </div>
                <Radio.Group onChange={onDataType} value={GiveParams.dataType}>
                  <Radio value={"0"}>学习数据</Radio>
                  <Radio value={"1"}>图片数据</Radio>
                </Radio.Group>
                {!GiveParams.dataType && GiveParams.dataType != 0 ? null : (
                  <Tag
                    color="#FCF6EC"
                    height={20}
                    className={styles.data_share_configdata_tag}
                    icon={
                      <ExclamationCircleOutlined style={{ color: "#E6A23C" }} />
                    }
                  >
                    <span style={{ color: "#E6A23C" }}>
                      {GiveParams.dataType == 1
                        ? "图片数据共享说明：接收设备需与共享设备需要保证商品数据一致，否则无法接收共享的ICON"
                        : GiveParams.dataType == 0
                        ? "学习数据共享说明： 接收数据的设备需与共享设备保持产品线一致、版本号一致，否则无法勾选"
                        : ""}
                    </span>
                  </Tag>
                )}
              </div>
            ),
          },
          // 选择数据来源
          {
            description: (
              <div>
                <div className={styles.data_share_lable}>
                  <div className={styles.data_share_lable_title}>
                    <span>*</span>选择数据来源
                  </div>
                  <Radio.Group
                    onChange={onDataSources}
                    value={GiveParams.dataSource}
                  >
                    <Radio value={"3"}>数据汇总</Radio>
                    <Radio value={"0"}>门店/设备</Radio>
                  </Radio.Group>
                </div>
                <div className={styles.data_share_lable}>
                  <div className={styles.data_share_lable_title}></div>
                  {GiveParams.dataSource == 3 ? (
                    <div>
                      <Select
                        style={{ width: "200px" }}
                        onChange={(e, option) => onDataSourcesSelect(e, option)}
                        placeholder="请选择数据汇总！"
                        value={GiveParams.dataSetId}
                      >
                        {dataSetList.map((v) => {
                          return (
                            <Option value={v.id} key={v.id} info={v}>
                              {v.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                  ) : GiveParams.dataSource == 0 ? (
                    <div className={styles.page_share_select_fuck}>
                      <Select
                        // getPopupContainer={(triggerNode) =>
                        //   triggerNode.parentNode
                        // }
                        showSearch
                        filterOption={(i, o) => o.props.children.includes(i)}
                        style={{ width: "200px" }}
                        onChange={(e, option) => onShopChange(e, option)}
                        placeholder="请选择门店！"
                        value={GiveParams.shopId}
                        // placement="top"
                      >
                        {shopList.map((v) => {
                          return (
                            <Option value={v.id} key={v.id} info={v}>
                              {v.name}
                            </Option>
                          )
                        })}
                      </Select>
                      <Select
                        style={{ width: "200px", marginLeft: "20px" }}
                        onChange={(e, option) => onShopDeviceChange(e, option)}
                        placeholder="请选择设备！"
                        value={GiveParams.deviceId}
                        // getPopupContainer={(triggerNode) =>
                        //   triggerNode.parentNode
                        // }
                        showSearch
                        filterOption={(i, o) => o.props.children.includes(i)}
                      >
                        {deviceList.map((v) => {
                          return (
                            <Option
                              value={v.deviceSn}
                              key={v.deviceSn}
                              info={v}
                            >
                              {v.cdKeyCode}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                  ) : null}
                </div>
                {GiveParams.shopId &&
                GiveParams.deviceId &&
                GiveParams.dataType == "0" ? (
                  <div className={styles.data_share_lable}>
                    <div className={styles.data_share_lable_title}></div>
                    已选择：
                    {/* 门店名称 （用来展示） */}
                    {GiveParams.shopName}
                    {/* 秤号 （用来展示） */}
                    {GiveParams.scaleNo}
                    {/* window还是安卓（用来展示） */}
                    {GiveParams.productLineName}
                    {/* 版本 （用来展示） */}
                    {GiveParams.productLineTypeName}
                    {/* 版本号 （用来展示） */}
                    {GiveParams.matchingRulesVersion}
                    {isShowTitle ? (
                      <Button
                        type="link"
                        onClick={() => {
                          window.localStorage.setItem(
                            "deviceSn",
                            GiveParams?.deviceId
                          )
                          const info =
                            GiveParams.shopName +
                            "" +
                            GiveParams.scaleNo +
                            "" +
                            GiveParams.productLineName +
                            "" +
                            GiveParams.productLineTypeName +
                            "" +
                            GiveParams.matchingRulesVersion
                          history.push(
                            `/admin/newdatashare/viewdata?info = ${info}`
                          )
                        }}
                      >
                        查看识别数据
                      </Button>
                    ) : null}
                  </div>
                ) : null}
                {GiveParams.shopId &&
                GiveParams.deviceId &&
                GiveParams.dataType == "1" ? (
                  <div className={styles.data_share_lable}>
                    <div className={styles.data_share_lable_title}></div>
                    已选择：{GiveParams.shopName}
                    {GiveParams.scaleNo}
                    {GiveParams.productLineName}
                    {GiveParams.productLineTypeName}
                    {GiveParams.matchingRulesVersion}
                    <Button
                      type="link"
                      onClick={() => {
                        window.localStorage.setItem(
                          "deviceSn",
                          GiveParams?.deviceId
                        )
                        const info =
                          GiveParams.shopName +
                          "" +
                          GiveParams.scaleNo +
                          "" +
                          GiveParams.productLineName +
                          "" +
                          GiveParams.productLineTypeName +
                          "" +
                          GiveParams.matchingRulesVersion
                        history.push(
                          `/admin/newdatashare/picturedata?info = ${info}`
                        )
                      }}
                    >
                      查看图片数据
                    </Button>
                  </div>
                ) : null}
              </div>
            ),
          },
          //选择接收设备
          {
            description: (
              <div>
                <div className={styles.data_share_lable}>
                  <div className={styles.data_share_lable_title}>
                    <span>*</span>选择接收设备
                  </div>
                  <div>
                    <span>设备总数:{isShowTable ? total : 0}个</span>
                    <span style={{ marginLeft: "20px" }}>
                      选择接收设备:{" "}
                      {isShowTable ? GiveParams.deviceSns.length : 0}个
                    </span>
                  </div>
                </div>
                {/* 设备列表查询条件 */}
                <div className={styles.data_share_device_search}>
                  <div className={styles.data_share_device_search_left}>
                    <div
                      className={styles.data_share_device_search_left_childs}
                    >
                      门店名称：
                      <PubInput
                        onChange={(val) => {
                          setGetTableParams({
                            ...GetTableParams,
                            shopName: val,
                          })
                        }}
                        placeholder="门店名称"
                        value={GetTableParams.shopName}
                      />
                    </div>
                    <div
                      className={styles.data_share_device_search_left_childs}
                    >
                      秤号：
                      <PubInput
                        onChange={(val) => {
                          setGetTableParams({
                            ...GetTableParams,
                            scaleNo: val,
                          })
                        }}
                        placeholder="秤号"
                        value={GetTableParams.scaleNo}
                      />
                    </div>
                    <div
                      className={styles.data_share_device_search_left_childs}
                    >
                      激活码：
                      <PubInput
                        onChange={(val) => {
                          setGetTableParams({
                            ...GetTableParams,
                            cdKeyCode: val,
                          })
                        }}
                        placeholder="激活码"
                        value={GetTableParams.cdKeyCode}
                      />
                    </div>

                    <div
                      className={styles.data_share_device_search_left_childs}
                    >
                      是否在线：
                      <Select
                        style={{ width: "200px" }}
                        placeholder="请选择是否在线"
                        value={GetTableParams.online}
                        onChange={(e) => {
                          setGetTableParams({
                            ...GetTableParams,
                            online: e,
                          })
                        }}
                      >
                        <Option value={"1"} key={"1"}>
                          在线
                        </Option>
                        <Option value={"0"} key={"0"}>
                          离线
                        </Option>
                      </Select>
                    </div>
                  </div>

                  <div className={styles.data_share_device_search_right}>
                    <Button
                      type="primary"
                      onClick={() => {
                        // 数据集合下拉接口
                        setTimeout(getNewDeviceList, 0)
                      }}
                    >
                      查询
                    </Button>
                    <Button
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        setGetTableParams({
                          pageNum: 1,
                          pageSize: 10,
                          online: undefined,
                          cdKeyCode: undefined,
                          scaleNo: undefined,
                          shopName: undefined,
                        })
                        // 数据集合下拉接口
                        setTimeout(getNewDeviceList, 0)
                      }}
                    >
                      重置
                    </Button>
                  </div>
                </div>
                <Table
                  dataSource={isShowTable ? newDeviceList : []}
                  columns={columns}
                  rowSelection={rowSelection}
                  pagination={onTableChange()}
                  // scroll={{ x: "max-content", y: `${WinHeight - 470}px` }}
                  scroll={{ x: "max-content", y: "500px" }}
                  rowKey="deviceSn"
                  onRow={(record) => {
                    return {
                      onClick: (e) => {
                        if (record.disabled) {
                          message.error(record.reason)
                        }
                      },
                    }
                  }}
                />
              </div>
            ),
          },
          // 共享方式
          {
            description: (
              <div className={styles.data_share_lable}>
                <div className={styles.data_share_lable_title}>
                  <span>*</span>
                  共享方式
                </div>
                <Radio.Group onChange={onShareWay} value={GiveParams.shareType}>
                  <Radio value={"0"}>覆盖</Radio>
                  <Radio value={"1"}>追加</Radio>
                </Radio.Group>
              </div>
            ),
          },
          // 开始共享
          {
            description: (
              <div className={styles.data_share_lable}>
                <div style={{ marginLeft: "20px" }}>
                  <Button type="primary" onClick={onBeginShare}>
                    开始共享
                  </Button>
                </div>
                <Tag
                  color="#FCF6EC"
                  height={20}
                  className={styles.data_share_configdata_tag}
                  icon={
                    <ExclamationCircleOutlined style={{ color: "#E6A23C" }} />
                  }
                >
                  <span style={{ color: "#E6A23C" }}>
                    开启共享后，会占用设备资源，请选择在设备空闲时开始共享
                  </span>
                </Tag>
              </div>
            ),
          },
        ]}
      />

      <Modal open={isShowModal} onCancel={onHandCancal} footer={[]}>
        <div className={styles.data_share_modal}>
          <div className={styles.data_share_modal_child}>
            <CheckCircleOutlined
              style={{ color: "#2EB998", marginRight: "5px" }}
            />
            共享成功
          </div>
          <Button type="primary" onClick={onHandJumpRecord}>
            查看共享记录
          </Button>
        </div>
      </Modal>

      <Modal
        open={imgUrl ? true : false}
        onCancel={() => setImgUrl("")}
        footer={[]}
        width={"50%"}
        height={"80%"}
      >
        <Image src={imgUrl} className={styles.data_share_image} />
      </Modal>
    </Card>
  )
}

export default Index
