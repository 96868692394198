import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Form,
  Table,
  Modal,
  message,
  Select,
  Cascader,
  Tooltip,
  TreeSelect,
} from "antd"
import * as ShopmanageDevice from "../../services/storemanage"
import { useHistory } from "react-router-dom"
import { QuestionCircleOutlined } from "@ant-design/icons"
import PubInput from "../../../components/PubInput"
import styles from "./Index.module.css"
import { treeDataSSSS } from "./city"

const { Option } = Select

// 门店管理

function Index(props) {
  let history = useHistory()
  const [form] = Form.useForm()
  //排序类型
  const [sortedInfo, setSortedInfo] = useState({})
  //
  const [treeData, setTreeData] = useState(treeDataSSSS || []) // 区域树
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [addressOptions, setAddressOptions] = useState([]) //省市区树
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false) //modal弹窗状态
  const [shopList, setShopList] = useState({}) //门店列表数据（父级）
  const [loading, setLoading] = useState(false) //门店列表loading
  const [account, setAccount] = useState("") //添加门店账号的value
  const [shopId, setShopId] = useState("") //门店id（--------用来做门店账号添加的----------）
  const [modaltext, setModaltext] = useState("") //动态添加/编辑Modal-title
  const [FormValuie, setFormValuie] = useState({
    name: undefined,
    id: undefined,
    codeList: [],
    shopCode: undefined,
  }) // 编辑门店时的回显
  const [EditShopOpen, setEditShopOpen] = useState(false) // 编辑门店打开Modal
  const [DelShow, setDelShow] = useState(false) // 批量删除打开Modal
  const [AllDevice, setAllDevice] = useState([]) // 所有的设备
  const [shopAuthList, setShopAuthList] = useState([]) // 门店权限列表
  const [districtAuthList, setDistrictAuthList] = useState([]) // 区域权限列表
  const [dataType, setDataType] = useState(undefined) // 数据类型（0：全部数据，1：按门店，2：按区域）

  // --------------
  const [ParamsInfo, setParamsInfo] = useState({
    ProvinceCityRegion: [], //province省,city市,region区
    code: undefined, // 门店名称/门店账号/激活码.
    pageNum: 1,
    pageSize: 20,
    orderType: undefined, // 排序传值
    districtId: undefined,
  })
  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })
  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // -----------------

  // 初始化
  useEffect(() => {
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    getShopList() //门店列表接口
    getAreaTree() // 省市区树
    getDataAuth() // 当前项目的数据权限信息
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 当前项目的数据权限信息
   */
  const getDataAuth = async () => {
    try {
      const res = await ShopmanageDevice.Data_Auth()
      if (!res) {
        return false
      }
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      console.log(res?.data)
      setDataType(res?.data?.dataType == 0 ? "0" : res?.data?.dataType)
      setDistrictAuthList(res?.data?.districtAuthList)
      setShopAuthList(res?.data?.shopAuthList)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 省市区树
   */
  const getAreaTree = async () => {
    try {
      const { code, data, msg } = await ShopmanageDevice.Area_Tree()
      if (code != 200) {
        message.error(msg)
        return
      }
      setAddressOptions(data)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 导出接口
   */
  const onExportDevice = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        // ...ParamsInfoRef,
        province: ParamsInfoRef.ProvinceCityRegion[0] || "",
        city: ParamsInfoRef.ProvinceCityRegion[1] || "",
        region: ParamsInfoRef.ProvinceCityRegion[2] || "",
        code: ParamsInfoRef.code || "",
        orderType: ParamsInfoRef.orderType || "",
        pageNum: ParamsInfoRef.pageNum || 1,
        pageSize: ParamsInfoRef.pageSize || 20,
      }
      console.log(params)
      const res = await ShopmanageDevice.Export_shop_list(params)
      if (!res) {
        return false
      }
      message.success("门店列表导出")
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 编辑门店，打开Modal
   */
  const onHandOpenModal = (info) => {
    setEditShopOpen(true)
    const params = {
      name: info?.shopName,
      id: info?.shopId,
      codeList: [],
      shopCode: info.shopCode, // 门店编号
    }
    setFormValuie(params)
    // 门店下设备列表接口
    getDeviceList(params)
    // //设备列表
    // 门店下设备列表接口
    getAllDevice(info?.shopId)
  }

  /**
   * 跳转区位管理
   */
  const onJumpEdit = (rowInfo) => {
    history.push(`/admin/accountmanage/storearea?shopId=${rowInfo.shopId}`)
  }

  /**
   * 编辑门店，确定按钮
   */
  const onSubmit = async () => {
    try {
      if (!FormValuie.name) {
        message.warning("请输入门店名称")
        return
      }
      // if (!FormValuie.shopCode) {
      //   message.warning("请输入门店编号")
      //   return
      // }
      if (FormValuie.codeList.length == 0) {
        message.warning("请选择关联设备")
        return
      }
      const { code, msg, data } = await ShopmanageDevice.Edit_Shop(FormValuie)
      if (code != 200) {
        message.error(msg)
      }
      if (data) {
        message.success("编辑门店成功")
        setEditShopOpen(false)
        setFormValuie({
          name: undefined,
          id: undefined,
          codeList: [],
        })
        getShopList() //门店列表接口
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 批量删除
   */
  const onManyDelete = async () => {
    try {
      const ids = selectedRowKeys
      console.log(ids)
      const { code, data, msg } = await ShopmanageDevice.shop_batch_delete(ids)
      if (code != 200) {
        message.warning(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        getShopList() //门店列表接口
        setDelShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      //门店列表接口，
      setTimeout(getShopList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        code: undefined,
        ProvinceCityRegion: [], //province省,city市,region区
        pageNum: 1,
        pageSize: 20,
        orderType: undefined,
        districtId: undefined,
      })
      setSortedInfo("")
      //门店列表接口
      setTimeout(getShopList, 0)
      setSelectedRowKeys([])
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 门店列表接口
   */
  const getShopList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
        province: ParamsInfoRef.ProvinceCityRegion[0] || "",
        city: ParamsInfoRef.ProvinceCityRegion[1] || "",
        region: ParamsInfoRef.ProvinceCityRegion[2] || "",
      }
      setLoading(true)
      const { code, data, msg } = await ShopmanageDevice.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLoading(false)
      setShopList(data)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setShopList([])
    }
  }

  //跳转设备管理
  const handJumo = (info, rowInfo) => {
    if (info && info > 0) {
      window.localStorage.setItem("shopId", rowInfo?.shopId || "")
      props.history.push({
        pathname: "/admin/storemanage/equipmentmanage",
      })
    }
  }

  /**
   * 门店下设备列表接口
   */
  const getDeviceList = async (info) => {
    try {
      // const params = {
      //   pageNum: 1,
      //   pageSize: 100000,
      //   keyCode: "",
      //   productLineId: "",
      //   productLineTypeId: "",
      //   shopId: info?.id,
      // }
      const { code, msg, data } = await ShopmanageDevice.Devices_List(info?.id)
      if (code != 200) {
        message.warning(msg)
        return
      }
      console.log(data)
      if (data) {
        const NewArr = []
        data.map((v) => {
          NewArr.push(v.cdKeyCode)
        })

        setFormValuie({ ...info, codeList: NewArr })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 所有设备
   */
  // const getAllDeviceList = async (shopId) => {
  //   try {
  //     console.log(shopId)
  //     const { code, msg, data } = await ShopmanageDevice.Devices_List(shopId)
  //     if (code != 200) {
  //       message.warning(msg)
  //       return
  //     }
  //     setAllDevice(data || [])
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  /**
   * 项目下所有的设备列表
   */
  const getAllDevice = async () => {
    try {
      const { code, data, msg } = await ShopmanageDevice.SimplePullList()
      if (code != 200) {
        message.error(msg)
        return
      }
      setAllDevice(data || [])
    } catch (error) {
      console.log(error)
    }
  }

  //modal确认按钮
  const handleOk = async () => {
    try {
      if (account.length < 11 && account.length > 0) {
        message.error("请输入11位门店账号")
        return false
      }
      const params = { account: account, shopId: shopId }
      const { code, data, msg } = await ShopmanageDevice.Add_Account(params)
      if (code != 200) {
        message.error(msg)
        return false
      }
      if (data) {
        setShopId("") //清除shopid
        setAccount("") //清除门店账号
        getShopList() //门店列表接口
        setIsModalOpen(false)
        message.success(`门店账号${modaltext}成功`)
      }
    } catch (error) {
      console.log(error)
    }
  }
  //关闭modal
  const handleCancel = () => {
    setShopId("") //清除shopid
    setAccount("") //清除门店账号
    setModaltext("") //清除modal-title
    setIsModalOpen(false)
  }

  //父级，门店列表接口
  let columns = []
  if (dataType == 1) {
    columns = [
      {
        title: "门店名称",
        dataIndex: "shopName",
        key: "shopName",
        ellipsis: { showTitle: true },
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "设备数",
        dataIndex: "deviceNum",
        ellipsis: { showTitle: true },
        key: "deviceNum",
        width: 120,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
        // sorter: true,
        // orderType: sortedInfo?.order,
        // sortOrder:
        //   sortedInfo?.columnKey === "deviceNum" ? sortedInfo?.order : null,
      },
      {
        title: "称重笔数",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的使用量">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "outputNum",
        ellipsis: { showTitle: true },
        key: "outputNum",
        width: 120,
      },
      {
        title: "总识别率",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的总识别率">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "aiOkRate",
        ellipsis: { showTitle: true },
        key: "aiOkRate",
        width: 150,
        // sorter: true,
        // orderType: sortedInfo?.order,
        // sortOrder:
        //   sortedInfo.columnKey === "aiOkRate" ? sortedInfo.order : null,
        render: (info) => {
          return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
        },
      },
      {
        title: "首位识别率",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的首位识别率">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "top1",
        ellipsis: { showTitle: true },
        key: "top1",
        width: 150,
        // sorter: true,
        // orderType: sortedInfo?.order,
        // sortOrder: sortedInfo.columnKey === "top1" ? sortedInfo.order : null,
        render: (info) => {
          return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
        },
      },
      {
        title: "所属区域",
        dataIndex: "districtName",
        ellipsis: { showTitle: true },
        key: "districtName",
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
        // render: (info) => {
        //   return (
        //     <div className={styles.device_table_info}>
        //       <Tooltip title={info} placement="topLeft">
        //         <span>{info}</span>
        //       </Tooltip>
        //     </div>
        //   )
        // },
      },
      {
        title: "地理位置",
        dataIndex: "address",
        ellipsis: { showTitle: true },
        key: "address",
        width: 200,
        render: (info) => {
          return (
            <div className={styles.device_table_info}>
              <Tooltip title={info} placement="topLeft">
                <span>{info ? info : "-"}</span>
              </Tooltip>
            </div>
          )
        },
      },
      {
        title: "门店编号",
        dataIndex: "shopCode",
        key: "shopCode",
        width: 200,
        ellipsis: { showTitle: true },
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "搜索占比",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的搜索占比">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "optSearchRate",

        ellipsis: { showTitle: true },
        key: "optSearchRate",
        width: 120,
        render: (info) => {
          return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
        },
      },
      {
        title: "门店创建时间",
        dataIndex: "shopCreateDate",
        ellipsis: { showTitle: true },
        key: "shopCreateDate",
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "最近同步时间",
        dataIndex: "dataSyncDate",
        ellipsis: { showTitle: true },
        key: "dataSyncDate",
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "操作",
        ellipsis: { showTitle: true },
        fixed: "right",
        width: 200,
        render: (info, rowInfo) => {
          return (
            <>
              <a
                onClick={() => {
                  history.push(
                    `/admin/devicemanage/shopmanag/xappmanage?shopId=${info.shopId}`
                  )
                }}
              >
                小程序账号管理
              </a>
              <a
                onClick={() => onHandOpenModal(info)}
                style={{ marginLeft: "20px" }}
              >
                编辑门店
              </a>
              <a
                style={{ marginLeft: "20px" }}
                onClick={() => onJumpEdit(rowInfo)}
              >
                区位管理
              </a>
            </>
          )
        },
      },
    ]
  } else {
    columns = [
      {
        title: "门店名称",
        dataIndex: "shopName",
        key: "shopName",
        ellipsis: { showTitle: true },
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "设备数",
        dataIndex: "deviceNum",
        ellipsis: { showTitle: true },
        key: "deviceNum",
        width: 120,
        sorter: true,
        orderType: sortedInfo?.order,
        sortOrder:
          sortedInfo?.columnKey === "deviceNum" ? sortedInfo?.order : null,
      },
      {
        title: "称重笔数",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的使用量">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "outputNum",
        ellipsis: { showTitle: true },
        key: "outputNum",
        width: 120,
      },
      {
        title: "总识别率",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的总识别率">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "aiOkRate",
        ellipsis: { showTitle: true },
        key: "aiOkRate",
        width: 150,
        sorter: true,
        orderType: sortedInfo?.order,
        sortOrder:
          sortedInfo.columnKey === "aiOkRate" ? sortedInfo.order : null,
        render: (info) => {
          return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
        },
      },
      {
        title: "首位识别率",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的首位识别率">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "top1",
        ellipsis: { showTitle: true },
        key: "top1",
        width: 150,
        sorter: true,
        orderType: sortedInfo?.order,
        sortOrder: sortedInfo.columnKey === "top1" ? sortedInfo.order : null,
        render: (info) => {
          return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
        },
      },
      {
        title: "所属区域",
        dataIndex: "districtName",
        ellipsis: { showTitle: true },
        key: "districtName",
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
        // render: (info) => {
        //   return (
        //     <div className={styles.device_table_info}>
        //       <Tooltip title={info} placement="topLeft">
        //         <span>{info}</span>
        //       </Tooltip>
        //     </div>
        //   )
        // },
      },
      {
        title: "地理位置",
        dataIndex: "address",
        ellipsis: { showTitle: true },
        key: "address",
        width: 200,
        render: (info) => {
          return (
            <div className={styles.device_table_info}>
              <Tooltip title={info} placement="topLeft">
                <span>{info ? info : "-"}</span>
              </Tooltip>
            </div>
          )
        },
      },
      {
        title: "门店编号",
        dataIndex: "shopCode",
        key: "shopCode",
        width: 200,
        ellipsis: { showTitle: true },
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "搜索占比",
        filterDropdown: <div></div>,
        filterIcon: (
          <Tooltip placement="top" title="近7天的搜索占比">
            <QuestionCircleOutlined />
          </Tooltip>
        ),
        dataIndex: "optSearchRate",

        ellipsis: { showTitle: true },
        key: "optSearchRate",
        width: 120,
        render: (info) => {
          return <>{info == 0 ? "0%" : (info * 100).toFixed(2) + "%"}</>
        },
      },
      {
        title: "门店创建时间",
        dataIndex: "shopCreateDate",
        ellipsis: { showTitle: true },
        key: "shopCreateDate",
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "最近同步时间",
        dataIndex: "dataSyncDate",
        ellipsis: { showTitle: true },
        key: "dataSyncDate",
        width: 200,
        render: (info) => {
          return <>{info ? info : "-"}</>
        },
      },
      {
        title: "操作",

        ellipsis: { showTitle: true },
        fixed: "right",
        width: 200,
        render: (info, rowInfo) => {
          return (
            <>
              <a
                onClick={() => {
                  history.push(
                    `/admin/devicemanage/shopmanag/xappmanage?shopId=${info.shopId}`
                  )
                }}
              >
                小程序账号管理
              </a>
              <a
                onClick={() => onHandOpenModal(info)}
                style={{ marginLeft: "20px" }}
              >
                编辑门店
              </a>
              <a
                style={{ marginLeft: "20px" }}
                onClick={() => onJumpEdit(rowInfo)}
              >
                区位管理
              </a>
            </>
          )
        },
      },
    ]
  }

  /**
   * 表格选中功能
   */
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  /**
   * 排序
   */
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter)

    // ascend:正序      descend：倒序
    if (sorter.field === "deviceNum") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 4 : sorter.order === "descend" ? 3 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "aiOkRate") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      })
    }
    if (sorter.field === "top1") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 6 : sorter.order === "descend" ? 5 : "",
        pageNum: pagination.current,
      })
    }
    if (!sorter.field) {
      setParamsInfo({
        ...ParamsInfo,
        pageNum: pagination.current,
      })
    }

    setTimeout(getShopList, 0)
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${shopList?.total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: shopList?.total,
    }
    return paginationProps
  }
  return (
    <div>
      <Card>
        <div className={styles.shopmanage_store_head}>
          <div className={styles.shopmanage_store_head_search}>
            {dataType == 2 ? (
              <div className={styles.shopmanage_store_head_search_child}>
                所属区域：
                <Select
                  allowClear
                  style={{ width: 200 }}
                  value={ParamsInfo?.districtId || undefined}
                  placeholder="请选择区域名称"
                  onChange={(e) => {
                    setParamsInfo({ ...ParamsInfo, districtId: e })
                  }}
                >
                  {districtAuthList &&
                    districtAuthList.map((v) => {
                      return (
                        <Option value={v.districtId} key={v.districtId}>
                          {v.pathName && v.districtName
                            ? v.pathName + "/" + v.districtName
                            : !v.pathName && v.districtName
                            ? v.districtName
                            : v.pathName && !v.districtName
                            ? v.pathName
                            : ""}
                        </Option>
                      )
                    })}
                </Select>
              </div>
            ) : null}
            <div className={styles.shopmanage_store_head_search_child}>
              地理位置：
              <Cascader
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择地理位置"
                style={{ width: 200 }}
                fieldNames={{
                  label: "name",
                  value: "name",
                  children: "areaListRspList",
                }}
                options={addressOptions}
                value={ParamsInfo.ProvinceCityRegion}
                allowClear
                changeOnSelect={true}
                onChange={(e) =>
                  setParamsInfo({ ...ParamsInfo, ProvinceCityRegion: e || "" })
                }
              />
            </div>
            <div className={styles.shopmanage_store_head_search_child}>
              门店-设备：
              <PubInput
                onChange={(val) => setParamsInfo({ ...ParamsInfo, code: val })}
                placeholder="门店名称/激活码"
                value={ParamsInfo.code}
              />
            </div>
          </div>
          <div className={styles.shopmanage_store_head_btn}>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
            <Button onClick={handClearn}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <div className={styles.shopmanage_store_head_btn_upload}>
          <Button
            type="primary"
            danger
            onClick={() => {
              setDelShow(true)
            }}
            disabled={selectedRowKeys.length == 0 ? true : false}
          >
            批量删除
          </Button>
          <Button type="primary" onClick={onExportDevice}>
            导出数据
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            rowSelection={rowSelection}
            dataSource={shopList?.list ? shopList?.list : []}
            rowKey="shopId"
            loading={loading}
            scroll={{ x: "max-content", y: `${WinHeight - 410}px` }}
            onChange={handleChange}
          />
        </div>
      </Card>

      {/* 添加、编辑门店账号 */}
      <Modal
        width={500}
        title={modaltext + "门店账号"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确认"
        cancelText="取消"
      >
        <div className={styles.shopmanage_store_modal}>
          门店账号：
          <PubInput
            onChange={(val) => setAccount(val)}
            placeholder="门店账号"
            value={account}
            max={111}
          />
        </div>
      </Modal>

      {/* 编辑门店 */}
      <Modal
        title="编辑门店"
        open={EditShopOpen}
        keyboard
        maskClosable
        onOk={() => onSubmit()}
        onCancel={() => {
          setEditShopOpen(false)
        }}
      >
        <div className={styles.shopmanage_shop_accent_modal}>
          <div className={styles.shopmanage_shop_accent_input_title}>
            <span style={{ color: "#F56C6C" }}>*</span> 门店名称：
            <PubInput
              onChange={(val) => setFormValuie({ ...FormValuie, name: val })}
              placeholder="门店名称"
              value={FormValuie?.name}
              max={20}
              showCount={true}
            />
          </div>
          {/* <div className={styles.shopmanage_shop_accent_input_title}>
            <span style={{ color: "#F56C6C" }}>*</span> 门店编号：
            <PubInput
              onChange={(val) =>
                setFormValuie({ ...FormValuie, shopCode: val })
              }
              placeholder="门店编号"
              value={FormValuie?.shopCode}
              max={20}
              showCount={true}
            />
          </div> */}
          <div className={styles.shopmanage_shop_accent_select}>
            <span style={{ color: "#F56C6C" }}>*</span> 关联设备：
            <Select
              mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择关联设备"
              style={{ width: 200 }}
              allowClear
              value={FormValuie?.codeList}
              showSearch
              filterOption={(i, o) => o.props.value.includes(i)}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, codeList: e })
              }}
            >
              {AllDevice.map((item, index) => {
                return (
                  <Option value={item?.cdKeyCode} key={index}>
                    {item?.cdKeyCode}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      </Modal>

      {/* 删除二次提示框 */}
      <Modal
        open={DelShow}
        destroyOnClose
        keyboard
        title="系统提示"
        onCancel={() => {
          setDelShow(false)
        }}
        onOk={() => onManyDelete()}
      >
        <div className={styles.shopmanage_shop_accent_delete}>
          <QuestionCircleOutlined
            className={styles.shopmanage_shop_accent_delete_icon}
          />
          删除后不可恢复，确定删除选中门店吗？
        </div>
      </Modal>
    </div>
  )
}

export default Index
