import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Table,
  Select,
  message,
  Modal,
  Form,
  Tag,
  Tooltip,
  Card,
  Switch,
  Tree,
  Cascader,
} from "antd"
import * as AccoubtManage from "../../services/accoubt_manage"
import styles from "./Index.module.css"
import md5 from "md5"
import { PlusOutlined } from "@ant-design/icons"
import PubInput from "../../../components/PubInput"
import { render } from "@testing-library/react"
import { duration } from "moment"

const { Option } = Select

function Index(props) {
  let JumpInfo
  if (props?.location?.search) {
    if (props?.location?.search?.split("?")[1]) {
      JumpInfo = props?.location?.search?.split("?")[1]?.split("&") || undefined
    }
  }
  const [form] = Form.useForm()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [token, setToken] = useState("") //上传token
  const [uid, setUid] = useState([]) //uid
  const [domain, setDomain] = useState("") //域名
  const [dataList, setDataList] = useState([]) //表格列表
  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false) //上传loging
  const [imgUrl, setImgUrl] = useState("") //上传成功后返回的图片路径
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [total, setTotal] = useState() //每页显示的行数
  const [isModalOpen, setIsModalOpen] = useState(false) //新建编辑Modal状态
  const [modalTitle, setModalTitle] = useState("") // 新建编辑Modal-title
  const [isDeleteOpen, setSsDeleteOpen] = useState(false) //删除Modal状态
  const [isResetOpen, setIsResetOpen] = useState(false) //重置密码Modal状态
  const [isDisableEnable, setIsDisableEnable] = useState(false) //禁用启用Modal状态
  const [accountId, setAccountId] = useState("") // 编辑时的ID
  const [tableInfo, setTableInfo] = useState({}) // 表格操作功能时临时缓存的数据
  const [roleIdList, setRoleIdList] = useState([]) // 所属角色列表

  const [changeScope, setChangeScope] = useState(undefined) // 管辖范围自存Value
  const [ScopeList, setScopeList] = useState([
    { shopId: undefined, regionId: undefined },
  ]) // 选择区域选择门店
  const [treeData, setTreeData] = useState([]) // 新增树区域菜单数据
  // const [PullList, setPullList] = useState([]) // 门店下拉列表
  // const [RegionList, setRegionList] = useState([]) // 门店下区域列表
  const [RegionTree, setRegionTree] = useState([]) // 获取店内区域树

  const [districtAuthList, setDistrictAuthList] = useState([]) // 区域权限
  const [shopAuthList, setShopAuthList] = useState([]) // 门店权限
  const [expandedKeys, setExpandedKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)

  const [GiveParams, setGiveParams] = useState({
    pageNum: 1,
    pageSize: 20,
    account: "", // 登录账号
    roleId: "", // 角色id
    changeType: JumpInfo
      ? JumpInfo[0].split("=")[1]
        ? 1
        : undefined
      : undefined, //
    changeTypeChild: JumpInfo
      ? JumpInfo[0] && JumpInfo[0].split("=")[1]
        ? Number(JumpInfo[0].split("=")[1])
        : undefined
      : undefined, //
  })

  //保证每次拿到的都是准确的值
  const AccountRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    AccountRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  /**
   * 初始化
   */
  useEffect(() => {
    //调用账号列表接口
    onGetUserList()
    //调用所属角色列表
    onRolePullList()
    //调用获取token接口
    gettoken()
    // 组织架构树形列表组
    getDistrictTreeList()
    // 当前项目的数据权限信息
    getAuthAll()
    // 获取店内区域树
    getShopRegionTree()

    setUid(window.localStorage.getItem("uid").toString().padStart(8, "0"))
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 获取店内区域树
   */
  const getShopRegionTree = async () => {
    try {
      const res = await AccoubtManage.Shop_Region_Tree()
      if (!res) {
        return
      }
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      if (res?.data?.length > 0) {
        res?.data.map((v) => {
          v.value = v.shopId
          v.label = v.shopName
          v.shopRegionListRes &&
            v.shopRegionListRes.map((w) => {
              w.value = w.id
              w.label = w.name
            })
          v.children = v.shopRegionListRes
          v.children.unshift({ label: "全部", value: "0" })
        })
      }
      setRegionTree(res?.data)
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 当前项目的数据权限信息
   */
  const getAuthAll = async () => {
    try {
      const res = await AccoubtManage.Auth_All()
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      console.log(res?.data)
      setDistrictAuthList(res?.data?.districtAuthList)
      setShopAuthList(res?.data?.shopAuthList)
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 组织架构树形列表组
   */
  const getDistrictTreeList = async () => {
    try {
      const res = await AccoubtManage.District_TreeList()
      if (res?.code != 200) {
        message.error(res?.msg)
        return
      }
      let NewArr = [
        {
          id: "0",
          name: "总部",
          subList: res?.data || [],
        },
      ]
      setTreeData(NewArr)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Tree组件选中
   */
  const onCheck = (checkedKeys, info) => {
    let FFF = []
    const QQQ = info.checkedNodes
    let VVV = checkedKeys.filter((obj1) =>
      QQQ.some((obj2) => obj1 == obj2.parentId)
    )
    let WWW = QQQ.filter((obj1) => !VVV.some((obj2) => obj1.parentId === obj2))
    if (
      WWW &&
      WWW.map((v) => {
        FFF.push(v.id)
      })
    )
      setCheckedKeys(FFF)
  }

  /**
   * 获取 token
   */
  const gettoken = async () => {
    try {
      const { code, msg, data } = await AccoubtManage.Get_Token()
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setToken(data.token)
        setDomain(data?.domain || "")
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 所属角色列表
   */
  const onRolePullList = async () => {
    try {
      const params = ""
      const { code, msg, data } = await AccoubtManage.RolePullList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setRoleIdList(data)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 账号列表
   */
  const onGetUserList = async () => {
    try {
      setLoading(true)
      const { GiveParamsRef } = AccountRef.current
      const params = {
        ...GiveParamsRef,
        dataType: GiveParamsRef.changeType,
        shopId:
          GiveParamsRef.changeType == 1
            ? GiveParamsRef.changeTypeChild
            : undefined,
        districtId:
          GiveParamsRef.changeType == 2
            ? GiveParamsRef.changeTypeChild
            : undefined,
      }
      const { code, data, msg } = await AccoubtManage.GetAccountList(params)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      const { list, total } = data
      setDataList(list)
      setTotal(total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: total,
      onChange: (current) => {
        setPageNum(current)
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(onGetUserList, 0)
      },
    }
    return paginationProps
  }

  /**
   * 新增账号
   */
  const onAddAccount = () => {
    setScopeList([{ shopId: undefined, regionId: undefined }])
    setCheckedKeys([])
    setChangeScope(undefined)

    // 清空ID
    setAccountId("")
    //清空表单
    form.resetFields()
    //Modal-title赋值
    setModalTitle("新建账号")
    //打开modal
    setIsModalOpen(true)
    // 清空图片
    setImgUrl("")
  }

  /**
   * 编辑账号
   */
  const onEditAccount = async (accountId) => {
    try {
      // 先清空
      form.resetFields()
      setImgUrl("")

      const { code, data, msg } = await AccoubtManage.AccountInfo(accountId)
      if (code != 200) {
        message.error(msg)
        return
      }
      console.log("详情数据返回：", data)
      setChangeScope(data?.dataType ? `${data?.dataType}` : undefined)
      // 按门店数逻辑处理
      if (data?.dataType == 1 && data?.shopAuthList?.length > 0) {
        data?.shopAuthList.map((v) => {
          v.regionId = v.regionId == 0 ? "0" : v.regionId
        })
        setScopeList(data?.shopAuthList)
      }
      // 按区域逻辑处理
      if (data?.dataType == 2 && data?.districtAuthList?.length > 0) {
        let NewArr = []
        data?.districtAuthList.map((v) => {
          NewArr.push(v.districtId == 0 ? "0" : v.districtId)
          return NewArr
        })
        setCheckedKeys(NewArr)
      }
      //给form表单赋值
      form.setFieldsValue(data)
      // 给图片赋值
      setImgUrl(data?.headPortrait || "")
      //Modal-title赋值
      setModalTitle("编辑账号")
      //打开modal框
      setIsModalOpen(true)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 重置
   */
  const onHandClearn = () => {
    setPageNum(1)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      account: undefined,
      roleId: undefined,
      changeType: undefined, //
      changeTypeChild: undefined, //
    })
    // 调用账号列表接口
    setTimeout(onGetUserList, 0)
  }

  /**
   * 查询
   */
  const onHandSearch = () => {
    setPageNum(1)
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    // 调用账号列表接口
    setTimeout(onGetUserList, 0)
  }

  /**
   * 校验是否去重
   */
  function hasUniqueNamesById(arr) {
    const idToNames = new Map()

    for (const obj of arr) {
      const { shopId, regionId } = obj

      // 如果 shopId 已经存在，则检查 regionId 是否已经存在于对应的集合中
      if (idToNames.has(shopId) && idToNames.get(shopId).has(regionId)) {
        return false // 发现重复，直接返回 false
      }

      // 如果 shopId 还没有被添加到 Map 中，则创建一个新的 Set 并添加 regionId
      if (!idToNames.has(shopId)) {
        idToNames.set(shopId, new Set([regionId]))
      } else {
        // 如果 shopId 已经存在，则向对应的 Set 中添加 regionId
        idToNames.get(shopId).add(regionId)
      }
    }

    return true // 检查完成，没有发现重复
  }
  /**
   * 监听表单提交
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        //新建 / 编辑接口传值
        const params = {
          ...values,
          headPortrait: imgUrl,
          accountId: accountId || undefined,
          password: values.password ? md5(values.password) : "",
        }

        //当管辖范围选择为按门店时，判断选择区域是否有空值
        const isHasNullValue = ScopeList.every(
          (item) =>
            item.shopId !== undefined &&
            item.shopId !== null &&
            item.shopId !== ""
        )

        // 按门店
        if (!isHasNullValue && values?.dataType == 1) {
          message.error("条件不可为空")
          return
        }
        // 校验在同一门店下是否选择了相同区域
        if (!hasUniqueNamesById(ScopeList) && values?.dataType == 1) {
          message.error("请勿在同一门店下选择相同区域")
          return
        }
        // 按区域
        if (checkedKeys.length == 0 && values?.dataType == 2) {
          message.error("条件不可为空")
          return
        }
        let checkedKeysNewArr = []
        let zero = undefined
        if (checkedKeys?.length > 0) {
          checkedKeys.map((v) => {
            if (v == 0) {
              zero = "0"
            } else {
              checkedKeysNewArr.push(v)
            }
            return checkedKeysNewArr
          })
        }
        params.phone = params.phone || ""
        params.shopAuthList = ScopeList // 门店权限数组
        params.districtIdList = zero ? [zero] : checkedKeysNewArr // 组织架构id数组
        console.log("要给接口传的对象：", params)
        //请求新建 / 编辑接口，values.accountId有的话是编辑，否则是新建
        const { code, msg, data } = accountId
          ? await AccoubtManage.EditAccount(params)
          : await AccoubtManage.AddAccount(params)
        if (code != 200) {
          message.error(msg)
          return
        }
        //新建/ 编辑 成功或失败
        if (data) {
          //关闭modal
          setIsModalOpen(false)
          message.success(accountId ? "账号编辑成功" : "账号新建成功")
          //清空存储的表单数据
          form.resetFields()
          // 调用账号列表接口
          setTimeout(onGetUserList, 0)
          setCheckedKeys([])
          setScopeList([{ shopId: undefined, regionId: undefined }])
        } else {
          message.error(accountId ? "账号编辑失败" : "账号新建失败")
        }
      })
      .catch((err) => {
        // 校验失败
      })
  }

  /**
   * 监听禁用启用确认按钮
   */
  const onChangeState = async () => {
    // (1:启用 2:停用)
    try {
      const params = {
        accountId: tableInfo?.accountId,
        status: tableInfo?.status == 1 ? 2 : 1,
      }
      const { code, data, msg } = await AccoubtManage.AccountStatus(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success(tableInfo?.status == 1 ? "禁用成功" : "启用成功")
        setIsDisableEnable(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监听删除按钮
   */
  const onDelete = async () => {
    try {
      const { code, data, msg } = await AccoubtManage.DeleteAccount(
        tableInfo?.accountId
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        setSsDeleteOpen(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 监听重置密码
   */
  const setReseyOk = async () => {
    try {
      const { code, data, msg } = await AccoubtManage.ResetPassword(
        tableInfo?.accountId
      )
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("密码重置成功")
        setIsResetOpen(false)
        setTimeout(onGetUserList, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 上传
   */
  const uploadProps = {
    accept: ".jpg,.png,.jpeg",
    data: function (e) {
      return new Promise(function (resolve, reject) {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(e)
        fileReader.onload = (ee) => {
          var _md5only = md5(ee.target.result)
          resolve({
            token,
            key: `icons/project/${uid}/_${_md5only}.${e.type.split("/")[1]}`,
          })
        }
        fileReader.onerror = (err) => {
          reject(err)
        }
      })
    },
    beforeUpload: async (e) => {
      try {
        const sizeQualified = await handleFilebeforeUpload(e)
        // message.success(sizeQualified)
        setImgLoading(true)
        return true
      } catch (err) {
        message.error("上传失败,请按建议上传")
        setImgLoading(false)
        return false
      }
    },
    onChange(info) {
      const _file = info.file
      if (_file.status === "done") {
        console.log(_file, "上传成功的数据")
        setImgLoading(false)
        setImgUrl(domain + "" + _file?.response?.key || "")
      }
      if (_file.status === "error") {
        setImgLoading(false)
        message.error(_file?.response.error)
      }
    },
  }

  /**
   * 限制宽高类型
   */
  const handleFilebeforeUpload = (file) => {
    return new Promise(function (resolve, reject) {
      let filereader = new FileReader()
      filereader.onload = (e) => {
        let src = e.target.result
        const image = new Image()
        image.onload = function () {
          if (
            (file.type === "image/png" ||
              file.type === "image/jpeg" ||
              file.type === "image/jpg") &&
            file.size < 512000
          ) {
            // 上传图片的宽高与传递过来的限制宽高作比较，超过限制则调用失败回调
            resolve("上传成功")
          } else {
            reject("上传失败,请按建议上传")
          }
        }
        image.onerror = reject
        image.src = src
      }
      filereader.readAsDataURL(file)
    })
  }

  const columns = [
    {
      title: "真实姓名",
      dataIndex: "realname",
      ellipsis: { showTitle: true },
      key: "realname",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "登录账号",
      dataIndex: "account",
      ellipsis: { showTitle: true },
      key: "account",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "管辖范围",
      dataIndex: "dataAuths",
      ellipsis: { showTitle: true },
      key: "dataAuths",
      width: 150,
      render: (info) => {
        return info
          ? info &&
              info.map((v, i) => {
                return <div key={i}>{v}</div>
              })
          : "-"
      },
    },
    // {
    //   title: "登录账号",
    //   dataIndex: "account",
    //   ellipsis: { showTitle: true },
    //   key: "account",
    //   width: 150,
    // },
    {
      title: "所属角色",
      dataIndex: "roleStr",
      ellipsis: { showTitle: true },
      key: "roleStr",
      width: 150,
      render: (info) => {
        return info
          ? info &&
              info.map((v, i) => {
                return <div key={i}>{v}</div>
              })
          : "-"
      },
    },
    {
      title: "账号状态",
      dataIndex: "status",
      ellipsis: { showTitle: true },
      key: "status",
      width: 150,
      render: (info, rowInfo) => {
        return (
          <Switch
            disabled={rowInfo?.isSuper == 1 ? true : false}
            onChange={() => {
              setTableInfo(rowInfo)
              setIsDisableEnable(true)
            }}
            value={info == 1 ? true : false}
          />
        )
        // return <>{info == 1 ? "启用" : info == 2 ? "禁用" : ""}</>
      },
    },
    {
      title: "创建人",
      dataIndex: "creator",
      ellipsis: { showTitle: true },
      key: "creator",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "创建时间",
      dataIndex: "createDate",
      ellipsis: { showTitle: true },
      key: "createDate",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>
      },
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 200,
      render: (info) => {
        return (
          <div className={styles.account_table_a}>
            <a
              type="link"
              onClick={() => {
                if (info?.isSuper == 1) {
                  return
                } else {
                  setAccountId(info?.accountId)
                  onEditAccount(info?.accountId)
                }
              }}
              disabled={info?.isSuper == 1 ? true : false}
            >
              编辑
            </a>
            {/* <a
              type="link"
              disabled={info?.isSuper == 1 ? true : false}
              onClick={() => {
                // 临时缓存数据
                if (info?.isSuper == 1) {
                  return
                } else {
                  setTableInfo(info)
                  setIsDisableEnable(true)
                }
              }}
            >
              {info.status == 1 ? "禁用" : info.status == 2 ? "启用" : ""}
            </a> */}
            <a
              type="link"
              disabled={info?.isSuper == 1 ? true : false}
              onClick={() => {
                if (info?.isSuper == 1) {
                  return
                } else {
                  // 临时缓存数据
                  setTableInfo(info)
                  setSsDeleteOpen(true)
                }
              }}
            >
              删除
            </a>
            <a
              type="link"
              disabled={info?.isSuper == 1 ? true : false}
              onClick={() => {
                if (info?.isSuper == 1) {
                  return
                } else {
                  // 临时缓存数据
                  setTableInfo(info)
                  setIsResetOpen(true)
                }
              }}
            >
              重置密码
            </a>
          </div>
        )
      },
    },
  ]

  return (
    <div className={styles.account_page}>
      {/* 新建编辑账号Modal */}
      <Modal
        // forceRender
        getContainer={false}
        title={modalTitle}
        open={isModalOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setGiveParams({ ...GiveParams })
          setIsModalOpen(false)
          //清空存储的表单数据
          form.resetFields()
          setScopeList([{ shopId: undefined, regionId: undefined }])
          setCheckedKeys([])
          setChangeScope(undefined)
        }}
        onOk={onFinish}
        width={"30%"}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          // wrapperCol={{ span: 9 }}
          onFinish={onFinish}
        >
          {/* 真实姓名 */}
          <Form.Item
            label="真实姓名"
            name="realname"
            rules={[
              { required: true, message: "真实姓名不能为空!" },
              // { pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{1,10}$/, "g"), message: "支持中英文、数字、特殊字符，长度为10" },
            ]}
          >
            <PubInput placeholder="用户的真实姓名" width={"80%"} max={10} />
          </Form.Item>

          {/* 登录账号 */}
          <Form.Item
            label="登录账号"
            name="account"
            rules={[
              { required: true, message: "登录账号不能为空!" },
              // { pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{1,10}$/, "g"), message: "支持中英文、数字、特殊字符，长度为10" },
            ]}
          >
            <PubInput placeholder="登录该平台的账号" width={"80%"} max={15} />
          </Form.Item>

          {/* 手机号 */}
          <Form.Item
            label="手机号"
            name="phone"
            rules={[
              {
                pattern: new RegExp(/^1[3456789]\d{9}$/, "g"),
                message: "请输入正确的手机号",
              },
            ]}
          >
            <PubInput
              placeholder="用于忘记密码找回的手机号"
              width={"80%"}
              max={11}
            />
          </Form.Item>

          {/* 密码 */}
          <Form.Item
            label="登录密码"
            name="password"
            rules={[
              {
                required: accountId ? false : true,
                message: "密码不能为空!",
              },
              {
                pattern: new RegExp(/^[\da-zA-Z@#\$&-+\?\.:;,<>]{5,15}$/, "g"),
                message: "支持英文、数字、特殊字符，长度为5到15",
              },
            ]}
          >
            <PubInput placeholder="登录该平台的密码" width={"80%"} max={15} />
          </Form.Item>

          {/* 所属角色 */}
          <Form.Item
            label="所属角色"
            name="roleIdList"
            rules={[
              {
                required: true,
                message: "所属角色不能为空!",
              },
            ]}
            className={styles.account_form_item}
          >
            <Select
              placeholder="选择该账号所属的角色"
              mode="multiple"
              style={{ width: "80%" }}
              showSearch={false}
            >
              {roleIdList.length > 0 &&
                roleIdList.map((v) => {
                  return (
                    <Option key={v.roleId} value={v.roleId}>
                      <div className={styles.account_form_option}>
                        <div className={styles.account_form_optionname}>
                          <Tooltip title={v.roleName}>{v.roleName}</Tooltip>
                        </div>
                        <div>
                          {v.status == 2 ? (
                            <Tag color="#f50">已禁用</Tag>
                          ) : null}
                        </div>
                      </div>
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>

          {/* 管辖范围 */}
          <Form.Item
            label="管辖范围"
            name="dataType"
            rules={[
              {
                required: true,
                message: "管辖范围不能为空!",
              },
            ]}
          >
            <Select
              placeholder="选择管辖范围"
              style={{ width: "80%" }}
              showSearch={false}
              onChange={(e) => {
                setChangeScope(e)
                setScopeList([{ shopId: undefined, regionId: undefined }])
                setCheckedKeys([])
              }}
            >
              <Option key={0} value={0}>
                所有范围
              </Option>
              <Option key={1} value={1}>
                按门店
              </Option>
              <Option key={2} value={2}>
                按区域
              </Option>
            </Select>
          </Form.Item>
          <div className={styles.account_form}>
            {changeScope == 1 ? (
              <Form.Item label=" " colon={false}>
                {ScopeList &&
                  ScopeList.map((v, i) => {
                    return (
                      <div className={styles.form_option_body}>
                        <Cascader
                          showSearch
                          value={
                            !v.shopId && !v.regionId
                              ? []
                              : v.shopId && !v.regionId
                              ? [v.shopId]
                              : [v.shopId, v.regionId]
                          }
                          options={RegionTree}
                          onChange={(e) => {
                            setGiveParams({ ...GiveParams })
                            if (e) {
                              let NewArr = ScopeList
                              NewArr[i] = {
                                shopId: e[0],
                                regionId: e[1],
                              }
                              setScopeList(NewArr)
                            } else {
                              let NewArr = ScopeList
                              NewArr[i] = {
                                shopId: undefined,
                                regionId: undefined,
                              }
                              setScopeList(NewArr)
                            }
                          }}
                          placeholder="请选择管理的门店和区域"
                          style={{ width: "65%" }}
                        />
                        <div className={styles.option_body}>
                          {i == 0 ? (
                            <div
                              className={styles.option_body_add}
                              onClick={() => {
                                setGiveParams({
                                  ...GiveParams,
                                })
                                let NewArr = ScopeList
                                NewArr.push({
                                  shopId: undefined,
                                  regionId: undefined,
                                })
                                setScopeList(NewArr)
                              }}
                            >
                              +
                            </div>
                          ) : null}
                          {ScopeList.length == 1 ? null : (
                            <div
                              className={styles.option_body_reduce}
                              onClick={() => {
                                setGiveParams({
                                  ...GiveParams,
                                })
                                let NewArr = ScopeList
                                NewArr.splice(i, 1)
                                setScopeList(NewArr)
                              }}
                            >
                              <span style={{ marginBottom: "3px" }}>-</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </Form.Item>
            ) : changeScope == 2 ? (
              <Form.Item label=" " colon={false}>
                {treeData && treeData?.length > 0 ? (
                  <div className={styles.form_body}>
                    <Tree
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                      checkable
                      defaultExpandAll
                      onCheck={onCheck}
                      checkedKeys={checkedKeys}
                      treeData={treeData}
                      fieldNames={{
                        title: "name",
                        key: "id",
                        children: "subList",
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles.form_body}>
                    <div
                      style={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      暂无区域可选
                    </div>
                  </div>
                )}
              </Form.Item>
            ) : null}
          </div>

          {/* 头像 */}
          {/* <Form.Item label="头像">
            <Upload
              action="https://up-z0.qiniup.com"
              name="file"
              showUploadList={false}
              multiple={false}
              {...uploadProps}
            >
              <div className={styles.account_upload_btn}>
                {imgLoading ? (
                  <Spin />
                ) : imgUrl ? (
                  <div className={styles.account_upload_img}>
                    <img src={imgUrl} alt="" />
                  </div>
                ) : (
                  <div className={styles.account_upload_icon}>
                    <PlusOutlined style={{ fontSize: "30px" }} />
                    上传图片
                  </div>
                )}
              </div>
            </Upload>
          </Form.Item> */}
        </Form>
      </Modal>

      {/* 重置密码 */}
      <Modal
        title="重置密码"
        open={isResetOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setIsResetOpen(false)
          //清空临时缓存数据
          setTableInfo()
        }}
        onOk={setReseyOk}
      >
        <div>重置后密码为：yoyo123456</div>
        <div>是否确认重置？</div>
      </Modal>

      {/* 删除 */}
      <Modal
        title="删除"
        open={isDeleteOpen}
        destroyOnClose
        keyboard
        onCancel={() => {
          setSsDeleteOpen(false)
          //清空临时缓存数据
          setTableInfo()
        }}
        onOk={onDelete}
      >
        <div>确认删除【 账号数据：{tableInfo?.account} 】？</div>
        <div>删除后该账号将不存在</div>
      </Modal>

      {/* 禁用启用 */}
      <Modal
        title={tableInfo?.status == 1 ? "禁用" : "启用"}
        open={isDisableEnable}
        destroyOnClose
        keyboard
        onCancel={() => {
          setIsDisableEnable(false)
          //清空临时缓存数据
          setTableInfo({})
        }}
        onOk={onChangeState}
      >
        <div>
          确认
          {tableInfo?.status == 1
            ? "禁用"
            : tableInfo?.status == 2
            ? "启用"
            : ""}
          【 账号数据：{tableInfo?.account} 】？
        </div>
        <div>
          {tableInfo?.status == 1
            ? "禁用后该账号无法正常登录平台"
            : tableInfo?.status == 2
            ? "启用后该账号可以正常登录平台"
            : ""}
        </div>
      </Modal>

      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.account_page_search}>
          <div className={styles.account_search_body}>
            <div className={styles.account_search_body_child}>
              <span>角色名称：</span>
              <Select
                style={{ width: 200, marginRight: "20px" }}
                onChange={(e) => {
                  setGiveParams({ ...GiveParams, roleId: e })
                }}
                value={GiveParams?.roleId || undefined}
                placeholder="请选择角色名称"
              >
                {roleIdList &&
                  roleIdList.map((v) => {
                    return (
                      <Option value={v.roleId} key={v.roleId}>
                        {v.roleName}
                      </Option>
                    )
                  })}
              </Select>
              <span>登录账号：</span>
              <PubInput
                placeholder="账号名称"
                value={GiveParams?.account || undefined}
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, account: val })
                }}
              />

              <span style={{ marginLeft: "20px" }}>管辖范围：</span>
              <Select
                placeholder="请选择管辖范围"
                style={{
                  width:
                    GiveParams?.changeType === "0" || !GiveParams?.changeType
                      ? 200
                      : 100,
                  margin: "0 10px 0 0px",
                }}
                value={GiveParams?.changeType || undefined}
                onChange={(e) => {
                  setGiveParams({
                    ...GiveParams,
                    changeType: e,
                    changeTypeChild: undefined,
                  })
                }}
              >
                <Option value={"0"} key={"0"}>
                  所有范围
                </Option>
                <Option value={1} key={1}>
                  门店
                </Option>
                <Option value={2} key={2}>
                  区域
                </Option>
              </Select>
              {GiveParams?.changeType === "0" ? null : GiveParams?.changeType ==
                1 ? (
                <Select
                  style={{ width: 200 }}
                  allowClear
                  value={GiveParams?.changeTypeChild || undefined}
                  placeholder="请选择门店名称"
                  onChange={(e) => {
                    setGiveParams({ ...GiveParams, changeTypeChild: e })
                  }}
                >
                  {shopAuthList &&
                    shopAuthList.map((v) => {
                      return (
                        <Option value={v.shopId} key={v.shopId}>
                          {v.shopName}
                        </Option>
                      )
                    })}
                </Select>
              ) : GiveParams?.changeType == 2 ? (
                <Select
                  allowClear
                  style={{ width: 200 }}
                  value={GiveParams?.changeTypeChild || undefined}
                  placeholder="请选择区域名称"
                  onChange={(e) => {
                    setGiveParams({ ...GiveParams, changeTypeChild: e })
                  }}
                >
                  {districtAuthList &&
                    districtAuthList.map((v) => {
                      return (
                        <Option value={v.districtId} key={v.districtId}>
                          {v.districtName}
                        </Option>
                      )
                    })}
                </Select>
              ) : null}
            </div>
          </div>
          <div className={styles.account_search_button}>
            <Button type="primary" onClick={onHandSearch}>
              查询
            </Button>
            <Button onClick={onHandClearn}>重置</Button>
          </div>
        </div>
      </Card>
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        <Button type="primary" onClick={onAddAccount}>
          新建账号
        </Button>
        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="accountId"
          loading={loading}
          scroll={{ x: "max-content", y: `${WinHeight - 430}px` }}
        />
      </Card>
    </div>
  )
}

export default Index
