import React, { useEffect, useState, useRef } from "react"
import { FormOutlined } from "@ant-design/icons"
import {
  Input,
  Badge,
  Button,
  Drawer,
  Form,
  Table,
  Modal,
  message,
  Popconfirm,
  Select,
} from "antd"
import {
  Add_Account,
  Shop_List,
  shop_account_list,
  add_shop_account,
  delete_shop_account,
  Edit_Shop,
  Devices_List,
  shop_batch_delete,
} from "../../../services/storemanage"
import { QuestionCircleOutlined } from "@ant-design/icons"
import "./Index.css"

const { Option } = Select

// 门店管理

function Index(props) {
  const [form] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false) //modal弹窗状态
  const [shopList, setShopList] = useState({}) //门店列表数据（父级）
  const [loading, setLoading] = useState(false) //门店列表loading
  const [expandedRowKeys, setExpandedRowKeys] = useState([]) //控制子集展开的数量
  const [deviceList, setDeviceList] = useState([]) //设备列表（子级）
  const [loading1, setLoading1] = useState(false) //设备列表loading
  const [account, setAccount] = useState("") //添加门店账号的value
  const [shopId, setShopId] = useState("") //门店id（--------用来做门店账号添加的----------）
  const [code, setCode] = useState("") //门店名称/门店账号/激活码
  const [modaltext, setModaltext] = useState("") //动态添加/编辑Modal-title
  const [pageNum, setPageNum] = useState(1) //当前页码数
  const [pageSize, setPageSize] = useState(20) //每页显示的行数
  const [open, setOpen] = useState(false) // 门店小程序侧边栏打开关闭状态
  const [addAccentOpen, setAddAccentOpen] = useState(false) // 添加账号打开关闭Modal状态
  const [shopAccentList, setShopAccentList] = useState([]) // 账号
  const [FormValuie, setFormValuie] = useState({
    name: undefined,
    id: undefined,
    codeList: [],
  }) // 编辑门店时的回显
  const [EditShopOpen, setEditShopOpen] = useState(false) // 编辑门店打开Modal
  const [DelShow, setDelShow] = useState(false) // 批量删除打开Modal
  const [AllDevice, setAllDevice] = useState([]) // 所有的设备

  const codeRef = useRef(null)
  useEffect(() => {
    codeRef.current = code
  }, [code])

  useEffect(() => {
    getShopList() //门店列表接口
  }, [])

  /**
   * 查询门店下的主账号
   */
  const getShopAccountList = async (id) => {
    try {
      const params = {
        isSuper: 1,
        pageNum: 1,
        pageSize: 1000,
        shopId: id,
      }
      const { code, data, msg } = await shop_account_list(params)
      if (code != 200 || data === null) {
        message.error(msg)
        return
      }
      setShopAccentList(data.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 打开门店小程序侧边栏
   */
  const onHandOpen = (info) => {
    const { shopId } = info
    setShopId(shopId)
    setOpen(true)
    //调用查询门店下的主账号接口
    getShopAccountList(shopId)
  }

  /**
   * 编辑门店，打开Modal
   */
  const onHandOpenModal = (info) => {
    console.log(info)
    setEditShopOpen(true)
    const params = {
      name: info?.shopName,
      id: info?.shopId,
      codeList: [],
    }
    setFormValuie(params)
    // 门店下设备列表接口
    getDeviceList(params)
    //设备列表
    getAllDeviceList()
  }

  /**
   * 编辑门店，确定按钮
   */
  const onSubmit = async () => {
    try {
      if (!FormValuie.name) {
        message.warning("请输入门店名称")
        return
      }
      if (FormValuie.codeList.length == 0) {
        message.warning("请选择关联设备")
        return
      }
      const { code, msg, data } = await Edit_Shop(FormValuie)
      if (code != 200) {
        message.error(msg)
      }
      if (data) {
        message.success("编辑门店成功")
        setEditShopOpen(false)
        setFormValuie({
          name: undefined,
          id: undefined,
          codeList: [],
        })
        getShopList() //门店列表接口
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 批量删除
   */
  const onManyDelete = async () => {
    try {
      const ids = selectedRowKeys
      console.log(ids)
      const { code, data, msg } = await shop_batch_delete(ids)
      if (code != 200) {
        message.warning(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        getShopList() //门店列表接口
        setDelShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 关闭门店小程序侧边栏
   */
  const onClose = () => {
    setOpen(false)
  }

  /**
   * 打开添加账号Modal
   */
  const onAddAccent = () => {
    setAddAccentOpen(true)
    form.resetFields()
  }

  /**
   * 关闭添加账号Modal
   */
  const handCloseModal = () => {
    setAddAccentOpen(false)
  }

  /**
   *添加账号Modal确认按钮
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.shopId = shopId
        add_shop_account(values).then((res) => {
          if (res.code != 200) {
            return
          }
          if (res.data) {
            message.success("门店账号添加成功")
            //调用查询门店下的主账号接口
            getShopAccountList(shopId)
            setAddAccentOpen(false)
          }
        })
      })
      .catch((err) => {
        // 校验失败
      })
  }

  /**
   * 删除账号
   */
  const shopAccect = async (info) => {
    const { id } = info
    try {
      const params = {
        id: id,
      }
      const { code, msg, data } = await delete_shop_account(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("删除成功")
        //调用查询门店下的主账号接口
        getShopAccountList(shopId)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const handlSearch = () => {
    //点击查询时将子集关闭，排除数据污染
    setExpandedRowKeys([])
    getShopList(1) //门店列表接口，默认为1页码
    setPageNum(1) //页码为1
  }
  // 重置
  const handClearn = () => {
    //点击查询时将子集关闭，排除数据污染
    setExpandedRowKeys([])
    getShopList(1) //门店列表接口，默认为1页码
    setPageNum(1) //页码为1
    setCode("")
    setTimeout(getShopList, 0)
    setSelectedRowKeys([])
  }

  /**
   * 门店列表接口
   */
  const getShopList = (info) => {
    let _code = codeRef.current
    const params = {
      code: _code,
      pageNum: info ? info : pageNum,
      pageSize: pageSize,
    }
    setLoading(true)
    Shop_List(params)
      .then((res) => {
        if (res.code == 200) {
          setLoading(false)
          setShopList(res.data)
        }
      })
      .catch((err) => {
        setLoading(false)
        setShopList([])
      })
  }
  //跳转设备管理
  const handJumo = (info, rowInfo) => {
    if (info && info > 0) {
      window.localStorage.setItem("shopId", rowInfo?.shopId || "")
      props.history.push({
        pathname: "/admin/storemanage/equipmentmanage",
      })
    }
  }
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${shopList?.total}条`,
      pageSize: pageSize,
      current: pageNum,
      total: shopList?.total,
      onChange: (current) => {
        setPageNum(current)
        getShopList(current)
      },
    }
    return paginationProps
  }
  /**
   * 门店下设备列表接口
   */
  const getDeviceList = async (info) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 100000,
        keyCode: "",
        productLineId: "",
        productLineTypeId: "",
        shopId: info?.id,
      }
      const { code, msg, data } = await Devices_List(params)
      if (code != 200) {
        message.warning(msg)
        return
      }
      if (data && data.list) {
        const NewArr = []
        data.list.map((v) => {
          NewArr.push(v.cdKeyCode)
        })

        setFormValuie({ ...info, codeList: NewArr })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 所有设备
   */
  const getAllDeviceList = async () => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 100000,
        keyCode: "",
        productLineId: "",
        productLineTypeId: "",
        shopId: "",
      }
      const { code, msg, data } = await Devices_List(params)
      if (code != 200) {
        message.warning(msg)
        return
      }
      if (data && data.list) {
        setAllDevice(data.list)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 打开modal
  const showModal = (info) => {
    const { shopId, shopAccount } = info
    //判断，当shopAccount有值时为门店账号编辑，否则为添加
    if (shopAccount) {
      setModaltext("编辑")
      setAccount(shopAccount) //编辑时，数据回显
    } else {
      setModaltext("添加")
      setAccount("") //添加门店账号时，保险起见先清除一次输入框
    }
    //存储数据的shopid
    setShopId(shopId || "")
    setIsModalOpen(true)
  }
  //modal确认按钮
  const handleOk = () => {
    if (account.length < 11 && account.length > 0) {
      message.error("请输入11位门店账号")
      return false
    }
    const params = {
      account: account,
      shopId: shopId,
    }
    Add_Account(params).then((res) => {
      if (res.code == 200 && res.data) {
        setShopId("") //清除shopid
        setAccount("") //清除门店账号
        getShopList() //门店列表接口
        setIsModalOpen(false)
        message.success(`门店账号${modaltext}成功`)
      }
    })
  }
  //关闭modal
  const handleCancel = () => {
    setShopId("") //清除shopid
    setAccount("") //清除门店账号
    setModaltext("") //清除modal-title
    setIsModalOpen(false)
  }

  //父级，门店列表接口
  const columns = [
    {
      title: "门店名称",
      dataIndex: "shopName",

      key: "shopName",
      ellipsis: { showTitle: true },
    },
    {
      title: "设备数",
      dataIndex: "deviceNum",

      ellipsis: { showTitle: true },
      key: "deviceNum",
      width: 100,
      render: (info, rowInfo) => {
        return (
          <a
            style={{ color: "#19bc89" }}
            onClick={() => handJumo(info, rowInfo)}
          >
            {info}
          </a>
        )
      },
    },
    {
      title: "TOP1",
      dataIndex: "top1",

      ellipsis: { showTitle: true },
      key: "top1",
      width: 100,
      render: (info) => {
        return <>{info > 0 ? (info * 100).toFixed(2) + " %" : 0}</>
      },
    },
    {
      title: "识别率",
      dataIndex: "aiOkRate",

      ellipsis: { showTitle: true },
      key: "aiOkRate",
      width: 100,
      render: (info) => {
        return <>{info > 0 ? (info * 100).toFixed(2) + " %" : 0}</>
      },
    },
    {
      title: "使用量",
      dataIndex: "total",

      ellipsis: { showTitle: true },
      key: "total",
      width: 100,
    },
    {
      title: "搜索占比",
      dataIndex: "optSearchRate",

      ellipsis: { showTitle: true },
      key: "optSearchRate",
      width: 100,
      render: (info) => {
        return <>{info > 0 ? (info * 100).toFixed(2) + " %" : 0}</>
      },
    },
    {
      title: "创建时间",
      dataIndex: "createDate",

      ellipsis: { showTitle: true },
      key: "createDate",
    },
    {
      title: "操作",

      ellipsis: { showTitle: true },
      fixed: "right",
      render: (info) => {
        return (
          <>
            <Button
              type="link"
              style={{ color: "#19BC89" }}
              onClick={() => onHandOpen(info)}
            >
              门店账号
            </Button>
            <Button
              type="link"
              style={{ color: "#19BC89" }}
              onClick={() => onHandOpenModal(info)}
            >
              编辑门店
            </Button>
          </>
        )
      },
    },
  ]

  const shopAccentParentColumns = [
    {
      title: "用户名",
      dataIndex: "nickname",
      key: "nickname",

      ellipsis: { showTitle: true },
    },
    {
      title: "手机号",
      dataIndex: "username",
      key: "username",

      ellipsis: { showTitle: true },
    },
    {
      title: "操作",

      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <Popconfirm
            placement="bottom"
            title="确认要删除该账号吗?"
            onConfirm={() => {
              shopAccect(info)
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button type="link" style={{ color: "#19BC89" }}>
              删除
            </Button>
          </Popconfirm>
        )
      },
    },
  ]

  /**
   * 表格选中功能
   */
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  return (
    <div>
      <div className="page_shopmanage_store_head">
        <Input
          style={{ width: "200px" }}
          value={code}
          placeholder="门店账号/门店名称/激活码"
          onChange={(e) => {
            setCode(e.target.value)
          }}
        />
        <div>
          {" "}
          <Button type="primary" onClick={handlSearch}>
            查询
          </Button>
          <Button onClick={handClearn}>重置</Button>
        </div>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Button
          type="primary"
          onClick={() => {
            setDelShow(true)
          }}
          disabled={selectedRowKeys.length == 0 ? true : false}
        >
          批量删除
        </Button>
      </div>
      <div>
        <Table
          columns={columns}
          pagination={onTableChange()}
          rowSelection={rowSelection}
          dataSource={shopList?.list ? shopList?.list : []}
          rowKey="shopId"
          loading={loading}
          scroll={{ x: "max-content", y: window.screen.height * 0.5 }}
        />
      </div>

      {/* 添加、编辑门店账号 */}
      <Modal
        width={500}
        title={modaltext + "门店账号"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确认"
        cancelText="取消"
      >
        <div className="page_store_modal">
          门店账号：
          <Input
            placeholder="请输入门店账号"
            maxLength={11}
            value={account}
            onChange={(e) => {
              setAccount(e.target.value)
            }}
          />
        </div>
      </Modal>

      {/* 门店小程序侧边栏 */}
      <Drawer
        title="门店小程序账号"
        placement="right"
        onClose={onClose}
        open={open}
        keyboard
        width={"30%"}
      >
        <div className="shop_accent_title">
          <h3>账号</h3>
          <Button type="primary" onClick={() => onAddAccent()}>
            添加
          </Button>
        </div>
        <Table
          dataSource={shopAccentList}
          columns={shopAccentParentColumns}
          rowKey="id"
        />
      </Drawer>

      {/* 门店小程序添加账号Modal */}
      <Modal
        width={500}
        title="添加门店账号"
        open={addAccentOpen}
        onOk={onFinish}
        onCancel={handCloseModal}
        okText="确认"
        cancelText="取消"
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="用户名"
            name="nickname"
            rules={[
              {
                required: true,
                message: "用户名不能为空!",
              },
            ]}
          >
            <Input
              placeholder="请输入用户名！"
              style={{ width: "200px" }}
              maxLength={15}
            />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="username"
            rules={[
              {
                required: true,
                message: "手机号不能为空!",
              },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: "请输入正确的手机号！",
              },
            ]}
          >
            <Input
              placeholder="请输入手机号！"
              style={{ width: "200px" }}
              maxLength={11}
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* 编辑门店 */}
      <Modal
        title="编辑门店"
        open={EditShopOpen}
        keyboard
        maskClosable
        onOk={() => onSubmit()}
        onCancel={() => {
          setEditShopOpen(false)
        }}
      >
        <div className="shop_accent_modal">
          <div className="shop_accent_input_title">
            <span style={{ color: "#F56C6C" }}>*</span> 门店名称：
            <Input
              placeholder="请输入门店名称"
              style={{ width: "200px" }}
              value={FormValuie?.name}
              showCount
              maxLength={20}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, name: e.target.value })
              }}
            />
          </div>
          <div className="shop_accent_select">
            <span style={{ color: "#F56C6C" }}>*</span> 关联设备：
            <Select
              mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择关联设备"
              style={{ width: 200 }}
              allowClear
              value={FormValuie?.codeList}
              showSearch
              filterOption={(i, o) => o.props.value.includes(i)}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, codeList: e })
              }}
            >
              {AllDevice.map((item, index) => {
                return (
                  <Option value={item?.cdKeyCode} key={index}>
                    {item?.skuName}
                  </Option>
                )
              })}
            </Select>
          </div>
        </div>
      </Modal>

      {/* 删除二次提示框 */}
      <Modal
        open={DelShow}
        destroyOnClose
        keyboard
        title="系统提示"
        onCancel={() => {
          setDelShow(false)
        }}
        onOk={() => onManyDelete()}
      >
        <div className="shop_accent_delete">
          <QuestionCircleOutlined className="shop_accent_delete_icon" />
          删除后不可恢复，确定删除选中门店吗？
        </div>
      </Modal>
    </div>
  )
}

export default Index
