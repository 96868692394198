import React, { useEffect, useState, useRef } from "react"
import * as DataShareServer from "../../services/data_share"
import { useHistory } from "react-router-dom"
import { Table, Button, Drawer, Spin, Card, message } from "antd"
import styles from "./index.module.css"
function Index(props) {
  const history = useHistory()
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [showInfo, setShowInfo] = useState()
  const [open, setOpen] = useState(false)
  const [learnList, setLearnList] = useState([])
  const [total, setTotal] = useState(0)
  const [imgList, setImgList] = useState([])
  const [loadingList, setLoadingList] = useState(false)
  const [loadingImg, setLoadingImg] = useState(false)
  const [GiveParams, setGiveParams] = useState({
    pageNo: 1,
    pageSize: 20,
    deviceSn: window.localStorage.getItem("deviceSn"),
  })
  // 保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })
  // 每次值变化时都会触发更新
  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    const NewInfo = history?.location?.search
      .split("?")[1]
      .split("$")[0]
      .split("=")[1]
    setShowInfo(decodeURI(NewInfo))

    getLearnList()
  }, [])

  /**
   * 自定义表格高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  //列表
  const getLearnList = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoadingList(true)
      const { code, data, msg } = await DataShareServer.Learn_List(
        GiveParamsRef
      )
      if (code != 200) {
        message.error(msg)
        setLoadingList(false)
        return
      }
      setLoadingList(false)
      setLearnList(data?.goodsRsps || [])
      setTotal(data?.total || 0)
    } catch (error) {
      console.log(error)
      setLoadingList(false)
    }
  }
  // 图片
  const getImgList = async (info) => {
    try {
      const params = {
        count: info?.featLength,
        skuCode: info?.skuCode,
        deviceSn: GiveParams?.deviceSn,
        limit: 10,
      }
      setLoadingImg(true)
      const { code, data, msg } = await DataShareServer.img_List(params)
      if (code != 200) {
        message.error(msg)
        setLoadingImg(false)
        return
      }
      if (data) {
        setLoadingImg(false)
        setImgList(data)
      }
    } catch (error) {
      console.log(error)
      setLoadingImg(false)
    }
  }
  const columns = [
    {
      title: "商品编码",
      dataIndex: "skuCode",

      key: "skuCode",
      ellipsis: { showTitle: true },
    },
    {
      title: "商品名称",
      dataIndex: "skuName",

      key: "skuName",
      ellipsis: { showTitle: true },
    },
    {
      title: "学习数据图片/张",
      dataIndex: "featLength",

      key: "featLength",
      ellipsis: { showTitle: true },
      render: (info, rowInfo) => {
        return (
          <div
            onClick={() => {
              setOpen(true)
              getImgList(rowInfo)
            }}
            style={{ color: "#19BC89", cursor: "pointer" }}
          >
            {" "}
            {info}
          </div>
        )
      },
    },
  ]
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams?.pageSize,
      current: GiveParams?.pageNo,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNo: current,
        })
        setTimeout(getLearnList, 0)
      },
    }
    return paginationProps
  }

  const onClose = () => {
    setOpen(false)
    setImgList([])
  }
  return (
    <Card>
      <div className={styles.view_data_title}>
        <div> 已选择：{showInfo}</div>
      </div>
      <Table
        pagination={onTableChange()}
        dataSource={learnList}
        columns={columns}
        loading={loadingList}
        rowKey="skuCode"
        scroll={{ y: `${WinHeight - 350}px` }}
      />
      <Drawer
        width={500}
        title="查看学习数据"
        placement="right"
        onClose={onClose}
        open={open}
        loading={loadingImg}
      >
        {loadingImg ? (
          <div className={styles.loading}>
            <Spin size="large" />
          </div>
        ) : (
          <div className={styles.view_data_body}>
            {imgList.map((v) => {
              return <img src={v} />
            })}
          </div>
        )}
      </Drawer>
    </Card>
  )
}

export default Index
